export const customer = {
  get: 'customerEndpoint/getCustomer',
  create: 'customerEndpoint/createCustomer',
  createCallCenter: 'customerEndpoint/createCustomerCallCenter',
  login: 'customerEndpoint/login',
  loginPost: 'customerEndpoint/loginPost',
  loginGoogle: 'customerEndpoint/loginGoogle',
  loginFacebook: 'customerEndpoint/loginFacebook',
  loginFirebase: 'customerEndpoint/loginFirebase',
  deleteDevice: 'customerEndpoint/deleteDevice', // LogOut
  validate: 'customerEndpoint/verifyCustomerV2',
  validateToken: 'customerEndpoint/validateCodeLogin',
  validateSmsToken: 'customerEndpoint/getValidationTokenPhone',
  validateSmsTokenFinal: 'customerEndpoint/getValidationTokenPhoneFinal',
  validateWhatsapp: 'whatsApp/send',
  resetPassword: 'customerEndpoint/resetPassword',
  changePassword: 'customerEndpoint/changePassword',
  changePasswordV2: 'customerEndpoint/changePasswordV2',
  update: 'customerEndpoint/updateCustomerOnly',
  interests: 'customerEndpoint/createCustomerInterests',
  getUserOrigin: 'customerEndpoint/getUserOrigin',
  getCustomerByPhoneNumberOrDocumentNumber: 'customerEndpoint/getCustomerCallCenter',
  getCustomerByDocumentId: 'crm/v3/customer/search',
  getCustomerLogin: 'customerEndpoint/getCustomerLoginV2',
  sendMessage: 'customerEndpoint/sendCodeLogin',
  customerOnly: 'customerEndpoint/customerOnly',
};
