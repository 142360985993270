import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {sasCartApi} from '../../../api-v3/sas-subscription/sas-cart.api';
import {RequestService} from '../../../../handlers/request/request.service';
import {CreateCart, CreateCartDetail, MergeCart} from '../../../models-v3/sas-subscription/requests/cart';
import {globalEnv} from '../../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class SasCartService {
  constructor(private req: RequestService) { }

  create(data: CreateCart): Observable<any> {
    return this.req.post(`${globalEnv.sas}${sasCartApi.cart}`, data, null, null, false);
  }

  createDetail(data: CreateCartDetail): Observable<any> {
    return this.req.post(`${globalEnv.sas}${sasCartApi.cartDetail}`, data, null, null, false);
  }

  delete(cartId): Observable<any> { // Cart Id
    return this.req.delete(`${globalEnv.sas}${sasCartApi.cart}`, cartId, null, null, false);
  }

  getBilling(id, params): Observable<any> { // Anonymous => UUID, Customer => Id
    const type = Number.isNaN(id) ? '/anonymous' : '/customer';
    return this.req.get(`${globalEnv.sas}${sasCartApi.billing}${type}`, params, id, null, false);
  }

  getCartById(cartId): Observable<any> { // Cart Id
    return this.req.get(`${globalEnv.sas}${sasCartApi.cart}`, null, cartId, null, false);
  }

  getCartByCustomerId(customerId): Observable<any> { // Customer Id
    const api = `${sasCartApi.cart}/customer`;
    return this.req.get(`${globalEnv.sas}${api}`, null, customerId, false, false);
  }

  getCartByUuid(uuid): Observable<any> { // Anonymous UUID
    return this.req.get(`${globalEnv.sas}${sasCartApi.cart}/anonymous`, null, uuid, null, false);
  }

  getCartTotalAmount(cartId): Observable<any> {
    const api = sasCartApi.cartAmount.replace(':cartId', cartId);
    return this.req.get(`${globalEnv.sas}${api}`);
  }

  getThermometer(cartId): Observable<any> {
    const api = sasCartApi.thermometer.replace(':cartId', cartId);
    return this.req.get(`${globalEnv.sas}${api}`, null, null, null, false);
  }

  merge(data: MergeCart): Observable<any> {
    return this.req.put(`${globalEnv.sas}${sasCartApi.mergeAnonymousCart}`, data, null, null, false, false);
  }

  validateProductInCart(cartId, productId): Observable<any> {
    const api = sasCartApi.validateProductInCart
      .replace(':cartId', cartId)
      .replace(':productId', productId);
    return this.req.get(api);
  }
}
