export const algoliaVzlaEnv = {
  hitsPerPage: '24',
  envs: {
    prod: {
      id: 'VCOJEYD2PO',
      key: 'e6f5ccbcdea95ff5ccb6fda5e92eb25c',
      index: 'prod-vzla'
    },
    dev: {
      id: 'VCOJEYD2PO',
      key: 'e6f5ccbcdea95ff5ccb6fda5e92eb25c',
      index: 'dev-vzla'
    },
    sandbox: { // no existe vzla
      id: 'VCOJEYD2PO',
      key: 'e6f5ccbcdea95ff5ccb6fda5e92eb25c',
      index: 'dev-vzla'
    },
  },
  index: ['prod-vzla', 'properties']
};
