import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateCartEvent {
  private agileCart = new BehaviorSubject(null);
  private cart = new BehaviorSubject(null);
  updatedAgileCart = this.agileCart.asObservable();
  updatedCart = this.cart.asObservable();

  changeAgileCart(cart: any) {
    this.agileCart.next(cart);
  }

  changeCart(cart: any) {
    this.cart.next(cart);
  }
}
