import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {creditCard} from '../../api/customer/credit-card.api';
import {RequestService} from '../../../handlers/request/request.service';

import {
  Default,
  Delete,
  Validate,
} from '../../models/customer/requests/credit-card';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class CreditCardService {
  constructor(private req: RequestService) {}

  create(data): Observable<any> {
    return this.req.post(`${globalEnv.gateway}${creditCard.create}`, data, null, null, false);
  }

  get(id): Observable<any> {
    return this.req.get(`${globalEnv.be2}${creditCard.get}`, id, null, true);
  }

  consultBin(creditCardNumber): Observable<any> {
    return this.req.get(`${globalEnv.gateway}${creditCard.bin}`, null, null, null, null, false, creditCardNumber);
  }

  validateOrders(validationData: Validate): Observable<any> {
    return this.req.get(`${globalEnv.be2}${creditCard.validateOrders}`, validationData, null, true);
  }

  validateSubscription(params: Delete): Observable<any> {
    return this.req.delete(`${globalEnv.be2}${creditCard.deleteCreditCard}`, null, params, true);
  }

  delete(params: Delete): Observable<any> {
    return this.req.delete(`${globalEnv.be2}${creditCard.delete}`, null, params, true);
  }

  default(params: Default): Observable<any> {
    return this.req.put(`${globalEnv.be2}${creditCard.default}`, {}, params, null, true);
  }
}
