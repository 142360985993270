import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DepartmentManager} from '../../data/manager/department/department.manager';
import {CmsManager} from '../../data/manager/cms/cms.manager';

@Injectable({
  providedIn: 'root',
})
export class DepartmentVm {
  constructor(
    private departmentManager: DepartmentManager,
    private cmsManager: CmsManager,
  ) {}

  getDepartment(): Observable<any> {
    return this.departmentManager.get();
  }

  getBannersByDepartment(params): Observable<any> {
    return this.cmsManager.getBanners(params);
  }

  getBestSeller(params): Observable<any> {
    return this.departmentManager.getBestSeller(params);
  }

  getCategoryProductsFromAlgolia(params): Observable<any> {
    return this.departmentManager.getCategoryProductsFromAlgolia(params);
  }

  getSubcategoriesAndFilters(params): Observable<any> {
    return this.departmentManager.getSubcategoriesAndFilters(params);
  }

  getProductsFromFilter(data): Observable<any> {
    return this.departmentManager.getProductsFromFilter(data);
  }
}
