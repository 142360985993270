import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateBuyAndGetModalEvent {
  private data = new Subject();
  buyAndGetProduct = this.data.asObservable();

  updateProductBuyAndGet(data: any) {
    this.data.next(data);
  }
}
