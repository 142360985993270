import {Injectable} from '@angular/core';
import * as algoliasearchProxy from 'algoliasearch/lite';
import {RequestService} from 'src/app/data/handlers/request/request.service';
import {Observable} from 'rxjs';
import {AlgoliaSearchInterface} from '../../models/algolia/requests/algolia';
import {countryConfig} from '../../../../../country-config/country-config';
import {globalEnv} from '../../connection/global/global.env';
import {algoliaEnv} from '../../../../../environments/colombia/algolia.env';

const algoliasearch = algoliasearchProxy.default || algoliasearchProxy;

@Injectable({
  providedIn: 'root',
})
export class AlgoliaService {
  constructor(private req: RequestService) { }
  env = countryConfig.algolia; // environment.algolia.envs.prod;
  client = algoliasearch(this.env.id, this.env.key);
  index = this.client.initIndex(this.env.index);

  searchText(data: AlgoliaSearchInterface): Promise<any> {
    let objSend = Object.assign(data);

    if (data.index) {
      this.index.indexName = data.index;
      delete data.index;
    } else {
      this.index.indexName = this.env.index;
    }

    if (data.sendParams) {
      delete objSend.sendParams;
    } else {
      objSend = {};
    }

    return this.index.search(objSend);
  }

  getSeo(id): Observable<any> {
    const headers = {
      'X-Algolia-API-Key': algoliaEnv.envs.prod.key,
      'X-Algolia-Application-Id': algoliaEnv.envs.prod.id,
      noSendCountry: true,
    };
    return this.req.get(`${globalEnv.alg}1/indexes/items_seo/${id}`, null, null, null, null, false, headers);
  }

  algoliaConfigurationPaymentMethod(): Observable<any> {
    const headers = {
      'X-Algolia-API-Key': algoliaEnv.envs.prod.key,
      'X-Algolia-Application-Id': algoliaEnv.envs.prod.id,
      noSendCountry: true,
    };
    return this.req.get(`${globalEnv.alg}1/indexes/properties/CREDIT_CARD.CREATION_CONFIG_DEV`, null, null, null, null, false, headers);
  }
}
