import {Injectable} from '@angular/core';
import {ScanAndGoService} from '../../networking/services/scan-and-go/scan-and-go.service';
import {Observable} from 'rxjs';
import {CustomerManager} from '../customer/customer/customer.manager';
import {ScanAndGoRatingInterface} from '../../networking/models/scan-and-go/request/scan-and-go';

@Injectable({
  providedIn: 'root',
})
export class ScanAndGoManager {
  constructor(private scanAndGoService: ScanAndGoService,
              private customerManager: CustomerManager) { }

  getScanAngGo(): Observable<any> {
    return this.scanAndGoService.getScanAngGo();
  }

  saveSelfie(photo) {
    return this.scanAndGoService.saveSelfie(photo);
  }

  rate({rating, comments}): Observable<any> {
    const data: ScanAndGoRatingInterface = {
      // OJO: Se deja el disabled porque 'module' es una palabra reservada para JavaScript
      // tslint:disable
      'module': 'SAG',
      qualification: rating,
      commentary: comments || '',
      customer: this.customerManager.customer.id.toString(),
    };
    return this.scanAndGoService.rate(data);
  }
}
