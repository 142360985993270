import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {LocalStorageManager} from '../../manager/local-storage/local-storage.manager';

@Injectable({
  providedIn: 'root',
})
export class SessionGuard implements CanActivate {
  constructor(private router: Router,
              private lsm: LocalStorageManager) { }

  canActivate(): boolean {
    const customer = this.lsm.get('customer');
    // console.log('ROUTE GUARD', {customer});
    if (!customer || customer.isAnonymous) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
