<span *ngIf="type === 'arrow-fill'">
  <div class="arrow-fill-content" [class]="custom">
    <div class="arrow-fill" [ngStyle]="{'border-top-color': color }"></div>
  </div>
</span>

<span *ngIf="type === 'arrow-simple'">
  <div class="arrow-simple-content" [class]="custom">
    <div class="arrow-simple">
      <div class="line-one arrow" [ngStyle]="{'background-color': color }"></div>
      <div class="line-two arrow" [ngStyle]="{'background-color': color }"></div>
    </div>
  </div>
</span>

<span *ngIf="type === 'search'">
  <div class="search-content">
  <div class="search">
    <div class="circle" [ngStyle]="{'border-color': color }"></div>
    <div class="line" [ngStyle]="{'background-color': color }"></div>
  </div>
  </div>
</span>

<span *ngIf="type === 'close'">
  <div class="close">
    <div class="line-one" [ngStyle]="{'background-color': color }"></div>
    <div class="line-two" [ngStyle]="{'background-color': color }"></div>
  </div>
</span>

<span *ngIf="type === 'check'">
  <div class="check-content" [class]="custom">
    <div class="check" [ngStyle]="{'background-color': color }">
      <div class="line-one"></div>
      <div class="line-two"></div>
    </div>
  </div>
</span>