import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {department} from '../../api/department/department.api';
import {RequestService} from '../../../handlers/request/request.service';
import {BestSeller} from '../../models/department/requests/best-seller';
import {GetProductsFromAlgolia} from '../../models/department/requests/get-products-from-algolia';
import {CategoryId} from '../../models/department/requests/category-id';
import {ProductFromFilter} from '../../models/department/requests/product-from-filter';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  constructor(private req: RequestService) { }

  get(): Observable<any> {
    return this.req.get(`${globalEnv.be2}${department.getDepartments}`, null, null, true);
  }

  getCategoryProductsFromAlgolia(params: GetProductsFromAlgolia): Observable<any> {
    return this.req.get(`${globalEnv.be2}${department.getCategoryProductsFromAlgolia}`, params, null, true);
  }

  getBestSeller(params: BestSeller): Observable<any> {
    return this.req.get(`${globalEnv.be2}${department.bestSeller}`, params, null, true);
  }

  getSubcategoriesAndFilters(params: CategoryId): Observable<any> {
    return this.req.get(`${globalEnv.be2}${department.getSubcategoriesAndFilters}`, params, null, true);
  }

  getProductsFromFilter(data: ProductFromFilter): Observable<any> {
    return this.req.post(`${globalEnv.be2}${department.getProductsFromFilter}`, data, null, false);
  }
}
