import {State, Action, StateContext, StateOperator} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  SaveDeliveryAddressAction,
  SaveAddressesAction,
  CreateAddressAction,
  UpdateAddressAction,
  DeleteAddressAction,
} from '../actions/address.actions';
import {UpdateAddressEvent} from '../../ui/shared/event-listeners/update-address/update-address.event';

function createAddress(payload): StateOperator<any> {
  const createdAddress = payload.address;
  // console.log('createdAddress:', createdAddress);
  return state => {
    // console.log('>>>state:', state);
    let existingAddress = false;
    let addresses = !state.addresses || !state.addresses ? [] : state.addresses
      .map(address => {
        if (address.idAddress === createdAddress.idAddress) {
          address = {...createdAddress};
          existingAddress = true;
        }
        return address;
      });

    if (!existingAddress) {
      addresses = [...addresses, createdAddress];
    }

    return {
      ...state,
      addresses: [
        ...addresses,
      ],
    };
  };
}

function updateAddress(payload): StateOperator<any> {
  const updatedAddress = payload.address;
  // console.log('updatedAddress:', updatedAddress);
  return state => {
    // console.log('>>>state:', state);
    const addresses = !state.addresses || !state.addresses ? [] : state.addresses
      .map(address => {
        if (address.idAddress === updatedAddress.idAddress) {
          address = {...updatedAddress};
        }
        return address;
      });

    return {
      ...state,
      addresses: [
        ...addresses,
      ],
    };
  };
}

function removeAddress(payload): StateOperator<any> {
  const addressIdToRemove = payload.addressId;
  // console.log('addressIdToRemove:', addressIdToRemove);
  return state => {
    // console.log('>>>state:', state);
    const addresses = !state.addresses || !state.addresses ? [] : state.addresses
      .map(address => {
        if (address.idAddress.toString() !== addressIdToRemove.toString()) {
          return address;
        }
        return null;
      }).filter(address => !!address);

    return {
      ...state,
      addresses: [...addresses],
    };
  };
}

@State({
  name: 'addressStore',
  defaults: {
    addresses: [],
  },
})
@Injectable()
export class AddressState {
  constructor(private addressEvent: UpdateAddressEvent) {}

  @Action(SaveAddressesAction)
  SaveAddressesAction(ctx: StateContext<any>, action: SaveAddressesAction) {
    const state = ctx.getState();
    // console.log('SaveAddressesAction State =>', state);
    // console.log('SaveAddressesAction action =>', action);
    ctx.setState({...state,
      addresses: [
        ...action.addresses,
      ]});
  }

  @Action(CreateAddressAction)
  CreateAddressAction(ctx: StateContext<any>, action: CreateAddressAction) {
    // const state = ctx.getState();
    // console.log('CreateAddressAction State =>', state);
    // console.log('CreateAddressAction action =>', action);
    ctx.setState(createAddress(action));
  }

  @Action(SaveDeliveryAddressAction)
  SaveDeliveryAddressAction(ctx: StateContext<any>, action: SaveDeliveryAddressAction) {
    const state = ctx.getState();
    // console.log('SaveDeliveryAddressAction State =>', state);
    // console.log('SaveDeliveryAddressAction action =>', action);
    ctx.setState({...state,
      addresses: [
        ...state.addresses,
        action.address, // Custom state management to set as default
      ]});
    this.addressEvent.changeAddresses(true);
    // console.log('SaveDeliveryAddressAction State =>', ctx.getState());
  }

  @Action(UpdateAddressAction)
  UpdateAddressAction(ctx: StateContext<any>, action: UpdateAddressAction) {
    // console.log('UpdateAddressAction State =>', state);
    // console.log('UpdateAddressAction action =>', action);
    ctx.setState(updateAddress(action));
    // const state = ctx.getState();
    // this.addressEvent.changeAddresses(true);
  }

  @Action(DeleteAddressAction)
  DeleteAddressAction(ctx: StateContext<any>, action: DeleteAddressAction) {
    // console.log('DeleteAddressAction action =>', action);
    ctx.setState(removeAddress(action));
    // const state = ctx.getState();
    // console.log('DeleteAddressAction State =>', state);
  }
}
