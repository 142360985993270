import {Component, OnInit, NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {countryConfig} from 'src/country-config/country-config';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-navbar-ssr',
  templateUrl: './navbar-ssr.component.html',
  styleUrls: ['./navbar-ssr.component.scss'],
})
export class NavbarSsrComponent implements OnInit {
  arrayDepartments = [{
    "id": "1",
    "name": "Salud y medicamentos",
    "url": "http://lh3.googleusercontent.com/nB3jhpuGy3Tmw9FHXmE8PvkheE9PowhhPAnhejuT0ZfKnAOp08UlKwnCzH4nKcjM2KaFWJgPEFyTJo600tvZ-6aEmD1rJ_YuWOA",
    "primaryColor": "#84D24C",
    "children": [{
        "id": "3",
        "name": "Alivio del dolor",
        "children": [{
            "id": "14",
            "name": "Dolor general",
            "nameUrl": "dolor-general"
          },
          {
            "id": "15",
            "name": "Dolor fuerte",
            "nameUrl": "dolor-fuerte"
          },
          {
            "id": "16",
            "name": "Dolor abdominal y cólico",
            "nameUrl": "dolor-abdominal-y-colico"
          },
          {
            "id": "17",
            "name": "Muscular y articular",
            "nameUrl": "muscular-y-articular"
          },
          {
            "id": "18",
            "name": "Niños",
            "nameUrl": "ninos"
          },
          {
            "id": "19",
            "name": "Tratamiento de varices",
            "nameUrl": "tratamiento-de-varices"
          }
        ],
        "nameUrl": "alivio-del-dolor"
      },
      {
        "id": "8",
        "name": "Botiquín y primeros auxilios",
        "children": [{
            "id": "20",
            "name": "Curitas, algodones, adhesivos y botiquín",
            "nameUrl": "curitas-algodones-adhesivos-y-botiquin"
          },
          {
            "id": "21",
            "name": "Antisépticos",
            "nameUrl": "antisepticos"
          },
          {
            "id": "22",
            "name": "Compresas",
            "nameUrl": "compresas"
          },
          {
            "id": "23",
            "name": "Vendas",
            "nameUrl": "vendas"
          },
          {
            "id": "24",
            "name": "Jeringas, guantes y material hosp.",
            "nameUrl": "jeringas-guantes-y-material-hosp"
          },
          {
            "id": "25",
            "name": "Aparatos médicos y pastilleros",
            "nameUrl": "aparatos-medicos-y-pastilleros"
          }
        ],
        "nameUrl": "botiquin-y-primeros-auxilios"
      },
      {
        "id": "13",
        "name": "Cuidado de la vista",
        "children": [{
            "id": "26",
            "name": "Solución para lentes de contacto",
            "nameUrl": "solucion-para-lentes-de-contacto"
          },
          {
            "id": "27",
            "name": "Gotas, lubricantes y lágrimas artificiales",
            "nameUrl": "gotas-lubricantes-y-lagrimas-artificiales"
          },
          {
            "id": "28",
            "name": "Gafas de sol",
            "nameUrl": "gafas-de-sol"
          },
          {
            "id": "29",
            "name": "Gafas de lectura",
            "nameUrl": "gafas-de-lectura"
          },
          {
            "id": "451",
            "name": "Lentes de contacto",
            "nameUrl": "lentes-de-contacto"
          }
        ],
        "nameUrl": "cuidado-de-la-vista"
      },
      {
        "id": "10",
        "name": "Cuidado de los pies y antimicóticos",
        "children": [{
            "id": "30",
            "name": "Talcos y desodorantes de pie",
            "nameUrl": "talcos-y-desodorantes-de-pie"
          },
          {
            "id": "31",
            "name": "Antimicótico",
            "nameUrl": "antimicotico"
          },
          {
            "id": "32",
            "name": "Plantillas",
            "nameUrl": "plantillas"
          },
          {
            "id": "33",
            "name": "Apósitos y tratamiento de callos",
            "nameUrl": "apositos-y-tratamiento-de-callos"
          },
          {
            "id": "34",
            "name": "Accesorios para pies",
            "nameUrl": "accesorios-para-pies"
          },
          {
            "id": "35",
            "name": "Refrescantes y humectantes de pies",
            "nameUrl": "refrescantes-y-humectantes-de-pies"
          },
          {
            "id": "36",
            "name": "Antiprurignoso",
            "nameUrl": "antiprurignoso"
          }
        ],
        "nameUrl": "cuidado-de-los-pies-y-antimicoticos"
      },
      {
        "id": "9",
        "name": "Dermatológicos",
        "children": [{
            "id": "37",
            "name": "Antiprurignoso",
            "nameUrl": "antiprurignoso"
          },
          {
            "id": "38",
            "name": "Antimicótico tópico",
            "nameUrl": "antimicotico-topico"
          },
          {
            "id": "39",
            "name": "Protectores dérmicos",
            "nameUrl": "protectores-dermicos"
          },
          {
            "id": "40",
            "name": "Tratamiento de cicatrices y heridas",
            "nameUrl": "tratamiento-de-cicatrices-y-heridas"
          },
          {
            "id": "41",
            "name": "Tratamiento de psoriasis",
            "nameUrl": "tratamiento-de-psoriasis"
          },
          {
            "id": "42",
            "name": "Queratolíticos",
            "nameUrl": "queratoliticos"
          },
          {
            "id": "43",
            "name": "Tratamiento de alopecia",
            "nameUrl": "tratamiento-de-alopecia"
          }
        ],
        "nameUrl": "dermatologicos"
      },
      {
        "id": "5",
        "name": "Droguería",
        "nameUrl": "drogueria"
      },
      {
        "id": "11",
        "name": "Incontinencia",
        "children": [{
            "id": "44",
            "name": "Pañales incontinencia",
            "nameUrl": "panales-incontinencia"
          },
          {
            "id": "45",
            "name": "Otros",
            "nameUrl": "otros"
          }
        ],
        "nameUrl": "incontinencia"
      },
      {
        "id": "7",
        "name": "Nutrición y vida saludable",
        "children": [{
            "id": "46",
            "name": "Alimentos saludables",
            "nameUrl": "alimentos-saludables"
          },
          {
            "id": "47",
            "name": "Artículos deportivos",
            "nameUrl": "articulos-deportivos"
          },
          {
            "id": "48",
            "name": "Nutrición",
            "nameUrl": "nutricion"
          },
          {
            "id": "49",
            "name": "Pastilleros y dosificadores",
            "nameUrl": "pastilleros-y-dosificadores"
          }
        ],
        "nameUrl": "nutricion-y-vida-saludable"
      },
      {
        "id": "12",
        "name": "Rehabilitación y equipos médicos",
        "children": [{
            "id": "50",
            "name": "Vendas",
            "nameUrl": "vendas"
          },
          {
            "id": "51",
            "name": "Calcetines y medias terapeúticas",
            "nameUrl": "calcetines-y-medias-terapeuticas"
          },
          {
            "id": "52",
            "name": "Equipos médicos",
            "nameUrl": "equipos-medicos"
          },
          {
            "id": "53",
            "name": "Accesorios deportivos",
            "nameUrl": "accesorios-deportivos"
          },
          {
            "id": "54",
            "name": "Productos para rehabilitación",
            "nameUrl": "productos-para-rehabilitacion"
          },
          {
            "id": "55",
            "name": "Compresas",
            "nameUrl": "compresas"
          }
        ],
        "nameUrl": "rehabilitacion-y-equipos-medicos"
      },
      {
        "id": "4",
        "name": "Salud digestiva",
        "children": [{
            "id": "56",
            "name": "Antiácidos y antiulcerosos",
            "nameUrl": "antiacidos-y-antiulcerosos"
          },
          {
            "id": "57",
            "name": "Digestivos",
            "nameUrl": "digestivos"
          },
          {
            "id": "58",
            "name": "Antidiarréicos y sueros",
            "nameUrl": "antidiarreicos-y-sueros"
          },
          {
            "id": "59",
            "name": "Laxantes y fibras",
            "nameUrl": "laxantes-y-fibras"
          },
          {
            "id": "60",
            "name": "Antiflatulentos",
            "nameUrl": "antiflatulentos"
          },
          {
            "id": "61",
            "name": "Productos para el mareo",
            "nameUrl": "productos-para-el-mareo"
          }
        ],
        "nameUrl": "salud-digestiva"
      },
      {
        "id": "431",
        "name": "Salud y medicamentos",
        "children": [{
          "id": "432",
          "name": "Droguería",
          "nameUrl": "drogueria"
        }],
        "nameUrl": "salud-y-medicamentos"
      },
      {
        "id": "2",
        "name": "Tratamiento de la gripa",
        "children": [{
            "id": "62",
            "name": "Resfriado y gripa",
            "nameUrl": "resfriado-y-gripa"
          },
          {
            "id": "63",
            "name": "Dolor de garganta",
            "nameUrl": "dolor-de-garganta"
          },
          {
            "id": "64",
            "name": "Tratamiento de la tos",
            "nameUrl": "tratamiento-de-la-tos"
          },
          {
            "id": "65",
            "name": "Descongestionante",
            "nameUrl": "descongestionante"
          },
          {
            "id": "66",
            "name": "Niños",
            "nameUrl": "ninos"
          }
        ],
        "nameUrl": "tratamiento-de-la-gripa"
      },
      {
        "id": "6",
        "name": "Vitaminas y productos naturales",
        "children": [{
            "id": "67",
            "name": "Vitaminas",
            "nameUrl": "vitaminas"
          },
          {
            "id": "68",
            "name": "Multivitamínicos",
            "nameUrl": "multivitaminicos"
          },
          {
            "id": "69",
            "name": "Naturales",
            "nameUrl": "naturales"
          },
          {
            "id": "70",
            "name": "Minerales",
            "nameUrl": "minerales"
          }
        ],
        "nameUrl": "vitaminas-y-productos-naturales"
      }
    ],
    "link": "salud-y-medicamentos",
    "nameUrl": "salud-y-medicamentos",
    "classColor": "green"
  },
  {
    "id": "72",
    "id2": 2,
    "imgLocal": "./assets/svg/categoria-belleza.svg",
    "description": "Consigue todo en productos belleza, labiales, pestañinas,\n         máscaras, polvos, bases, esmaltes, cremas faciales, contornos, estuches y kits.",
    "name": "Belleza",
    "url": "http://lh3.googleusercontent.com/DA5pnHUsfXqzT7fX-4YoHT_4ZIDU5vZtg7rDPcPsMOhrR9Rg0x3N5Ydc66eJ36h7au7_L0i-MZWUzP-ehnQOCELdXagQSehl9wU",
    "primaryColor": "#B14497",
    "images": [{
        "id": "0",
        "url": "http://lh3.googleusercontent.com/7mBs0AydH7SC1hre0f4PFpt999nB4fvZitW7iUTehHEPtrr4yZ5tfuO5g6K5w80BFTd4RPdm_fFnbcNIrqHkzOxLMNflFrfqR-s"
      },
      {
        "id": "0",
        "url": "http://lh3.googleusercontent.com/DrRBocu-JJhMiJ9HMbIO_1bS1uXcGC76gQ9-nrWrJnquw3EDUBCnIRkVW_Hbu8hYP6Jd6R-ZUcUoiuMYSXTRmGgzVm6oEu4CDxk"
      },
      {
        "id": "0",
        "url": "http://lh3.googleusercontent.com/G36i5Q_VO_ISkSVc-dKBbMlOvBWQQAe54vCTwn8eDZrUgjjaker4lFn0U6dbfU_UEeYnVOO7kYaPhO4B38CtV7yJ8CmtVBK6ZMM"
      }
    ],
    "children": [{
        "id": "82",
        "name": "Accesorios de belleza",
        "children": [{
            "id": "91",
            "name": "Ojos",
            "nameUrl": "ojos"
          },
          {
            "id": "92",
            "name": "Rostro y cuerpo",
            "nameUrl": "rostro-y-cuerpo"
          },
          {
            "id": "93",
            "name": "Uñas",
            "nameUrl": "unas"
          },
          {
            "id": "94",
            "name": "Estuches y kits",
            "nameUrl": "estuches-y-kits"
          }
        ],
        "nameUrl": "accesorios-de-belleza"
      },
      {
        "id": "81",
        "name": "Cosméticos",
        "children": [{
            "id": "115",
            "name": "Ojos",
            "nameUrl": "ojos"
          },
          {
            "id": "116",
            "name": "Labios",
            "nameUrl": "labios"
          },
          {
            "id": "117",
            "name": "Bases y polvos",
            "nameUrl": "bases-y-polvos"
          },
          {
            "id": "118",
            "name": "Uñas",
            "nameUrl": "unas"
          },
          {
            "id": "378",
            "name": "Lociones y siliconas",
            "nameUrl": "lociones-y-siliconas"
          }
        ],
        "nameUrl": "cosmeticos"
      },
      {
        "id": "83",
        "name": "Cuidado de la piel",
        "children": [{
            "id": "119",
            "name": "Cuidado solar",
            "nameUrl": "cuidado-solar"
          },
          {
            "id": "120",
            "name": "Cuidado corporal",
            "nameUrl": "cuidado-corporal"
          },
          {
            "id": "121",
            "name": "Cuidado facial",
            "nameUrl": "cuidado-facial"
          }
        ],
        "nameUrl": "cuidado-de-la-piel"
      },
      {
        "id": "391",
        "name": "Dercosmeticos",
        "children": [{
            "id": "411",
            "name": "Cuidado Corporal",
            "nameUrl": "cuidado-corporal"
          },
          {
            "id": "412",
            "name": "Cuidado Capilar",
            "nameUrl": "cuidado-capilar"
          },
          {
            "id": "413",
            "name": "Cuidado Facial",
            "nameUrl": "cuidado-facial"
          },
          {
            "id": "414",
            "name": "Maquillaje",
            "nameUrl": "maquillaje"
          },
          {
            "id": "415",
            "name": "Proteccion Solar",
            "nameUrl": "proteccion-solar"
          }
        ],
        "nameUrl": "dercosmeticos"
      },
      {
        "id": "211",
        "name": "Fragancias",
        "children": [{
          "id": "212",
          "name": "Fragancias",
          "nameUrl": "fragancias"
        }],
        "nameUrl": "fragancias"
      }
    ],
    "link": "belleza",
    "nameUrl": "belleza",
    "classColor": "purple"
  },
  {
    "id": "154",
    "name": "Cuidado del bebé",
    "primaryColor": "#14C5C7",
    "children": [{
        "id": "157",
        "name": "Compotas y cereales",
        "children": [{
            "id": "159",
            "name": "Compotas",
            "nameUrl": "compotas"
          },
          {
            "id": "160",
            "name": "Cereales",
            "nameUrl": "cereales"
          },
          {
            "id": "161",
            "name": "Orgánicos",
            "nameUrl": "organicos"
          },
          {
            "id": "162",
            "name": "Galletas, papillas y yogurts",
            "nameUrl": "galletas-papillas-y-yogurts"
          }
        ],
        "nameUrl": "compotas-y-cereales"
      },
      {
        "id": "156",
        "name": "Fórmulas infantiles",
        "children": [{
            "id": "163",
            "name": "Crecimiento",
            "nameUrl": "crecimiento"
          },
          {
            "id": "164",
            "name": "Prematuros",
            "nameUrl": "prematuros"
          },
          {
            "id": "165",
            "name": "Seguimiento",
            "nameUrl": "seguimiento"
          },
          {
            "id": "166",
            "name": "Especializadas",
            "nameUrl": "especializadas"
          }
        ],
        "nameUrl": "formulas-infantiles"
      },
      {
        "id": "158",
        "name": "Higiene del bebé",
        "children": [{
            "id": "167",
            "name": "Shampoo",
            "nameUrl": "shampoo"
          },
          {
            "id": "168",
            "name": "Jabones",
            "nameUrl": "jabones"
          },
          {
            "id": "169",
            "name": "Acondicionadores",
            "nameUrl": "acondicionadores"
          },
          {
            "id": "170",
            "name": "Fragancias y colonias",
            "nameUrl": "fragancias-y-colonias"
          },
          {
            "id": "171",
            "name": "Cremas",
            "nameUrl": "cremas"
          },
          {
            "id": "172",
            "name": "Aceites",
            "nameUrl": "aceites"
          },
          {
            "id": "173",
            "name": "Talcos",
            "nameUrl": "talcos"
          },
          {
            "id": "174",
            "name": "Accesorios de bebé",
            "nameUrl": "accesorios-de-bebe"
          },
          {
            "id": "175",
            "name": "Copitos",
            "nameUrl": "copitos"
          }
        ],
        "nameUrl": "higiene-del-bebe"
      },
      {
        "id": "155",
        "name": "Pañales y pañitos húmedos",
        "children": [{
            "id": "176",
            "name": "Pañales",
            "nameUrl": "panales"
          },
          {
            "id": "177",
            "name": "Toallitas y pañitos húmedos",
            "nameUrl": "toallitas-y-panitos-humedos"
          }
        ],
        "nameUrl": "panales-y-panitos-humedos"
      }
    ],
    "link": "cuidado-del-bebe",
    "nameUrl": "cuidado-del-bebe",
    "classColor": "light-blue"
  },
  {
    "id": "178",
    "id2": 3,
    "name": "Cuidado personal",
    "primaryColor": "#63428F",
    "children": [{
        "id": "183",
        "name": "Bienestar sexual",
        "children": [{
            "id": "184",
            "name": "Preservativos",
            "nameUrl": "preservativos"
          },
          {
            "id": "185",
            "name": "Lubricantes",
            "nameUrl": "lubricantes"
          },
          {
            "id": "186",
            "name": "Higiene Íntima",
            "nameUrl": "higiene-intima"
          }
        ],
        "nameUrl": "bienestar-sexual"
      },
      {
        "id": "179",
        "name": "Cuidado bucal",
        "children": [{
            "id": "187",
            "name": "Cremas dentales",
            "nameUrl": "cremas-dentales"
          },
          {
            "id": "188",
            "name": "Cepillos dentales",
            "nameUrl": "cepillos-dentales"
          },
          {
            "id": "189",
            "name": "Enjuagues dentales",
            "nameUrl": "enjuagues-dentales"
          },
          {
            "id": "190",
            "name": "Seda dental",
            "nameUrl": "seda-dental"
          },
          {
            "id": "191",
            "name": "Especializados",
            "nameUrl": "especializados"
          }
        ],
        "nameUrl": "cuidado-bucal"
      },
      {
        "id": "182",
        "name": "Cuidado de la piel",
        "children": [{
            "id": "192",
            "name": "Cuidado solar",
            "nameUrl": "cuidado-solar"
          },
          {
            "id": "193",
            "name": "Cuidado corporal",
            "nameUrl": "cuidado-corporal"
          },
          {
            "id": "194",
            "name": "Cuidado facial",
            "nameUrl": "cuidado-facial"
          },
          {
            "id": "195",
            "name": "Repelentes e insecticidas",
            "nameUrl": "repelentes-e-insecticidas"
          }
        ],
        "nameUrl": "cuidado-de-la-piel"
      },
      {
        "id": "180",
        "name": "Cuidado del cabello",
        "children": [{
            "id": "196",
            "name": "Shampoo ",
            "nameUrl": "shampoo-"
          },
          {
            "id": "197",
            "name": "Acondicionador",
            "nameUrl": "acondicionador"
          },
          {
            "id": "198",
            "name": "Cremas, geles y lociones",
            "nameUrl": "cremas-geles-y-lociones"
          },
          {
            "id": "199",
            "name": "Ampollas",
            "nameUrl": "ampollas"
          },
          {
            "id": "200",
            "name": "Tintes para cabello",
            "nameUrl": "tintes-para-cabello"
          },
          {
            "id": "201",
            "name": "Lociones y siliconas",
            "nameUrl": "lociones-y-siliconas"
          },
          {
            "id": "202",
            "name": "Desrizadores",
            "nameUrl": "desrizadores"
          },
          {
            "id": "203",
            "name": "Spray",
            "nameUrl": "spray"
          },
          {
            "id": "204",
            "name": "Baños de crema",
            "nameUrl": "banos-de-crema"
          },
          {
            "id": "251",
            "name": "Accesorios cabello",
            "nameUrl": "accesorios-cabello"
          }
        ],
        "nameUrl": "cuidado-del-cabello"
      },
      {
        "id": "372",
        "name": "Dermocosmética",
        "children": [{
            "id": "373",
            "name": "Capilar",
            "nameUrl": "capilar"
          },
          {
            "id": "374",
            "name": "Corporal",
            "nameUrl": "corporal"
          },
          {
            "id": "375",
            "name": "Facial",
            "nameUrl": "facial"
          },
          {
            "id": "377",
            "name": "Proteccion solar",
            "nameUrl": "proteccion-solar"
          },
          {
            "id": "376",
            "name": "Maquillaje",
            "nameUrl": "maquillaje"
          }
        ],
        "nameUrl": "dermocosmetica"
      },
      {
        "id": "181",
        "name": "Higiene personal",
        "children": [{
            "id": "205",
            "name": "Jabones",
            "nameUrl": "jabones"
          },
          {
            "id": "206",
            "name": "Desodorantes y antitranspirantes",
            "nameUrl": "desodorantes-y-antitranspirantes"
          },
          {
            "id": "207",
            "name": "Depilación y afeitado",
            "nameUrl": "depilacion-y-afeitado"
          },
          {
            "id": "208",
            "name": "Protección femenina",
            "nameUrl": "proteccion-femenina"
          },
          {
            "id": "209",
            "name": "Incontinencia",
            "nameUrl": "incontinencia"
          }
        ],
        "nameUrl": "higiene-personal"
      }
    ],
    "link": "cuidado-personal",
    "nameUrl": "cuidado-personal",
    "classColor": "dark-purple"
  },
  {
    "id": "71",
    "id2": 5,
    "name": "Alimentos y bebidas",
    "primaryColor": "#418FDE",
    "children": [{
        "id": "74",
        "name": "Aguas, gaesosas, jugos, tés y energizantes",
        "children": [{
            "id": "95",
            "name": "Aguas",
            "nameUrl": "aguas"
          },
          {
            "id": "96",
            "name": "Gaseosas",
            "nameUrl": "gaseosas"
          },
          {
            "id": "97",
            "name": "Jugos",
            "nameUrl": "jugos"
          },
          {
            "id": "98",
            "name": "Tés",
            "nameUrl": "tes"
          },
          {
            "id": "99",
            "name": "Energizantes",
            "nameUrl": "energizantes"
          }
        ],
        "nameUrl": "aguas-gaesosas-jugos-tes-y-energizantes"
      },
      {
        "id": "80",
        "name": "Alimentos saludables",
        "children": [{
            "id": "100",
            "name": "Alimentos",
            "nameUrl": "alimentos"
          },
          {
            "id": "101",
            "name": "Snacks dulces",
            "nameUrl": "snacks-dulces"
          },
          {
            "id": "102",
            "name": "Snacks salados",
            "nameUrl": "snacks-salados"
          },
          {
            "id": "210",
            "name": "Endulzantes",
            "nameUrl": "endulzantes"
          }
        ],
        "nameUrl": "alimentos-saludables"
      },
      {
        "id": "78",
        "name": "Alimentos, panes, quesos y huevos",
        "children": [{
            "id": "103",
            "name": "Panes",
            "nameUrl": "panes"
          },
          {
            "id": "104",
            "name": "Huevos, pastas y harinas",
            "nameUrl": "huevos-pastas-y-harinas"
          },
          {
            "id": "105",
            "name": "Lácteos",
            "nameUrl": "lacteos"
          },
          {
            "id": "106",
            "name": "Quesos y charcutería",
            "nameUrl": "quesos-y-charcuteria"
          },
          {
            "id": "107",
            "name": "Café y té",
            "nameUrl": "cafe-y-te"
          },
          {
            "id": "108",
            "name": "Cereales",
            "nameUrl": "cereales"
          },
          {
            "id": "109",
            "name": "Ensaladas, sanduches y frutas",
            "nameUrl": "ensaladas-sanduches-y-frutas"
          },
          {
            "id": "110",
            "name": "Sopas, salsas y aderezos",
            "nameUrl": "sopas-salsas-y-aderezos"
          },
          {
            "id": "111",
            "name": "Atunes",
            "nameUrl": "atunes"
          }
        ],
        "nameUrl": "alimentos-panes-quesos-y-huevos"
      },
      {
        "id": "77",
        "name": "Bebidas alcohólicas",
        "children": [{
            "id": "112",
            "name": "Cervezas",
            "nameUrl": "cervezas"
          },
          {
            "id": "113",
            "name": "Vinos",
            "nameUrl": "vinos"
          },
          {
            "id": "114",
            "name": "Bebidas alcohólicas",
            "nameUrl": "bebidas-alcoholicas"
          }
        ],
        "nameUrl": "bebidas-alcoholicas"
      },
      {
        "id": "76",
        "name": "Dulces, chocolates, galletas, tortas y helados",
        "children": [{
            "id": "122",
            "name": "Chocolates",
            "nameUrl": "chocolates"
          },
          {
            "id": "123",
            "name": "Helados y postres",
            "nameUrl": "helados-y-postres"
          },
          {
            "id": "124",
            "name": "Galletas",
            "nameUrl": "galletas"
          },
          {
            "id": "125",
            "name": "Dulces, chupetas y caramelos",
            "nameUrl": "dulces-chupetas-y-caramelos"
          },
          {
            "id": "126",
            "name": "Tortas y ponqués",
            "nameUrl": "tortas-y-ponques"
          },
          {
            "id": "127",
            "name": "Granolas",
            "nameUrl": "granolas"
          },
          {
            "id": "128",
            "name": "Cereales",
            "nameUrl": "cereales"
          },
          {
            "id": "129",
            "name": "Gomas",
            "nameUrl": "gomas"
          },
          {
            "id": "130",
            "name": "Chicles y mentas",
            "nameUrl": "chicles-y-mentas"
          }
        ],
        "nameUrl": "dulces-chocolates-galletas-tortas-y-helados"
      },
      {
        "id": "79",
        "name": "Leches, avenas y yogurts",
        "children": [{
            "id": "138",
            "name": "Leches y bebidas lácteas",
            "nameUrl": "leches-y-bebidas-lacteas"
          },
          {
            "id": "139",
            "name": "Yogurt",
            "nameUrl": "yogurt"
          },
          {
            "id": "140",
            "name": "Avenas",
            "nameUrl": "avenas"
          },
          {
            "id": "141",
            "name": "Lácteos saborizados",
            "nameUrl": "lacteos-saborizados"
          }
        ],
        "nameUrl": "leches-avenas-y-yogurts"
      },
      {
        "id": "75",
        "name": "Snacks salados y pasabocas",
        "children": [{
            "id": "145",
            "name": "Snacks y pasabocas",
            "nameUrl": "snacks-y-pasabocas"
          },
          {
            "id": "146",
            "name": "Maníes y frutos secos",
            "nameUrl": "manies-y-frutos-secos"
          },
          {
            "id": "147",
            "name": "Granolas",
            "nameUrl": "granolas"
          }
        ],
        "nameUrl": "snacks-salados-y-pasabocas"
      }
    ],
    "link": "alimentos-y-bebidas",
    "nameUrl": "alimentos-y-bebidas",
    "classColor": "blue"
  },
  {
    "id": "73",
    "id2": 4,
    "name": "Hogar, mascotas y otros.",
    "primaryColor": "#418FDE",
    "children": [{
        "id": "87",
        "name": "Acc. del hogar, ferreteria y cuidado de zapatos",
        "children": [{
            "id": "88",
            "name": "Accesorios del hogar",
            "nameUrl": "accesorios-del-hogar"
          },
          {
            "id": "89",
            "name": "Cuidado de los zapatos",
            "nameUrl": "cuidado-de-los-zapatos"
          },
          {
            "id": "90",
            "name": "Ferrereria y otros",
            "nameUrl": "ferrereria-y-otros"
          }
        ],
        "nameUrl": "acc-del-hogar-ferreteria-y-cuidado-de-zapatos"
      },
      {
        "id": "84",
        "name": "Higiene del hogar",
        "children": [{
            "id": "131",
            "name": "Papel higiénico",
            "nameUrl": "papel-higienico"
          },
          {
            "id": "132",
            "name": "Servilletas, secantes y pañuelos",
            "nameUrl": "servilletas-secantes-y-panuelos"
          },
          {
            "id": "133",
            "name": "Repelentes e insecticidas",
            "nameUrl": "repelentes-e-insecticidas"
          },
          {
            "id": "134",
            "name": "Detergentes y jabones",
            "nameUrl": "detergentes-y-jabones"
          },
          {
            "id": "135",
            "name": "Lavaplatos",
            "nameUrl": "lavaplatos"
          },
          {
            "id": "136",
            "name": "Ambientadores",
            "nameUrl": "ambientadores"
          },
          {
            "id": "137",
            "name": "Desinfectantes y acc. de limpieza",
            "nameUrl": "desinfectantes-y-acc-de-limpieza"
          }
        ],
        "nameUrl": "higiene-del-hogar"
      },
      {
        "id": "85",
        "name": "Mascotas",
        "children": [{
            "id": "142",
            "name": "Alimentos",
            "nameUrl": "alimentos"
          },
          {
            "id": "143",
            "name": "Salud e Higiene",
            "nameUrl": "salud-e-higiene"
          },
          {
            "id": "144",
            "name": "Accesorios",
            "nameUrl": "accesorios"
          }
        ],
        "nameUrl": "mascotas"
      },
      {
        "id": "213",
        "name": "Otros",
        "children": [{
            "id": "214",
            "name": "Baterías",
            "nameUrl": "baterias"
          },
          {
            "id": "215",
            "name": "Decoración",
            "nameUrl": "decoracion"
          },
          {
            "id": "216",
            "name": "Diversión",
            "nameUrl": "diversion"
          },
          {
            "id": "217",
            "name": "Libros",
            "nameUrl": "libros"
          },
          {
            "id": "218",
            "name": "Medias",
            "nameUrl": "medias"
          },
          {
            "id": "219",
            "name": "Tecnología",
            "nameUrl": "tecnologia"
          },
          {
            "id": "220",
            "name": "Textiles",
            "nameUrl": "textiles"
          },
          {
            "id": "221",
            "name": "Viajero",
            "nameUrl": "viajero"
          }
        ],
        "nameUrl": "otros"
      },
      {
        "id": "86",
        "name": "Vasos, platos, envases, cocina y picnic",
        "children": [{
            "id": "148",
            "name": "Cocina y picnic",
            "nameUrl": "cocina-y-picnic"
          },
          {
            "id": "149",
            "name": "Envases y contenedores",
            "nameUrl": "envases-y-contenedores"
          },
          {
            "id": "150",
            "name": "Papeles envolventes",
            "nameUrl": "papeles-envolventes"
          },
          {
            "id": "151",
            "name": "Platos",
            "nameUrl": "platos"
          },
          {
            "id": "152",
            "name": "Vasos",
            "nameUrl": "vasos"
          },
          {
            "id": "153",
            "name": "Navidad",
            "nameUrl": "navidad"
          }
        ],
        "nameUrl": "vasos-platos-envases-cocina-y-picnic"
      }
    ],
    "link": "hogar-mascotas-y-otros",
    "nameUrl": "hogar-mascotas-y-otros",
    "classColor": "blue"
  }
];
  countryConfig = countryConfig;
  activeDepartment = {
    'Salud y medicamentos': false,
    Belleza: false,
    'Cuidado del bebé': false,
    'Cuidado personal': false,
    'Alimentos y bebidas': false,
    'Hogar, mascotas y otros.': false,
  };

  constructor() {}

  ngOnInit() {

  }

}
@NgModule({
  declarations: [NavbarSsrComponent],
  exports: [NavbarSsrComponent],
  imports: [CommonModule,RouterModule],
})
export class NavbarSsrModule { }
