export const zendeskConfigVen = {
  chat: {
    webWidget: {
      chat: {
        departments: {
          enabled: ['Farmacéutico VE']
        }
      },
      contactOptions: {
        enabled: true,
        chatLabelOnline: {'*': 'Habla con nosotros'},
        chatLabelOffline: {'*': 'Chat no disponible'},
        contactFormLabel: {'*': 'Sugerencias o Reclamaciones'},
      },
      launcher: {
        chatLabel: {
          '*': 'Habla con nosotros',
        },
        mobile: {
          labelVisible: true,
        },
      },
      offset: {
        horizontal: '0px',
        vertical: '0px',

        mobile: {
          horizontal: '0px',
          vertical: '50px',
        },
      },
      label: {
        'es-ES': 'Habla con nosotros',
      },
    },
  },
};