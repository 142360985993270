import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SessionStorageManager} from '../../../../data/manager/session-storage/session-storage.manager';

@Injectable({
  providedIn: 'root',
})
export class UpdateDeliveryTypeEvent {
  private deliveryType = new BehaviorSubject(this.ssm.get('city'));
  currentDeliveryType = this.deliveryType.asObservable();

  constructor(private ssm: SessionStorageManager) { }

  changeDeliveryType(deliveryType: any) {
    this.deliveryType.next(deliveryType);
  }
}
