import {Injectable} from '@angular/core';
import {countryConfig} from 'src/country-config/country-config';
// import {Observable} from 'rxjs';
import {Observable} from 'rxjs';
import {AlgoliaService} from '../../networking/services/algolia/algolia.service';
import {AlgoliaSearchInterface} from '../../networking/models/algolia/requests/algolia';
import {environment} from '../../../../environments/environment';
import {Product} from '../../networking/models/product/responses/product.class';
import {LocationManager} from '../location/location.manager';
import {GoogleTagService} from '../../networking/services/shared/google-tag/google-tag.service';

@Injectable({
  providedIn: 'root',
})
export class AlgoliaManager {
  constructor(private algoliaService: AlgoliaService,
              private location: LocationManager,
              private gtm: GoogleTagService) {}

  search(
        query: string,
        facets: string,
        page: string = '0',
        filtersAdd?: string,
        sendIdStoreGroup = true,
        indexQuery?,
        sendParams = true,
): Promise<any> {
    const data: AlgoliaSearchInterface = {
      query,
      facets,
      page,
      filters: this.getFilters(sendIdStoreGroup, filtersAdd),
      hitsPerPage: environment.algoliaConfig.hitsPerPage,
      index: indexQuery,
      sendParams,
    };
    this.gtm.searchAlgolia(data);
    return this.algoliaService.searchText(data).then((response: any) => {
      response.hits = response.hits.map(item => new Product(item));
      return response;
    });
  }

  getSeoItem(idItem): Observable<any> {
    return this.algoliaService.getSeo(idItem);
  }

  getTutorials(): Promise<any> {
    return this.search('', '', '', '', false, 'prod_video', false);
  }

  getTutorial(idTutorial): Promise<any> {
    return this.search('', '', '', `objectID:${idTutorial}`, false, 'prod_video', true);
  }

  getTutorialLanding(idTutorial): Promise<any> {
    return this.search('', '', '', `objectID:${idTutorial}`, false, 'landing_pages', true);
  }

  private getFilters(sendIdStoreGroup: boolean, filtersAdd?: string): string {
    if (countryConfig.isColombia) {
      return `${sendIdStoreGroup ? `idStoreGroup:${this.location.getIdStoreGroup()}` : ''} ${filtersAdd}`;
    }
    return `${sendIdStoreGroup ? `stock >= 0  AND idCity:${this.location.city.id ? this.location.city.id : 'CCS'}` : ''} ${filtersAdd}`;
  }
}
