<!-- <div mdbModal #basicModal="mdbModal"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     [class.iphone]="browser?.iphone"
     [class.mobile-full-screen]="mobileFullScreen"
     aria-labelledby="myBasicModalLabel"
     [attr.data-backdrop]="'static'"
     aria-hidden="true"
     [config]="{ignoreBackdropClick: eventClick}">
  <div [class]="dialogClass + ' modal-dialog ' + (modalSize ? 'modal-' + modalSize : '')" [class.modal-fluid]="screenSize.mobile" role="document"> -->
<div class="modal-container" [ngClass]="{'show': basicModalStatus, 'fade': basicModalStatus}">
  <div [class]="'modal-dialog ' + (modalSize ? 'modal-' + modalSize : '')" [class.modal-fluid]="screenSize.mobile" role="document">
    <div [class]="'modal-content ' + contentClass">
      <div [class]="'modal-header ' + headerClass">
        <button *ngIf="showClose" type="button" class="close pull-right" [class.persist-x]="persistXToClose" aria-label="Close" (click)="hideModal()">
          <app-icons class="close-icon" [ngClass]="{'d-none d-lg-block': !persistXToClose}" [type]="'close'" [color]="'#418fde'"></app-icons>
          <app-icons *ngIf="!persistXToClose" [ngClass]="{'d-lg-none': !persistXToClose}" [type]="'arrow-simple'" [custom]="'left'" [color]="'#418fde'"></app-icons>

        </button>
        <h4 [class]="titleClass" class="modal-title w-100 text-center" *ngIf="title">{{ title }}</h4>
        <div [class]="'icon-wrapper ' + contentIconClass" *ngIf="iconSrc">
          <span [class]="'circle ' + iconClass"><img [src]="iconSrc" class="icon" alt="Modal Icon"></span>
        </div>
      </div>
      <div [class]="'modal-body ' + bodyClass">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer text-center" *ngIf="options.showFooter" [ngClass]="{'block': options.customClass !== 'transparent'}">
        <app-button *ngIf="options.closeButton" [title]="options.closeButtonText" [customClasses]="options.customClass" (click)="hideModal()" aria-label="Close" mdbWavesEffect></app-button>
      </div>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="overlay"></div>
