import {countryConfig} from 'src/country-config/country-config';

export class Product {
  description;
  detailDescription;
  quantitySold;
  pum;
  urlImage;
  id;
  totalStock;
  offerDescription;
  mediaImageUrl;
  seoUrl = '';
  petalText;
  petalColor;
  petalType;

  // stock;
  // totalStock;

  constructor(product: any) {
    Object.assign(this, product);
    this.description = product.mediaDescription || product.description;
    this.detailDescription = product.grayDescription || product.detailDescription;
    this.quantitySold = product.quantity || product.quantitySold;
    this.mediaImageUrl = product.mediaImageUrl || product.imageUrl;
    // if (this.mediaImageUrl && this.mediaImageUrl.includes('\/')) {
    //   // this.mediaImageUrl = 'assets/svg/no-img.png';
    //   console.log( this.mediaImageUrl, this.mediaImageUrl.replace(/\//g, '').trim());
    //   this.mediaImageUrl = this.mediaImageUrl.replace(/\//g, '').trim();
    // } else {
    //   console.log(false);
    // }
    // (window.screen.width <= 786) ? '=s140' : '=s380'
    // this.mediaImageUrl = browser && (browser.safari || browser.ie) ? this.mediaImageUrl : `${this.mediaImageUrl}=rw`;
    this.id = parseInt(product.id, 10);
    this.seoUrl = this.urlFormatter(this.description);
    // this.stock = 8;
    // this.totalStock = 5;

    // validate pum
    if (product.labelPum && product.measurePum && product.measurePum > 0) {
      if (product.offerPrice && product.offerPrice > 0) {
        this.pum = `${product.labelPum} ${(product.offerPrice / product.measurePum).toFixed(2)}`;
      } else {
        this.pum = `${product.labelPum} ${(product.fullPrice / product.measurePum).toFixed(2)}`;
      }
    }

    // validate multi imgs
    if (product.listUrlImages && product.listUrlImages.length > 0) {
      if (product.mediaImageUrl) {
        const idx = product.listUrlImages.indexOf(product.mediaImageUrl);
        if (idx !== -1) {
          product.listUrlImages.splice(idx, 1);
        } else {
          product.listUrlImages.unshift(product.mediaImageUrl);
        }
        product.mediaImageUrl = product.listUrlImages[0];
      }
    }
    if (product.type === 'GROUP' && (product.first_description || product.firstDescription)) {
      this.mediaImageUrl = product.url_image || product.urlImage;
      this.offerDescription = product.first_description || product.firstDescription;
      // this.firstDescription = product.first_description || product.firstDescription;
      this.description = this.offerDescription;
      this.id = product.id_offer || product.id;
    }

    if (countryConfig.isVenezuela) {
      // momentaneo
      if (!product.allowedStock) { product.allowedStock = 20; }
      // fin momentaneo
      if (product.type !== 'GROUP') {
        this.totalStock = product.stock || product.totalStock || 0;
      }
      this.setPetalStatus(product);
    }
  }

  private urlFormatter = text => {
    if (!text) { return text; }
    return text.toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/´/g, '')
      .replace(/ /g, '-')
      .replace(/--/g, '-')
      .replace(/[\xa0]/g, '')
      .replace(/[&\/\\#,+()$~%.'":=*?!<>{}]/g, '');

    // console.log('Formatted URL:', url);
    // return url;
  }

  private setPetalStatus(product: any) {
    this.petalText = 'Disponible';
    this.petalType = 0;
    if (this.totalStock > 2) {
      this.petalColor = '#008000'; // green
    } else {
      this.petalColor = '#940000'; // red
    }
    if ((product.greenStore || product.greenDelivery) && this.totalStock > 1) {
      this.petalColor = '#008000';
      if (product.greenStore) {
        this.totalStock = 0;
        this.petalText = 'Solo disponible en tienda';
        this.petalType = 2;
      }
      return;
    }
    if (this.totalStock < product.allowedStock) {
      this.totalStock = 0;
      this.petalText = 'No disponible';
      this.petalType = 1;
    }
    if (product.availableOnline === false) {
      this.totalStock = 0;
      this.petalText = 'Solo disponible en tienda';
      this.petalType = 2;
    }
    if (product.noFullPrice) {
      this.totalStock = 0;
      this.petalText = 'Cómpralo llamando al <a href="tel:0800-327628636"> 0800-3276286</a>';
      this.petalType = 3;
    }
  }
}
