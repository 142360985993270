import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {cart} from '../../api/cart/cart.api';
import {RequestService} from '../../../handlers/request/request.service';
import {Delete, DeleteProduct, AddProduct, GetCart} from '../../models/cart/requests/cart';
import {HandleSubstitutesReq, MergeCartReq, SubstitutesReq} from '../../models/cart/requests/substitutes-req';
import {SubstitutesRes} from '../../models/cart/responses/substitutes-res';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private req: RequestService) { }

  get(data: GetCart): Observable<any> {
    return this.req.post(`${globalEnv.be2}${cart.get}`, data, null);
  }

  getAgile(data: GetCart): Observable<any> {
    return this.req.post(`${globalEnv.be2}${cart.getAgile}`, data, null);
  }

  addProduct(params: AddProduct): Observable<any> {
    return this.req.post(`${globalEnv.be2}${cart.addProduct}`, null, params, true);
  }

  removeProduct(params: DeleteProduct): Observable<any> {
    return this.req.delete(`${globalEnv.be2}${cart.deleteProduct}`, null, params, true);
  }

  deleteCart(params: Delete): Observable<any> {
    return this.req.delete(`${globalEnv.be2}${cart.deleteCart}`, null, params, true);
  }

  getAdvertisedItems(): Observable<any> {
    return this.req.get(`${globalEnv.be2}${cart.getAdvertisedItems}`, null, null, true);
  }

  getHighDemand(params): Observable<any> {
    return this.req.get(`${globalEnv.be2}${cart.getHighDemand}`, params);
  }

  getHighDemandVzla(params): Observable<any> {
    return this.req.get(`${globalEnv.be2}${cart.getHighDemandVzla}`, params);
  }

  getSubstitutes(data: SubstitutesReq, params): Observable<SubstitutesRes> {
    return this.req.post(`${globalEnv.be2}${cart.getSubstitutes}`, data, params, true);
  }

  handleSubstitutes(data: HandleSubstitutesReq): Observable<any> {
    return this.req.post(`${globalEnv.be2}${cart.handleSubstitutes}`, data);
  }

  merge(data: MergeCartReq): Observable<any> {
    return this.req.post(`${globalEnv.be2}${cart.merge}`, data);
  }

  shoppingCart(data): Observable<any> {
    return this.req.post(`${globalEnv.gateway}${cart.shoppingCart}`, data, null, false, false);
  }
}
