import {Component, ElementRef, AfterViewInit, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ResizeService} from './resize.service';
import {SCREEN_SIZE} from './screen-size.enum';

@Component({
  selector: 'app-size-detector',
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.scss'],
})
export class SizeDetectorComponent implements AfterViewInit {
  prefix = 'is-';
  sizes = [
    {
      id: SCREEN_SIZE.XS,
name: 'xs',
      css: 'd-block d-sm-none',
    },
    {
      id: SCREEN_SIZE.SM,
name: 'sm',
      css: 'd-none d-sm-block d-md-none',
    },
    {
      id: SCREEN_SIZE.MD,
name: 'md',
      css: 'd-none d-md-block d-lg-none',
    },
    {
      id: SCREEN_SIZE.LG,
name: 'lg',
      css: 'd-none d-lg-block d-xl-none',
    },
    {
      id: SCREEN_SIZE.XL,
name: 'xl',
      css: 'd-none d-xl-block',
    },
  ];
  isBrowser;

  constructor(private elementRef: ElementRef,
              private resizeSvc: ResizeService,
              @Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    if (!this.isBrowser) {
      return this.sizes[3].id;
    }

    const currentSize = this.sizes.find(x => {
      const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);
      return window.getComputedStyle(el).display !== 'none';
    });
    // console.log({currentSize});
    this.resizeSvc.onResize(currentSize.id);
    return null;
  }
}
