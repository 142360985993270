import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import { Product } from '../../networking/models/product/responses/product.class';
import { FavoritesService } from '../../networking/services/favorites/favorites.service';
import { CustomerManager } from '../customer/customer/customer.manager';
import { SessionStorageManager } from '../session-storage/session-storage.manager';

@Injectable({
  providedIn: 'root',
})
export class FavoritesManager {

  constructor( private favoritesService: FavoritesService, private customerMg: CustomerManager, private ssMg: SessionStorageManager) { }

  get(): Observable<any> {
    return this.favoritesService.get({idCustomerWebSafe: this.customerMg.customer.idCustomerWebSafe}).pipe(
      map(res => {
        if (res?.items) {
          res.items = res.items.map(item => new Product(item));
          this.saveInSs(res.items);
        }
        return res;
      }),
    );
  }

  getSs() {
    return ( this.ssMg.has('favorites') ) ? this.ssMg.get('favorites') : [];
  }

  saveInSs(items = []) {
    if (items.length > 0) {
      this.ssMg.set('favorites', items);
    } else {
      this.ssMg.removeItem('favorites');
    }
  }

  addRemove(item): Observable<any>  {
    const favoritesCurrent = this.getSs();
    const isFavorite = favoritesCurrent.findIndex( i => Number(i.id) === Number(item.id) );
    const objSend = {
      idCustomerWebSafe: this.customerMg.customer.idCustomerWebSafe,
      items : []
    };

    if ( isFavorite !== -1 ) {
      favoritesCurrent.splice(isFavorite, 1);
    } else {
      favoritesCurrent.push(item);
    }

    objSend.items.push(Number(item.id));

    return this.favoritesService.update(objSend).pipe(
      map( res => {
        if (res?.confirmation) {
          this.saveInSs(favoritesCurrent);
          return true;
        }
        return false;
      })
    );
  }

  isFavorite( product ) {
    const shareProduct = this.getSs();
    return shareProduct.filter( item => Number(item.id) === Number(product.id) ).length > 0;
  }

}
