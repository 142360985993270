import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnableLifeMilesEvent {
  private event = new BehaviorSubject(false);
  status = this.event.asObservable();

  update(event: any) {
    this.event.next(event);
  }
}
