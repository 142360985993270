import {Injectable} from '@angular/core';
import {Observable, of, throwError, zip} from 'rxjs';
import {finalize, tap, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {DeliveryTypeEnum} from '../../networking/models/location/enum/delivery-type.enum';
import {AddressManager} from '../customer/address/address.manager';
import {LocationManager} from '../location/location.manager';
import {UpdateAddressEvent} from '../../../ui/shared/event-listeners/update-address/update-address.event';
import {CartManager} from '../cart/cart.manager';
import {LocalStorageManager} from '../local-storage/local-storage.manager';
import {AddressService} from '../../networking/services/customer/address.service';
import {CustomerService} from '../../networking/services/customer/customer.service';
import {CreateAddressAction} from '../../actions/address.actions';

@Injectable({
  providedIn: 'root',
})
export class CallCenterManager {
  customerAddresses = 'customerAddresses';

  constructor(private customerService: CustomerService,
              private lsm: LocalStorageManager,
              private cartManager: CartManager,
              private locationManager: LocationManager,
              private addressManager: AddressManager,
              private router: Router,
              private store: Store,
              private addressEvent: UpdateAddressEvent,
              private addressService: AddressService) { }

  saveCustomerCallCenter(customer) {
    this.lsm.set('customerCallCenter', customer);
    // this.store.dispatch(new SaveCustomer(customer)).subscribe();
  }

  validate(data): Observable<any> {
    const validateData: any = {
      address: data.address,
      city: data.cityId,
    };
    // console.log('validateData', validateData);
    if (data.placeId) { validateData.placeId = data.placeId; }
    return this.addressService.validate(validateData);
  }

  createCustomerCallCenter(customer) {
    const params = {
      keyClient: 12345,
    };
    customer.registeredByCall = 'CALLCENTER';

    return this.customerService.createCustomerCallCenter(customer, params);
  }

  update(rawData) {
    this.saveCustomerCallCenter(rawData);
    // return of(data);
  }

  getCustomerByPhoneNumberOrDocumentNumber(data) {
    return this.customerService.getCustomerByPhoneNumberOrDocumentNumber(data);
  }

  getCustomerByDocumentId(data) {
    return this.customerService.getCustomerByDocumentId(data);
  }

  getCustomer() {
    return this.lsm.get('customerCallCenter');
  }

  handleAddressData(rawData, alterData) {
    const data = {...rawData};
    // this.lsm.removeItem(this.customerAddresses);
    // console.log('AddressRawData:', data);
    data.city = data.cityId;
    delete data.cityId;
    if (!data.placeId) { delete data.placeId; }

    if (data.deliveryType !== DeliveryTypeEnum.ENVIALOYA) { delete data.courierCode; }

    if (data.deliveryType === DeliveryTypeEnum.EXPRESS) {
      return this.validate(rawData)
        .pipe(switchMap(response => {
          // console.log('Vaidated Address:', response);
          if (response.statusCode > 200) { return throwError(response); }
          if (response.statusCode > 204) {
            delete data.placeId;
            data.address = alterData.address;
            data.latitude = alterData.latitude;
            data.longitude = alterData.longitude;
            data.assignedStore = parseInt(alterData.idStore, 10);
            // return of(data);
            return this.create(data);
          }
          delete data.placeId;
          data.address = response.address;
          data.latitude = response.latitude;
          data.longitude = response.longitude;
          // tslint:disable-next-line:radix
          // eslint-disable-next-line radix
          data.assignedStore = parseInt(response.idStore, 10);
          // return of(data);
          return this.create(data);
        }));
    }
    // return of(data);
    return this.create(data);
  }

  create(data): Observable<any> {
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    const customer = callCenterCustomer || this.lsm.get('customer');
    const idCustomerWebSafe = {idCustomerWebSafe: customer.idCustomerWebSafe};
    return this.addressService.create(data, idCustomerWebSafe)
      .pipe(tap(res => {
        this.addressManager.setDeliveryAddress(res.address);
        this.locationManager.updateDeliveryType(res.address);
        this.addressEvent.changeAddress(res.address);

        if (callCenterCustomer) {
          // console.log(callCenterCustomer);
          if (!callCenterCustomer.addresses) {
            callCenterCustomer.addresses = [];
          }
          callCenterCustomer.addresses = [...callCenterCustomer.addresses, res.address];
          callCenterCustomer.deliveryAddress = res.address;
        }
        this.store.dispatch(new CreateAddressAction(res.address))
          .subscribe(() => this.lsm.set(this.customerAddresses, this.store.snapshot().addressStore.addresses));
      }));
  }

  logOut(reload = true): Observable<any> {
    // console.log('LoggingOut, redirect?', redirect);
    if (reload) {
      zip([
        this.cartManager.deleteCart({hasCoupon: false, deliveryType: "EXPRESS"}),
        this.cartManager.deleteCart({hasCoupon: false, deliveryType: "SCANANDGO"})
      ])
        .pipe(finalize(() => {
          // console.log('Deleted Customer Call Center');
          this.lsm.removeItem('customerCallCenter');
          this.router.navigate(['call-center']).then(() => { window.location.reload(); });
        }));
    }

    return of(this.lsm.removeItem('customerCallCenter'));
  }
}
