import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateSessionModalEvent {
  private modalStatus = new BehaviorSubject({open: false});
  updatedModalStatus = this.modalStatus.asObservable();

  changeModalStatus(status: any) {
    this.modalStatus.next(status);
  }
}
