import {
  Component,
  OnInit,
  HostListener,
  AfterContentInit,
  OnDestroy,
  ViewContainerRef,
  ComponentFactoryResolver,
  Injector, ViewChild, SimpleChange, Inject, PLATFORM_ID,
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, first, take} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {SwUpdate} from '@angular/service-worker';
import {Observable, timer} from 'rxjs';
// import * as firebase from 'firebase/app';
import {isPlatformBrowser} from '@angular/common';
import {countryConfig} from 'src/country-config/country-config';
import {Select, Store} from '@ngxs/store';
import {AngularFireDatabase} from '@angular/fire/database';
import {RootVm} from './view-model/root/root.vm';
import {SessionEvent} from './ui/shared/event-listeners/session/session.event';
import {ResizeService} from './ui/shared/modules/size-detector/resize.service';
import {ScreenSize} from './ui/shared/modules/size-detector/ScreenSize';
import {ResizeEvent} from './ui/shared/event-listeners/resize/resize.event';
import {UpdateOnlineOnlyModalEvent} from './ui/shared/event-listeners/update-online-only-modal/update-online-only-modal.event';
import {ScrollEvent} from './ui/shared/event-listeners/scroll/scroll.event';
import {BrowserDetectorService} from './data/networking/services/shared/broswer-detector/browser-detector.service';
import {UpdateMetaTitleService} from './ui/shared/services/updateMetaTitle/update-meta-title.service';
import {JsonLdSeoEvent} from './ui/shared/event-listeners/json-ld-seo/json-ld-seo.event';

import {SwUpdateService} from './ui/shared/services/sw-update/sw-update.service';
import {ScrollInfiniteEvent} from './ui/shared/event-listeners/scroll-infinite/scroll-infinite.event';
import {GoogleTagService} from './data/networking/services/shared/google-tag/google-tag.service';
import {EnableLifeMilesEvent} from './ui/shared/event-listeners/enable-life-miles/enable-life-miles.event';

import {PageEvent} from './ui/shared/event-listeners/event-page/event-page.event';
import {CountryConfigEvent} from './ui/shared/event-listeners/country-config-event/country-config-event';
import {UpdateBuyAndGetModalEvent} from './ui/shared/event-listeners/update-buy-and-get-modal/update-buy-and-get-modal.event';
import {
  DeleteScanAndGoConfigAction,
  SaveScanAndGoFirebaseConfigAction,
  UpdateScanAndGoFirebaseActiveAction,
} from './data/actions/scan-and-go.actions';
import {UpdateScanAndGoModalEvent} from './ui/shared/event-listeners/update-scan-and-go-modal/update-scan-and-go-modal.event';
import {P2cVm} from './view-model/p2c/p2c.vm';
import {LocalStorageManager} from './data/manager/local-storage/local-storage.manager';
import { ScanAndGoVm } from './view-model/scan-and-go/scan-and-go.vm';
import { ShowSelectAddressEvent } from './ui/shared/event-listeners/show-select-address/show-select-address.event';

declare const $: any;
declare const window: any;
declare let JSBridge:any;

const fbDataBase = countryConfig.isColombia
  ? 'https://stunning-base-scan-and-go.firebaseio.com/'
  : 'https://oracle-services-vzla-scan-and-go.firebaseio.com/';
const table = 'userStatus';
const userStatusPath = `${table}/store-id-`;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ResizeService],
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
  isBrowser = false;
  screenSize: ScreenSize = {mobile: false, desktop: true};
  disableSasCart = true;
  loadModals;
  routerHome = false;
  browser;
  schema;
  jsonLD;
  updateAvailable = false;
  colorNav;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  hasFooter = false;
  routerOutletContainer: any;
  showTitle = false;
  navBarInstance;
  pagesEvents = true;
  showContent = false;
  countryConfig = countryConfig;
  countryName = countryConfig.countryName;
  configModule: any;
  homeR;
  sagFinalScan = false;
  sagModalStatus: any = {open: false};

  @Select(state => state.scanAndGoStore) scanAndGoStore$: Observable<any>;
  sagSubscriptions: any = {};
  customer;
  idProductOnline = 0;
  showSelectAddress = false;
  showAppBar = false;
  spaceAddBarAddress = '';
  currentRoute = '/';

  @ViewChild('footer', {read: ViewContainerRef}) footer: ViewContainerRef;
  @ViewChild('onBoardingModal', {read: ViewContainerRef}) onBoardingModal: ViewContainerRef;
  @ViewChild('menuBottom', {read: ViewContainerRef}) menuBottom: ViewContainerRef;
  @ViewChild('toast', {read: ViewContainerRef}) toast: ViewContainerRef;
  @ViewChild('productToast', {read: ViewContainerRef}) productToast: ViewContainerRef;
  @ViewChild('onlineOnlyModal', {read: ViewContainerRef}) onlineOnlyModal: ViewContainerRef;
  @ViewChild('buyAndGetModal', {read: ViewContainerRef}) buyAndGetModal: ViewContainerRef;
  @ViewChild('sasSidebarCart', {read: ViewContainerRef}) sasSidebarCart: ViewContainerRef;
  @ViewChild('sasProductNotAvailable', {read: ViewContainerRef}) sasProductNotAvailable: ViewContainerRef;
  @ViewChild('customModal', {read: ViewContainerRef}) customModal: ViewContainerRef;
  @ViewChild('navbar', {read: ViewContainerRef}) navbar: ViewContainerRef;
  @ViewChild('chatButton', {read: ViewContainerRef}) chatButton: ViewContainerRef;
  @ViewChild('helpButton', {read: ViewContainerRef}) helpButton: ViewContainerRef;
  @ViewChild('p2cHomebar', {read: ViewContainerRef}) p2cHomebar: ViewContainerRef;
  @ViewChild('p2cPayModal', {read: ViewContainerRef}) p2cPayModal: ViewContainerRef;
  @ViewChild('p2cInfoModal', {read: ViewContainerRef}) p2cInfoModal: ViewContainerRef;
  @ViewChild('p2cMobile', {read: ViewContainerRef}) p2cMobile: ViewContainerRef;

  count = 0;
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    this.scroll.changeScrollWindow(event.target.scrollTop);
    const max = this.routerOutletContainer.scrollHeight;
    const pos = this.routerOutletContainer.scrollTop + this.routerOutletContainer.offsetHeight;
    if (max >= pos) {
      // console.log({max, pos});
      timer(1000).pipe(first(), debounceTime(800)).subscribe(() => {
        this.count++;
        // this.getFooter(this.count);
      });
    }
  }
  constructor(private vm: RootVm,
              private loginEvent: SessionEvent,
              private resizeService: ResizeService,
              private router: Router,
              private route: ActivatedRoute,
              private resizeEvent: ResizeEvent,
              private onlineOnlyEvent: UpdateOnlineOnlyModalEvent,
              private buyAndGetEvent: UpdateBuyAndGetModalEvent,
              private scroll: ScrollEvent,
              private browserService: BrowserDetectorService,
              private updateMeta: UpdateMetaTitleService,
              private jsonLdEvent: JsonLdSeoEvent,
              private sanitizer: DomSanitizer,
              private swUpdate: SwUpdate,
              private scrollInfinite: ScrollInfiniteEvent,
              private gtmService: GoogleTagService,
              private viewContainerRef: ViewContainerRef,
              private cfr: ComponentFactoryResolver,
              private injector: Injector,
              private enableLifeMilesEvent: EnableLifeMilesEvent,
              private swUpdateService: SwUpdateService,
              private eventsPages: PageEvent,
              private configCountry: CountryConfigEvent,
              private store: Store,
              private lsm: LocalStorageManager,
              private rtdb: AngularFireDatabase,
              private sagvm: ScanAndGoVm,
              private p2cvm: P2cVm,
              private modalStatusEvent: UpdateScanAndGoModalEvent,
              private eventShowSelectAddress: ShowSelectAddressEvent,
              @Inject(PLATFORM_ID) platformId) {
    // firebase.initializeApp(countryConfig.firebaseConfig);
    this.configCountry.getCountryConfig.subscribe((config: any) => {
      if (config.general) { this.configModule = config.general; }
    });
    this.updateMeta.updateMetaTitle({});
    this.isBrowser = isPlatformBrowser(platformId);
    this.homeR = this.isBrowser ? window.screen.width <= 786 : true;
    this.route.queryParams.subscribe((params: any) => {
      const isapp = params.isApp;
      if (params.verbose) { this.lsm.get('verbosemode'); }
      if (isapp != null) {
        this.lsm.set('webview', isapp);
        window.onSagQRReaded = (qrcode: String) => {
          if(!!this.p2cvm.orderId) {
            this.p2cvm.qrcodeEvent.emit(qrcode);
          } else {
            this.sagvm.qrcodeEvent.emit(qrcode);
          }
        }
        caches.keys().then(k => {
          k.forEach(i => caches.delete(i).then());
        });
        this.swUpdate.available.subscribe(() => {
          console.log('Recargando la pagina');
          window.location.reload();
        });
      } else {
        this.swUpdate.available.subscribe(() => {
          if(!this.lsm.get('webview')) {
            console.warn('Actualizacion pendiente 1');
            this.updateAvailable = true;
            if (this.updateAvailable) {
              caches.keys()
                .then(k => {
                  k.forEach(i => caches.delete(i).then());
                });
            }
          }
        });
        // this.vm.disableDevOutputs();
      }
    });
  }

  reload() {
    if (this.isBrowser) {
      window.location.reload();
    }
  }

  loadIndexScripts() {
    ((w, d, s, l, i) => {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0];
      const j: any = d.createElement(s);
      const dl = l !== 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', countryConfig.analytics.gtmContainer);
    ((w, d, s, i) => {
      const f = d.getElementsByTagName(s)[0];
      const j: any = d.createElement(s);
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtag/js?id=${i}`;
      f.parentNode.insertBefore(j, f);

      window.dataLayer = window.dataLayer || [];
      const dataLayer = [...window.dataLayer];
      // eslint-disable-next-line prefer-rest-params
      function gtag() { dataLayer.push(arguments); }
      // @ts-ignore
      gtag('js', new Date());
      // @ts-ignore
      gtag('config', i);
    })(window, document, 'script', countryConfig.analytics.googleAdsConversionId);

    // Cargando contenido P2C
    setTimeout(() => {
      this.getP2cHomebar();
      this.getP2cMobile();
      this.getP2cPayModal();
      this.getP2cInfoModal();
    }, 100);

    // timer(500)
    //   .pipe(first())
    //   .subscribe(() => {
    //     this.gtmService.loadScripts();
    //     // console.log('LoadedScripts');
    //   });
  }

  ngOnInit(): void {
    if (!this.isBrowser) { return; }

    // console.log(this.vm.getSaGStatus());
    if (this.vm.getSaGStatus()) {
      // this.router.navigate(['/escanea-y-listo/inicio']);
      this.vm.removeSaGStatus();
    }
    this.monitorScanAndGo();

    // timer(3000).pipe(first())
    //   .subscribe(() => {
    this.loadIndexScripts();
      // });
    this.browser = this.browserService.checkBrowser();
    this.vm.getConfigurationMultiCountry();

    this.router.events.subscribe((events: any) => {
      if (events instanceof NavigationEnd) {
        // console.log('RouterEvents:', events);
        if (events.url?.includes('?qr=true')) {
          this.sagFinalScan = true;
        }

        if (events.url.indexOf('producto/') === -1) {
          this.updateMeta.updateMetaTitle({});
        }
        this.disableSasCart = events.url.indexOf('carrito') >= 0 || events.url.indexOf('checkout') >= 0;
        this.routerHome = events.url === '/';
        this.showAppBar = this.routerHome;
        document.querySelector('.cont-router-outlet').scrollTop = 0;

        if (!this.disableSasCart && !this.sasSidebarCart) {
          timer(3000)
            .pipe(first())
            .subscribe(() => {
              this.getProductNotAvailable();
              this.getModalOnBoarding();
              this.getSasSidebarCart();
              this.getHelpButton();
            });
        };

        this.currentRoute = events.url;

        this.addRemoveSpaceNavbar();

      }
    });

    this.validateCustomer();

    setTimeout(() => {
      if (JSON.parse(localStorage.customer).id) {
        const customerJson = {
          id: JSON.parse(localStorage.customer).id,
          idCustomerWebSafe: JSON.parse(localStorage.customer).idCustomerWebSafe,
          token: JSON.parse(localStorage.customer).token.token,
          tokenIdWebSafe: JSON.parse(localStorage.customer).token.tokenIdWebSafe,
        };
        if (this.sagvm.isIOS) {
            const sendMessage = () => {
              window.webkit.messageHandlers.jsMessageHandler.postMessage(customerJson);
            }
            sendMessage();
        } else if (this.sagvm.isAndroid) {
          const sendMessage = () => {
            try {
              JSBridge.onUserLogged(JSON.stringify(customerJson));
            } catch {}
          }
          sendMessage();
        }
      }
    }, 3000);

    /*this.vm.validateServices()
      .subscribe(response => {}, error => console.log('Error@Validate services: ', error));*/
    // localStorage.customer = JSON.stringify(this.customer);
    this.vm.createAnonymous()
      .pipe(
        // tap(response => console.log('createAnonymous =>', response))
        // finalize(() => )
      )
      .subscribe(response => {
        if (response) {
          this.loginEvent.changeLoginStatus(false);
          // console.log('Create anonymous', response);
          timer(2000)
            .pipe(first())
            .subscribe(() => {
              this.vm.getOriginProperties();
              this.vm.getSupportContact(); // TODO: Validate if needed here!
            });
        }
      });
    // this.vm.hasCustomer().subscribe();

    this.jsonLdEvent.content
      .subscribe(json => {
        if (json) {
          // console.log('JsonLD V2:', json);
          this.schema = json;
          this.jsonLD = this.getSafeHTML(json);
        }
      });

    timer(1500)
      .pipe(take(1))
      .subscribe(() => {
        this.inactivityTime(this.isBrowser);
        this.showTitle = false;
        this.getProductToast();

        this.loadModals = true;
        setTimeout(() => {
          this.getCustomModal();
          this.getProductNotAvailable();
          this.getModalOnBoarding();
          this.getToast();
          this.getSasSidebarCart();
          if (countryConfig.isVenezuela) { this.vm.getAvailability(); }
        }, 1);
        this.onlineOnlyEvent.onlineOnlyProduct
          .subscribe((product: any) => {
            if (product && this.idProductOnline !== product.id) {
              this.idProductOnline = product.id;
              this.getOnlineOnlyModal({product, status: {open: true}});
            }
          });
        this.buyAndGetEvent.buyAndGetProduct
          .subscribe(data => {
            if (data) {
              // console.log('Product!', data);
              this.getBuyAndGetModal({data, status: {open: true}});
            }
          });
      });
  }

  listenEmiterColor(event) {
    this.colorNav = event;

    if (this.navBarInstance) {
      this.navBarInstance.ngOnChanges({colorNav: new SimpleChange(null, event, false)});
    }
  }

  getSafeHTML(value: {}) {
    const json = JSON.stringify(value, null, 2);
    const html = `${json}`;
    // Inject to inner html without Angular stripping out content
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  async getFooter(c = 0) {
    const footer = document.querySelector('app-footer');
    // console.log(footer, c);
    if (!footer && c === 1) {
      this.footer.clear();
      const {FooterComponent} = await import('./ui/shared/modules/footer/footer.component');
      const factory = this.cfr.resolveComponentFactory(FooterComponent);
      this.footer.createComponent(factory, null, this.injector);
      // console.log(instance);
    }
  }

  async getModalOnBoarding() {
    if (this.onBoardingModal) {
      this.onBoardingModal.clear();
      const {ModalOnBoardingComponent} = await import('./ui/modals/modal-onboarding/modal-on-boarding.component');
      const factory = this.cfr.resolveComponentFactory(ModalOnBoardingComponent);
      this.onBoardingModal.createComponent(factory, null, this.injector);
      // console.log(instance);
    }
  }

  async getMenuBottom() {
    if (this.menuBottom) {
      this.menuBottom.clear();
      const {MenuBottomComponent} = await import('./ui/shared/modules/menu-bottom/menu-bottom.component');
      const factory = this.cfr.resolveComponentFactory(MenuBottomComponent);
      const {instance} = this.menuBottom.createComponent(factory, null, this.injector);
      instance.emitNavColor.subscribe(event => {
        this.listenEmiterColor(event);
      });
      // console.log(instance);
    }
  }

  async getToast() {
    if (this.toast) {
      this.toast.clear();
      const {ToastComponent} = await import('./ui/shared/modules/toast/toast.component');
      const factory = this.cfr.resolveComponentFactory(ToastComponent);
      this.toast.createComponent(factory, null, this.injector);
      // console.log(instance);
    }
  }

  async getProductToast() {
    if (this.productToast) {
      this.productToast.clear();
      const {ProductToastComponent} = await import('./ui/shared/modules/product-toast/product-toast.component');
      const factory = this.cfr.resolveComponentFactory(ProductToastComponent);
      this.productToast.createComponent(factory, null, this.injector);
      // console.log(instance);
    }
  }

  async getOnlineOnlyModal(data) {
    const {product, status} = data;
    if (this.onlineOnlyModal) {
      this.onlineOnlyModal.clear();
    }
    // console.log(document.querySelectorAll('app-online-only-modal'));
    const {OnlineOnlyModalComponent} = await import('./ui/modals/online-only-modal/online-only-modal.component');
    const factory = this.cfr.resolveComponentFactory(OnlineOnlyModalComponent);
    const {instance} = this.onlineOnlyModal.createComponent(factory, null, this.injector);
    instance.product = product;
    setTimeout(() => {
      instance.modalStatus = status;
    }, 100);
    // console.log(instance);
  }

  async getBuyAndGetModal(data) {
    const {status} = data;
    if (this.buyAndGetModal) {
      this.buyAndGetModal.clear();
    }
    const {BuyAndGetComponent} = await import('./ui/modals/buy-and-get/buy-and-get.component');
    const factory = this.cfr.resolveComponentFactory(BuyAndGetComponent);
    const {instance} = this.buyAndGetModal.createComponent(factory, null, this.injector);
    // console.log('Data info', data.data.data);
    instance.data = data.data.data;
    setTimeout(() => {
      instance.buyAndGetModalStatus = status;
    }, 100);
    // console.log(instance);
  }

  async getSasSidebarCart() {
    if (this.sasSidebarCart) {
      this.sasSidebarCart.clear();
      const {SasSidebarCartComponent} = await import('./ui/shared/modules/sas-sidebar-cart/sas-sidebar-cart.component');
      const factory = this.cfr.resolveComponentFactory(SasSidebarCartComponent);
      this.sasSidebarCart.createComponent(factory, null, this.injector);
      // console.log(instance);
    }
  }

  async getProductNotAvailable() {
    if (this.sasProductNotAvailable) {
      this.sasProductNotAvailable.clear();
      const {SasProductNotAvailableComponent} = await import('./ui/modals/sas-product-not-available/sas-product-not-available.component');
      const factory = this.cfr.resolveComponentFactory(SasProductNotAvailableComponent);
      this.sasProductNotAvailable.createComponent(factory, null, this.injector);
      // console.log(instance);
    }
  }

  async getCustomModal() {
    if (this.customModal) {
      this.customModal.clear();
      const {CustomModalComponent} = await import('./ui/modals/custom-modal/custom-modal.component');
      const factory = this.cfr.resolveComponentFactory(CustomModalComponent);
      this.customModal.createComponent(factory, null, this.injector);
      // console.log(instance);
    }
  }

  async getNavbar() {
    if (this.navbar) {
      this.navbar.clear();
      const {NavbarComponent} = await import('./ui/shared/modules/navbar/navbar/navbar.component');
      const factory = this.cfr.resolveComponentFactory(NavbarComponent);
      const {instance} = this.navbar.createComponent(factory, null, this.injector);
      instance.colorNav = this.colorNav;
      this.navBarInstance = instance;
      // console.log(instance);
    }
  }

  async getChatButton() {
    if (this.chatButton) {
      this.chatButton.clear();
      const {ChatButtonComponent} = await import('./ui/shared/modules/chat-button/chat-button.component');
      const factory = this.cfr.resolveComponentFactory(ChatButtonComponent);
      this.chatButton.createComponent(factory, null, this.injector);
      // console.log(instance);
    }
  }

  async getHelpButton() {
    if (this.helpButton && this.configModule?.zendesk) {
      this.helpButton.clear();
      const {HelpButtonComponent} = await import('./ui/shared/modules/help-button/help-button.component');
      const factory = this.cfr.resolveComponentFactory(HelpButtonComponent);
      this.helpButton.createComponent(factory, null, this.injector);
      // console.log(instance);
    }
  }

  async getP2cHomebar() {
    if (this.p2cHomebar) {
      this.p2cHomebar.clear();
      const {P2cHomebarComponent} = await import('./ui/shared/modules/p2c-homebar/p2c-homebar.component');
      const factory = this.cfr.resolveComponentFactory(P2cHomebarComponent);
      this.p2cHomebar.createComponent(factory, null, this.injector);
    }
  }

  async getP2cPayModal() {
    if (this.p2cPayModal) {
      this.p2cPayModal.clear();
      const {P2cPayModalComponent} = await import('./ui/modals/p2c-pay/p2c-pay-modal.component');
      const factory = this.cfr.resolveComponentFactory(P2cPayModalComponent);
      this.p2cPayModal.createComponent(factory, null, this.injector);
    }
  }

  async getP2cInfoModal() {
    if (this.p2cInfoModal) { this.p2cInfoModal.clear(); }
    const {P2cInfoModalComponent} = await import('./ui/modals/p2c-info/p2c-info-modal.component');
    const factory = this.cfr.resolveComponentFactory(P2cInfoModalComponent);
    this.p2cInfoModal.createComponent(factory, null, this.injector);
  }

  async getP2cMobile() {
    if (this.p2cMobile) { this.p2cInfoModal.clear(); }
    const {P2cMobileComponent} = await import('./ui/shared/modules/p2c-mobile/p2c-mobile.component');
    const factory = this.cfr.resolveComponentFactory(P2cMobileComponent);
    this.p2cMobile.createComponent(factory, null, this.injector);
  }

  ngAfterContentInit(): void {
    timer(150)
      .pipe(first())
      .subscribe(() => {
        this.getNavbar();
        // this.getFooter(1);
        // this.getHelpButton();
      });
    if (!this.isBrowser) { return; }
    this.resizeService.onResize$.subscribe(size => {
      this.screenSize = size;
      this.resizeEvent.changeScreenSize(size);
      if (this.screenSize?.mobile) {
        if (!document.querySelector('app-menu-bottom')) {
          timer(100).pipe(first()).subscribe(() => { this.getMenuBottom(); });
        }
      }
    });

    timer(1000)
      .pipe(first())
      .subscribe(() => {
        this.routerOutletContainer = document.querySelector('.cont-router-outlet');
        $('.sw-update-alert').on('closed.bs.alert', () => {
          this.updateAvailable = false;
        });
      });

    // window.addEventListener('beforeunload', () => {
    //   if (this.store?.snapshot().scanAndGoStore?.config) {
    //     this.vm.setSaGStatus();
    //     this.lsm.set('mobileSaG', true);
    //     this.router.navigate(['/escanea-y-listo/inicio']);
    //     this.disableScanAndGo();
    //     this.store.dispatch(new DeleteScanAndGoConfigAction()).pipe(first()).subscribe();
    //   }
    // });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden' && this.store?.snapshot().scanAndGoStore?.config) {
        this.disableScanAndGo();
      }
    });
  }

  inactivityTime(isBrowser?) {
    function reloadPage() {
      // console.log('reload page')
      if (isBrowser) {
        window.location.reload();
      }
    }

    const setTimer = d => timer(d).pipe(first()).subscribe(() => { reloadPage(); });

    const delay = 15 * 60 * 1000;
    let time = setTimer(delay);

    function resetTimer() {
      time.unsubscribe();
      time = setTimer(delay);
    }

    // DOM Events
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    document.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer; // touchscreen presses
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer; // touchpad clicks
    document.onkeypress = resetTimer;
    document.addEventListener('scroll', resetTimer, true);
  }

  onModalScrollDown() {
    if (this.isBrowser && window.location.pathname !== '/') {
      this.scrollInfinite.changeInfiniteWindow({scrollChange: true});
    }
  }

  private reloadPage() {
    // console.log('reload page')
    if (this.isBrowser) {
      window.location.reload();
    }
  }

  openZdWidget() {
    // zE('webWidget', 'open');
  }

  getAllServices() {
    if (this.pagesEvents) {
      this.pagesEvents = false;
      this.showContent = true;
      this.eventsPages.setEventPage({services: true});
      this.vm.getLifeMilesConfig()
          .subscribe(res => {
            // console.log('enableLifeMiles origin', res);
            this.enableLifeMilesEvent.update(res);
          });
      // this.loadIndexScripts();
      // this.getHelpButton();
      // this.getChatButton();
    }
  }

  disableScanAndGo() {
    if(countryConfig.isVenezuela) {
      this.lsm.set('mobileSaG', false);
      this.store.dispatch(new UpdateScanAndGoFirebaseActiveAction(false)).pipe(first()).subscribe();
    }
  }

  sagFirebase(storeId) {
    const path = `${userStatusPath}${storeId}/${this.customer.id}/`;
    // console.info(`CONECTING TO FIREBASE: ${fbDataBase} Path: ${path}`);
    const dbRefUserStatus = this.rtdb.database.app.database(fbDataBase).ref(path);
    this.sagSubscriptions.fbDb = this.rtdb.object(dbRefUserStatus).valueChanges()
      .pipe(debounceTime(500))
      .subscribe((res: any) => {
      // console.log('SAG FIREBASE SUBSCRIPTION =>>', {res});
      this.store.dispatch(new SaveScanAndGoFirebaseConfigAction(res)).subscribe();
    });
  }

  updateFirebase(data, storeId) {
    const {status, active, reload_cart} = data;
    const path = `${userStatusPath}${storeId}/${this.customer?.id}/`;
    if (status) {
      this.rtdb.database.app.database(fbDataBase).ref(path).update({status});
    }
    if (typeof active === 'boolean') {
      this.rtdb.database.app.database(fbDataBase).ref(path).update({active});
    }
    if (typeof reload_cart === 'boolean') {
      this.rtdb.database.app.database(fbDataBase).ref(path).update({reload_cart});
    }
  }

  monitorScanAndGo() {
    this.onScanAndGoModalEvent();
    this.customer = this.vm.getCustomer();
    let storeId; // FOR PRODUCTION
    // let storeId = countryConfig.defaultStoreId;  // FOR TESTING PURPOSES 🚨
    let sagStore;

    this.sagSubscriptions.store = this.scanAndGoStore$
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
      )
      .subscribe(store => {
        // console.log('Actulizando datos SAG');
        this.customer = this.vm.getCustomer();
        if (!this.customer || this.customer.isAnonymous) { return; }
        if (store?.config && store.config?.storeId !== storeId) {
          this.sagFirebase(store.config.storeId); // FOR PRODUCTION
          // this.sagFirebase(storeId); // FOR TESTING PURPOSES 🚨
          storeId = store.config.storeId;
        }
        // console.log({store});
        if (store.firebase) {
          // console.log(store.firebase.review);
          if (store.firebase.status !== sagStore?.firebase?.status) {
            this.updateFirebase({status: store.firebase.status}, storeId);
          }
          if (store.firebase.active !== sagStore?.firebase?.active) {
            this.updateFirebase({active: store.firebase.active}, storeId);
          }
          if (store.firebase.review === false) {
            // console.log(store.firebase.review);
            this.closeSaGModal();
          }
          if (store.firebase.reload_cart !== sagStore?.firebase?.reload_cart) {
            // console.log('reload_cart', store.firebase.reload_cart);
            this.updateFirebase({reload_cart: store.firebase.reload_cart}, storeId);
          }
        }

        sagStore = {...store};
      });
  }

  closeSaGModal() {
    this.sagModalStatus = {close: true};
  }

  onScanAndGoModalEvent() {
    this.modalStatusEvent.updatedModalStatus.subscribe(status => {
      if (status) {
        this.sagModalStatus = status;
      }
    });
  }

  removeAppbar() {
    this.showAppBar = !this.showAppBar;
    if (!this.showAppBar) {
      this.spaceAddBarAddress = '';
    }
  }

  validateCustomer(){
    this.vm.hasCustomer().subscribe( (res: any) => {
      this.addRemoveSpaceNavbar();
    });
  }

  addRemoveSpaceNavbar(){

    this.showSelectAddress = (this.currentRoute === '/' && this.vm.getCustomer()?.id !== 0);
    this.spaceAddBarAddress = this.showAppBar && this.showSelectAddress ? 'space-app-bar' : this.showAppBar ? 'space-app-bar-address' : '';
    this.eventShowSelectAddress.set({show: this.showSelectAddress});
  }


  ngOnDestroy(): void {
    this.disableScanAndGo();
  }

}
