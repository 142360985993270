<button type="button" [class]="'app-button ' + customClasses" [class.disabled]="isDisabled" [disabled]="isDisabled || isLoading" [class.btn]="isButton" [id]="buttonId" [attr.data-cuf]="productCuf" (click)="click()">
  <ng-content *ngIf="isTemplate; else defaultButton"></ng-content>
  <ng-template #defaultButton>
    <span
      class="icon-facebook icon-social"
      *ngIf="isFacebook && !isLoading"
    ></span>
    <span class="icon-google icon-social" *ngIf="isGoogle && !isLoading"></span>
    <span class="icon-apple icon-social" *ngIf="isApple && !isLoading"></span>
    <img class="left-img" *ngIf="srcImg && sideImg === 'left'" [src]="srcImg"/>
    <span class="title" *ngIf="!isLoading">{{ title }}</span>
  </ng-template>
  <app-spinner [isLoading]="isLoading" [default]="spinnerBlue" [small]="true"></app-spinner>
</button>