import {ApplicationRef, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {concat, interval} from 'rxjs';
import {first} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SwUpdateService {
  isBrowser;
  constructor(appRef: ApplicationRef,
              swUpdate: SwUpdate,
              @Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.

    if (this.isBrowser) {
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));

      const everyFifteenMinutes$ = interval(15 * 60 * 1000); // interval(6 * 60 * 60 * 1000);
      const everyFifteenMinutesOnceAppIsStable$ = concat(appIsStable$, everyFifteenMinutes$);
      everyFifteenMinutesOnceAppIsStable$.subscribe(() => swUpdate.checkForUpdate());
    }
  }
}
