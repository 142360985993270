import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateSasCartEvent {
  private sasCart = new BehaviorSubject(null);
  updatedSasCart = this.sasCart.asObservable();

  changeSasCart(cart: any) {
    this.sasCart.next(cart);
  }
}
