export class CreateAddressAction {
  static readonly type = '[Address] Create Address ';
  constructor(public address: any) {}
}

export class SaveAddressesAction {
  static readonly type = '[Address] Save Addresses';
  constructor(public addresses: any) {}
}

export class SaveDeliveryAddressAction {
  static readonly type = '[Address] Save Delivery Address';
  constructor(public address: any) {}
}

export class DeleteAddressAction {
  static readonly type = '[Address] Delete Address';
  constructor(public addressId: any) {}
}

export class UpdateAddressAction {
  static readonly type = '[Address] Update Address';
  constructor(public address: any) {}
}
