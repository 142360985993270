import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {location} from '../../api/location/location';
import {RequestService} from '../../../handlers/request/request.service';
import {DeliveryType} from '../../models/shared/requests/delivery-type';
import {CityName} from '../../models/location/requests/stores';
import {KeyClient} from '../../models/shared/requests/key-client';
import {SupportNumbersRes} from '../../models/location/responses/support-numbers-res';
import {AutocompleteReq} from '../../models/location/requests/autocomplete-req';
import {AutocompleteRes} from '../../models/location/responses/autocomplete-res';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private req: RequestService) { }

  getCountries(): Observable<any> {
    return this.req.get(`${globalEnv.be2}${location.countries}`, null, null, true);
  }

  getCities(params: DeliveryType): Observable<any> {
    return this.req.get(`${globalEnv.be2}${location.cities}`, params, null, true);
  }

  getDeliveryTypeText(): Observable<any> {
    return this.req.get(`${globalEnv.be2}${location.deliveryTypeText}`);
  }

  getAllStores(params?: CityName): Observable<any> {
    return this.req.get(`${globalEnv.be2}${location.stores}`, params, null, true);
  }

  getSupportContact(params: KeyClient): Observable<SupportNumbersRes> {
    return this.req.get(`${globalEnv.be2}${location.supportNumbers}`, params);
  }

  autocomplete(data: AutocompleteReq): Observable<AutocompleteRes> {
    return this.req.post(`${globalEnv.be2}${location.autocomplete}`, data);
  }

  getActiveCities(): Observable<any> {
    return this.req.get(`${globalEnv.be2}${location.activeCities}`);
  }
}
