import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateCustomerEvent {
  private customer = new BehaviorSubject(null);
  updatedCustomer = this.customer.asObservable();

  updateCustomer(customer: any) {
    this.customer.next(customer);
  }
}
