import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageEvent {
  private eventPage = new BehaviorSubject({});
  eventPageListener = this.eventPage.asObservable();

  setEventPage(event: any) {
    this.eventPage.next(event);
  }
}
