import {State, Action, StateContext, StateOperator} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SaveCartAction, UpdateCartProductAction, RemoveCartProductAction, DeleteCartAction} from '../actions/cart.actions';
import {UpdateCartEvent} from '../../ui/shared/event-listeners/update-cart/update-cart.event';

function updateProductQuantity(payload): StateOperator<any> {
  // console.log('payload:', payload);
  return state => {
    // console.log('>>>state:', state);
    let quantityDiff = 0;
    let productExists = false;
    let itemList = !state.cart || !state.cart.itemList ? [] : state.cart.itemList.map(item => {
      if (item.id === payload.product.id) {
        quantityDiff = payload.quantity - item.quantitySold;
        item = {...item, quantitySold: payload.quantity};
        productExists = true;
      }
      return item;
    }).filter(item => item.quantitySold > 0);

    if (!productExists) {
      quantityDiff = payload.quantity;
      const product = {...payload.product, quantitySold: payload.quantity};
      itemList = [...itemList, product];
    }
    return {
      ...state,
      cart: {
        ...state.cart,
        itemList,
        quantityFarmatodo: state.cart ? state.cart.quantityFarmatodo + quantityDiff : quantityDiff,
      },
    };
  };
}

function removeProduct(payload): StateOperator<any> {
  // console.log('Remove product:', payload);
  return state => {
    const itemList = state.cart && state.cart.itemList
      ? state.cart.itemList.filter(item => item.id !== payload.product.id) : [];
    const cart = {
        ...state.cart,
        itemList,
        quantityFarmatodo: state.cart ? state.cart.quantityFarmatodo - payload.product.quantitySold : 0,
      };
    return {
      ...state,
      cart: itemList && itemList.length > 0 ? cart : null,
    };
  };
}

@State({
  name: 'cartStore',
  defaults: {cart: null},
})
@Injectable()
export class CartState {
  constructor(private updateCartEvent: UpdateCartEvent) {}

  @Action(SaveCartAction)
  SaveCart(ctx: StateContext<any>, action: SaveCartAction) {
    let state = ctx.getState();
    // console.log('State@SaveCart =>', state);
    // console.log('action@SaveCart =>', action);
    ctx.setState({
      ...state,
      cart: action.cart,
    });
    state = ctx.getState();
    this.updateCartEvent.changeAgileCart(state.cart);
  }

  @Action(DeleteCartAction)
  DeleteCart(ctx: StateContext<any>) {
    ctx.setState({
      cart: null,
    });
    const state = ctx.getState();
    // console.log('State@DeleteCart =>', ctx.getState());
    this.updateCartEvent.changeAgileCart(state.cart);
  }

  @Action(UpdateCartProductAction)
  UpdateCartProduct(ctx: StateContext<any>, action: UpdateCartProductAction) {
    // console.log('State@UpdateCart =>', state);
    // console.log('action@UpdateCart =>', action);
    ctx.setState(updateProductQuantity(action));
    const state = ctx.getState();
    this.updateCartEvent.changeAgileCart(state.cart);
  }

  @Action(RemoveCartProductAction)
  RemoveCartProduct(ctx: StateContext<any>, action: RemoveCartProductAction) {
    // console.log('action@RemoveProductFromCart =>', action);
    ctx.setState(removeProduct(action));
    const state = ctx.getState();
    // console.log('State@RemoveProductFromCart =>', state);
    this.updateCartEvent.changeAgileCart(state.cart);
  }
}
