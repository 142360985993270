import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {SasAlgoliaService} from '../../networking/services-v3/sas-algolia/sas-algolia.service';
import {LocalStorageManager} from '../../manager/local-storage/local-storage.manager';
import {WindowService} from '../../networking/services/shared/window/window.service';
import {Banner} from '../../networking/models/cms/responses/Banner.class';
import {BrowserDetectorService} from '../../networking/services/shared/broswer-detector/browser-detector.service';

@Injectable({
  providedIn: 'root',
})
export class SasAlgoliaManager {
  constructor(private algoliaService: SasAlgoliaService,
              private lsm: LocalStorageManager,
              private browserDetectorService: BrowserDetectorService,
              private windowService: WindowService) { }

  getCheckoutTerms(): Observable<any> {
    return this.algoliaService.getCheckoutTerms()
      .pipe(map(res => (res.termsAndConditions ? res.termsAndConditions : [])));
  }

  getDiscounts(): Observable<any> {
    const prop = 'programAndSaveDiscounts';
    const discounts = this.lsm.get(prop);
    if (discounts) { return of(discounts); }
    return this.algoliaService.getDiscounts()
      .pipe(tap(res => this.lsm.set(prop, res)));
  }

  getFacets(): Observable<any> {
    const observable = this.algoliaService.getFacets();
    if (this.lsm.has('facets')) {
      return of(this.lsm.get('facets'));
    }

    return observable
    .pipe(tap(facets => this.lsm.set('facets', facets)));
  }

  getFaq(): Observable<any> {
    return this.algoliaService.getFaq();
  }

  getHourRange(): Observable<any> {
    const prop = 'sasHourRange';
    if (this.lsm.has(prop)) {
      return of(this.lsm.get(prop));
    }
    return this.algoliaService.getHourRange()
      .pipe(tap(res => this.lsm.set(prop, res)));
  }

  getImages(screenSize): Observable<any> {
    const width = this.windowService.getInnerWidth();
    let observable = this.algoliaService.getImages();
    const browser = this.browserDetectorService.checkBrowser();
    const imgFormat = browser.chrome || browser.firefox ? '-rw' : '';

    if (this.lsm.get('programAndSaveImages')) {
      observable = of(this.lsm.get('programAndSaveImages'));
    }

    return observable
      .pipe(
        map(response => (response.banners ? response.banners : response)),
        map(response => response
          .map(banner => {
            banner.subscribeAndSave = true;
            if (!banner.banners) {
              banner = new Banner(banner);
            }
            banner.urlBanner = `${screenSize.mobile ? banner.banners.mobile : banner.banners.desktop}=s${width}${imgFormat}`;
            return banner;
          })),
        tap(res => this.lsm.set('programAndSaveImages', res)),
);
  }

  getOnBoarding(): Observable<any> {
    return this.algoliaService.getOnBoarding();
  }

  getPeriodicity(): Observable<any> {
    return this.algoliaService.getPeriodicity();
  }

  getProductDetail(): Observable<any> {
    return this.algoliaService.getProductDetail();
  }

  getRestrictedDates(): Observable<any> {
    return this.algoliaService.getRestrictedDates();
  }

  getSchedule(): Observable<any> {
    return this.algoliaService.getSchedule();
  }

  getSubscriptionProperties(): Observable<any> {
    return this.algoliaService.getSubscriptionProperties();
  }

  getTerms(): Observable<any> {
    return this.algoliaService.getTerms();
  }
}
