export const algoliaEnv = {
  hitsPerPage: '24',
  envs: {
    prod: {
      id: 'VCOJEYD2PO',
      key: 'e6f5ccbcdea95ff5ccb6fda5e92eb25c',
      index: 'products'
    },
    dev: {
      id: 'testingYA3RG061BL',
      key: '3884ebb0619b13dcec74002f555c8990',
      index: 'products'
    },
    sandbox: {
      id: 'WOLGP9ZFDO',
      key: '30dfe2713adb2a5e599bcb18eb89042f',
      index: 'products'
    },
  },
  index: ['products', 'properties']
};
