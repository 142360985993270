export const P2cApi = {
  getBanksList: '/getBanks',
  createCustomerBank: '/savePagoMovil',
  removeCustomerBank: '/logicalDeletePagoMovil',
  getCustomerBanks: '/getPagoMovil/idCustomer',
  updateCustomerBanks: '/updatePagoMovil',
  verifyOrderActives: '/getOutstandingBalanceByCustomer/idCustomer',
  verifyPayment: '/verifyPayment',
  confirmPaymentOrder: '/confirmPaymentOrder'
};
