import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastEvent {
  private event = new BehaviorSubject(null);
  content = this.event.asObservable();

  updateToast(event: any) {
    this.event.next(event);
  }
}
