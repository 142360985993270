import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScanAndGoModalComponent} from './scan-and-go-modal.component';
import {BasicModalModule} from '../../shared/modules/modals/basic-modal/basic-modal.module';
import {SafeHtmlModule} from '../../shared/pipes/safe-html/safe-html.module';
import {ButtonModule} from '../../shared/modules/button/button.module';


@NgModule({
  declarations: [ScanAndGoModalComponent],
  exports: [
    ScanAndGoModalComponent
  ],
  imports: [
    CommonModule,
    BasicModalModule,
    SafeHtmlModule,
    ButtonModule,
  ]
})
export class ScanAndGoModalModule { }
