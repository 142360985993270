import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class UpdateAvailabilityEvent {
  private cityAvailability = new BehaviorSubject({});
  getCurrentCity = this.cityAvailability.asObservable();

  cityAvailabilityUpdate(city: any) {
    this.cityAvailability.next(city);
  }
}
