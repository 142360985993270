import {Injectable} from '@angular/core';
import {RequestService} from '../../../handlers/request/request.service';
import {home} from '../../api/cms/home.api';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private req: RequestService) { }

  getHome(data) {
    return this.req.post(`${globalEnv.be2}${home.new}`, data);
  }
}
