import {Inject, Injectable} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {DOCUMENT, Location} from '@angular/common';
import {countryConfig} from 'src/country-config/country-config';

@Injectable({
  providedIn: 'root',
})
export class UpdateMetaTitleService {
  constructor(private title: Title,
              private meta: Meta,
              @Inject(DOCUMENT) private dom,
              private location: Location) {}

  updateMetaTitle(data?: any, url?) {
    const metaObj = {
      title: countryConfig.isColombia
        ? 'Farmacia y droguería online 24 horas a domicilio - Farmatodo'
        : 'Farmatodo: Farmacia Online las 24 Horas | Delivery',
      description: countryConfig.isColombia
        ? `Farmatodo, cadena de droguerías de autoservicio especializada
      en productos para la salud, belleza, cuidado personal y cuidado del bebé.`
        : `Encuentra todo en medicamentos y productos de Farmacia, Cuidado Personal,
      Cuidado del Bebé, Belleza y Maquillaje, y todo en productos de hogar: alimentos, bebidas, mascotas y más en un solo lugar.`,
      image: 'https://lh3.googleusercontent.com/NLvo0TEP9V6YjntPtXzWnm3cvWYcljbRwG'
        + 'MWWGpVBntfZebvGyAljnyDwYM2ndzUjcuwIyMg1e_aSNyT4iQL-ALO--WSL0tl2g',
      site: `https://www.farmatodo.com.${countryConfig.cctld}`,
    };
    const canonical = url || (`https://www.farmatodo.com.${countryConfig.cctld}${data.pathName || this.location.path()}`);
    const canonicalId: any = this.dom.getElementById('canonical-id');
    canonicalId.href = canonical;
    if (data) {
      data.title = data.title ? data.title.replace(/\s+/g, ' ') : metaObj.title;
      data.description = data.description ? data.description.replace(/\s+/g, ' ') : metaObj.description;
    }

    // const url = data && data.url && data.url.substr(-1) === '/' ? data.url : `${data.url}/`;
    this.title.setTitle(`${data.title || metaObj.title}`);

    this.meta.updateTag({name: 'title', content: data.metaTitle ? data.metaTitle : data.title || metaObj.title});
    this.meta.updateTag({name: 'description', content: data.description || metaObj.description});
    this.meta.updateTag({name: 'image', content: data.img || metaObj.image});
    this.meta.updateTag({name: 'site', content: canonical || metaObj.site});
    this.meta.updateTag({itemprop: 'reviewCount', content: data.reviewCount || 0});
    this.meta.updateTag({itemprop: 'ratingValue', content: data.rating || 0});
    // Facebook meta
    this.meta.updateTag({property: 'og:title', content: data.metaTitle ? data.metaTitle : data.title || metaObj.title});
    this.meta.updateTag({property: 'og:description', content: data.description || metaObj.description});
    this.meta.updateTag({property: 'og:url', content: canonical || metaObj.site});
    this.meta.updateTag({property: 'og:image', content: data.img || metaObj.image});

    // TODO: Parametrizar con variable ´redes´ cuando se haga el proximo merge
    // console.log('TWITTER SITE:', countryConfig.isColombia ? '@farmatodocol' : '@farmatodovzla');
    this.meta.updateTag({name: 'twitter:site', content: countryConfig.isColombia ? '@farmatodocol' : '@farmatodovzla'});

    this.meta.updateTag({property: 'og:site_name', content: `Farmatodo ${countryConfig.countryName}`});
  }
}
