import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {PaymentMethodService} from '../../../networking/services/order/payment-method/payment-method.service';
import {LocalStorageManager} from '../../local-storage/local-storage.manager';
import {CustomerManager} from '../../customer/customer/customer.manager';
import {LocationManager} from '../../location/location.manager';
import {CallCenterManager} from '../../call-center/call-center.manager';
import {DeliveryTypeEnum} from '../../../networking/models/location/enum/delivery-type.enum';
import { countryConfig } from 'src/country-config/country-config';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodManager {
  constructor(private lsm: LocalStorageManager,
              private paymentMethodService: PaymentMethodService,
              private customerManager: CustomerManager,
              private callCenterManager: CallCenterManager,
              private locationManager: LocationManager,
              private store: Store) { }

  get isVenezuela() { return countryConfig.isVenezuela; }

  validate(): Observable<any> {
    return this.paymentMethodService.validate({idCustomerWebSafe: this.customerManager.customer.idCustomerWebSafe});
      /*.pipe(map(response => {
        // response = {confirmation: false, message: 'Este cupón se puede redicmir con compras de mínimo 35k'}
        if (!response.confirmation && response.message) {
          throw response;
        } else {
          return response;
        }
      }));*/
  }

  get(deliveryType?): Observable<any> {
    let {customer} = this.customerManager;
    const isSaG = this.lsm.get('mobileSaG');
    //  || !!this.store?.snapshot().scanAndGoStore?.config;
    const callCenterCustomer = this.callCenterManager.getCustomer();
    customer = callCenterCustomer || customer;
    const data: any = {
      id: customer?.id,
      idCustomerWebSafe: customer.idCustomerWebSafe,
      token: {
        token: this.customerManager.customer.token.token,
        tokenIdWebSafe: this.customerManager.customer.token.tokenIdWebSafe,
      },
      idStoreGroup: this.locationManager.getIdStoreGroup(),
      deliveryType: (deliveryType) ? deliveryType : isSaG ? DeliveryTypeEnum.SCANANDGO : this.locationManager.getDeliveryType(),
    };
    if(this.isVenezuela) {
      data.city = isSaG ? 'CCS' : this.locationManager.city.id;
    }
    return this.paymentMethodService.get(data)
      .pipe(
        map(res => (res.items ? res.items : [])),
        // map(paymentMethods => paymentMethods.sort((a, b) => a.positionIndex - b.positionIndex)),
        map(pM => pM
          .sort((a, b) => a.positionIndex - b.positionIndex)
          .map(pm => ({
            ...pm,
            src: this.getPaymentIcon(pm),
            description: pm.id.toString() === '3' ? 'Tarjeta de crédito' : pm.description,
          }))),
      );
  }

  private getPaymentIcon(pm: any) {
    const tp = pm.id.toString();
    let icon = '';
    if (tp === '1') {
      icon = 'cash';
    } else if (tp === '2') {
      icon = 'dataphone';
    } else if (tp === '6' || tp === '7')  {
      icon = 'p2c';
    } else {
      icon = 'tdc';
    }
    return `/assets/svg/icon-${icon}.svg`;
  }

}

