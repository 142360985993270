import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {sasSubscriptionApi} from '../../../api-v3/sas-subscription/sas-subscription.api';
import {RequestService} from '../../../../handlers/request/request.service';
import {CreateList, PeriodicityText, ProductInList, ReSchedule} from '../../../models-v3/sas-subscription/requests/list';
import {globalEnv} from '../../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class SasListService {
  constructor(private req: RequestService) { }

  create(data: CreateList): Observable<any> {
    return this.req.post(`${globalEnv.sas}${sasSubscriptionApi.create}`, data, null, null, true);
  }

  deleteList(subscriptionId, customerId): Observable<any> {
    const api = sasSubscriptionApi.deleteList.replace(':subscriptionId', subscriptionId);
    return this.req.delete(`${globalEnv.sas}${api}`, customerId, null, null, true);
  }

  getBasicList(customerId): Observable<any> {
    const api = sasSubscriptionApi.basicList.replace(':customerId', customerId);
    return this.req.get(`${globalEnv.sas}${api}`, null, null, null, true);
  }

  getCustomerDiscount(customerId): Observable<any> {
    const api = sasSubscriptionApi.customerDiscount.replace(':customerId', customerId);
    return this.req.get(`${globalEnv.sas}${api}`, null, null, null, true);
  }

  getListByCustomerId(customerId): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasSubscriptionApi.listByCustomer}`, null, customerId, null, true);
  }

  addProductToList(data: ProductInList): Observable<any> {
    return this.req.post(`${globalEnv.sas}${sasSubscriptionApi.listProduct}`, data, null, null, true);
  }

  editProductInList(data: ProductInList): Observable<any> {
    return this.req.put(`${globalEnv.sas}${sasSubscriptionApi.listProduct}`, data, null, null, null, true);
  }

  deleteProductInList(productId, listId) {
    const api = sasSubscriptionApi.deleteDetailList
    .replace(':productId', productId)
    .replace(':listId', listId);
    // console.log('Api', api);
    return this.req.delete(`${globalEnv.sas}${api}`, null, null, null, true);
  }

  editList(data) {
    const api = sasSubscriptionApi.editList;
    return this.req.put(`${globalEnv.sas}${api}`, data, null, null, null, true);
  }

  reSchedule(data: ReSchedule): Observable<any> {
    return this.req.put(`${globalEnv.sas}${sasSubscriptionApi.reSchedule}`, data, null, null, null, true);
  }

  validateProductInList(subscriptionId, productId): Observable<any> {
    const api = sasSubscriptionApi.validateProductInList
      .replace(':subscriptionId', subscriptionId)
      .replace(':productId', productId);
    return this.req.get(`${globalEnv.sas}${api}`, null, null, null, true);
  }

  validateProductInCustomerLists(customerId, productId): Observable<any> {
    const api = sasSubscriptionApi.validateProductInCustomerLists
      .replace(':customerId', customerId)
      .replace(':productId', productId);
    return this.req.get(`${globalEnv.sas}${api}`, null, null, null, true);
  }

  getDeliveryByShipping(customerId): Observable<any> {
    const api = sasSubscriptionApi.deliveryByShipping.replace(':customerId', customerId);
    return this.req.get(`${globalEnv.sas}${api}`, null, null, null, true);
  }

  getDeliveryByShippingDetail(customerId, shippingId): Observable<any> {
    const api = sasSubscriptionApi.deliveryByShippingDetail
      .replace(':customerId', customerId)
      .replace(':shippingId', shippingId);
    return this.req.get(`${globalEnv.sas}${api}`, null, null, null, true);
  }

  getPeriodicityText(params: PeriodicityText): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasSubscriptionApi.periodicityText}`, params, null, null, true);
  }

  getShippingSchedule(customerId): Observable<any> {
    const api = sasSubscriptionApi.shippingSchedule.replace(':customerId', customerId);
    return this.req.get(`${globalEnv.sas}${api}`, null, null, null, true);
  }

  getNotificationSourcesByCustomer(customerId): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasSubscriptionApi.notification}`, null, customerId);
  }
}
