import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {sasAlgoliaApi} from '../../api-v3/sas-algolia/sas-algolia.api';
import {RequestService} from '../../../handlers/request/request.service';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class SasAlgoliaService {
  constructor(private req: RequestService) { }

  getCheckoutTerms(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.checkoutTerms}`, null, null, null, false);
  }

  getDiscounts(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.discounts}`, null, null, null, false);
  }

  getFacets(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.facets}`, null, null, null, false);
  }

  getFaq(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.faq}`, null, null, null, false);
  }

  getHourRange(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.hourRange}`, null, null, null, false);
  }

  getImages(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.image}`, null, null, null, false);
  }

  getOnBoarding(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.onBoarding}`, null, null, null, false);
  }

  getPeriodicity(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.periodicity}`, null, null, null, false);
  }

  getProductDetail(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.productDetail}`, null, null, null, false);
  }

  getRestrictedDates(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.restrictedDates}`, null, null, null, false);
  }

  getSchedule(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.schedule}`, null, null, null, false);
  }

  getSubscriptionProperties(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.subscriptionProperties}`, null, null, null, false);
  }

  getTerms(): Observable<any> {
    return this.req.get(`${globalEnv.sas}${sasAlgoliaApi.termsAndConditions}`, null, null, null, false);
  }
}
