import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {ErrorService as ErrorHandler} from '../error/error.service';
import {GenericResponseInterface} from '../../networking/models/shared/responses/generic-response';
import {LocalStorageManager} from '../../manager/local-storage/local-storage.manager';
import {environment} from '../../../../environments/environment';
import {countryConfig} from '../../../../country-config/country-config';

let httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // country: countryConfig.indicator,
  }),
};

const httpOptionsBasic = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  apiKey = '';
  remoteConfig: any;
  isBrowser;
  ssrTokens = countryConfig.ssrTokens;

  static buildApiUrl(params?, id?): string {
    let url = '';

    if (params) {
      // eslint-disable-next-line no-restricted-syntax
      for (const prop in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(prop)) {
          url += `${prop}=${params[prop]}&`;
        }
      }

      url = url.substr(0, url.length - 1);
      url = `?${url}`;
    }

    if (id) {
      id = id ? (`/${id}`) : '';
      // id = id ? ('/' + id.toUpperCase()) : '';
      url = id + url;
    }

    // console.log(`url: ${url}`);
    return url;
  }

  static getTokens(customer) {
    if (!customer || !customer.token) {
      return '';
    }
    return `token=${customer.token.token}&tokenIdWebSafe=${customer.token.tokenIdWebSafe}`;
  }

  constructor(private http: HttpClient,
              private errorHandler: ErrorHandler,
              private lsm: LocalStorageManager,
              @Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.apiKey = !environment.deploymentVersion.developmentMode ? environment.apiKey.production : environment.apiKey.develop;
  }

  getTokens(customer) {
    // console.log('GET TOKENS, isBrowser?', this.isBrowser, 'SSR Tokens', this.ssrTokens);
    if (!this.isBrowser) {
      return `token=${this.ssrTokens.token.token}&tokenIdWebSafe=${this.ssrTokens.token.tokenIdWebSafe}`;
    }
    if (!customer || !customer.token) {
      return '';
    }
    return `token=${customer.token.token}&tokenIdWebSafe=${customer.token.tokenIdWebSafe}`;
  }

  buildReqUrl(api, withToken?, withSecureKey?) {
    let url = api;
    const token = this.lsm.has('customer') || !this.isBrowser ? this.getTokens(this.lsm.get('customer')) : '';

    url += withToken ? url.indexOf('?') !== -1 ? (`&${token}`) : `?${token}` : '';

    if (withSecureKey) {
      url += url.indexOf('?') !== -1 ? (`&key=${this.apiKey}`) : `?key=${this.apiKey}`;
      return url;
    }

    return url;
  }

  request(method, api, data, id?, params?, withToken?, withSecureKey = true, basicHeader = false, dataHeader?) {
    const parsedParams = RequestService.buildApiUrl(params, id);
    const url = this.buildReqUrl(api + parsedParams, withToken, withSecureKey);
    // console.log('URL REQUEST ==>>', url);
    const config: any = [url];
    if (data) { config.push(data); }

    if (dataHeader) {
      const httpSend = {
        'Content-Type': 'application/json',
        ...dataHeader,
      };

      if (!dataHeader.noSendCountry) {
        httpSend.country = countryConfig.indicator;
      } else {
        delete dataHeader.noSendCountry;
      }

      httpOptions = {
        headers: new HttpHeaders(httpSend),
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          country: countryConfig.indicator,
          ...dataHeader,
        }),
      };
    }

    if (environment.multiCountry) {
      if (method) { config.push(basicHeader ? httpOptionsBasic : httpOptions); }
      config.push(basicHeader ? httpOptionsBasic : httpOptions);
    } else {
      config.push(httpOptionsBasic);
    }
    // console.log('api: ', api );
    // console.log('config: ', config );
    // console.log('----------------------------------');
    return this.http[method](...config)
      .pipe(
        map((response: GenericResponseInterface) => (response && response.data ? response.data : response)),
        // tap(res => console.log(api)),
        catchError(error => throwError(this.errorHandler.handleError(api, error))),
      );
  }

  get(api, params?, id?, withToken?, withSecureKey?, basicHeader?, dataHeader?): Observable<any> {
    return this.request('get', api, null, id, params, withToken, withSecureKey, basicHeader, dataHeader);
  }

  post(api, data, params?, withToken?, withSecureKey?, basicHeader?): Observable<any> {
    return this.request('post', api, data, null, params, withToken, withSecureKey, basicHeader);
  }

  put(api, data = {}, params?, id?, withToken?, withSecureKey?, basicHeader?, dataHeader?): Observable<any> {
    return this.request('put', api, data, id, params, withToken, withSecureKey, basicHeader, dataHeader);
  }

  delete(api, id, params?, withToken?, withSecureKey?, basicHeader?): Observable<any> {
    return this.request('delete', api, null, id, params, withToken, withSecureKey, basicHeader);
  }
}
