import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShowSelectAddressEvent {
  private eventShowSelectAddress = new BehaviorSubject({});
  get = this.eventShowSelectAddress.asObservable();

  set(event: any) {
    this.eventShowSelectAddress.next(event);
  }
}
