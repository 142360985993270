import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {SasListService} from '../../../networking/services-v3/sas-subscription/sas-list/sas-list.service';
import {AddressManager} from '../../../manager/customer/address/address.manager';
import {LocalStorageManager} from '../../../manager/local-storage/local-storage.manager';
import {UpdateProductToastEvent} from '../../../../ui/shared/event-listeners/update-product-toast/update-product-toast.event';

@Injectable({
  providedIn: 'root',
})
export class SasListManager {
  constructor(private sasListService: SasListService,
              private addressManager: AddressManager,
              private lsm: LocalStorageManager,
              private productToastEvent: UpdateProductToastEvent) { }

  create(rawData): Observable<any> {
    const {cart, comments, payment, picking} = rawData;
    // console.log({cart, comments, payment, picking});
    const date = new Date(picking.date);
    const customer = this.lsm.get('customer');
    let weekDay = date.getDay();
    weekDay = weekDay === 0 ? 7 : weekDay;
    let data: any = {
      addressId: this.addressManager.getDeliveryAddress().idAddress,
      // amountCart: 1,
      cartId: cart.id || cart.cartId,
      comments,
      customerId: customer.id,
      dayOfMonth: date.getDate(),
      dayOfWeekId: weekDay,
      paymentTypeId: payment.id,
      periodId: picking.periodicity.id,
      periodQuantity: picking.quantity,
      startDate: picking.date,
      creditCardId: payment.creditCardId || null,
    };
    if (data.paymentTypeId.toString() === '3') {
      data = {...data, creditCardId: payment.creditCardId};
    }

    // console.log('CreateSasData:', data);
    // return of(data);
    return this.sasListService.create(data);
  }

  deleteList(subscriptionId, customerId): Observable<any> {
    return this.sasListService.deleteList(subscriptionId, customerId);
  }

  getBasicList(): Observable<any> {
    const customer = this.lsm.get('customer');
    if (customer && !customer.isAnonymous) {
      return this.sasListService.getBasicList(customer.id);
    }
    return of(null);
  }

  getCustomerDiscount(): Observable<any> {
    const prop = 'pasCustomerDiscount';
    if (this.lsm.has(prop)) {
      return of(this.lsm.get(prop));
    }
    let customer = this.lsm.get('customer');
    if (!customer) {
      customer = {id: 0};
    }
    return this.sasListService.getCustomerDiscount(customer.id)
      .pipe(tap(response => this.lsm.set(prop, response)));
  }

  getListByCustomerId(customerId): Observable<any> {
    return this.sasListService.getListByCustomerId(customerId);
  }

  addProductToList(data): Observable<any> {
    return this.sasListService.addProductToList(data);
  }

  editProductInList(data): Observable<any> {
    return this.sasListService.editProductInList(data);
  }

  deleteProductInList(productId, listId): Observable<any> {
    return this.sasListService.deleteProductInList(productId, listId);
  }

  editList(data): Observable<any> {
    return this.sasListService.editList(data);
  }

  reSchedule(data): Observable<any> {
    return this.sasListService.reSchedule(data);
  }

  validateProductInList(subscriptionId, productId): Observable<any> {
    return this.sasListService.validateProductInList(subscriptionId, productId);
  }

  validateProductInCustomerLists(productId): Observable<any> {
    const customer = this.lsm.get('customer');
    return this.sasListService.validateProductInCustomerLists(customer.id, productId);
  }

  getDeliveryByShipping(): Observable<any> {
    const customer = this.lsm.get('customer');
    if (!customer || customer.isAnonymous) {
      return of(null);
    }
    return this.sasListService.getDeliveryByShipping(customer.id);
  }

  getDeliveryByShippingDetail(customerId, shippingId): Observable<any> {
    return this.sasListService.getDeliveryByShippingDetail(customerId, shippingId);
  }

  getPeriodicityText(data): Observable<any> {
    const params = {
      nextDeliveryDateMillis: data.date,
      periodId: data.periodicity.id,
      quantity: data.quantity,
    };
    return this.sasListService.getPeriodicityText(params);
  }

  getShippingSchedule(): Observable<any> {
    const customer = this.lsm.get('customer');
    if (!customer || customer.isAnonymous) {
      return of(null);
    }
    return this.sasListService.getShippingSchedule(customer.id);
  }

  manageProductInList(data): Observable<any> {
    // console.log('manageProductInList:', data);
    const {product, list, quantity} = data;
    return this.validateProductInCustomerLists(product.id)
      .pipe(switchMap(res => {
        // console.log('ValidationRes:', res);
        const productData = {
          subscriptionListId: list[0].id,
          itemId: product.id,
          quantity: quantity + res.quantity,
        };
        // console.log('AddData:', productData);
        const obs = res.subscribed ? this.editProductInList(productData) : this.addProductToList(productData);
        return obs.pipe(
          tap(() => {
            this.productToastEvent.updateProductToast({...data.product, quantity: productData.quantity, sas: {list: true}});
          }),
        );
      }));
  }

  getNotificationSourcesByCustomer(customerId): Observable<any> {
    return this.sasListService.getNotificationSourcesByCustomer(customerId);
  }
}
