import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LocalStorageManager} from '../../../../data/manager/local-storage/local-storage.manager';

@Injectable({
  providedIn: 'root',
})
export class UpdateLocationEvent {
  private deliveryType = new BehaviorSubject(this.lsm.get('enableSas'));
  updatedDeliveryType = this.deliveryType.asObservable();

  constructor(private lsm: LocalStorageManager) { }

  changeDeliveryType(deliveryType: any) {
    this.deliveryType.next(deliveryType);
  }
}
