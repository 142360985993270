import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageManager {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  has(item): boolean {
    return this.isBrowser ? !!localStorage.getItem(item) : null;
  }

  get(item) {
    const itm = this.isBrowser ? localStorage.getItem(item) : null;
    return itm && itm !== 'undefined' ? JSON.parse(itm) : undefined;
  }

  set(key, item): void {
    if (this.isBrowser) {
      localStorage.setItem(key, JSON.stringify(item));
    }
  }

  removeItem(item): void {
    if (this.isBrowser) {
      localStorage.removeItem(item);
    }
  }

  removeAllItemsExcept(props = []): void {
    if (this.isBrowser) {
      const lsItems = window.localStorage;
      // eslint-disable-next-line no-restricted-syntax
      for (const prop in lsItems) {
        // eslint-disable-next-line no-prototype-builtins
        if (lsItems.hasOwnProperty(prop) && props.indexOf(prop) < 0) {
          window.localStorage.removeItem(prop);
        }
      }
    }
  }

  removeAllItems(): void {
    if (this.isBrowser) {
      localStorage.clear();
    }
  }
}
