<app-basic-modal [status]="status"
                 [modalSize]="chosenForReview ? '' : 'sm'"
                 [mobileFullScreen]="chosenForReview"
                 [persistXToClose]="!chosenForReview"
                 [bodyClass]="chosenForReview ? 'p-0' : ''"
                 [dialogClass]="chosenForReview ? 'p-0' : ''"
                 [contentClass]="chosenForReview ? 'p-0' : ''"
                 [headerClass]="chosenForReview ? 'd-none' : ''">
  <div class="text-center"
       [class.permission]="type === typesEnum.PERMISSION_GEO || type === typesEnum.PERMISSION_CAMERA"
       [class.personal-review]="type === isMobileSag || typesEnum.PERSONAL_SECURITY_REVIEW"
       [class.chosen-for-review]="chosenForReview">
    <img *ngIf="modal?.image" [src]="modal?.image" class="image" alt="Modal Icon Image">
    <h4 *ngIf=" modal?.title" class="title">{{ modal?.title }}</h4>
    <div *ngIf="modal?.message" [innerHTML]="modal?.message | safeHtml" class="message"></div>
    <div *ngIf="chosenForReview" class="progress-bar-container">
      <img class="progress-bar-gif" src="assets/gif/gif-progress-bar-bg-blue.gif" alt="Progress bar">
    </div>
    <div class="actions" *ngIf="modal?.action; else customAction">
      <ng-container *ngIf="type === typesEnum.PERMISSION_GEO || type === typesEnum.PERMISSION_CAMERA">
        <app-button class="action-button" [customClasses]="'transparent-sm p-0'" (click)="closeAndExit()" [title]="'Cancelar'"></app-button>
        <app-button class="action-button" [customClasses]="'transparent-sm p-0'" (click)="action()" [title]="'Configuración'"></app-button>
      </ng-container>
    </div>
    <ng-template #customAction>
      <ng-content></ng-content>
    </ng-template>
  </div>
</app-basic-modal>
