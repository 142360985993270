import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SizeDetectorComponent} from './size-detector.component';
import {ResizeService} from './resize.service';

@NgModule({
  declarations: [SizeDetectorComponent],
  imports: [
    CommonModule,
  ],
  exports: [SizeDetectorComponent],
  providers: [ResizeService],
})
export class SizeDetectorModule { }
