import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {debounceTime, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {countryConfig, countries} from 'src/country-config/country-config';
import {LocationService} from '../../networking/services/location/location.service';
import {LocalStorageManager} from '../local-storage/local-storage.manager';
import {SessionStorageManager} from '../session-storage/session-storage.manager';
import {DeliveryTypeEnum} from '../../networking/models/location/enum/delivery-type.enum';
import {UpdateDeliveryTypeEvent} from '../../../ui/shared/event-listeners/update-delivery-type/update-delivery-type.event';
import {UpdateLocationEvent} from '../../../ui/shared/event-listeners/update-location/update-location.event';
import {UpdateAvailabilityEvent} from '../../../ui/shared/event-listeners/update-availability/update-availability.event';

@Injectable({
  providedIn: 'root',
})
export class LocationManager {
  keyClient = {keyClient: countryConfig.generalEnv.keyClient};

  constructor(
    private locationService: LocationService,
    private lsm: LocalStorageManager,
    private ssm: SessionStorageManager,
    private deliveryTypeEvent: UpdateDeliveryTypeEvent,
    private updateAvailabilityEvent: UpdateAvailabilityEvent,
    private updateLocationEvent: UpdateLocationEvent,
  ) {}

  getCountries(): Observable<any> {
    return this.locationService.getCountries();
  }

  getCountriesFtd() {
    return countries.filter((cou: any) => cou.active = countryConfig.indicator !== cou.indicator);
  }

  getMainCountry() {
    return countries.filter((cou: any) => cou.active = countryConfig.indicator === cou.indicator)[0];
  }

  getCities(deliveryType, availability?): Observable<any> {
    deliveryType = deliveryType.toUpperCase();
    let cities = this.ssm.get('cities');
    // if (!!cities && cities[deliveryType]) {
    //   if (deliveryType === DeliveryTypeEnum.EXPRESS) {
    //     this.setDefaultCity(cities[deliveryType]);
    //   }
    //   if (countryConfig.isVenezuela && availability) {
    //     return of(this.mapCitiesVzla(cities[deliveryType]));
    //   }
    //   return of(cities[deliveryType]);
    // }
    return this.locationService.getCities({deliveryType})
      .pipe(
        map(res => res.items),
        tap(res => {
          if (!cities) {
            cities = {};
          }

          if (deliveryType === DeliveryTypeEnum.EXPRESS) {
            this.setDefaultCity(res);
          }

          const ct = {};
          ct[deliveryType] = res;
          if (!cities[deliveryType]) {
            cities = {...cities, ...ct};
          } else {
            cities[deliveryType] = res;
          }

          this.ssm.set('cities', cities);
          if (countryConfig.isVenezuela) { this.getActiveCities(); }
        }),
        shareReplay(1),
      );
  }

  private mapCitiesVzla(cities) {
    const cts = cities.filter(city => city.active)
      .map(city => {
        if (city.municipalityList) {
          city.municipalityList.filter(mun => mun.active)
            .map(mun => {
              if (mun.coordinates) {
                mun.coordinates = this.transformCoordinate(mun.coordinates);
              }
              return mun.coordinates;
            });
        }
        return city;
      });
    return cts?.length > 0 ? cts : cities;
  }

  transformCoordinate(coordinates) {
    return coordinates.split(';').map(i => i.split(',').reverse().map(c => Number(c)));
  }

  setDefaultCity(cities) {
    const localCity = this.ssm.get('city');
    let deliveryAddress = this.lsm.get('deliveryAddress');

    if (deliveryAddress && deliveryAddress.deliveryType === DeliveryTypeEnum.ENVIALOYA) {
      deliveryAddress = countryConfig.generalEnv.deliveryTypes.envialoya;
      this.setCity(deliveryAddress);
      return;
    }
    if (deliveryAddress && deliveryAddress.deliveryType === DeliveryTypeEnum.NATIONAL) {
      deliveryAddress = countryConfig.generalEnv.deliveryTypes.national;
      this.setCity(deliveryAddress);
      return;
    }
    // console.log({cities}, {localCity}, localCity.toString());
    if (!localCity || (!localCity.id && !localCity.deliveryType)) {
      const cityToSearch = deliveryAddress || countryConfig.generalEnv.deliveryTypes.express;
      // console.log({cityToSearch});
      cities.forEach(city => {
        // console.log({city});
        if (city.id === cityToSearch.city || city.id === cityToSearch.id) {
          this.setCity(city);
        }
      });
    }
  }

  validateDeliveryTypeUpdate(location, localCity?) {
    if (!localCity || !localCity.deliveryType
      || localCity.deliveryType !== location.deliveryType
      || (location.deliveryType === DeliveryTypeEnum.EXPRESS
        && localCity.deliveryType === DeliveryTypeEnum.EXPRESS
        && location.id !== localCity.id)) {
      // console.log('UpdateLocationEvent:', location);
      this.updateLocationEvent.changeDeliveryType(location);
    }
  }

  setCity(city) {
    // console.log('Setting city:', city);
    const localCity = this.city;
    this.ssm.set('city', city);
    this.updateAvailabilityEvent.cityAvailabilityUpdate(city.active);
    this.validateDeliveryTypeUpdate(city, localCity);
  }

  get city() {
    const city = this.ssm.get('city');
    return city || {};
  }

  getCity() {
    return of(this.city);
  }

  getDeliveryType() {
    let {city} = this;
    const deliveryAddress = this.lsm.get('deliveryAddress');
    city = city && city.deliveryType ? city : deliveryAddress && deliveryAddress.deliveryType ? deliveryAddress : {};
    // console.log({city});
    return city && city.deliveryType ? city.deliveryType : 'EXPRESS';
  }

  getIdStoreGroup() {
    let {city} = this;
    const deliveryAddress = this.lsm.get('deliveryAddress');
    city = city && city.deliveryType ? city : deliveryAddress && deliveryAddress.deliveryType ? deliveryAddress : {};
    // console.log({city});
    return city && city.defaultStore ? city.defaultStore : countryConfig.generalEnv.deliveryTypes.express.defaultStore;
  }

  getSupportContact(): Observable<any> {
    const prop = 'supportContact';
    const contacts = this.lsm.get(prop);
    if (contacts) {
      return of(contacts);
    }
    return this.locationService.getSupportContact(this.keyClient).pipe(
      tap((response: any) => {
        if (response.items) {
          this.lsm.set(prop, response.items);
          return response.items;
        }
      }),
    );
  }

  getDeliveryTypeText(): Observable<any> {
    const deliveryTypeText = 'deliveryTypeText';
    if (this.ssm.has(deliveryTypeText)) { return of(this.ssm.get(deliveryTypeText)); }
    return this.locationService.getDeliveryTypeText()
      .pipe(
        debounceTime(500),
        map(r => {
          const dtt = {};
          // eslint-disable-next-line no-restricted-syntax
          for (const prop in r) {
            if (r[prop]) {
              dtt[prop] = {};
              // eslint-disable-next-line no-restricted-syntax
              for (const label in r[prop]) {
                if (r[prop][label]) {
                  dtt[prop][label.toUpperCase()] = r[prop][label];
                }
              }
            }
          }
          // console.log('dtt =>', dtt);
          return dtt;
        }),
        tap(r => this.ssm.set(deliveryTypeText, r)),
        shareReplay(1),
      );
  }

  autocomplete(data): Observable<any> {
    const customer = this.lsm.get('customer');
    data = {
      ...data,
      // city: data.city ? data.city : '',
      token: customer.token.token,
      tokenIdWebSafe: customer.token.tokenIdWebSafe,
    };
    return this.locationService.autocomplete(data).pipe(map(res => res.predictions));
  }

  updateDeliveryType(address) {
    // console.log('UpdateDT:', address);
    switch (address.deliveryType) {
      case DeliveryTypeEnum.ENVIALOYA:
        this.setCity(countryConfig.generalEnv.deliveryTypes.envialoya);
        this.deliveryTypeEvent.changeDeliveryType(countryConfig.generalEnv.deliveryTypes.envialoya);
        break;
      case DeliveryTypeEnum.NATIONAL:

        this.setCity(countryConfig.generalEnv.deliveryTypes.national);
        this.deliveryTypeEvent.changeDeliveryType(countryConfig.generalEnv.deliveryTypes.national);
        break;
      default:
        this.getCities(DeliveryTypeEnum.EXPRESS)
          .subscribe(cities => {
            const city = cities.filter(ct => ct.id === address.city)[0];
            // console.log('setCity', city);

            this.setCity(city);
            this.deliveryTypeEvent.changeDeliveryType(city);
          });
        break;
    }
  }

  getAllStores(): Observable<any> {
    const cityName = this.city && this.city.name ? this.city.name : 'Bogotá';
    return this.locationService.getAllStores({cityName});
  }

  getLocation() {
    return this.lsm.get('deliveryAddress');
  }

  getCityById(cityId): Observable<any> {
    let city;
    return this.getCities(DeliveryTypeEnum.EXPRESS)
      .pipe(
        switchMap(cities => {
          city = cities.filter(c => c.id === cityId)[0];
          // console.log('city EXPRESS:', city);
          return city ? of({city}) : this.getCities(DeliveryTypeEnum.NATIONAL);
        }),
        switchMap(res => {
          if (!res.city) {
            city = res.filter(c => c.id === cityId)[0];
            // console.log('city NATIONAL:', city);
          } else {
            city = res.city;
          }
          return city ? of(city) : throwError({message: 'Ciudad no encontrada'});
        }),
      );
  }

  getEnvialoYaCityByName(cityName): Observable<any> {
    return this.getCities(DeliveryTypeEnum.ENVIALOYA)
      .pipe(
        map(cities => {
          let city;
          cities.forEach(c => {
            if (cityName.indexOf(c.name.toLowerCase()) >= 0) {
              city = c;
            }
          });
          // console.log('city ENVIALOYA:', city);
          if (city) { return city; }
          const message = 'Ciudad no encontrada. Por favor selecciona la ciudad correspondiente de la lista.';
          throw new Error(message);
        }),
      );
  }

  getNameMainCity(): string {
    return countryConfig.isColombia ? 'Bogotá' : 'Caracas';
  }

  getActiveCities() {
    const cities = this.ssm.get('cities');
    if(!cities) { return false; }
    cities.EXPRESS = cities.EXPRESS ? this.disableCities(cities.EXPRESS) : [];
    this.locationService.getActiveCities().subscribe((res: any) => {
      for (const cityActive of res.cities) {
        const index = cities.EXPRESS.findIndex(city => city.id === cityActive.idCity);
        if (cities.EXPRESS[index]) { cities.EXPRESS[index].active = true; }
        if (cities.EXPRESS[index]?.municipalityList) {
          for (const munActive of cityActive.municipalities) {
            const indexMun = cities.EXPRESS[index].municipalityList.findIndex(mun => mun.name === munActive.name);
            if (cities.EXPRESS[index].municipalityList[indexMun]) {
              cities.EXPRESS[index].municipalityList[indexMun].active = true;
            }
          }
        }
      }
      this.ssm.set('cities', cities);
      this.currentCityUpdate(cities);
    });
  }

  private disableCities(cities: any): any {
    return cities.map(city => {
      city.active = false;
      if (city.municipalityList) {
        city.municipalityList = city.municipalityList.map(mun => {
          mun.active = false;
          return mun;
        });
      }
      return city;
    });
  }

  private currentCityUpdate(cities) {
    const localCity = this.ssm.get('city');
    cities.EXPRESS.map(city => {
      if (city.id === localCity.id) {
        this.setCity(city);
      }
      return city;
    });
  }

  getCurrentPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => { reject(err); });
    });
  }
}
