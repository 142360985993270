import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageManager {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  has(item): boolean {
    return this.isBrowser ? !!window.sessionStorage.getItem(item) : null;
  }

  get(item) {
    const itm = this.isBrowser ? window.sessionStorage.getItem(item) : null;
    return itm && itm !== 'undefined' ? JSON.parse(itm) : undefined;
  }

  set(key, item): void {
    if (this.isBrowser) {
      window.sessionStorage.setItem(key, JSON.stringify(item));
    }
  }

  removeItem(item): void {
    if (this.isBrowser) {
      window.sessionStorage.removeItem(item);
    }
  }

  removeAllItemsExcept(props = []): void {
    if (this.isBrowser) {
      const ssItems = window.sessionStorage;
      // eslint-disable-next-line no-restricted-syntax
      for (const prop in ssItems) {
        // eslint-disable-next-line no-prototype-builtins
        if (ssItems.hasOwnProperty(prop) && props.indexOf(prop) < 0) {
          window.sessionStorage.removeItem(prop);
        }
      }
    }
  }

  removeAllItems(): void {
    if (this.isBrowser) {
      window.sessionStorage.clear();
    }
  }
}
