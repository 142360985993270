import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateOnlineOnlyModalEvent {
  private product = new Subject();
  onlineOnlyProduct = this.product.asObservable();

  updateProductOnlineOnlyProduct(product: any) {
    this.product.next(product);
  }
}
