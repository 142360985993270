import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppCustomPreloader} from './app-routing-loader';
import {SessionGuard} from './data/auth/guards/session.guard';
import {CallCenterGuard} from './data/auth/guards/call-center.guard';
import {ColombiaGuard} from './data/auth/guards/colombia.guard';

const routes: Routes = [
  {
    path: 'producto/:id',
    loadChildren: () => import('./ui/product-detail/product-detail.module').then(m => m.ProductDetailModule),
  },
  {
    path: 'product/:id/:name',
    redirectTo: 'producto/:id',
  },
  {
    path: '#/product/:id/:name',
    redirectTo: 'producto/:id',
  },
  {
    path: 'product/:id',
    redirectTo: 'producto/:id',
  },
  {
    path: 'producto/:id/:name',
    redirectTo: 'producto/:id',
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./ui/home/home.module').then(m => m.HomeModule),
    //data: {preload: true},
  },
  {
    path: 'buscar',
    pathMatch: 'full',
    loadChildren: () => import('./ui/algolia/algolia.module').then(m => m.AlgoliaModule),
  },
  {
    path: 'carrito',
    pathMatch: 'full',
    loadChildren: () => import('./ui/cart/cart.module').then(m => m.CartModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('./ui/checkout/checkout.module').then(m => m.CheckoutModule),
    canActivate: [SessionGuard],
  },
  {
    path: 'catalogo',
    pathMatch: 'full',
    loadChildren: () => import('./ui/catalogo/catalogo.module').then(m => m.CatalogoModule),
  },
  {
    path: 'envialo-ya',
    pathMatch: 'full',
    loadChildren: () => import('./ui/envialo-ya/envialo-ya.module').then(m => m.EnvialoYaModule),
  },
  {
    path: 'legal/:page',
    pathMatch: 'full',
    loadChildren: () => import('./ui/legal/legal.module').then(m => m.LegalModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'terminos-y-condiciones',
    pathMatch: 'full',
    loadChildren: () => import('./ui/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'registro',
    pathMatch: 'full',
    loadChildren: () => import('./ui/session/session.module').then(m => m.SessionModule),
  },
  {
    path: 'departamentos',
    pathMatch: 'full',
    loadChildren: () => import('./ui/departments-responsive/departments-responsive.module')
      .then(m => m.DepartmentsResponsiveModule),
  },
  {
    path: 'mundo-ofertas',
    loadChildren: () => import('./ui/world-offers/world-offers.module').then(m => m.WorldOffersModule),
    canActivate: [ColombiaGuard],
  },
  {
    path: 'mundoOfertas',
    loadChildren: () => import('./ui/world-offers/world-offers.module').then(m => m.WorldOffersModule),
    canActivate: [ColombiaGuard],
  },
  {
    path: 'programate-y-ahorra',
    loadChildren: () => import('./ui/program-and-save/program-and-save.module').then(m => m.ProgramAndSaveModule),
    canActivate: [ColombiaGuard],
  },
  {
    path: 'escanea-y-listo',
    loadChildren: () => import('./ui/scan-and-go/scan-and-go.module').then(m => m.ScanAndGoModule),
    // canActivate: [ColombiaGuard],
  },
  {
    path: 'mundo-ofertas/:idGroup',
    loadChildren: () => import('./ui/group-detail/group-detail.module').then(m => m.GroupDetailModule),
    canActivate: [ColombiaGuard],
  },
  {
    path: 'marca/:nameBrand',
    loadChildren: () => import('./ui/brand/brand.module').then(m => m.BrandModule),
  },
  {
    path: 'marcas/:brand',
    loadChildren: () => import('./ui/provider-landing/provider-landing.module').then(m => m.ProviderLandingModule),
  },
  {
    path: 'marcas',
    loadChildren: () => import('./ui/provider-general/provider-general.module').then(m => m.ProviderGeneralModule),
  },
  // {
  //   path: 'fanaticas',
  //   loadChildren: () => import('./ui/fanaticas-landing/fanaticas-landing.module').then(m => m.FanaticasLandingModule),
  // },
  {
    path: 'spotscan',
    loadChildren: () => import('./ui/spot-scan/spot-scan.module').then(m => m.SpotScanModule),
  },
  {
    path: 'sugeridos/:idGroup',
    loadChildren: () => import('./ui/group-detail/group-detail.module').then(m => m.GroupDetailModule),
  },
  {
    path: 'destacados/:idGroup',
    loadChildren: () => import('./ui/group-detail/group-detail.module').then(m => m.GroupDetailModule),
  },
  {
    path: 'categorias/:deparment',
    loadChildren: () => import('./ui/categories/categories.module').then(m => m.CategoriesModule),
  },
  {
    path: 'categorias/:deparment/:categorie',
    loadChildren: () => import('./ui/categories/categories.module').then(m => m.CategoriesModule),
  },
  {
    path: 'categorias/:deparment/:categorie/:subCategorie',
    loadChildren: () => import('./ui/categories/categories.module').then(m => m.CategoriesModule),
  },
  {
    path: 'ordenes',
    loadChildren: () => import('./ui/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [SessionGuard],
  },
  {
    path: 'detalle-orden/:id',
    loadChildren: () => import('./ui/order-detail/order-detail.module').then(m => m.OrderDetailModule),
    canActivate: [SessionGuard],
  },
  {
    path: 'calificar/:id',
    loadChildren: () => import('./ui/qualify-order/qualify-order.module').then(m => m.QualifyOrderModule),
    canActivate: [SessionGuard],
  },
  {
    path: 'error',
    pathMatch: 'full',
    loadChildren: () => import('./ui/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: 'redime-tu-cupon',
    loadChildren: () => import('./ui/cupon/cupon.module').then(m => m.CuponModule),
  },
  {
    path: 'tiendas',
    loadChildren: () => import('./ui/locate-store/locate-store.module').then(m => m.LocateStoreModule),
  },
  {
    path: 'cuenta',
    loadChildren: () => import('./ui/customer-profile/customer-profile.module').then(m => m.CustomerProfileModule),
    //canActivate: [RouteGuardService],
  },
  {
    path: 'lifemiles',
    loadChildren: () => import('./ui/life-miles-page/life-miles-page.module').then(m => m.LifeMilesPageModule),
    canActivate: [SessionGuard, ColombiaGuard],
  },
  {
    path: '404',
    loadChildren: () => import('./ui/not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: 'call-center',
    loadChildren: () => import('./ui/call-center/call-center.module').then(m => m.CallCenterModule),
    canActivate: [CallCenterGuard, ColombiaGuard],
  },
  {
    path: 'producto/:id',
    loadChildren: () => import('./ui/product-detail/product-detail.module').then(m => m.ProductDetailModule),
  },
  {
    path: 'covid',
    loadChildren: () => import('./ui/covid/covid.module').then(m => m.CovidModule),
  },
  {
    path: 'tutorial/:id',
    loadChildren: () => import('./ui/shared/modules/tutorials/tutorials.module').then(m => m.TutorialsModule),
  },
  {
    path: 'mobile',
    loadChildren: () => import('./ui/customer-profile/zendesk-support/zendesk-support.module').then(m => m.ZendeskSupportModule),
  },
  {
    path: 'content/:tag',
    loadChildren: () => import('./ui/content/content.module').then(m => m.ContentModule),
  },
  {
    path: 'favoritos',
    loadChildren: () => import('./ui/favorites/favorites.module').then((m) => m.FavoritesModule),
    canActivate: [SessionGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('./ui/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    initialNavigation: 'enabled',
    preloadingStrategy: AppCustomPreloader,
})],
  exports: [RouterModule],
  providers: [AppCustomPreloader],
})
export class AppRoutingModule {}
