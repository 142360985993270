import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {P2cService} from '../../networking/services-v3/p2c/p2c.service';


@Injectable({
  providedIn: 'root'
})
export class P2cManager {

  constructor(private service: P2cService) { }

  createCustomerBank(data: any): Observable<any> {
    return this.service.createCustomerBank(data);
  }

  getCustomerBanks(idCustomer: string): Observable<any> {
    return this.service.getCustomerBanks(idCustomer);
  }

  updateCustomerBank(customerBank: string): Observable<any> {
    return this.service.updateCustomerBank(customerBank);
  }

  remCustomerBank(idCustomerBank: string): Observable<any> {
    return this.service.remCustomerBank(idCustomerBank);
  }

  getBanksList(): Observable<any> {
    return this.service.getBanksList();
  }

  verifyOrderActives(idCustomer: string): Observable<any> {
    return this.service.verifyOrderActives(idCustomer);
  }

  sendPayment(order: string, payment: string, reference: string, amount: string ): Observable<any> {
    return this.service.sendPayment(order, payment, reference, amount);
  }

  confirmPaymentOrder(params: any): Observable<any> {
    return this.service.confirmPaymentOrder(params);
  }
}
