export const sasSubscriptionApi = {
  basicList: 'customer/v1/subscription-list/:customerId/my-list',
  create: 'customer/v1/subscription-list/cart',
  customerDiscount: 'customer/v1/subscription-list/customer/:customerId/discount',
  deleteList: 'customer/v1/subscription-list/:subscriptionId/customer',
  listByCustomer: 'customer/v1/subscription-list/customer',
  listProduct: 'customer/v1/subscription-list/item',
  deleteDetailList: 'customer/v1/subscription-list/item/:productId/list/:listId',
  editList: 'customer/v1/subscription-list',

  reSchedule: 'customer/v1/subscription-list/reschedule',
  validateProductInList: 'customer/v1/subscription-list/item/validate/list/:subscriptionId/item/:productId',
  validateProductInCustomerLists: 'customer/v1/subscription-list/validate/customer/:customerId/item/:productId',

  // '?nextDeliveryDateMillis=:startDate&periodId=:periodId&quantity=:quantity',
  deliveryByShipping: 'customer/v1/subscription-list/shipping/delivery/customer/:customerId',
  deliveryByShippingDetail: 'customer/v1/subscription-list/shipping/delivery/info/:shippingId/customer/:customerId',
  periodicityText: 'customer/v1/subscription-list/periodicity/delivery/text',
  shippingSchedule: 'customer/v1/subscription-list/customer/:customerId/shipping/schedule',

  // TODO: REVISAR!!
  notification: 'customer/v1/subscription/notification/customer',
};
