/* eslint-disable no-console */
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {BootstrapModuleFn as Bootstrap, hmr, WebpackModule} from '@ngxs/hmr-plugin';
// import 'zone.js/dist/task-tracking';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
// import {AppBrowserModule} from './app/app.browser.module';

declare const module: WebpackModule;
if (environment.production) {
  enableProdMode();
}

const bootstrap: Bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

function bootstrapping() {
  bootstrap().catch(err => console.log(err));
}

function load() {
  if (document.readyState !== 'loading') {
    bootstrapping();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      bootstrapping();
    });
  }
}

if (environment.hmr) {
  hmr(module, bootstrap).catch(err => console.error(err));
} else {
  load();
}
