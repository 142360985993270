import {CustomerState} from './customer.state';
import {CartState} from './cart.state';
import {AddressState} from './address.state';
import {SasCartState} from './sas-cart.state';
import {CreditCardState} from './credit-card.state';
import {ApiKeyState} from './api-key.state';
import {ScanAndGoState} from './scan-and-go.state';

export const states = [
  CustomerState,
  CartState,
  AddressState,
  SasCartState,
  CreditCardState,
  ApiKeyState,
  ScanAndGoState,
];
