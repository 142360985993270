export class SaveCartAction {
  static readonly type = '[Cart] Save Cart';
  constructor(public cart: any) {}
}

export class DeleteCartAction {
  static readonly type = '[Cart] Delete Cart';
  // constructor() {}
}

export class UpdateCartProductAction {
  static readonly type = '[Cart] Update Cart Product';
  constructor(public product: any, public quantity: any) {}
}

export class RemoveCartProductAction {
  static readonly type = '[Cart] Remove Cart Product';
  constructor(public product: any) {}
}
