import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import {BannerService} from '../../networking/services/cms/banner.service';
import {NewBanners} from '../../networking/models/department/requests/banners';
import {BannerRes} from '../../networking/models/cms/responses/banner-res';
import {WindowService} from '../../networking/services/shared/window/window.service';
import {BrowserDetectorService} from '../../networking/services/shared/broswer-detector/browser-detector.service';
import {GoogleTagService} from '../../networking/services/shared/google-tag/google-tag.service';
import {HomeService} from '../../networking/services/home/home.service';
import {Product} from '../../networking/models/product/responses/product.class';
import {defaultData} from '../../../ui/home/default-home-data';
import {countryConfig} from '../../../../country-config/country-config';
import {CustomerManager} from '../customer/customer/customer.manager';
import {LocationManager} from '../location/location.manager';

@Injectable({
  providedIn: 'root',
})
export class CmsManager {
  isBrowser;

  constructor(private bannerService: BannerService,
              private windowService: WindowService,
              private homeService: HomeService,
              private browserDetectorService: BrowserDetectorService,
              private gtmService: GoogleTagService,
              private customerManager: CustomerManager,
              private locationManager: LocationManager,
              @Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  getBanners(params: NewBanners): Observable<BannerRes> {
    // console.log('GET BANNERS PARAMS', {params})
    const width = this.isBrowser ? (window.innerWidth < 600 ? 945 : window.innerWidth) : 1500; // this.windowService.getInnerWidth();
    const bannerWidth = params.type === 'MAIN_BANNER' && width < 992 ? width + 150 : params.type === 'NEW_SERVICES' ? 450 : width;
    return this.bannerService.get(params)
    .pipe(map(response => {
      // console.log('banner response', response);
      if (!response) { return []; }
      const browser = this.browserDetectorService.checkBrowser();
      const imgFormat = browser.chrome || browser.firefox ? '-rw' : '';
      // console.log('Banners =>', response.items, response.items.length);
      return response.map(i => { i.urlBanner += `=s${bannerWidth}${imgFormat}`; return i; });
    }),
      tap(banners => this.gtmService.onImpressionsPromo(banners)));
  }

  getBannersHome(params: NewBanners): Observable<BannerRes> {
    const width = this.isBrowser ? (window.innerWidth < 600 ? 945 : window.innerWidth) : 1500; // this.windowService.getInnerWidth();
    const browser = this.browserDetectorService.checkBrowser();
    const imgFormat = browser.safari || browser.ie ? '' : '-rw';
    const bannerWidth = params.type === 'MAIN_BANNER' && width < 992 ? width + 150 : params.type === 'NEW_SERVICES' ? 450 : width;
    return this.bannerService.getBannersHome(params)
      .pipe(map(response => {
        // console.log(response);
        if (response.desktop && response.desktop.mainBanner) {
          response.desktop.mainBanner = response.desktop.mainBanner.map(i => { i.urlBanner += `=s${bannerWidth}${imgFormat}`; return i; });
        }
        if (response.mobile && response.mobile.mainBanner) {
          response.mobile.mainBanner = response.mobile.mainBanner.map(i => { i.urlBanner += `=s${bannerWidth}${imgFormat}`; return i; });
        }
        return response;
      }));
  }

  getHome(params, screenSize?, ssrData?) {
    const defaultTokens = countryConfig.ssrTokens;
    const customer = this.isBrowser ? this.customerManager.customer : null;
    const idStore = this.isBrowser ? parseInt(this.locationManager.getIdStoreGroup(), 10) : 26;
    params = {
      idStoreGroup: idStore,
      idCustomerWebSafe: customer?.idCustomerWebSafe || defaultTokens?.idCustomerWebSafe,
      tokenIdWebSafe: customer?.token?.tokenIdWebSafe || defaultTokens?.token?.tokenIdWebSafe,
      token: customer?.token?.token || defaultTokens?.token?.token,
      source: this.isBrowser ? ((screenSize?.mobile || window.innerWidth < 992) ? 'RESPONSIVE' : 'WEB') : 'WEB',
      // carouselLimit: 15,
      city: this.isBrowser ? (idStore < 1000 ? (this.locationManager.city?.id || 'BOG') : '') : 'BOG',
      ...params,
    };

    const obs = ssrData ? of(defaultData) : this.homeService.getHome(params);
    // const obs: any = of(defaultData);

    const width = this.isBrowser ? (window.innerWidth < 600 ? 945 : window.innerWidth) : 1500; // this.windowService.getInnerWidth();
    const browser = this.browserDetectorService.checkBrowser();
    const imgFormat = browser.safari || browser.ie ? '' : '-rw';
    const bannerWidth = params.type === 'MAIN_BANNER' && width < 992 ? width + 150 : params.type === 'NEW_SERVICES' ? 450 : width;
    return obs
      .pipe(map((response: any) => {
        // console.log(response);
        const source = response.homeSections;
        const banners = source.filter(type => type.componentType === 'BANNER');
        const thinBanners = source.filter(type => type.componentType === 'THIN_BANNER');
        const staticBanners = source.filter(type => type.componentType === 'STATIC_BANNER');
        const itemList = source.filter(type => type.componentType?.includes('ITEM_LIST'));
        if (banners[0] && banners[0].list && banners[0].list[0]) {
          banners[0].list = [...banners[0].list]
            .map( i => { 
              const postfix = `=s${bannerWidth}${imgFormat}`;
              if(!i.urlBanner?.endsWith(postfix)) { i.urlBanner+= postfix; }
              return i; 
            });
        }
        if (thinBanners[0] && thinBanners[0].urlBanner) {
          thinBanners.urlBanner = thinBanners
            .map(i => {
              const found = i.urlBanner.includes('-rw');
              if (!found) {
                i.urlBanner += `=s${bannerWidth}${imgFormat}`;
              }
              return i;
            });
        }
        if (staticBanners && staticBanners[0] && staticBanners[0].list && staticBanners[0].list[0]) {
          staticBanners[0].list = staticBanners[0].list
            .map(i => { i.urlBanner += `=s${bannerWidth}${imgFormat}`; return i; });
        }
        if (itemList) {
          itemList.map(item => {
            const array = [];
            return !item.list ? [] : item.list.map(i => {
              if (i.type === 'UNIQUE') {
                i.product = i.product && i.product[0] ? new Product(i.product[0]) : new Product(i.product);
                array.push(i.product);
              }
              if (i.action === 'SUGGESTED') {
                // console.log('Suggested', i);
                i.url = `/sugeridos/${i.id}`;
                i = new Product(i);
                array.push(i);
              }
              if (i.action === 'HIGHLIGHTED') {
                // console.log('hightlighted', i);
                i.url = `/destacados/${i.id}`;
                i = new Product(i);
                array.push(i);
              }
              item.products = array;
              return i;
            });
          });
        }

        const count = {
          list: 0,
          banner: 0,
        };
        response.homeSections = response.homeSections.map(c => {
          if (c.componentType === 'HTML_LABEL' && c.label.toLowerCase().indexOf('hola') >= 0) {
            c.ssr = true;
          }

          if (c.componentType === 'ITEM_LIST' && count.list === 0) {
            c.ssr = true;
            count.list++;
          }

          if (c.componentType === 'THIN_BANNER' && count.banner === 0) {
            c.ssr = true;
            count.banner++;
          }
          return c;
        });

        return response;
      }));
  }
}
