import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Select, Store} from '@ngxs/store';
import {SasCartService} from '../../../networking/services-v3/sas-subscription/sas-cart/sas-cart.service';
import {SasListManager} from '../sas-list/sas-list.manager';
import {SessionStorageManager} from '../../../manager/session-storage/session-storage.manager';
import {DeleteSasCartAction, SaveSasCartAction, UpdateSasCartProductAction} from '../../../actions/sas-cart.actions';
import {Product} from '../../../networking/models/product/responses/product.class';
import {SasAlgoliaManager} from '../../sas-algolia/sas-algolia.manager';
import {LocationManager} from '../../../manager/location/location.manager';
import {DeliveryTypeEnum} from '../../../networking/models/location/enum/delivery-type.enum';
import {UpdateOnBoardingModalEvent} from '../../../../ui/shared/event-listeners/update-on-boarding-modal/update-on-boarding-modal.event';
import {GoogleTagService} from '../../../networking/services/shared/google-tag/google-tag.service';
import {ToastEvent} from '../../../../ui/shared/event-listeners/toast/toast.event';
import {LocalStorageManager} from '../../../manager/local-storage/local-storage.manager';
import {SasProductNotAvailableEvent} from '../../../../ui/shared/event-listeners/sas-product-not-available/sas-product-not-available.event';
import {UpdateProductToastEvent} from '../../../../ui/shared/event-listeners/update-product-toast/update-product-toast.event';

@Injectable({
  providedIn: 'root',
})
export class SasCartManager {
  constructor(private sasCartService: SasCartService,
              private sasListManager: SasListManager,
              private ssm: SessionStorageManager,
              private sasAlgoliaManager: SasAlgoliaManager,
              private locationManager: LocationManager,
              private store: Store,
              private onBoardingModalStatusEvent: UpdateOnBoardingModalEvent,
              private gtmService: GoogleTagService,
              private toastEvent: ToastEvent,
              private lsm: LocalStorageManager,
              private productToastEvent: UpdateProductToastEvent,
              private sasProductNotAvailableEvent: SasProductNotAvailableEvent) { }

  @Select(state => state.sasCartStore) sasCart$: Observable<any>;

  create(data?): Observable<any> {
    const customer = this.lsm.get('customer');
    data = {
      ...data,
      customerId: customer.id,
    };
    return this.sasCartService.create(data)
      .pipe(
        catchError(() => null),
        tap(cart => this.saveSasCart(cart)),
        // shareReplay(1)
      );
  }

  createDetail(rawData): Observable<any> {
    return this.getCart()
      .pipe(switchMap(cart => {
        // console.log('basic cart:', cart);
        if (!cart) {
          return this.sasListManager.getBasicList()
            .pipe(switchMap(list => {
              // console.log('basic list:', list);
              if (!list) {
                return this.create();
              }
              return this.sasListManager.manageProductInList({...rawData, list: list.subscriptionListName});
            }));
        }
        return of(cart);
      }), switchMap(res => {
        // console.log('sw cart:', res);
        if (res.subscriptionListId) {
          return of(null);
        }
        // console.log({rawData});
        const data: any = {
          cartId: res.id,
          item: rawData.product.id,
          quantity: rawData.quantity,
          markedToExpress: false,
          origin: rawData.product.rsList ? rawData.product.rsList : (rawData.product.origin || ''),
        };
        if (rawData.product.observations && rawData.product.observations !== '') {
          data.observations = rawData.product.observations;
        }
        return this.sasCartService.createDetail(data)
          .pipe(
            tap(response => {
              // console.log('createDetail:', response)
              if (response && response.id) {
                if (res.amountCart === 0 && data.quantity > 0) {
                  this.onBoardingModalStatusEvent.changeModalStatus({open: true});
                }
                const eventData = {
                  product: rawData.product,
                  list: rawData.product.rsList || '',
                  preprocessor: rawData.preprocessor,
                  cartType: 'subscriptionCart',
                };
                if (data.quantity > 0) {
                  this.gtmService.onAddToCart(eventData);
                  this.productToastEvent.updateProductToast({...rawData.product, quantity: rawData.quantity, sas: true});
                } else {
                  this.gtmService.onRemoveFromCart(eventData);
                  this.toastEvent.updateToast({success: true, message: 'Producto eliminado satisfactoriamente'});
                }
                this.store.dispatch(new UpdateSasCartProductAction(rawData.product, rawData.quantity))
                  .subscribe();
              }
            }),
          );
      }), catchError(() => {
        // console.log('Error:', error);
        if (rawData.quantity > 0) {
          this.sasProductNotAvailableEvent.changeStatus({open: true});
        }
        return null;
      }));
  }

  removeSasProduct(data): Observable<any> {
    data = {
      ...data,
      quantity: 0,
    };
    return this.createDetail(data);
  }

  delete(): Observable<any> {
    const cart = this.getLocalCart();
    if (cart) {
      return this.sasCartService.delete(cart.id)
        .pipe(
          tap(() => this.deleteCartAction()),
        );
    }
    return of(null);
  }

  deleteCartAction() {
    this.store.dispatch(new DeleteSasCartAction()).subscribe();
    return this.ssm.removeItem('sasCart');
  }

  getBilling(): Observable<any> {
    const cart = this.getLocalCart();
    const customer = this.lsm.get('customer');
    const id = customer.isAnonymous ? cart?.anonymousUuid : customer.id;
    return this.sasCartService.getBilling(id, {deliveryType: this.locationManager.getDeliveryType()})
      .pipe(map(response => {
        response = {...response, ...response.amount};
        response.delivery = [{value: response.delivery, isDefault: true}];
        response.savings = response.saving;
        response.deliveryType = DeliveryTypeEnum.SUBSCRIPTION;
        return response;
      }));
  }

  saveSasCart(cart) {
    // console.log('SaveSasCart:', cart);
    if (cart) {
      this.store.dispatch(new SaveSasCartAction(cart)).subscribe();
      let sasCart = this.ssm.get('sasCart');
      sasCart = sasCart || {};
      const cartToSave = cart && cart.amountCart > 0 ? {...sasCart, ...cart} : {...cart};
      this.ssm.set('sasCart', {...cartToSave});
    }
  }

  getLocalCart() {
    return this.ssm.get('sasCart');
  }

  getStoreCart() {
    // const cart = this.ssm.get('sasCart');
    const cart = this.store.snapshot().sasCartStore?.sasCart;
    // console.log('sasCart$', cart); // TODO: Revisar Store de SAS CArt
    return cart || null;
  }

  getCart(): Observable<any> {
    const localCart = this.getStoreCart();
    if (localCart && localCart.id) {
      return of(localCart);
    }
    const cart = this.getLocalCart();
    const customer = this.lsm.get('customer');
    // console.log({cart, customer});
    if (cart) {
      const cartId = cart.id;
      const uuid = cart.anonymousUuid;

      if (cartId) {
        return this.getCartById(cartId);
      }
      if (uuid) {
        return this.getCartByUuid(uuid);
      }
    } else if (customer && !customer.isAnonymous) {
      return this.getCartByCustomerId();
    }
    return of(null);
  }

  getCartById(cartId): Observable<any> {
    return this.sasCartService.getCartById(cartId)
      .pipe(
        catchError(() => null),
        map(cart => {
          if (cart && cart.items) {
            cart.itemList = cart.items.map(item => new Product(item));
            cart.id = cart.id || cart.cartId;
            cart.deliveryType = DeliveryTypeEnum.SUBSCRIPTION;
            cart.itemQuantity = cart.amountCart;
            return cart;
          }
          return null;
        }),
        tap(cart => this.saveSasCart(cart)),
        shareReplay(1),
      );
  }

  getCartByCustomerId(): Observable<any> {
    const customer = this.lsm.get('customer');
    return this.sasCartService.getCartByCustomerId(customer.id)
      .pipe(
        catchError(() => null),
        map(cart => {
          if (cart && cart.items) {
            cart.itemList = cart.items.map(item => new Product(item));
            cart.id = cart.id || cart.cartId;
            cart.deliveryType = DeliveryTypeEnum.SUBSCRIPTION;
            cart.itemQuantity = cart.amountCart;
            return cart;
          }
          return null;
        }),
        tap(cart => this.saveSasCart(cart)),
        shareReplay(1),
      );
  }

  getCartByUuid(uuid): Observable<any> {
    return this.sasCartService.getCartByUuid(uuid)
      .pipe(
        catchError(() => null),
        map(cart => {
          if (cart && cart.items) {
            cart.itemList = cart.items.map(item => new Product(item));
            cart.id = cart.id || cart.cartId;
            cart.deliveryType = DeliveryTypeEnum.SUBSCRIPTION;
            cart.itemQuantity = cart.amountCart;
            return cart;
          }
          return null;
        }),
        tap(cart => this.saveSasCart(cart)),
        shareReplay(1),
      );
  }

  getCartTotalAmount(cartId): Observable<any> {
    return this.sasCartService.getCartTotalAmount(cartId);
  }

  getThermometer(): Observable<any> {
    const cart = this.getLocalCart();

    if (cart) {
      return this.sasAlgoliaManager.getDiscounts()
        .pipe(switchMap(response => this.sasCartService.getThermometer(cart.id)
            .pipe(
              map(res => {
                res.breakpoints = {
                  unit: '%',
                  values: response.discounts.map(i => parseInt(i.label.replace('%', ''), 10)),
                  currentValue: parseInt(res.percentageToApply.replace('%', ''), 10),
                };
                const discount = response.discounts.filter(i => i.label === res.percentageToApply)[0];
                res.message = discount ? discount.message.cart : '';
                return res;
              }),
              // tap(res => console.log('Thermometer:', res))
            )));
    }
    return of(null);
  }

  merge(data): Observable<any> {
    return this.sasCartService.merge(data);
  }

  validateProductInCart(cartId, productId): Observable<any> {
    return this.sasCartService.validateProductInCart(cartId, productId);
  }

  isSasEnabled() {
    const city = this.ssm.get('city');
    const isEnabled = !(city && city.deliveryType === DeliveryTypeEnum.ENVIALOYA);
    this.ssm.set('enableSas', isEnabled);
    return isEnabled;
  }
}
