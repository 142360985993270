import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxsModule} from '@ngxs/store';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AngularFireAuthModule} from '@angular/fire/auth';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ServiceWorkerModule} from '@angular/service-worker';
// import {countryConfig} from '../country-config/country-config';
import {states} from './data/states/index.state';
import {SizeDetectorModule} from './ui/shared/modules/size-detector/size-detector.module';
import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserStateInterceptor} from './data/handlers/interceptors/browser-state.interceptor';
import {countryConfig} from '../country-config/country-config';
import {ScanAndGoModalModule} from './ui/modals/scan-and-go-modal/scan-and-go-modal.module';
import {ButtonModule} from './ui/shared/modules/button/button.module';
import {FooterModule} from './ui/shared/modules/footer/footer.component';
import {NavbarSsrModule} from './ui/shared/modules/navbar-ssr/navbar-ssr.component';
import { IconsModule } from './ui/shared/modules/icons/icons.module';

declare let require: any;
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-ftd'}),
    BrowserTransferStateModule,
    AppRoutingModule,
    ServiceWorkerModule.register('service-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    AngularFireModule.initializeApp(countryConfig.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    // TransferHttpCacheModule,
    // InterceptorModule,
    MDBBootstrapModule.forRoot(),
    SizeDetectorModule,
    NgxsModule.forRoot(states, {developmentMode: !environment.production}),
    BrowserAnimationsModule,
    InfiniteScrollModule,
    HttpClientModule,
    ScanAndGoModalModule,
    ButtonModule,
    FooterModule,
    NavbarSsrModule,
    IconsModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'es'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
