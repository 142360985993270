import {Injectable} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {Select, Store} from '@ngxs/store';
import {countryConfig} from 'src/country-config/country-config';
import {CartService} from '../../networking/services/cart/cart.service';
import {LocalStorageManager} from '../local-storage/local-storage.manager';
import {CartRes} from '../../networking/models/cart/responses/cart-res';
import {DeleteCartAction, RemoveCartProductAction, SaveCartAction, UpdateCartProductAction} from '../../actions/cart.actions';
import {AlgoliaManager} from '../algolia/algolia.manager';
import {Product} from '../../networking/models/product/responses/product.class';
import {LocationManager} from '../location/location.manager';
import {AddressManager} from '../customer/address/address.manager';
import {DeliveryTypeEnum} from '../../networking/models/location/enum/delivery-type.enum';
import {SessionStorageManager} from '../session-storage/session-storage.manager';
import {UpdateProductToastEvent} from '../../../ui/shared/event-listeners/update-product-toast/update-product-toast.event';
import {UpdateOnlineOnlyModalEvent} from '../../../ui/shared/event-listeners/update-online-only-modal/update-online-only-modal.event';
import {GoogleTagService} from '../../networking/services/shared/google-tag/google-tag.service';
import {ToastEvent} from '../../../ui/shared/event-listeners/toast/toast.event';

@Injectable({
  providedIn: 'root',
})
export class CartManager {
  @Select(state => state.cartStore) cart$: Observable<any>;

  constructor(private cartService: CartService,
              private lsm: LocalStorageManager,
              private ssm: SessionStorageManager,
              private store: Store,
              private algoliaManager: AlgoliaManager,
              private locationManager: LocationManager,
              private addressManager: AddressManager,
              private productToastEvent: UpdateProductToastEvent,
              private onlineOnlyEvent: UpdateOnlineOnlyModalEvent,
              private gtmService: GoogleTagService,
              private toastEvent: ToastEvent) {}

  getCartData() {
    let customer = this.lsm.get('customer');
    const mobileSaG = this.lsm.get('mobileSaG');
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    customer = callCenterCustomer || customer;
    if (!customer) { return null; }
    // const isSaG = mobileSaG || !!this.store?.snapshot().scanAndGoStore?.config;
    return {
      deliveryType: mobileSaG ? DeliveryTypeEnum.SCANANDGO : this.locationManager.getDeliveryType(),
      id: customer ? customer.id : 0,
      idCustomerWebSafe: customer.idCustomerWebSafe,
      idStoreGroup: this.locationManager.getIdStoreGroup(),
      source: countryConfig.generalEnv.source,
      token: customer.token.token,
      tokenIdWebSafe: customer.token.tokenIdWebSafe,
    };
  }

  get generalConfig() {
    const config = this.ssm.get('multiCountry');
    return config && config.general;
  }

  saveCartAction(data) {
    this.store.dispatch(new SaveCartAction(data)).subscribe();
  }

  deleteCartAction() {
    this.store.dispatch(new DeleteCartAction()).subscribe();
    this.ssm.removeItem('cart');
  }

  updateCartAction(type, data) {
    if (type === 'remove') {
      // console.log('remove product data:', data);
      this.store.dispatch(new RemoveCartProductAction(data.product)).subscribe();
      this.toastEvent.updateToast({success: true, message: 'Producto eliminado satisfactoriamente'});
      this.gtmService.onRemoveFromCart(data);
    } else {
      const {product, quantity} = data;
      // console.log('porduct:', product);
      this.store.dispatch(new UpdateCartProductAction(product, quantity))
        .subscribe(() => {
          // console.log('updated cart:', res);
        });
    }
  }

  get(): Observable<CartRes> {
    let customer = this.lsm.get('customer');
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    customer = callCenterCustomer || customer;
    let cartData;
    cartData = this.getCartData();
    // console.log({cartData});
    if (customer && cartData) {
      return this.cartService.get(cartData).pipe(
        map(response => {
          if (response.totalQuantity > 0 || response.itemList?.length > 0) {
            let hasCoupon = false;
            response.carts = [];
            response.deliveryType = cartData.deliveryType;
            response.isProvider = false;
            response.isHighDemand = false;
            // const deliveryLabels = response.deliveryTimeLabel ? response.deliveryTimeLabel.deliveryTimeLabelWeb : '';

            if (response.itemList) {
              if (this.generalConfig.coupons) {
                const coupon = response.itemList.filter((item: any) => item.coupon)[0];
                if (coupon) { hasCoupon = true; }
              }
              response.itemList = response.itemList.map(item => new Product(item));

              const ftdCart = {
                itemList: response.itemList,
                isProvider: false,
                isExpress: true,
                isFtd: true,
                itemQuantity: response.quantityFarmatodo || response.itemList?.length, // response.totalQuantity
                deliveryLabels: response.deliveryTimeLabel?.deliveryTimeLabelWeb,
                deliveryType: response.deliveryType,
              };

              response.carts = [...response.carts, ftdCart];
            }

            response.billing = {
              delivery: [
                {value: response.deliveryPrice, isDefault: true},
                {value: response.providerDeliveryPrice || 0, isProvider: true},
              ],
              savings: response.offerPrice,
              subTotal: response.subTotalPrice,
              total: response.totalPrice,
              deliveryLabels: response.deliveryTimeLabel ? response.deliveryTimeLabel.deliveryTimeLabelWeb : undefined,
              deliveryType: response.deliveryType,
              quantityFarmatodo: response.quantityFarmatodo || response.itemList?.length,
              quantityProviders: response.quantityProviders || 0,
              totalTaxes: response.totalTaxes || 0,
            };

            if (response.providerList && response.providerList.length > 0) {
              response.providerList = response.providerList.map(list => {
                list.itemList = list.itemList.map(item => new Product(item));
                return list;
              });

              const providerCart = {
                itemList: response.providerList.flatMap(list => list.itemList.flatMap(item => item)),
                isProvider: true,
                isExpress: false,
                itemQuantity: response.providerList.reduce((accumulator, currentValue) => accumulator + currentValue.quantityItem, 0),
                deliveryLabels: response.deliveryTimeLabel ? response.deliveryTimeLabel.deliveryTimeLabelWeb : undefined,
              };
              // console.log('itemsProviderCart:', providerCart.itemList);
              response.carts = [...response.carts, providerCart];

              const coupon = response.providerList.map(list => list.itemList.filter(item => item.coupon)[0])[0];
              if (coupon) { hasCoupon = true; }
              response.isProvider = true;
            }

            if (response.topRangeWeight) {
              response.breakpoints = {
                unit: 'kg',
                values: [response.topRangeWeight - 1, Math.ceil(response.weight)],
                currentValue: response.weight,
              };
            }

            response.hasCoupon = hasCoupon;

            // console.log('CARRITO FINAL', response);
            return response;
          }
          return null;
        }),
        tap(cart => this.ssm.set('cart', cart)) /*, shareReplay(1)*/
      );
    }
    return of(null);
    // return throwError('No existe usuario logueado...');
  }

  getAgile(force?): Observable<any> {
    let customer = this.lsm.get('customer');
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    customer = callCenterCustomer || customer;
    let cartData;
    cartData = this.getCartData();
    if (!customer || !cartData || !cartData.deliveryType || !cartData.idStoreGroup) {
      return of(null);
    }
    return this.cart$.pipe(take(1),
      switchMap(response => {
        if (force || !response || !response.cart) {
          return this.cartService.getAgile(cartData)
            .pipe(
              map(cart => {
                if (cart.itemList) { cart.itemList = cart.itemList.map(item => new Product(item)); }
                return cart;
              }), tap(cart => this.saveCartAction(cart)), shareReplay(1),
            );
        }
        return of(response.cart);
      }));
  }

  addProduct(data): Observable<any> {
    let customer = this.lsm.get('customer');
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    const isSaG = this.lsm.get('mobileSaG');
    // || !!this.store?.snapshot().scanAndGoStore?.config;
    customer = callCenterCustomer || customer;
    let params: any = {
      idCustomerWebSafe: customer.idCustomerWebSafe,
      idStoreGroup: this.locationManager.getIdStoreGroup(),
      quantity: data.quantity,
    };
    params = {
      ...params,
      id: data.product?.id ? data.product.id : '',
      isInShoppingCart: true,
      deliveryType: isSaG ? 'SCANANDGO' : this.locationManager.getDeliveryType(),
      // itemBarcode: data.itemBarcode ? data.itemBarcode : data.product.barcode,
    };
    if(isSaG && data.itemBarcode) {
      params.isInShoppingCart = !data.itemBarcode;
      params.deliveryType = DeliveryTypeEnum.SCANANDGO;
      params.itemBarcode = data.itemBarcode;
    }

    if (!isSaG) {
      params.origin = data.product.rsList || data.product.origin;
    }

    if (data.product) {
      if (data.product.onlyOnline) {
        this.onlineOnlyEvent.updateProductOnlineOnlyProduct({...data.product, quantitySold: data.quantity});
      }
      if (data.product.observations) {
        params.observations = data.product.observations;
      }
    }

    return this.cartService.addProduct(params)
      .pipe(tap(res => {
        let payload;
        if (data.deliveryType) {
          payload = {product: new Product(res), quantity: data.quantity};
        } else {
          payload = {product: new Product(data.product), quantity: data.quantity};
        }
        this.updateCartAction(null, payload);
        this.gtmService.onAddToCart({
          product: {...data.product, quantitySold: data.quantity},
          list: data.list,
          preprocessor: data.preprocessor,
          cartType: data.cartType,
        });
        this.productToastEvent.updateProductToast({...payload.product ? payload.product : res, quantity: data.quantity});
      }));
  }

  removeProduct(data): Observable<any> {
    let customer = this.lsm.get('customer');
    const delivery = this.ssm.get('cart');
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    customer = callCenterCustomer || customer;
    let params: any = {
      idStoreGroup: this.locationManager.getIdStoreGroup(),
      idCustomerWebSafe: customer.idCustomerWebSafe,
      idProduct: data.product.id,
    };
    if (delivery.deliveryType === DeliveryTypeEnum.SCANANDGO) {
      params = {
        ...params,
        deliveryType: delivery.deliveryType,
      };
    }
    // console.log('Remove product', params);
    return this.cartService.removeProduct(params)
      .pipe(tap(() => this.updateCartAction('remove', data)));
  }

  deleteCart(params): Observable<any> {
    let customer = this.lsm.get('customer');
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    const isSaG = this.lsm.get('mobileSaG');
    customer = callCenterCustomer || customer;
    // console.log('customer:', customer);
    params = {...params, idCustomerWebSafe: customer.idCustomerWebSafe, deliveryType: isSaG ? 'SCANANDGO' : 'EXPRESS'};
    return this.cartService.deleteCart(params)
      .pipe(tap(() => this.deleteCartAction()));
  }

  getAdvertisedItems(): Observable<any> {
    const prop = 'advertisedItems';
    if (this.lsm.has(prop)) {
      const advertisedItems = this.lsm.get(prop);
      if (advertisedItems.expDate > new Date().getTime()) { return of(advertisedItems.hits); }
    }
    return this.cartService.getAdvertisedItems()
      .pipe(switchMap(response => {
        if (response && response.items) {
          const filters = `AND ( ${response.items.map(item => `id:${item}`).join(' OR ')} )`;
          return from(this.algoliaManager.search('', '*', '0', filters))
            .pipe(tap(res => {
              const date = new Date();
              this.lsm.set(prop, {expDate: date.setHours(date.getHours() + 12), hits: res.hits});
            }), map(res => res.hits));
        }
        return response;
      }));
  }

  getHighDemand(): Observable<any> {    
    return this.cartService.getHighDemand(this.getHighDemandParams())
      .pipe(map(response => {
        if (!response.highDemand || (response.statusCode && response.statusCode !== 200)) {
          throw response;
        }
        // console.log(response);
        return response.highDemand;
      }));
  }

  getHighDemandVzla(): Observable<any> {
    return this.cartService.getHighDemandVzla(this.getHighDemandParams())
      .pipe(map(response => {
        if ((!response.highDemand || response.statusCode) && response.statusCode !== 200) {
          throw response;
        }
        return response.highDemand;
      }));
  }

  private getHighDemandParams() {
    const params: any = { storeId: this.locationManager.getIdStoreGroup() };
    if(!countryConfig.isColombia) {
        const location = this.locationManager.getLocation();
        params['lat'] = location.latitude;
        params['lng'] = location.longitude;
        params['idCity'] = location.city;
        params['municipality'] = location.municipality;
      };
    return params;
  }

  getSubstitutes(products): Observable<any> {
    let customer = this.lsm.get('customer');
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    customer = callCenterCustomer || customer;
    if (this.locationManager.getDeliveryType() === DeliveryTypeEnum.EXPRESS) {
      const deliveryAddress = this.addressManager.getDeliveryAddress()
        ? {id: this.addressManager.getDeliveryAddress().idAddress} : {};
      const data = {
        idAddress: deliveryAddress.id,
        idCustomerWebSafe: customer.idCustomerWebSafe,
        items: products.map(item => ({itemId: item.id, requestQuantity: item.quantitySold})),
      };
      return this.cartService.getSubstitutes(data, {keyClient: countryConfig.generalEnv.keyClient})
        .pipe(map(response => {
          if (response.itemsToSubstitute) {
            const productsFormat = [];
            response.toSubstitutes.forEach(substitutes => {
              if (substitutes.substitutesByList) {
                substitutes.substitutesByList = substitutes.substitutesByList.map(item => new Product(item));
              }
              productsFormat.push(substitutes);
            });
            return {...response, productsFormat};
          }
          return {...response};
        }));
    }
    return of(null);
  }

  handleSubstitutes(products) {
    let customer = this.lsm.get('customer');
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    customer = callCenterCustomer || customer;
    const data: any = {
      deliveryType: this.locationManager.getDeliveryType(),
      idCustomerWebSafe: customer.idCustomerWebSafe,
      idStoreGroupFromRequest: this.locationManager.getIdStoreGroup(),
      isInShoppingCart: false,
      token: customer.token.token,
      tokenIdWebSafe: customer.token.tokenIdWebSafe,
    };

    if (products.toDelete.length > 0) {
      data.itemsToDelete = products.toDelete;
    }

    if (products.toSubstitute.length > 0) {
      data.items = products.toSubstitute.map(item => ({
        itemId: item.id,
        substitute: true,
        quantityRequested: item.quantitySold,
        origin: item.origin,
      }));
    }

    return this.cartService.handleSubstitutes(data);
  }

  getLocalCart() {
    return this.ssm.get('cart');
  }

  merge(data): Observable<any> {
    data = {
      ...data,
      idStoreGroup: this.locationManager.getIdStoreGroup(),
      deliveryType: this.locationManager.getDeliveryType(),
    };
    return this.cartService.merge(data);
  }

  shoppingCart(data): Observable<any> {
    const customer = this.lsm.get('customer');
    // console.log('store', this.locationManager.getDeliveryType());

    data = {
      items: data,
      source: 'WEB',
      customerId: customer.id,
      deliveryType: this.locationManager.getDeliveryType(),
      storeId: this.locationManager.getIdStoreGroup(),
    };
    return this.cartService.shoppingCart(data);
  }
  getDeliveryType() {
    return this.locationManager.getDeliveryType();
  }
}
