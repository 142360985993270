import {State, Action, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SaveApiKeyAction} from '../actions/api-key.actions';

@State({
  name: 'apiKeyStore',
  defaults: {
    key: '',
  },
})
@Injectable()
export class ApiKeyState {
  // constructor() {}

  @Action(SaveApiKeyAction)
  SaveCustomer(ctx: StateContext<any>, action: SaveApiKeyAction) {
    // let state = ctx.getState();
    ctx.setState({key: action.apiKey});
    // state = ctx.getState();
  }
}
