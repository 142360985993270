import {Injectable} from '@angular/core';
import {timer} from 'rxjs';
import {first} from 'rxjs/operators';
import { UpdateSessionModalEvent } from 'src/app/ui/shared/event-listeners/update-session-modal/update-session-modal.event';
import {countryConfig} from 'src/country-config/country-config';
import {LocationManager} from '../../../../manager/location/location.manager';

declare let dataLayer: any;
declare let JSBridge:any;
declare let window:any;

@Injectable({
  providedIn: 'root',
})

export class GoogleTagService {
  // nonCategorized = 'Uncategorized';
  delay = 2000;
  dl = [];
  currencyCode = 'COP';
  preprocessors = {
    purchase: {
      data: GoogleTagService.classification,
      convention(product) {
        return GoogleTagService.standardizeProduct(product);
        // return {
        //   name: product.description,
        //   id: product.id,
        //   price: GoogleTagService.currency(GoogleTagService.getFinalPrice(product)),
        //   fullPrice: GoogleTagService.currency(product.fullPrice),
        //   discount: product.fullPrice - product.offerPrice,
        //   brand: product.marca,
        //   category: product.Categoría,
        //   subcategory: product.subCategory,
        //   variant: product.detailDescription,
        //   quantity: product.quantitySold,
        //   coupon: ''
        // };
      },
    },
    productCart: {
      data: GoogleTagService.item,
      convention(product, quantity) {
        return GoogleTagService.standardizeProduct(product, quantity);
        // return {
        //   name: product.description,
        //   id: product.id,
        //   price: GoogleTagService.currency(GoogleTagService.getFinalPrice(product)),
        //   discount: product.offerPrice,
        //   brand: product.marca,
        //   category: product.Categoría,
        //   subcategory: product.subCategory,
        //   variant: product.detailDescription,
        //   quantity
        // };
      },
    },
    productCartRemoveAll: {
      data: GoogleTagService.item,
      convention(product, quantity) {
        return GoogleTagService.standardizeProduct(product, quantity);
        // return {
        //   name: product.description,
        //   id: product.id,
        //   price: GoogleTagService.currency(GoogleTagService.getFinalPrice(product)),
        //   brand: product.marca,
        //   category: product.rsCategory || product.categorie,
        //   variant: product.detailDescription,
        //   quantity: product.quantitySold ? product.quantitySold : product.quantity
        // };
      },
    },
    product: {
      data: GoogleTagService.item,
      convention(product, quantity) {
        return GoogleTagService.standardizeProduct(product, quantity);
        // return {
        //   name: product.description,
        //   id: product.id,
        //   price: GoogleTagService.currency(GoogleTagService.getFinalPrice(product)),
        //   fullPrice: product.fullPrice,
        //   discount: product.fullPrice - product.offerPrice,
        //   brand: product.marca,
        //   rawBrand: product.rawBrand,
        //   category: product.rsCategory || product.categorie,
        //   variant: product.detailDescription,
        //   quantity
        // };
      },
    },
    productDetail: {
      data(itemData) {
        if (Array.isArray(itemData)) {
          return GoogleTagService.classification(itemData);
        }
        return GoogleTagService.item(itemData);
      },
      convention(product) {
        return GoogleTagService.standardizeProduct(product);
        // return {
        //   name: product.description,
        //   id: product.id,
        //   price: GoogleTagService.currency(GoogleTagService.getFinalPrice(product)),
        //   fullPrice: product.fullPrice.toString(),
        //   discount: (product.fullPrice - product.offerPrice).toString(),
        //   brand: product.marca,
        //   rawBrand: product.rawBrand,
        //   category: product.Categoría,
        //   subcategory: product.subCategory,
        //   variant: product.detailDescription,
        // };
      },
    },
    clickmenu: {
      data: GoogleTagService.classification,
      convention(product) {
        return {
          name: product.description ? product.description : product.mediaDescription,
          id: product.id,
          price: GoogleTagService.currency(GoogleTagService.getFinalPrice(product)),
          brand: product.marca,
          category: product.rsCategory || product.categorie,
          variant: product.detailDescription ? product.detailDescription : product.grayDescription,
          list: product.rsList || '',
          position: '',
        };
      },
    },
    normalizeGroupData: {
      data(data) {
        const newData = [];
        if (data) {
          return data.filter(itemData => !itemData.type && itemData.type !== 'GROUP');
        }
        return newData;
      },
      convention(product) {
        return {
          name: product.description ? product.description : product.mediaDescription,
          id: product.id,
          price: GoogleTagService.currency(GoogleTagService.getFinalPrice(product)),
          brand: product.marca,
          category: product.rsCategory || product.categorie,
          variant: product.detailDescription ? product.detailDescription : product.grayDescription,
          list: product.rsList || '',
          position: '',
        };
      },
    },
    promoBanner: {
      convention(promo) {
        if (promo.campaignName) {
          return {
            name: promo.campaignName || '',
            creative: promo.creative || '',
            position: promo.position || '',
            campaignName: promo.campaignName || '',
          };
        }
        return false;
      },
    },
  };

  static classification(data) {
    // console.log('Data@Classification:', data);
    if (Array.isArray(data)) {
      data = data.map(itemData => ({...itemData, ...GoogleTagService.item(itemData), special: true}));
    } else {
      data = {...data, ...GoogleTagService.item(data), special: true};
    }
    return data;
  }

  static standardizeProduct(product, quantity?) {
    return {
      name: product.description,
      id: product.id,
      price: product.offerPrice > 0 ? product.offerPrice : product.fullPrice,
      offerPrice: product.offerPrice,
      // GoogleTagService.currency(GoogleTagService.getFinalPrice(product)),
      fullPrice: product.fullPrice.toString(),
      discount: product.offerPrice > 0 ? (product.fullPrice - product.offerPrice).toString() : null,
      brand: product.marca,
      rawBrand: product.rawBrand,
      department: product?.departments?.toString(),
      category: product.categorie,
      subcategory: product.subCategory,
      variant: product.detailDescription,
      coupon: '',
      quantity: quantity || product.quantitySold,
    };
  }

  static item(item) {
    const product = {...item};
    const categorie = 'categorie';
    const subCategory = 'subCategory';
    const categoria = 'Categoría';
    const subCategoria = 'SubCategoría';

    let dep = product.departments && product.departments.length ? product.departments[0] : '';
    let cat = item[categorie] ? item[categorie] : '';
    let subCat = item[subCategory] ? item[subCategory] : '';
    if (dep !== '') {
      product.rsCategory = `${dep}/${cat}/${subCat}`;
    } else {
      dep = product.Departamentos ? product.Departamentos[0] : '';
      cat = item[categoria] ? item[categoria] : '';
      subCat = item[subCategoria] ? item[subCategoria] : '';
      if (dep !== '') {
        product.rsCategory = `${dep}/${cat}/${subCat}`;
      } else {
        product.rsCategory = 'Uncategorized';
      }
    }
    try {
      const supplier = (product.supplier ? `__${product.supplier}` : '');
      product.rawBrand = product.marca;
      if (product.marca.indexOf(supplier) <= 0) {
        product.marca += supplier;
      }
    } catch (error) {
      // console.log(error)
    }

    return product;
  }

  static currency(data) {
    return data ? data.toString().replace(/\./g, '') : '0';
  }

  static getParams(url) {
    const paramsString = url.split('?')[1];
    if (paramsString) {
      const obj = {};
      paramsString.split('&').forEach(str => {
        const kv = str.split('=');
        obj[kv[0]] = kv[1];
      });
      return obj;
    }
    return url;
  }

  /*static pushDataLayer(data) {
    // if (environment.production && dataLayer) {
    console.log('newData:', data);
    console.log(dataLayer);
    if (!dataLayer) {
      this.dl.push(data);
      return;
    }
    if (this.dl.length > 0) {
      dataLayer = [...this.dl];
      this.dl = [];
    }
    dataLayer.push(data);
    // }
    // console.log('dataLayer:', data);
  }*/

  /*static onEvent(category, action, label, event = 'event') {
    GoogleTagService.pushDataLayer({
      event,
      data: {
        category,
        action,
        label
      }
    });
  } */

  static calculateDelivery(deliveries) {
    return deliveries.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
  }

  static getFinalPrice(product) {
    return product.offerPrice > 0 ? product.offerPrice : product.fullPrice;
  }

  static validateProductProperties(product) {
    if (!product.categorie) {
      product = {...product, categorie: ''};
    }
    if (!product.marca) {
      product = {...product, marca: ''};
    }

    return product;
  }

  constructor(
    private locationManager: LocationManager,
    private sessionModalStatusEvent: UpdateSessionModalEvent
  ) {}

  pushDataLayer(data, delay = this.delay) {
    timer(delay)
      .pipe(first())
      .subscribe(() => {
        try {
          // console.count('trying datalayer', data);
          dataLayer.push(data);
        } catch {
          // console.count('no data layer', data);
          this.pushDataLayer(data, delay);
        }
      });
  }

  onEvent(category, action, label, payload ?, event = 'event', delay = 0) {
    this.pushDataLayer({
      event,
      data: {
        category,
        action,
        label,
        payload,
      },
    }, delay);
  }

  getProducts(products, preprocessor) {
    const data = 'data';
    return this.preprocessors[preprocessor] && this.preprocessors[preprocessor][data]
      ? this.preprocessors[preprocessor][data](products) : products;
  }

  getData(preprocessor, product, quantity?) {
    const convention = 'convention';
    return this.preprocessors[preprocessor] ? this.preprocessors[preprocessor][convention](product, quantity) : product;
  }

  onImpressions(products, list, preprocessor = 'normalizeGroupData') {
    try {
      products = this.getProducts(products, preprocessor);
      const arrayProducts = [];
      for (let index = 0; index < products.length; index++) {
        const product = GoogleTagService.validateProductProperties({...products[index], rsListProduct: list});
        const data = this.getData(preprocessor, product);
        data.list = product.rsList || list;
        data.position = index + 1;
        arrayProducts.push(data);
      }
      this.pushDataLayer({
        event: 'impressions',
        ecommerce: {
          currencyCode: this.currencyCode, // Local currency is optional.
          impressions: arrayProducts,
        },
      });
    } catch (error) {
      // console.log(error);
    }
  }

  onImpressionsPromo(promotions, preprocessor = 'promoBanner') {
    try {
      promotions = this.getProducts(promotions, preprocessor);
      const arrayPromotions = [];

      for (const promotion of promotions) {
        const data = this.getData(preprocessor, promotion);
        if (data !== false) {
          arrayPromotions.push(data);
        }
      }

      this.pushDataLayer({
        event: 'impressionsPromo',
        ecommerce: {
          promoView: {
            promotions: arrayPromotions,
          },
        },
      });
    } catch (error) {
      // console.log(error);
    }
  }

  onDetail(product, list, delay = 0, preprocessor = 'productDetail') {
    list = product.rsList || list;
    try {
      product = this.getProducts(product, preprocessor);
      product = GoogleTagService.validateProductProperties(product);
      const data = this.getData(preprocessor, product);
      this.locationManager.getCity()
        .subscribe(city => {
          data.city = city ? city.name : '';
          this.pushDataLayer({
            event: 'detail',
            ecommerce: {
              detail: {
                actionField: {list},
                products: [data],
              },
            },
          }, delay);
        });
    } catch (error) {
      // console.log(error);
    }
  }

  onClickProduct(product, list = 'Clic de producto', preprocessor) {
    list = product.rsList || list;
    try {
      product = this.getProducts(product, preprocessor);
      product = GoogleTagService.validateProductProperties(product);
      const data = this.getData(preprocessor, product);
      sessionStorage.setItem('productList', list);
      this.pushDataLayer({
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: {list},
            products: [data],
          },
        },
        eventCallback() {
          // window.location.href = product.rsUrlDetail;
          // $location.path(product.rsUrlDetail)
        },
      });
    } catch (error) {
      // console.log('Error@onClickProduct', error);
    }

    // $location.path(product.rsUrlDetail);
  }

  onClickPromo(promotion, router, preprocessor = 'promoBanner') {
    try {
      promotion = this.getProducts(promotion, preprocessor);
      const data = this.getData(preprocessor, promotion);

      if (data) {
        this.pushDataLayer({
          event: 'promotionClick',
          ecommerce: {
            promoClick: {
              promotions: data ? [data] : [],
            },
          },
        });
      }

      let url = '';
      if (promotion.redirectURL) {
        let index = promotion.redirectURL.indexOf(countryConfig.isColombia ? 'farmatodo.com.co' : 'farmatodo.com.ve');
        if (index >= 0) {
          index = promotion.redirectURL.indexOf(countryConfig.isColombia ? 'com.co' : 'com.ve');
          url = promotion.redirectURL.substring(index + 7);
        }
      } else if (promotion.redirectUrl) {
        let index = promotion.redirectUrl.indexOf(countryConfig.isColombia ? 'farmatodo.com.co' : 'farmatodo.com.ve');
        if (index >= 0) {
          index = promotion.redirectUrl.indexOf(countryConfig.isColombia ? 'com.co' : 'com.ve');
          url = promotion.redirectUrl.substring(index + 7);
        }
      }

      if (url) {
        let params = {};
        if (url.indexOf('?') >= 0) {
          params = GoogleTagService.getParams(url);
          url = url.split('?')[0];
        }
        // console.log(params);
        // console.log({url});
        router.navigate([url], {queryParams: {...params}});
      } else {
        window.location.href = promotion.redirectUrl;
      }
    } catch (error) {
      // console.log(error);
      window.location.href = promotion.redirectUrl;
    }
  }

  onAddToCart(eventData, preprocessor = 'productCart') {
    let {product} = eventData;
    delete eventData.product;
    const {cartType = 'ftdCart'} = eventData;

    try {
      product = this.getProducts(product, preprocessor);
      product = GoogleTagService.validateProductProperties(product);
      const data = this.getData(preprocessor, product, product.quantitySold);

      this.locationManager.getCity()
        .subscribe(city => {
          data.city = city ? city.name : '';
          this.pushDataLayer({
            event: 'addToCart',
            ecommerce: {
              currencyCode: this.currencyCode,
              add: {
                actionField: {list: product.rsList || '', cartType},
                products: [data],
              },
            },
          });
        });
    } catch (error) {
      // console.log(error);
    }
  }

  onRemoveFromCart(eventData) {
    const {product} = eventData;
    delete eventData.product;
    const {preprocessor, cartType = 'ftdCart'} = eventData;
    try {
      let products = [];

      const preProcess = prod => {
        prod = this.getProducts(product, preprocessor);
        if (!prod.marca) {
          prod.marca = '';
        }
        return this.getData(preprocessor, prod, product.quantity);
      };

      if (!Array.isArray(product)) {
        products = [preProcess(product)];
      } else {
        for (const element of product) {
          products.push(preProcess(element));
        }
      }

      this.pushDataLayer({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            actionField: {list: product.rsList || '', cartType},
            products,
          },
        },
      });
    } catch (error) {
      // console.log(error);
    }

    // poderIoUpdate start
    // poderIoTagService.removeItemCart(product);
    // poderIoUpdate end
  }

  onCheckout(products, preprocessor = 'purchase') {
    products = this.getProducts(products, preprocessor);
    const arrayProducts = [];

    for (const product of products) {
      if (!product.marca) {
        product.marca = '';
      }
      const data = this.getData(preprocessor, product);
      arrayProducts.push(data);
    }

    this.locationManager.getCity()
      .subscribe(city => {
        this.pushDataLayer({
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: {step: 1, option: 'Checkout'},
              products: arrayProducts,
              city: city ? city.name : '',
            },
          },
        });
      });

    // this.onEvent('compra', 'click', 'realizarpedido');
  }

  onPurchase(cart, order, customer, preprocessor = 'purchase', delay = 0) {
    const {carts, billing} = cart;
    let products = carts.flatMap(c => c.itemList);
    const coupon = products.filter(p => p.coupon).pop();
    products = products.filter(p => !p.coupon);
    // console.log(products, order); // quitar
    products = this.getProducts(products, preprocessor);
    const arrayProducts = [];

    for (let product of products) {
      product = GoogleTagService.validateProductProperties(product);
      // console.log('Product Purchase:', product);
      const data = this.getData(preprocessor, product);
      arrayProducts.push(data);
    }

    this.locationManager.getCity()
      .subscribe(city => {
        this.pushDataLayer({
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: order.id,
                affiliation: 'WEB',
                revenue: GoogleTagService.currency(billing.total),
                coupon: coupon ? coupon.mediaDescription : '',
                shipping: GoogleTagService.currency(GoogleTagService.calculateDelivery(billing.delivery)),
                pickingDate: order.pickingDate,
                currency: this.currencyCode,
                offerPrice: cart.offerPrice,
                bvData: {
                  discount: cart.offerPrice.toFixed(2).toString(),
                  price: billing.total.toFixed(2).toString(),
                  total: (billing.total - billing.delivery[0].value).toFixed(2).toString(),
                },
              },
              products: arrayProducts,
              city: city ? city.name : '',
              storeId: this.locationManager.getIdStoreGroup(),
              customer: {
                email: customer.email,
                name: customer.firstName,
              },
            },
          },
        }, delay);
      });

    this.onEvent('compra', 'click', 'realizarpedido');
  }

  onCart(event, products = []) {
    // cartProducts
    products = products.map(p => ({...p}));
    // console.log('onCart:', products);
    this.locationManager.getCity()
      .subscribe(city => {
        this.pushDataLayer({
          event,
          data: {
            products,
            city: city ? city.name : '',
          },
        });
      });
  }

  onLogin(customer) {
    this.sessionModalStatusEvent.changeModalStatus({close: true});
    if (customer.id) {
      const customerJson = {
        id: customer.id,
        idCustomerWebSafe: customer.idCustomerWebSafe,
        token: customer.token.token,
        tokenIdWebSafe: customer.token.tokenIdWebSafe,
      };
      const webview = JSON.parse(localStorage.webview);
      console.warn('localStorage.webview', localStorage.webview);
      if (webview == 'IOS') {
        const sendMessage = () => {
          window.webkit.messageHandlers.jsMessageHandler.postMessage(customerJson);
        }
        sendMessage();
      } else if (webview == 'ANDROID') {
        const sendMessage = () => {
          console.log('JSBridge.onUserLogged',customerJson);
          try {
            JSBridge.onUserLogged(JSON.stringify(customerJson));
            console.log(`JSBridge eviado.`);
          } catch (e) {
            console.log(`JSBridge no exite en web.`);
            console.error(e);
          }
        }
        sendMessage();
      }
    }
    this.pushDataLayer({
      event: 'login',
      data: {
        customer,
      },
    });
  }

  onSignUp(customer) {
    this.pushDataLayer({
      event: 'signUp',
      data: {
        customer,
      },
    });
  }

  viewPageWorldOffers(products) {
    this.pushDataLayer({
      event: 'viewPageWorldOffers',
      products,
    });
  }

  searchAlgolia(textSearchAlgolia) {
    this.pushDataLayer({
      event: 'textSearchAlgolia',
      textSearchAlgolia,
    });
  }

  voiceSearchAlgolia(voiceSearchAlgolia) {
    this.pushDataLayer({
      event: 'voiceSearchAlgolia',
      voiceSearchAlgolia,
    });
  }

  viewPageGroups(id, nameGroup, hits) {
    const viewPageGroupsData: any = {
      name: 'Promotion Viewed',
      id,
      hits,
    };

    if (nameGroup === 'sugeridos') {
      viewPageGroupsData.type = 1;
    } else if (nameGroup === 'destacados') {
      viewPageGroupsData.type = 3;
    } else {
      viewPageGroupsData.type = 2;
    }

    if (hits && hits.length > 0) {
      viewPageGroupsData.nameGroup = (hits[0] && hits[0].offerDescription) ? hits[0].offerDescription : '';
    }

    this.pushDataLayer({
      event: 'viewPageGroups',
      viewPageGroupsData,
    });
  }

  checkoutStarted(checkoutStartedData) {
    this.pushDataLayer({
      event: 'checkoutStarted',
      checkoutStartedData,
    });
  }

  viewPageCategories(categories: any, products) {
    const categoryViewData = {
      categories,
      products,
    };
    this.pushDataLayer({
      event: 'categoryView',
      categoryViewData,
    });
  }

  filtersCategories(products, subFilters, delay = 0) {
    const filtersCategoriesData = {
      products,
      subFilters,
    };
    this.pushDataLayer({
      event: 'filtersCategories',
      filtersCategoriesData,
    }, delay);
  }

  viewPage(pageView) {
    this.pushDataLayer({
      event: 'pageView',
      pageView,
    });
  }

  cuponOk(cuponUserOk, delay = 0) {
    this.pushDataLayer({
      event: 'cuponUserOk',
      cuponUserOk,
    }, delay);
  }

  registeredOk(userRegisteredOk, delay = 0) {
    this.pushDataLayer({
      event: 'userRegisteredOk',
      userRegisteredOk,
    }, delay);
  }

  reOrder(dataReOrder, delay = 0) {
    this.pushDataLayer({
      event: 'addReOrder',
      dataReOrder,
    }, delay);
    this.onImpressions(dataReOrder.itemList, 'Volver a pedir', 'clickmenu');
  }

  loadScripts() {
    this.onEvent('loadScripts', 'loadScripts', 'loadScripts', 'loadScripts');
  }

  changeAddress(address) {
    this.onEvent('address', 'click', 'changeAddress', address , 'changeAddress');
  }

  agileCartUpdated(cart) {
    this.onEvent('agileCartUpdated', 'click', 'agileCartUpdated', cart, 'agileCartUpdated');
  }

  onFavorites(favorites) {
    this.pushDataLayer({
      event: 'onFavorites',
      data: {
        favorites,
      },
    });
  }

  addRemoveFavorite(favorite) {
    this.pushDataLayer({
      event: 'addRemoveFavorite',
      data: {
        favorite,
      },
    });
  }

}
