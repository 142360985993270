export class CreateCreditCardAction {
  static readonly type = '[CreditCard] Create CreditCard ';
  constructor(public creditCard: any) {}
}

export class SaveCreditCardAction {
  static readonly type = '[CreditCard] Save CreditCard';
  constructor(public creditCard: any) {}
}

export class SetDefaultCreditCardAction {
  static readonly type = '[CreditCard] Set Default CreditCard';
  constructor(public creditCardId: any) {}
}

export class DeleteCreditCardAction {
  static readonly type = '[CreditCard] Delete CreditCard';
  constructor(public creditCardId: any) {}
}
