export class SaveScanAndGoConfigAction {
  static readonly type = '[ScanAndGo] Save ScanAndGo Config';
  constructor(public config: any) {}
}

export class DeleteScanAndGoConfigAction {
  static readonly type = '[ScanAndGo] Delete ScanAndGo Config';
  // constructor() {}
}

export class SaveScanAndGoFirebaseConfigAction {
  static readonly type = '[ScanAndGo] Save ScanAndGo Firebase Config';
  constructor(public firebaseConfig: any) {}
}

export class UpdateScanAndGoFirebaseStatusAction {
  static readonly type = '[ScanAndGo] Update ScanAndGo Firebase Status';
  constructor(public status: string) {}
}

export class UpdateScanAndGoFirebaseActiveAction {
  static readonly type = '[ScanAndGo] Update ScanAndGo Firebase Active';
  constructor(public active: boolean) {}
}

export class UpdateScanAndGoCartAction {
  static readonly type = '[ScanAndGo] Update ScanAndGo Cart';
  constructor(public reloadCart: boolean) {}
}

export class UpdateScanAndGoStoreIdAction {
  static readonly type = '[ScanAndGo] Update ScanAndGo StoreId';
  constructor(public storeId: number) {}
}

export class UpdateScanAndGoOrderDataAction {
  static readonly type = '[ScanAndGo] Update ScanAndGo Order Data';
  constructor(public order: any) {}
}

