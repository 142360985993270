import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RequestService} from '../../../handlers/request/request.service';
import {scanAndGo} from '../../api/scan-and-go/scan-and-go.api';
import {globalEnv} from '../../connection/global/global.env';
import {ScanAndGoRatingInterface} from '../../models/scan-and-go/request/scan-and-go';

@Injectable({
  providedIn: 'root',
})
export class ScanAndGoService {
  constructor(private req: RequestService) { }

  getScanAngGo(): Observable<any> {
    return this.req.get(`${globalEnv.gateway}${scanAndGo.getInfo}`, null, null, true, null, false);
  }

  saveSelfie(photo) {
    // return this.req.post(`${globalEnv.gateway}${scanAndGo.selfie}`, photo, null, null, null);
    return this.req.post(`${globalEnv.gateway}${scanAndGo.selfie}`, photo, null, null, null);
  }

  rate(data: ScanAndGoRatingInterface): Observable<any> {
    return this.req.post(`${globalEnv.gateway}${scanAndGo.rating}`, data);
  }
}
