import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateProductToastEvent {
  private product = new BehaviorSubject(null);
  productToast = this.product.asObservable();

  updateProductToast(product: any) {
    this.product.next(product);
  }
}
