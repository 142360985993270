import {State, Action, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SaveCustomer} from '../actions/customer.actions';
import {UpdateCustomerEvent} from '../../ui/shared/event-listeners/update-customer/update-customer.event';

@State({
  name: 'customerStore',
  defaults: {
    customer: {},
  },
})
@Injectable()
export class CustomerState {
  constructor(private customerEvent: UpdateCustomerEvent) {}

  @Action(SaveCustomer)
  SaveCustomer(ctx: StateContext<any>, action: SaveCustomer) {
    let state = ctx.getState();
    // console.log('State SaveCustomer =>', state);
    // console.log('action SaveCustomer =>', action);
    ctx.setState({...state,
      customer: {
        ...state.customer,
        ...action.customer,
      }});
    state = ctx.getState();
    this.customerEvent.updateCustomer(state.customer);
  }
}
