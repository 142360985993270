import {
Component, Input, OnChanges, Output, EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  @Input() title: string;
  @Input() customClasses: string;
  @Input() isButton = true;
  @Input() isDisabled = false;
  @Input() isGoogle = false;
  @Input() isFacebook = false;
  @Input() isApple = false;
  @Input() isLoading = false;
  @Input() isTemplate = false;
  @Input() buttonId = '';
  @Input() srcImg: string;
  @Input() sideImg: string;
  @Input() productCuf = '';
  @Input() spinnerBlue = false;
  @Output() clicked = new EventEmitter();

  click() {
    this.clicked.emit(true);
  }

  ngOnChanges() {
  }
}
