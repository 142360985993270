export enum BackendGatewayColEnum {
  SAS_DEV = 'https://sas-v30-dev-dot-stunning-base-164402.appspot.com/',
  SAS = 'https://sas-v30-dot-stunning-base-164402.appspot.com/',
  SAS_QA = 'https://sas-v30-dot-stunning-base-164402.appspot.com/',
  DATA_FOUNDATION = 'https://datafoundation-dot-stunning-base-164402.appspot.com/',
  DATA_FOUNDATION_DEV = 'https://datafoundation-dev-dot-stunning-base-164402.appspot.com/',
  GATEWAY = 'https://gateway-dot-stunning-base-164402.appspot.com/',
  GATEWAY_CVV = 'https://cvv-address-dot-gateway-dot-stunning-base-164402.uc.r.appspot.com/',
  GATEWAY_CART = 'https://new-car-dot-gateway-dot-stunning-base-164402.appspot.com/',
  SANDBOX_MULTICOUNTRY = 'https://sandbox-mc-dot-multicountry-apigateway-dot-stunning-base-164402.uc.r.appspot.com/',
  BACK3 = 'https://qa-2019-dot-gateway-dot-stunning-base-164402.appspot.com/',
  CMS = 'https://cms-dot-stunning-base-164402.appspot.com/',
  CLOUD_FUNCTION = 'https://us-central1-stunning-base-164402.cloudfunctions.net/',
  DEVELOP = 'https://develop-dot-stunning-base-164402.appspot.com/_ah/api/',
  SANDBOX = 'https://sandbox-domicilios-farmatodo.appspot.com/_ah/api/',
  CONTINGENCY = 'https://contingency-dot-stunning-base-164402.appspot.com/_ah/api/',
  PROD = 'https://prod-dot-stunning-base-164402.appspot.com/_ah/api/',
  QA = 'https://qa-dot-stunning-base-164402.appspot.com/_ah/api/',
  QA_ENV = 'https://qa-env-dot-stunning-base-164402.appspot.com/_ah/api/',
  QA_ENV_1 = 'https://qa-env1-dot-stunning-base-164402.appspot.com/_ah/api/',
  QA_ENV_2 = 'https://qa-env2-dot-stunning-base-164402.appspot.com/_ah/api/',
  QA_ENV_3 = 'https://qa-env3-dot-stunning-base-164402.appspot.com/_ah/api/',
  // GateWay
  MP = 'https://mercado-pago-dot-gateway-dot-stunning-base-164402.uc.r.appspot.com/',
  // MP = 'https://mercado-pago2-dot-gateway-dot-stunning-base-164402.uc.r.appspot.com/',
  PRODUCTION = 'https://stunning-base-164402.appspot.com/_ah/api/',
  CO = 'https://gateway-dot-stunning-base-164402.uc.r.appspot.com',
  MULTICOUNTRY = 'https://multicountry-apigateway-dot-stunning-base-164402.uc.r.appspot.com/',
  CRM = 'https://gateway-dot-stunning-base-164402.uc.r.appspot.com/',
  ALGOLIA = 'https://vcojeyd2po-dsn.algolia.net/',
  API_MP = 'https://api.mercadopago.com/',
}
