import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {banner} from '../../api/cms/banner.api';
import {RequestService} from '../../../handlers/request/request.service';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(private req: RequestService) { }

  getBannersHome(params): Observable<any> {
    return this.req.get(`${globalEnv.cms}${banner.home}`, params);
  }

  get(params): Observable<any> {
    return this.req.get(`${globalEnv.cms}${banner.get}`, params);
  }
}
