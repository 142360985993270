import {Injectable} from '@angular/core';
import {Observable, of, throwError, zip} from 'rxjs';
import {catchError, debounceTime, finalize, first, map, switchMap, tap} from 'rxjs/operators';

import {Select, Store} from '@ngxs/store';
import {Router} from '@angular/router';
import {countryConfig} from 'src/country-config/country-config';
import {LocalStorageManager} from '../../local-storage/local-storage.manager';
import {CustomerService} from '../../../networking/services/customer/customer.service';

import {Login} from '../../../networking/models/customer/requests/login';

import {SaveCustomer} from '../../../actions/customer.actions';
import {SessionEvent} from '../../../../ui/shared/event-listeners/session/session.event';
import {SessionStorageManager} from '../../session-storage/session-storage.manager';
import {Product} from '../../../networking/models/product/responses/product.class';
import {SasCartManager} from '../../../manager-v3/sas-subscription/sas-cart/sas-cart.manager';
import {GoogleTagService} from '../../../networking/services/shared/google-tag/google-tag.service';
import {CartManager} from '../../cart/cart.manager';
import {ConfigurationManager} from '../../configuration/configuration.manager';

@Injectable({
  providedIn: 'root',
})
export class CustomerManager {
  count = 0;
  codeSmsChangePass = null;
  activeModalCreatePass = false;
  keyClient = {keyClient: CustomerManager.getKeyClient()};
  @Select(state => state.customerStore) customerStore$: Observable<any>;

  static getKeyClient() {
    return countryConfig.generalEnv.keyClient;
  }

  static getCoordinates() {
    return {
      lon: countryConfig.generalEnv.coordinates.lon,
      lat: countryConfig.generalEnv.coordinates.lat,
    };
  }

  constructor(private lsm: LocalStorageManager,
              private customerService: CustomerService,
              private ssm: SessionStorageManager,
              private store: Store,
              private sessionEvent: SessionEvent,
              private router: Router,
              private gtmService: GoogleTagService,
              private sasCartManager: SasCartManager,
              private configurationManager: ConfigurationManager,
              private cartManager: CartManager) { }

  saveCustomer(customer) {
    // console.log('SaveLocalCustomer:', customer);
    this.lsm.set('customer', customer);
    this.sessionEvent.updateHasCustomer({status: true, customer, isAnonymous: customer.isAnonymous});
    this.store.dispatch(new SaveCustomer(customer)).subscribe();
  }

  hasCustomer(): Observable<any> {
    const customer = this.lsm.get('customer');
    // console.log('hasCustomer =>', customer);
    this.sessionEvent.updateHasCustomer({status: !!customer, customer, isAnonymous: customer ? customer.isAnonymous : null});
    if (customer && !customer.isAnonymous) {
      return of(true);
    }
    return this.sessionEvent.hasCustomer;
  }

  hasValidCustomer(): boolean {
    const customer = this.lsm.get('customer');
    // console.log('Customer?', customer);
    return customer && !customer.isAnonymous;
  }

  getCustomerOrigin(data): Observable<any> {
    // console.log(data);
    const basicParams = {
      email: data.email || data.socialEmail,
      keyClient: CustomerManager.getKeyClient(),
    };
    return this.customerService.getUserOrigin(basicParams);
  }

  get(params, customer: any = {}): Observable<any> {
    return this.customerStore$
      .pipe(
        debounceTime(500),
        switchMap(() => {
          let customerData = customer;
          const paramsData = params;
          // console.log('CustomerStore$', response);
          /*if (response.customer.id) {
            return of(response.customer);
          }*/

          if (!customer || !customer.id) {
            customerData = this.customer && this.customer.id > 0 ? this.customer : null;
          }

          if (customerData && this.count < 2) {
            this.count += 1;
            paramsData.latitude = CustomerManager.getCoordinates().lat;
            paramsData.longitude = CustomerManager.getCoordinates().lon;
            paramsData.idCustomerWebSafe = customerData.idCustomerWebSafe;
            return this.customerService.get(paramsData).pipe(
              switchMap(res => this.getOrigin('ADDED_FROM_ALREADY_BOUGHT').pipe(
                map(origin => {
                  if (res && res.previousItems) {
                    res.previousItems = res.previousItems.map(i => {
                      let iData = i;
                      iData = new Product(i);
                      iData.rsList = origin || 'Últimas compras';
                      iData.origin = origin;
                      return iData;
                    });
                  }
                  // console.log({res, origin});
                  return res;
                }),
              )),
              tap(res => {
                // console.log({customer, res});
                this.saveCustomer({...customerData, ...res, isAnonymous: res.id <= 0});
                // console.log('GetCustomerSaved =>', this.customer);
              }),
            );
          }
          return of(null);
        }),
      );
  }

  get customer() {
    const customer = this.lsm.get('customer');
    return customer || null;
  }

  validateToken(token, id): Observable<any> {
    const data = {
      idCustomer: id,
      code: token,
    };
    if (this.activeModalCreatePass) {
      this.codeSmsChangePass = token;
    }
    // console.log('ValidatePhone:', phone);
    // const phone = {phone: `+${data.countryCode}${data.phone}`};
    return this.customerService.validateToken(data);
  }

  validateSmsToken(phone): Observable<any> {
    // console.log('ValidatePhone:', phone);
    // const phone = {phone: `+${data.countryCode}${data.phone}`};
    return this.customerService.validateSmsToken({phone}, this.keyClient);
  }

  validateSmsTokenFinal(): Observable<any> {
    return this.customerService.validateSmsTokenFinal(this.keyClient);
  }

  validateWhatsapp(data): Observable<any> {
    return this.customerService.validateWhatsapp(data);
  }

  validateCustomer(params: any): Observable<any> {
    const validationParams: any = {
      email: params.email,
      documentNumber: params.documentNumber,
      phone: params.countryCode + params.phone,
      isEdit: params.isEdit ? params.isEdit : false,
      keyClient: CustomerManager.getKeyClient(),
    };
    if (params.documentType) {
      validationParams.documentType = params.documentType;
    }
    return this.customerService.validateCustomer(validationParams);
    /*.pipe(
      switchMap(response => {
      if (response && response.confirmation) {
        return this.validateSmsToken(params);
      }
    })
    );*/
  }

  simpleCreate(data?, isAnonymous = false): Observable<any> {
    let dataInfo = data || '';
    const params = {
      anonymous: isAnonymous,
      keyClient: CustomerManager.getKeyClient(),
      latitude: CustomerManager.getCoordinates().lat,
      longitude: CustomerManager.getCoordinates().lon,
    };

    if (isAnonymous) {
      dataInfo = {
        dataManagement: true,
        termsAndConditions: true,
      };
    } else {
      dataInfo.dataManagement = true;
      dataInfo.termsAndConditions = true;
    }

    return this.customerService.create(dataInfo, params)
      .pipe(tap(response => {
        if (response && (!response.error && !isAnonymous)) {
          // Login!
          this.gtmService.registeredOk(data);
          const coord = CustomerManager.getCoordinates();

          const loginData: Login = {
            emailAddress: dataInfo.email,
            keyClient: CustomerManager.getKeyClient(),
            latitude: coord.lat,
            longitude: coord.lon,
          };

          const {provider} = dataInfo;
          delete dataInfo.provider;
          this.gtmService.onSignUp({...data, ...response, provider, password: undefined});

          if (provider === 'EMAIL') {
            loginData.password = dataInfo.password;
          } else if (provider === 'GOOGLE') {
            loginData.password = dataInfo.tokenGoogle;
          } else {
            loginData.password = dataInfo.tokenFacebook;
          }

          // console.log('Provider to login =>', provider);
          this.gtmService.registeredOk(dataInfo);
          // this.simpleLogin(loginData, provider).subscribe( customer  => {console.log('Login@Created...', customer);});
          // TODO: Emit login event
        }
      }));
  }

  create(data?, isAnonymous = false): Observable<any> {
    // data = TODO: Parse Customer Data

    let customerData = null;
    if (data?.phoneCode) {
      data.phone = data.phoneCode + data.phone;
    }
    if (data) {
      customerData = {
        addresses: [],
        firstName: data.firstName,
        lastName: data.lastName,
        gender: data.gender[0].toUpperCase(),
        documentNumber: data.documentNumber,
        email: data.email,
        phone: data.countryCode + data.phone,
        source: countryConfig.generalEnv.source,
        latitude: CustomerManager.getCoordinates().lat,
        longitude: CustomerManager.getCoordinates().lon,
        provider: data.registeredBy,
      };

      if (customerData.provider === 'GOOGLE') {
        customerData.tokenGoogle = data.passwords.password;
      } else if (customerData.provider === 'FACEBOOK') {
        customerData.tokenFacebook = data.passwords.password;
      } else if (customerData.provider === 'APPLE') {
        customerData.uidFirebase = data.passwords.password;
      } else {
        customerData.password = data.passwords.password;
      }
    }

    return this.simpleCreate(customerData, isAnonymous)
      .pipe(tap(customer => {
        const customerSimple = customer;
        if (customerSimple) {
          if (isAnonymous) {
            customerSimple.id = 0;
            customerSimple.isAnonymous = true;
          }
          this.saveCustomer(customerSimple);

          if (isAnonymous) {
            this.sessionEvent.updateHasCustomer({status: true, customerSimple, isAnonymous: customer.isAnonymous});
          }
        }

        /*if (isAnonymous) {
          // console.log('RELOADINNNNNNNGGGGGGG!!!!!!!!!!!!!!!')
          setTimeout(() => {
            location.reload();
          }, 1);
        }*/
      }));
  }

  changePassword(rawData): Observable<any> {
    const data = {
      id: this.customer.id,
      oldPassword: rawData.oldPassword,
      password: rawData.passwords.password,
      token: {
        token: this.customer.token.token,
        tokenIdWebSafe: this.customer.token.tokenIdWebSafe,
      },
    };
    // return of(data);
    return this.customerService.changePassword(data);
  }

  resetPassword(data): Observable<any> {
    const socialNetworks = ['gmail', 'facebook'];
    return this.getCustomerOrigin(data).pipe(switchMap(response => {
      if (response && response.origin && socialNetworks.indexOf(response.origin.toLowerCase()) >= 0) {
        return throwError(response);
      }
      return this.customerService.resetPassword(data, this.keyClient);
    }));
  }

  simpleLogin(params: Login, provider: string, mergeCarts = true): Observable<any> {
    // console.log('insideSimpleLogin:', params);
    return this.customerService.loginPost(params, provider).pipe(
      switchMap(customer => (mergeCarts ? this.mergeCarts(customer) : of(customer))),
      tap(customer => {
        this.saveCustomer(customer);
        this.gtmService.onLogin(customer);
      }),
      catchError(error => throwError(error)),
    );
  }

  login(data): Observable<any> {
    // console.log('insideLoginManager:', data);
    const provider = data.registeredBy;

    const deliveryType = {deliveryType: data.deliveryType};

    const basicParams = {
      email: data.email || data.socialEmail,
      keyClient: CustomerManager.getKeyClient(),
    };

    const params: any = {
      keyClient: CustomerManager.getKeyClient(),
      latitude: CustomerManager.getCoordinates().lat,
      longitude: CustomerManager.getCoordinates().lon,
    };

    if (provider === 'EMAIL') {
      params.emailAddress = data.email;
      params.password = data.password;
    } else if (provider === 'APPLE') {
      params.uidFirebase = data.uidFirebase;
    } else {
      params.tokenAccess = data.token;
    }

    if (provider === 'FACEBOOK') {
      params.emailAddress = data.socialEmail;
    }

    // console.log({params});

    return this.simpleLogin(params, provider)
      .pipe(
        first(),
        switchMap(response => this.get(deliveryType, response)),
        catchError(error => {
          // console.log('Error@Login:', error);
          if (error.code === 401 && error.message.toLowerCase().indexOf('contraseña') >= 0) {
            // || error.message.toLowerCase().indexOf('no encontrado') >= 0
            return throwError(error);
          }

          if (error.url.includes('mergeShoppingCartAnonymous')
            || (error.code === 409 && error.message.toLowerCase().indexOf('carrito') >= 0)) {
            // console.log('MERGE ERROR');
            return this.simpleLogin(params, provider, false);
          }
          return this.customerService.getUserOrigin(basicParams)
            .pipe(
              switchMap(res => {
                // console.log('UserOrigin:', res);
                if (res.statusCode === 204) {
                  return throwError(error);
                }
                return throwError(res);
              }),
              catchError(() => throwError(error)),
            );
        }),
      );
  }

  mergeCarts(customer) {
    const anonymousCustomer = this.customer;
    const sasCart = this.sasCartManager.getLocalCart();
    let obs = [];

    return this.cartManager.getAgile().pipe(switchMap(cart => {
      // console.log({anonymousCustomer, cart, sasCart});
      if (cart || sasCart) {
        if (cart) {
          const data = {
            idCustomerWebSafeAnonymous: anonymousCustomer.idCustomerWebSafe,
            idCustomerWebSafe: customer.idCustomerWebSafe,
            token: customer.token.token,
            tokenIdWebSafe: customer.token.tokenIdWebSafe,
          };
          obs = [
            this.cartManager.merge(data),
          ];
        }

        if (sasCart && sasCart.anonymousUuid) {
          const data = {
            customerId: customer.id,
            anonymousUuid: sasCart.anonymousUuid,
          };
          obs = [...obs, this.sasCartManager.merge(data)];
        }

        // const zip =

        return zip(...obs).pipe(
          // tap((res) => console.log('MergedCarts:', res)),
          tap(() => {
            this.cartManager.deleteCartAction();
            this.sasCartManager.deleteCartAction();
            // console.log({res});
          }),
          // catchError(error => of()),
          map(() => customer),
        );
      }
      this.cartManager.deleteCartAction();
      this.sasCartManager.deleteCartAction();
      return of(customer);
    }));
  }

  logOut(): Observable<any> {
    const params = {
      deviceId: navigator ? navigator.userAgent : '',
      firebaseTokenDevice: '',
      idCustomerWebSafe: this.customer.idCustomerWebSafe,
      isWeb: true,
    };
    // return of(params);
    const query = (countryConfig.isColombia) ? this.customerService.logOut(params) : of({});
    return query.pipe(finalize(() => {
      // this.router.navigate(['/']).then(() => {});
      setTimeout(() => {
        localStorage.clear();
        // console.log('ok');
        // window.location.href = '/';
        // window.location.reload();
      }, 100);
    }),
    tap(() => {
      this.lsm.removeAllItemsExcept(
        [
          'cities',
          'city',
          'originProperties',
          'enableSas',
          'programAndSaveDiscounts',
          'programAndSaveImages',
          'sasHourRange',
          'facets'],
      );
      this.ssm.removeAllItemsExcept(['deliveryTypeText', 'departments']);
    }));
  }

  update(rawData): Observable<any> {
    const phoneCode = rawData.phoneCode ? rawData.phoneCode : '';
    const data: any = {
      id: this.customer.id,
      firstName: rawData.firstName,
      lastName: rawData.lastName,
      gender: rawData.gender,
      countryId: rawData.countryId,
      phone: `${rawData.countryCode}${phoneCode}${rawData.phone}`,
      profileImageUrl: null,
      idCustomerWebSafe: this.customer.idCustomerWebSafe,
      token: {
        token: this.customer.token.token,
        tokenIdWebSafe: this.customer.token.tokenIdWebSafe,
      },
    };
    if (rawData.documentType) {
      data.documentType = rawData.documentType;
    }
    return this.customerService.update(data)
      .pipe(tap(res => {
        const customer = {...this.customer, ...res};
        this.saveCustomer(customer);
      }));
    // return of(data);
  }

  createCustomerInterests(data, params): Observable<any> {
    return this.customerService.createCustomerInterests(data, params);
  }

  getOrigin(property): Observable<any> {
    return this.configurationManager.getOrigin(property);
  }

  getCustomerLogin(data): Observable<any> {
    return this.customerService.getCustomerLogin(data);
  }

  sendMessage(data): Observable<any> {
    return this.customerService.sendMessage(data);
  }

  customerOnly(data): Observable<any> {
    const {idCustomerWebSafe, token, tokenIdWebSafe} = data.customerData;
    const {deliveryType} = data;
    const params = {
      deliveryType,
      idCustomerWebSafe,
      token,
      tokenIdWebSafe,
      latitude: CustomerManager.getCoordinates().lat,
      longitude: CustomerManager.getCoordinates().lon,
    }
    return this.customerService.customerOnly(params).pipe(tap(res => {
      const dataInfo = {
        token: {
          token,
          tokenIdWebSafe,
        },
        idCustomerWebSafe,
        ...res,
      }
      this.saveCustomer(dataInfo);
    }));
  }

  createPassword(data): Observable<any> {
    const dataSend = {
      idcustomer: String(this.customer.id),
      idcustomerwebsafe: this.customer.idCustomerWebSafe,
      token: this.customer.token.token,
      tokenidwebsafe: this.customer.token.tokenIdWebSafe,
      validationcode: this.codeSmsChangePass,
      ...data,
    };

    return this.customerService.createPassword(dataSend);
  }
}
