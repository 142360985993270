<div (mouseover)="getAllServices()" (click)="getAllServices()">
  <div *ngIf="!isBrowser">
    <app-navbar-ssr></app-navbar-ssr>
  </div>
  <ng-container #navbar *ngIf="isBrowser"></ng-container>
  <div class="open-app d-lg-none" [class.space-add]="showSelectAddress" *ngIf="isBrowser && countryConfig.downloadApp && showAppBar">
    <div class="close">
      <app-icons [type]="'close'" (click)="removeAppbar()"></app-icons>
    </div>
    <div class="download">
      <span class="text-download">Descarga gratis la<br>App de Farmatodo</span>
    </div>
    <div class="button-download">
      <a href="https://farmatodo.onelink.me/35y3/InstallHome">
        <app-button title="Descargar" customClasses="btn btn-download" sideImg="right" [srcImg]="logoStore">
        </app-button>
      </a>
    </div>
  </div>
  <div class="alert alert-info alert-dismissible fade show sw-update-alert" role="alert" [hidden]="!updateAvailable" *ngIf="loadModals">
    <span><strong>Una nueva versión está disponible.</strong> Puedes actualizar haciendo click en el botón</span>
    <button class="reload-button" (click)="reload()">Recargar</button>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- [@browserAnimation]="o.isActivated ? o.activatedRoute : ''" -->
  <ng-container #p2cHomebar></ng-container>
  <div class="cont-router-outlet" [class.space-select-address]="showSelectAddress" [ngClass]="spaceAddBarAddress" [class.sag-final]="sagFinalScan" (scroll)="onScroll($event)" [class.cont-home-r]="homeR && routerHome" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onModalScrollDown()">
  <!-- <div class="cont-router-outlet mt0"                                  (scroll)="onScroll($event)" [class.cont-home-r]="homeR && routerHome" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onModalScrollDown()"> -->
    <!-- #o="outlet" -->
    <!--cdk-virtual-scroll-viewport  style="height: 100vh" itemSize="1000"-->
    <router-outlet></router-outlet>
    <h1 *ngIf="showTitle">Farmatodo {{countryName}}</h1>
    <!--/cdk-virtual-scroll-viewport-->
    <!-- <ng-container #footer></ng-container> -->
    <app-footer></app-footer>
  </div>


  <ng-container *ngIf="loadModals && showContent">
    <div *ngIf="!disableSasCart">
      <ng-container #sasSidebarCart></ng-container>
      <ng-container #sasProductNotAvailable></ng-container>
    </div>

    <ng-container #customModal></ng-container>
    <ng-container #onlineOnlyModal></ng-container>
    <ng-container #buyAndGetModal></ng-container>
    <ng-container #onBoardingModal></ng-container>
    <ng-container #toast></ng-container>
  </ng-container>
  <ng-container #productToast></ng-container>
  <ng-container #menuBottom></ng-container>
  <ng-container #chatButton></ng-container>
  <ng-container #helpButton></ng-container>

  <app-scan-and-go-modal [hidden]="!sagFinalScan" [status]="sagModalStatus" [type]="'PERSONAL_SECURITY_REVIEW'">
    <app-button [buttonId]="'close-sag-modal'" [title]="'Aceptar'" (click)="closeSaGModal()" [customClasses]="'main lg'"></app-button>
  </app-scan-and-go-modal>
  <ng-container #p2cPayModal></ng-container>
  <ng-container #p2cInfoModal></ng-container>
  <ng-container #p2cMobile></ng-container>
  <!--app-menu-bottom (emitNavColor)="listenEmiterColor($event)" class="d-fixed d-md-none"></app-menu-bottom-->
  <!--div class="json-ld" [innerHTML]="jsonLD"></div-->

  <app-size-detector *ngIf="isBrowser"></app-size-detector>
</div>

<div id="braze-add-content" class="d-none"></div>
