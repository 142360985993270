import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class CountryConfigEvent {
  private countryConfigData = new BehaviorSubject({});
  getCountryConfig = this.countryConfigData.asObservable();

  changeCountryConfig(config: any) {
    this.countryConfigData.next(config);
  }
}
