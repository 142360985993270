import {State, Action, StateContext, StateOperator} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {UpdateCreditCardEvent} from '../../ui/shared/event-listeners/update-credit-card/update-credit-card.event';
import {
  CreateCreditCardAction,
  DeleteCreditCardAction,
  SaveCreditCardAction,
  SetDefaultCreditCardAction,
} from '../actions/credit-card.actions';

function removeCreditCard(payload): StateOperator<any> {
  const cardIdToRemove = payload.creditCardId;
  // console.log('cardIdToRemove:', cardIdToRemove);
  return state => {
    // console.log('>>>state:', state);
    const creditCards = !state.creditCards || !state.creditCards ? [] : state.creditCards
      .map(creditCard => {
        if (creditCard.creditCardId !== cardIdToRemove && creditCard.id !== cardIdToRemove) {
          return creditCard;
        }
        return null;
      }).filter(cc => !!cc);

    return {
      ...state,
      creditCards: [...creditCards],
    };
  };
}

function setDefaultCard(payload): StateOperator<any> {
  const newDefaultCardId = payload.creditCardId;
  // console.log('newDefaultCardId:', newDefaultCardId);
  return state => {
    // console.log('>>>state:', state);
    const creditCards = !state.creditCards || !state.creditCards ? [] : state.creditCards
      .map(creditCard => {
        creditCard = {...creditCard, defaultCard: false};
        if (
          (creditCard.creditCardId.toString() === newDefaultCardId.toString())
          || (creditCard.id && creditCard.id.toString() === newDefaultCardId.toString())
        ) {
          creditCard = {...creditCard, defaultCard: true};
        }
        return creditCard;
      });
    return {
      ...state,
      creditCards: [...creditCards],
    };
  };
}

@State({
  name: 'creditCardStore',
  defaults: {
    creditCards: [],
  },
})
@Injectable()
export class CreditCardState {
  constructor(private creditCardEvent: UpdateCreditCardEvent) {}

  @Action(SaveCreditCardAction)
  SaveCreditCardAction(ctx: StateContext<any>, action: SaveCreditCardAction) {
    const state = ctx.getState();
    // console.log('SaveCreditCardAction State =>', state);
    // console.log('SaveCreditCardAction action =>', action);
    ctx.setState({...state,
      creditCards: [
        ...action.creditCard,
      ]});
    // console.log('SaveCreditCardAction State =>', ctx.getState());
  }

  @Action(CreateCreditCardAction)
  CreateCreditCardAction(ctx: StateContext<any>, action: CreateCreditCardAction) {
    let state = ctx.getState();
    // console.log('CreateCreditCardAction action =>', action);
    ctx.setState({...state,
      creditCards: [
        ...state.creditCards,
        action.creditCard,
      ]});
    ctx.setState(setDefaultCard(action.creditCard));
    state = ctx.getState();
    // console.log('CreateCreditCardAction State =>', state);
    this.creditCardEvent.updateCreditCard(state.creditCards);
  }

  @Action(SetDefaultCreditCardAction)
  SetDefaultCreditCardAction(ctx: StateContext<any>, action: SetDefaultCreditCardAction) {
    // console.log('SetDefaultCreditCardAction action =>', action);
    ctx.setState(setDefaultCard(action));
    const state = ctx.getState();
    this.creditCardEvent.updateCreditCard(state.creditCards);
    // console.log('SetDefaultCreditCardAction State =>', state);
  }

  @Action(DeleteCreditCardAction)
  DeleteCreditCardAction(ctx: StateContext<any>, action: DeleteCreditCardAction) {
    // console.log('DeleteCreditCardAction action =>', action);
    ctx.setState(removeCreditCard(action));
    const state = ctx.getState();
    // console.log('DeleteCreditCardAction State =>', state);
    this.creditCardEvent.updateCreditCard(state.creditCards);
  }
}
