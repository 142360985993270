import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {countryConfig} from 'src/country-config/country-config';
import {isPlatformBrowser} from '@angular/common';
import {ConfigurationService} from '../../networking/services/configuration/configuration.service';
import {LocalStorageManager} from '../local-storage/local-storage.manager';
import {LocationManager} from '../location/location.manager';
import {DeliveryTypeEnum} from '../../networking/models/location/enum/delivery-type.enum';
import {SessionStorageManager} from '../session-storage/session-storage.manager';
import {CountryConfigEvent} from '../../../ui/shared/event-listeners/country-config-event/country-config-event';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationManager {
  keyClient = {keyClient: countryConfig.generalEnv.keyClient};
  isBrowser;

  constructor(private configService: ConfigurationService,
              private lsm: LocalStorageManager,
              private ssm: SessionStorageManager,
              private configCountryEvent: CountryConfigEvent,
              private locationManager: LocationManager,
              @Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  validateServices(): Observable<any> {
    return this.configService.validateServices(this.keyClient);
  }

  getOriginProperties(): Observable<any> {
    const prop = 'originProperties';

    /*const prodLs = [
      { objectID: 'ADDED_FROM_SUGGESTED_HOME', value: 'ADDED_FROM_SUGGESTED_HOME'},
      { objectID: 'ADDED_FROM_SUGGESTED_GROUP', value: 'ADDED_FROM_SUGGESTED_GROUP'},
      { objectID: 'ADDED_FROM_SEARCH', value: 'ADDED_FROM_SEARCH'},
      { objectID: 'ADDED_FROM_SAS_GALLERY', value: 'ADDED_FROM_SAS_GALLERY'},
      { objectID: 'ADDED_FROM_RECOMMENDED_CART', value: 'ADDED_FROM_RECOMMENDED_CART'},
      { objectID: 'ADDED_FROM_PRODUCT_DETAIL_ALSO_BOUGHT', value: 'ADDED_FROM_PRODUCT_DETAIL_ALSO_BOUGHT'},
      { objectID: 'ADDED_FROM_PRODUCT_DETAIL', value: 'ADDED_FROM_PRODUCT_DETAIL'},
      { objectID: 'ADDED_FROM_PREVIOUS_ORDER', value: 'ADDED_FROM_PREVIOUS_ORDER'},
      { objectID: 'ADDED_FROM_OFFER_SINGLE', value: 'ADDED_FROM_OFFER_SINGLE'},
      { objectID: 'ADDED_FROM_OFFER_GROUP', value: 'ADDED_FROM_OFFER_GROUP'},
      { objectID: 'ADDED_FROM_OFFER_FILTER', value: 'ADDED_FROM_OFFER_FILTER'},
      { objectID: 'ADDED_FROM_HIGHLIGHTED_HOME', value: 'ADDED_FROM_HIGHLIGHTED_HOME'},
      { objectID: 'ADDED_FROM_HIGHLIGHTED_GROUP', value: 'ADDED_FROM_HIGHLIGHTED_GROUP'},
      { objectID: 'ADDED_FROM_HIGHLIGHTED_CATEGORIES', value: 'ADDED_FROM_HIGHLIGHTED_CATEGORIES'},
      { objectID: 'ADDED_FROM_HIGHLIGHTED_CART', value: 'ADDED_FROM_HIGHLIGHTED_CART'},
      { objectID: 'ADDED_FROM_CATEGORIES', value: 'ADDED_FROM_CATEGORIES'},
      { objectID: 'ADDED_FROM_BEST_SELLERS_CATEGORIES', value: 'ADDED_FROM_BEST_SELLERS_CATEGORIES'},
      { objectID: 'ADDED_FROM_ALREADY_BOUGHT', value: 'ADDED_FROM_ALREADY_BOUGHT'}];

    this.lsm.set('originProperties', prodLs);*/

    if (this.ssm.has(prop)) {
      return of(this.ssm.get(prop));
    }

    return this.configService.getOriginProperties()
    .pipe(map((response: any) => {
      if (response.items) {
        const obj = 'objectID';
        const val = 'value';
        return response.items.map(property => {
          const p = {};
          const key = property[obj];
          p[key] = property[val];
          return p;
        });
      }
    }), tap(properties => this.ssm.set(prop, properties)));
  }

  get originProperties() {
    const prop = this.ssm.get('originProperties');
    return prop || {};
  }

  getOrigin(property): Observable<any> {
    return this.isBrowser ? this.getOriginProperties()
      .pipe(map(res => {
        const origin = res.filter(prop => prop[property])[0];
        return origin ? origin[property] : '';
      })) : of('');
  }

  getAlgoliaFacets(): Observable<any> {
    return this.configService.getAlgoliaFacets(this.keyClient);
  }

  getHighDemand() {
    const {city} = this.locationManager;
    if (!city || city.deliveryType !== DeliveryTypeEnum.EXPRESS) {
      return of(null);
    }
    return this.configService.getHighDemand(city.id);
  }

  getConfigurationMultiCountry() {
    const country: string = countryConfig.indicator.toLocaleLowerCase();
    this.configService.getMultiCountry({pais: country}).subscribe((response: any) => {
      this.ssm.set('multiCountry', response);
      this.setEventCountryConfig();
    });
  }

  setEventCountryConfig() {
    const config = this.ssm.get('multiCountry');
    this.configCountryEvent.changeCountryConfig(config);
  }

  getConfigurationModule(moduleName: string) {
    const module = this.ssm.get('multiCountry');
    return module ? module[moduleName] : {};
  }
}
