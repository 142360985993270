import {State, Action, StateContext, StateOperator} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  SaveScanAndGoConfigAction,
  UpdateScanAndGoFirebaseStatusAction,
  SaveScanAndGoFirebaseConfigAction,
  DeleteScanAndGoConfigAction,
  UpdateScanAndGoFirebaseActiveAction,
  UpdateScanAndGoCartAction,
  UpdateScanAndGoStoreIdAction,
  UpdateScanAndGoOrderDataAction
} from '../actions/scan-and-go.actions';
import {countryConfig} from '../../../country-config/country-config';

function updateScanAndGoFirebaseStatusAction(payload): StateOperator<any> {
  return state => ({...state, firebase: {...state.firebase, status: payload.status}});
}

function updateScanAndGoFirebaseActiveAction(payload): StateOperator<any> {
  return state => ({...state, firebase: {...state.firebase, active: payload.active}});
}

function updateScanAndGoCartAction(payload): StateOperator<any> {
  return state => ({...state, firebase: {...state.firebase, reload_cart: payload.reloadCart}});
}

function updateScanAndGoStoreIdAction(payload): StateOperator<any> {
  return state => ({...state, config: {...state.config, storeId: payload.storeId}});
}


@State({
  name: 'scanAndGoStore',
  defaults: {
    config: null,
    firebase: null,
    order: null,
  },
})
@Injectable()
export class ScanAndGoState {
  constructor() {}

  @Action(SaveScanAndGoConfigAction)
  SaveScanAndGoConfigAction(ctx: StateContext<any>, action: SaveScanAndGoConfigAction) {
    // console.log('action@SaveSAG =>', action);
    ctx.setState({
      ...ctx.getState(),
      config: {storeId: countryConfig.defaultStoreId, ...action.config},
    });
  }

  @Action(SaveScanAndGoFirebaseConfigAction)
  SaveScanAndGoFirebaseConfigAction(ctx: StateContext<any>, action: SaveScanAndGoFirebaseConfigAction) {
    // console.log('action@SaveFirebaseConfigSAG =>', action);
    ctx.setState({
      ...ctx.getState(),
      firebase: action.firebaseConfig,
    });
    // console.log('AFTER', ctx.getState());
  }

  @Action(UpdateScanAndGoOrderDataAction)
  UpdateScanAndGoOrderDataAction(ctx: StateContext<any>, action: UpdateScanAndGoOrderDataAction) {
    // console.log('action@SaveFirebaseConfigSAG =>', action);
    ctx.setState({
      ...ctx.getState(),
      order: action.order,
    });
    // console.log('AFTER', ctx.getState());
  }

  @Action(DeleteScanAndGoConfigAction)
  DeleteScanAndGoConfigAction(ctx: StateContext<any>) {
    ctx.setState({
      config: null,
      firebase: null,
      order: null,
    });
  }

  @Action(UpdateScanAndGoFirebaseStatusAction)
  UpdateScanAndGoFirebaseStatusAction(ctx: StateContext<any>, action: UpdateScanAndGoFirebaseStatusAction) {
    // console.log('action@UpdateStateSAG =>', action);
    ctx.setState(updateScanAndGoFirebaseStatusAction(action));
    // console.log('Updating STATUS current state:', ctx.getState());
  }

  @Action(UpdateScanAndGoFirebaseActiveAction)
  UpdateScanAndGoFirebaseActiveAction(ctx: StateContext<any>, action: UpdateScanAndGoFirebaseActiveAction) {
    // console.log('action@UpdateActionSAG =>', action);
    ctx.setState(updateScanAndGoFirebaseActiveAction(action));
    // console.log('Updating ACTIVE current state:', ctx.getState());
  }

  @Action(UpdateScanAndGoCartAction)
  UpdateScanAndGoCartAction(ctx: StateContext<any>, action: UpdateScanAndGoCartAction) {
    // console.log('action@UpdateCartSAG =>', action);
    ctx.setState(updateScanAndGoCartAction(action));
    // console.log('Updating reload cart current state:', ctx.getState());
  }

  @Action(UpdateScanAndGoStoreIdAction)
  UpdateScanAndGoStoreIdAction(ctx: StateContext<any>, action: UpdateScanAndGoStoreIdAction) {
    // console.log('action@UpdateCartSAG =>', action);
    ctx.setState(updateScanAndGoStoreIdAction(action));
    // console.log('Updating reload cart current state:', ctx.getState());
  }
}
