import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, distinctUntilChanged} from 'rxjs/operators';
import {SCREEN_SIZE} from './screen-size.enum';
import {ScreenSize} from './ScreenSize';

@Injectable()
export class ResizeService {
  get onResize$(): Observable<ScreenSize> {
    return this.resizeSubject.asObservable().pipe(
      map(size => ({
        mobile: size <= 2,
        desktop: size > 2,
      })),
      distinctUntilChanged(),
    );
  }

  private resizeSubject: Subject<SCREEN_SIZE>;

  constructor() {
    this.resizeSubject = new Subject();
  }

  onResize(size: SCREEN_SIZE) {
    this.resizeSubject.next(size);
  }
}
