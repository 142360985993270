import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RequestService} from '../../../handlers/request/request.service';
import {globalEnv} from '../../connection/global/global.env';
import {mercadopago} from '../../api/mercadopago/mercadopago.api';

@Injectable({
  providedIn: 'root',
})
export class MercadoPagoService {
  constructor(private req: RequestService) {}

  tokens(data): Observable<any> {
    const params = {
      public_key: mercadopago.publicKey,
    };
    return this.req.post(`${globalEnv.apiMp}${mercadopago.get}`, data, params, null, null, true);
  }
}
