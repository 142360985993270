import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {KeyClient} from '../../models/shared/requests/key-client';
import {configurationApi} from '../../api/configuration/configuration.api';
import {RequestService} from '../../../handlers/request/request.service';
import {ConfirmationResponse} from '../../models/shared/responses/confirmation-response';
import {FacetsRes} from '../../models/algolia/responses/facets';
import {OriginPropertiesResponse} from '../../models/algolia/responses/origin-properties';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private req: RequestService) { }

  validateServices(params: KeyClient): Observable<ConfirmationResponse> {
    return this.req.get(`${globalEnv.be2}${configurationApi.validateServices}`, params);
  }

  getOriginProperties(): Observable<OriginPropertiesResponse> {
    return this.req.get(`${globalEnv.be2}${configurationApi.originProperties}`);
  }

  getAlgoliaFacets(params: KeyClient): Observable<FacetsRes> {
    return this.req.get(`${globalEnv.be2}${configurationApi.algoliaFacets}`, params);
  }

  getHighDemand(id): Observable<any> {
    return this.req.get(`${globalEnv.dataFoundation}${configurationApi.highDemand}`, null, id, false, false);
  }

  getMultiCountry(params: any): Observable<any> {
    return this.req.get(`${globalEnv.cloudFunction}${configurationApi.multiCountry}`, params);
  }
}
