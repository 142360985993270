export enum BackendGatewayVenEnum {
  SAS = 'https://sas-v30-dot-stunning-base-164402.appspot.com/', // no existe en vzla
  DATA_FOUNDATION = 'https://datafoundation-dot-stunning-base-164402.appspot.com/', // no existe en vzla
  GATEWAY = 'https://gateway-dot-stunning-base-164402.appspot.com/', // no existe en vzla
  CMS = 'https://cms-dot-stunning-base-164402.appspot.com/', // no existe en vzla
  CLOUD_FUNCTION = 'https://us-central1-stunning-base-164402.cloudfunctions.net/', // no existe en vzla
  DEVELOP = 'https://dev-dot-sturdy-spanner-212219.appspot.com/_ah/api/',
  QA = 'https://wlqa-dot-oracle-services-vzla.uc.r.appspot.com/_ah/api/',
  QA_MULTICOUNTRY = 'https://qa-dot-multicountry-apigateway-dot-stunning-base-164402.uc.r.appspot.com/',
  // GateWay
  PRODUCTION = 'https://oracle-services-vzla.uc.r.appspot.com/_ah/api/',  
  
  
  // MULTICOUNTRY = 'https://qa-vzla-dot-multicountry-apigateway-dot-stunning-base-164402.uc.r.appspot.com/',
  // PAGOMOVIL = 'https://qaxcenter-2-dot-payments-dot-oracle-services-vzla.uc.r.appspot.com/backend/flexible/v1/payments',

  MULTICOUNTRY = 'https://multicountry-apigateway-dot-stunning-base-164402.uc.r.appspot.com/',
  // MULTICOUNTRY = 'https://prod-vzla-dot-multicountry-apigateway-dot-stunning-base-164402.uc.r.appspot.com/',
  PAGOMOVIL = 'https://payments-dot-oracle-services-vzla.uc.r.appspot.com/backend/flexible/v1/payments',
  
}