import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  // constructor() { }

  getInnerWidth() {
    return window.innerWidth;
  }
}
