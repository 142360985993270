import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BrowserDetectorService {
  isBrowser;
  constructor(@Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  checkBrowser() {
    // Get the user-agent string
    // @ts-ignore
    const userAgentString = this.isBrowser ? (navigator.userAgent || navigator.vendor || window.opera) : '';

    const isInstagram = userAgentString.indexOf('Instagram') > -1;
    const isIphone = userAgentString.toLowerCase().indexOf('iphone') > -1;

    // Detect Chrome
    let chromeAgent = userAgentString.indexOf('Chrome') > -1;

    // Detect Internet Explorer
    const IExplorerAgent = userAgentString.indexOf('Firefox') < 0
      && (userAgentString.indexOf('MSIE') > -1
      || userAgentString.indexOf('rv:') > -1);

    // Detect Firefox
    const firefoxAgent = userAgentString.indexOf('Firefox') > -1;

    // Detect Safari
    let safariAgent = userAgentString.indexOf('Safari') > -1;

    // Discard Safari since it also matches Chrome
    if ((chromeAgent) && (safariAgent)) {
      safariAgent = false;
    }

    // Detect Opera
    const operaAgent = userAgentString.indexOf('OP') > -1;

    // Discard Chrome since it also matches Opera
    if ((chromeAgent) && (operaAgent)) {
      chromeAgent = false;
    }

    return {
      safari: safariAgent,
      chrome: chromeAgent,
      firefox: firefoxAgent,
      ie: IExplorerAgent,
      opera: operaAgent,
      instagram: isInstagram,
      iphoneInstagram: isInstagram && isIphone,
      iphone: isIphone,
    };
  }
}
