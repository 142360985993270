export const metaTags = [
  {
    titleDepartment: 'salud-y-medicamentos',
    nameSimple: 'salud y medicamentos',
    metaTitle: 'Salud y Medicamentos',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
      + 'Aprovecha las mejores ofertas y promociones en medicamentos y productos de Farmacia. Ingresa y compra online - Delivery.',
    description:
      'Compra medicamentos online en farmatodo.com. Ingresa ahora y encuentra todos los tipos de '
      + 'medicamentos que buscas: dolor muscular, dolor general, dolor fuerte, dermatológicos, gripa y mucho más.',
  },
  {
    titleDepartment: 'belleza',
    nameSimple: 'belleza',
    metaTitle: 'Productos y Accesorios de belleza',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
    + 'Aprovecha las mejores ofertas y promociones en Cosméticos y más productos y marcas de maquillaje. '
    + 'Ingresa y compra online - Delivery.',
    description:
      'Ingresa ahora y podras ver la gran cantidad de accesorios de belleza que tenemos para que puedas lucir aún más bella.',
  },
  {
    titleDepartment: 'cuidado-del-bebe',
    nameSimple: 'bebé',
    metaTitle: 'Productos para el cuidado del bebé',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
      + 'Aprovecha las mejores ofertas y promociones en Alimentos y productos para el cuidado e higiene del bebé.'
      + ' Ingresa y compra online - Delivery.',
    description:
      'Encuentra todos los productos que necesitas para el cuidado de tu bebé en farmatodo.com. Compra '
      + 'pañales, cremas, productos de aseo, teteros, chupos y mucho más en un solo lugar. Realiza tus '
      + 'pedidos a domicilio: fácil y rápido.',
  },
  {
    titleDepartment: 'cuidado personal',
    nameSimple: 'cuidado personal',
    metaTitle: 'Productos para el cuidado personal',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo.'
      + ' Aprovecha las mejores ofertas y promociones en productos de cuidado e higiene personal. Ingresa y compra online - Delivery.',
    description:
      'Encuentra productos para el cuidado personal en farmatodo.com. Ingresa ahora, compra online y '
      + 'recibe a domicilio: fácil, rápido y sin filas en un solo lugar.',
  },
  {
    titleDepartment: 'alimentos-y-bebidas',
    nameSimple: 'alimentos y bebidas',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
      + 'Aprovecha las mejores ofertas y promociones en Alimentos, Bebidas, Snacks y muchos más. Ingresa y compra online - Delivery.',
    description:
      'Encuentra en Farmatodo todo el Alimentos, bebidas, licores, alimentos saludables y mucho más a un '
      + 'clic de distancia. Ingresa ahora y llévalos fácil y rápido.',
  },
  {
    titleDepartment: 'hogar-mascotas-y-otros',
    nameSimple: 'hogar mascota y otros',
    metaTitle: 'Productos para el hogar, ferretería y más',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
      + 'Aprovecha las mejores ofertas y promociones en Productos de Higiene del Hogar, Mascotas y más productos para el hogar. '
      + 'Ingresa y compra online - Delivery.',
    description:
      'Encuentra hogar, mascotas y otros en Farmatodo.com.co. Ingresa ahora, compra todos los productos'
      + ' que necesitas y recibe a Domicilio: rápido, fácil y seguro.',
  },
];
