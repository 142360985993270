import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateCreditCardEvent {
  private creditCard = new BehaviorSubject(null);
  updatedCreditCard = this.creditCard.asObservable();

  updateCreditCard(status: any) {
    this.creditCard.next(status);
  }
}
