/* eslint-disable max-len */
import {firebaseConfig} from '../environments/colombia/firebase-config';
import {firebaseEnv} from '../environments/colombia/firebase.env';
import {generalEnv, profileConfig} from '../environments/colombia/general.env';
import {firebaseVzlaConfig} from '../environments/venezuela/firebase-config';
import {firebaseVzlaEnv} from '../environments/venezuela/firebase.env';
import {generalVzlaEnv, profileConfigVzla} from '../environments/venezuela/general.env';
import {environment} from '../environments/environment';
import {metaTags as metaTagsCol} from '../environments/colombia/meta-data';
import {metaTags as metaTagsVen} from '../environments/venezuela/meta-data';
import {redes} from '../environments/colombia/redes.env';
import {redesVzla} from '../environments/venezuela/redes.env';
import {ssrTokens} from '../environments/colombia/ssr-tokens';
import {ssrTokens as ssrTokensVzla} from '../environments/venezuela/ssr-tokens';
import {zendeskConfigCol} from '../environments/colombia/zendesk.config';
import {zendeskConfigVen} from '../environments/venezuela/zendesk.config';
import {brazeConfigVzla} from '../environments/venezuela/braze-config';
import {brazeConfig} from '../environments/colombia/braze-config';
//import { isDevMode } from '@angular/core';

export enum CountryEnum {
  COLOMBIA = 'COL',
  VENEZUELA = 'VEN'
}

interface CountryConfig {
  algolia: {id: string, key: string, index: string };
  backend: any;
  countryName: string;
  indicator: CountryEnum;
  currency: string;
  cctld: string;
  documentTypes: {id: number, type: string, description: string}[];
  phoneCode: string[];
  countryCode: number;
  routeImg: string;
  countries: { id: string, prefix: number, flagUrl: string }[];
  firebaseEnv: { usePublicVapidKey: string, messagingSenderId: string};
  firebaseConfig: {
    apiKey: string,
    authDomain: string,
    databaseURL: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string
  };
  generalEnv: {
    keyClient: number, source: string,
    coordinates: { lat: number, lon: number },
    deliveryTypes: any,
  };
  isColombia?: boolean;
  isVenezuela?: boolean;
  zendesk: { chat: any},
  ssrTokens?: any;
  version: {
    RFC: string,
    date?: string,
  };
  analytics: {
    gtmContainer: string;
    googleAdsConversionId: string;
  };
  defaultStoreId: number;
  metaData: any;
  redes?: any;
  defaultCity: string;
  braze?: any;
  downloadApp?: boolean;
  textSeoHome?: any;
}

const colombia: CountryConfig = {
  algolia: environment.algoliaColombia,
  backend: environment.backendColombia,
  firebaseConfig,
  firebaseEnv,
  generalEnv,
  countryCode: 57,
  ...profileConfig,
  countryName: 'Colombia',
  indicator: CountryEnum.COLOMBIA,
  isColombia: true,
  zendesk: zendeskConfigCol,
  currency: 'COP',
  cctld: 'co',
  routeImg: 'svg/col-flag.svg',
  ssrTokens,
  version: {
    RFC: '',
    date: '',
  },
  analytics: {
    gtmContainer: 'GTM-K2QWH8B',
    googleAdsConversionId: 'AW-955172857',
  },
  defaultStoreId: 26,
  metaData: metaTagsCol,
  redes,
  defaultCity: 'BOG',
  braze: brazeConfig.production,
  downloadApp: true,
  textSeoHome: {
    footer: `
  <div class=" col-12 col-md-3">
      <h1 class="title-seo-h1">Droguería Online: Domicilio las 24 Horas</h1>
      <br><p class="description-page">¡Hola, Bienvenido a Farmatodo! Si aún no conoces nuestro portafolio, te contamos que tenemos todo lo que necesitas en un solo lugar:</p>
      <br><p class="description-page">Encontrarás desde productos de <a class= "text-seo-link" href="https://www.farmatodo.com.co/categorias/salud-y-medicamentos/drogueria">Droguería y farmacia</a>, Droguería y farmacia</a>, ya que <strong>somos especialistas en medicamentos y todos los productos relacionados con el cuidado de salud</strong>, hasta todos productos de despensa como tortillas, gaseosas y mucho más. Es decir, que puedes adquirir todo en un solo lugar y a un clic de distancia.</p>

    </div>

    <div class=" col-12 col-md-3">
      <h2 class="title-seo-h2">¿Cómo explorar nuestra página y categorías?</h2>
      <br><p class="description-page">Lo primero que debes hacer es familiarizarte con ella. En la parte superior hallarás diversas pestañas, las cuales se componen por <a class= "text-seo-link"href="https://www.farmatodo.com.co/categorias/salud-y-medicamentos">Salud y
      Medicamentos</a>, <a class= "text-seo-link"href="https://www.farmatodo.com.co/categorias/belleza">Belleza</a>, <a class= "text-seo-link"href="https://www.farmatodo.com.co/categorias/cuidado-del-bebe">Cuidado del Bebé</a>, <a class= "text-seo-link"href="https://www.farmatodo.com.co/categorias/cuidado-personal">Cuidado Personal</a>, <a class= "text-seo-link"href="https://www.farmatodo.com.co/categorias/alimentos-y-bebidas">Alimentos y Bebidas</a>, y todo lo relacionado con el <a class= "text-seo-link"href="https://www.farmatodo.com.co/categorias/hogar-mascotas-y-otros">hogar, mascotas, diversión, entre otros.</a></p>
      <br><p class="description-page">De allí, se desplegará un universo de opciones para guiarte y llevarte a los productos que estás buscando. Además, podrás encontrar muchos más servicios destacados como nuestro <strong>domicilio 24 horas, los 7 días de la semana</strong>, el mundo de  <a class= "text-seo-link"href="https://www.farmatodo.com.comundoOfertas">ofertas de productos en todas las categorías</a>, <strong> EnvíaloYaVenezuela y Servicios Médicos.</strong></p>
      <br><p class="description-page"><strong> ¡Déjate llevar y vive una experiencia única con Farmatodo!</strong></p>

    </div>
    <div class=" col-12 col-md-3">
      <h2 class="title-seo-h2">¡Dile adiós al dolor!</h2>
      <br><p class="description-page">No pares tus actividades diarias y alivia esos síntomas con <a class= "text-seo-link" href="https://www.farmatodo.com.co/alivio-del-dolor">medicamentos para el malestar general.</a> Sabemos que en ocasiones nuestro cuerpo presenta diferentes dolores generales debido a las constantes actividades físicas que realizamos y estas señales de incomodidad también se pueden presentar internamente en el cuerpo debido a una mala alimentación.</p>
      <br><p class="description-page">En Farmatodo podrás encontrar
      <a class= "text-seo-link" href="https://www.farmatodo.com.co/alivio-del-dolor/dolor-abdominal-y-colico">pastillas para cólicos</a> y aliviar esos dolores naturales. Además,  podrás navegar y encontrar <a class= "text-seo-link" href="https://www.farmatodo.com.co/alivio-del-dolor/muscular-y-articular">cremas para dolores musculares y articulares.</a> Así lograrás continuar con tus labores diarias. ¡Navega, descubre, elige tu producto y recíbelo a
      domicilio en menos de 35 minutos!</p>
    </div>

    <div class=" col-12 col-md-3">
      <h2 class="title-seo-h2">¡Previene los síntomas de la gripa!</h2>
      <br><p class="description-page">Sabemos que en ocasiones nuestro cuerpo recibe pequeñas bacterias que se adquieren de los objetos
      que tocamos en las calles o simplemente cuando hablamos muy cerca a una persona que puede tener gripe. Este tipo de síntomas los
      podemos controlar con
      <a class= "text-seo-link" href="https://www.farmatodo.com.co/categorias/salud-y-medicamentos/tratamiento-de-la-gripa">pastillas buenas para la gripe</a>
       o <a class= "text-seo-link" href="https://www.farmatodo.com.co/categorias/salud-y-medicamentos/tratamiento-de-la-gripa/
      descongestionante">
      medicamentos antigripales</a>, así al siguiente día ya te sentirás mejor y tu energía estará al 100%.</p>
      <br><p class="description-page">Evita los síntomas de
      <a class= "text-seo-link" href="https://www.farmatodo.com.co/categorias/salud-y-medicamentos/tratamiento-de-la-gripa/resfriado-y-gripa">la gripe</a>
      con una buena limpieza de manos cada vez que llegues a casa o de visitar a algún familiar, recuerda que el lavado y la duración del
      mismo, es fundamental para evitar infecciones. Además, un buen
      <a class= "text-seo-link" href="https://www.farmatodo.com.co/categorias/salud-y-medicamentos/botiquin-y-primeros-auxilios/antisepticos">gel
      antibacterial</a> te podrá prevenir de contraer bacterias indeseadas.</p>
    </div>`,
},
};
colombia.version = {
  RFC: `${colombia.indicator}-V3268-1.1.0`,
  date: '',
};

// eslint-disable-line @typescript-eslint/no-unused-vars
const venezuela: CountryConfig = {
  algolia: environment.algoliaVenezuela,
  backend: environment.backendVenezuela,
  firebaseConfig: firebaseVzlaConfig,
  firebaseEnv: firebaseVzlaEnv,
  generalEnv: generalVzlaEnv,
  countryCode: 58,
  ...profileConfigVzla,
  countryName: 'Venezuela',
  indicator: CountryEnum.VENEZUELA,
  isVenezuela: true,
  zendesk: zendeskConfigVen,
  currency: 'VEF',
  cctld: 've',
  routeImg: 'svg/ven-flag.svg',
  ssrTokens: ssrTokensVzla,
  version: {
    RFC: '',
    date: '',
  },
  analytics: {
    gtmContainer: 'GTM-W8V62KV',
    googleAdsConversionId: '',
  },
  defaultStoreId: 112,
  metaData: metaTagsVen,
  redes: redesVzla,
  defaultCity: 'CCS',
  braze: brazeConfigVzla.production,
  // braze: brazeConfigVzla.testing
  downloadApp: false,
  textSeoHome: {
    footer: ` <div class=" col-12 col-md-6">

     <h1 class="title-seo-h1">Farmacia online: Delivery</h1>
     <br><p class="description-page">¡Hola, bienvenido a Farmatodo! si aún no conoces nuestro portafolio, te contamos que tenemos todo lo que necesitas en un solo lugar:</p>
     <br><p class="description-page">Encontrarás desde productos de<strong> farmacia</strong>, ya que<strong> somos especialistas en medicamentos y todos los productos relacionados con el cuidado de salud</strong>, hasta todos productos de <a class= "text-seo-link" href="https://farmatodo.com.ve/categorias/alimentos-y-bebidas/alimentos?pag=2">despensa</a> como tortillas, <a class= "text-seo-link"href="https://farmatodo.com.ve/categorias/alimentos-y-bebidas/bebidas">gaseosas</a> y mucho más. Es decir, que puedes adquirir todo en un solo lugar y a un clic de distancia.</p>

   </div>
   <div class=" col-12 col-md-6">
     <h1 class="title-seo-h1">¿Cómo explorar nuestra página y categorías?</h1>
     <br><p  class="description-page">lo primero que debes hacer es familiarizarte con ella. En la parte superior hallarás diversas pestañas, las cuales se componen por<a class= "text-seo-link"href="https://farmatodo.com.ve/categorias/salud-y-medicamentos"> salud y medicamentos</a>, <a class= "text-seo-link"href="https://farmatodo.com.ve/categorias/belleza"> belleza</a>,<a class= "text-seo-link"href="https://farmatodo.com.ve/categorias/bebe">cuidado del bebé</a>,<a class= "text-seo-link"href="https://farmatodo.com.ve/categorias cuidado-personal"> cuidado personal</a>, <a class= "text-seo-link"href="https://farmatodo.com.ve/categorias/alimentos-y-bebidas"> alimentos y bebidas</a>, y todo lo relacionado con el <a class= "text-seo-link"href="https://farmatodo.com.ve/categorias/hogar-mascota-y-otros"> hogar, mascotas, diversión, entre otros</a>. De allí, se desplegará un universo de opciones para guiarte y llevarte a los productos que estás buscando. Además, podrás encontrar muchos más servicios destacados como nuestro<strong> delivery los 7 días de la semana</strong>, el <a class= "text-seo-link"href="https://farmatodo.com.ve/content/RevistaFarmatodo"> folleto de súper precios para todas las categorías</a>, <strong> envíaloYa, programa cuidamos tu salud, Asesoría farmacéutica en línea</strong> y muchos más.</p>
     <br><p  class="description-page">¡Déjate llevar y vive una experiencia única con farmatodo! </p>

   </div>`,
 },
};
venezuela.version = {
  RFC: `${venezuela.indicator}-V2721-1.0.0`,
  date: '',
};

export const countries = [colombia, venezuela];

// Descomentar cuando el país sea Colombia
export {colombia as countryConfig};

// Descomentar cuando el país sea Venezuela
// export {venezuela as countryConfig};
