<footer class="container-fluid justify-content-center footer" *ngIf="!show">
  <div class="col-12 pt-5 d-none d-lg-block">
    <div class="row links">
      <div class="col-3 imagen">
        <img [attr.data-src]="'assets/svg/logo-farmatodo.svg'" alt="Logo Farmatodo Footer" class="lazy-load-footer v-none" />
      </div>
      <div class="col-3 contacto">
        <h4 class="footer-title">NUESTRAS TIENDAS</h4>
        <ul class="footer-menu">
          <li>
            <a routerLink="/tiendas"> Ubicar Tienda</a>
          </li>
        </ul>
        <ng-container *ngIf="configModule?.aboutUs">
          <h4 class="footer-title">NOSOTROS</h4>
          <p class="contacto-information">Carrera 11a#98-50</p>
          <p class="contacto-information">Bogotá, Colombia</p>
          <p class="contacto-information">0317469000</p>
        </ng-container>
      </div>
      <div class="col-3 categorias">
        <h4>CATEGORÍAS</h4>
        <ul class="footer-menu">
          <li *ngFor="let item of categories">
            <a routerLink="/categorias/{{ item?.link }}">{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <div class="col-3 otros">
        <h4 class="footer-title">OTROS LINKS</h4>
        <ul class="footer-menu">
          <li *ngFor="let item of otherLinks">
            <a routerLink="{{ item?.link }}" (click)="menuNavigation(item.indexNav)">{{ item.name }}</a>
          </li>
          <li>
            <a href="https://farmatodo1393.zendesk.com/hc/es-419/requests/new?ticket_form_id=360001044991" target="_blank" rel="noopener noreferrer">Peticiones, quejas o reclamos</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row metodos">
      <div class="col-2 d-flex align-items-center flex-column text-center" *ngFor="let method of paymentMethods">
        <img [attr.data-src]="'assets/svg/' + method.img + '-footer.svg'" alt="" class="lazy-load-footer v-none" />
        <p>{{ method.name }}</p>
      </div>
    </div>
    <div class="row copy">
      <div class="col-3">
        <p>© {{ currentYear }} Farmatodo {{ countryName }}</p>
        <small class="text-version">{{ version.RFC }}</small>
      </div>
      <div class="col-3">
        <div class="imaginamos-text">Diseñado por</div>
        <a id="footer-open-link-imaginamos-11" href="https://imaginamos.com/" rel="noreferrer noopener" target="_blank">
          <img class="logo-imaginamos-d lazy-load-footer v-none imaginamos" [attr.data-src]="'assets/svg/imaginamos.svg'" alt="Imaginamos" />
        </a>
      </div>
      <div class="col-3 cont-redes-f">
        <div class="row">
          <div class="col-3 text-center">
            <a id="footer-open-link-facebook-21" href="https://www.facebook.com/{{ configModule?.socialNetworks.fb }}/" rel="noreferrer noopener" target="_blank" class="link-social-img">
              <img class="social-img lazy-load-footer v-none" [attr.data-src]="'assets/svg/facebook.png'" alt="facebook farmatodo" />
            </a>
          </div>
          <div class="col-3 text-center">
            <a id="footer-open-link-twitter-21" href="https://twitter.com/{{ configModule?.socialNetworks.tw }}" rel="noreferrer noopener" target="_blank" class="link-social-img">
              <img class="social-img lazy-load-footer v-none" [attr.data-src]="'assets/svg/twitter.png'" alt="twitter farmatodo" />
            </a>
          </div>
          <div class="col-3 text-center">
            <a id="footer-open-link-instagram-21" href="https://www.instagram.com/{{ configModule?.socialNetworks.ig }}/?hl=es" rel="noreferrer noopener" target="_blank" class="link-social-img">
              <img class="social-img lazy-load-footer v-none" [attr.data-src]="'assets/svg/instagram-footer.svg'" alt="instagram farmatodo" />
            </a>
          </div>
          <div class="col-3 text-center">
            <a id="footer-open-link-linkedin-21" href="https://co.linkedin.com/company/{{ configModule?.socialNetworks.in }}" rel="noreferrer noopener" target="_blank" class="link-social-img">
              <img class="social-img lazy-load-footer v-none" [attr.data-src]="'assets/svg/linkedin.png'" alt="linkendin farmatodo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 d-lg-none">
    <div class="row download" *ngIf="showDownApp">
      <div class="col-12 title">DESCARGA NUESTRA APP</div>
      <div class="col-12 content">
        <a id="footer-open-link-apple-store" href="https://itunes.apple.com/co/app/{{ configModule?.apps.ios }}?mt=8" rel="noreferrer noopener" target="_blank">
          <img [attr.data-src]="'assets/svg/app-store.svg'" alt="" class="img lazy-load-footer v-none" />
        </a>
        <a id="footer-open-link-play-store" href="https://play.google.com/store/apps/details?id={{
            configModule?.apps.android
          }}&hl=es" rel="noreferrer noopener" target="_blank">
          <img [attr.data-src]="'assets/svg/google-play.svg'" alt="" class="img lazy-load-footer v-none" />
        </a>
      </div>
    </div>
    <div class="row sociales">
      <div class="col-12 title">SIGUENOS EN REDES SOCIALES</div>
      <div class="col-12 content">
        <div class="row">
          <div class="col-3 text-center social-network-item">
            <a id="footer-open-link-facebook-2" href="https://www.facebook.com/{{ configModule?.socialNetworks.fb }}/" rel="noreferrer noopener" target="_blank" class="link-social-img">
              <img class="img" [src]="'assets/svg/facebook.png'" alt="facebook farmatodo" *ngIf="!lazyLoad; else lazyFb" />
              <ng-template #lazyFb>
                <img class="img lazy-load-footer v-none" [attr.data-src]="'assets/svg/facebook.png'" alt="facebook farmatodo" />
              </ng-template>
            </a>
          </div>
          <div class="col-3 text-center social-network-item">
            <a id="footer-open-link-twitter-2" href="https://twitter.com/{{ configModule?.socialNetworks.tw }}" rel="noreferrer noopener" target="_blank" class="link-social-img">
              <img class="img" [src]="'assets/svg/twitter.png'" alt="twitter farmatodo" *ngIf="!lazyLoad; else lazyTw" />
              <ng-template #lazyTw>
                <img class="img lazy-load-footer v-none" [attr.data-src]="'assets/svg/twitter.png'" alt="twitter farmatodo" />
              </ng-template>
            </a>
          </div>
          <div class="col-3 text-center social-network-item">
            <a id="footer-open-link-instagram-2" href="https://www.instagram.com/{{ configModule?.socialNetworks.ig }}/?hl=es" target="_blank" class="link-social-img">
              <img class="img" [src]="'assets/svg/instagram-footer.svg'" alt="instagram farmatodo" *ngIf="!lazyLoad; else lazyIg" />
              <ng-template #lazyIg>
                <img class="img lazy-load-footer v-none" [attr.data-src]="'assets/svg/instagram-footer.svg'" alt="instagram farmatodo" />
              </ng-template>
            </a>
          </div>
          <div class="col-3 text-center social-network-item">
            <a id="footer-open-link-linkedin-2" href="https://co.linkedin.com/company/{{ configModule?.socialNetworks.in }}" rel="noreferrer noopener" target="_blank" class="link-social-img">
              <img class="img" [src]="'assets/svg/linkedin.png'" alt="linkendin farmatodo" *ngIf="!lazyLoad; else lazyLi" />
              <ng-template #lazyLi>
                <img class="img lazy-load-footer v-none" [attr.data-src]="'assets/svg/linkedin.png'" alt="linkendin farmatodo" />
              </ng-template>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row metodos-pago">
      <div class="col-12 title">METODOS DE PAGO</div>
      <div class="col-12">
        <div class="row">
          <div class="col-6" *ngFor="let method of paymentMethods">
            <p class="list"><strong class="dot">·</strong> {{ method.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row diseno">
      <div class="col-6">
        <p>© {{ currentYear }} Farmatodo {{ countryName }} S.A.</p>
        <small class="text-version">{{ version.RFC }}</small>
      </div>
      <div class="col-6">
        <a id="footer-open-link-imaginamos-1" href="https://imaginamos.com/" rel="noreferrer noopener" target="_blank" class="link-disenamos">
          Diseñado por:
          <img
            class="logo-imaginamos-d imaginamos lazy-load-footer v-none"
            [attr.data-src]="'assets/svg/imaginamos.svg'"
            alt="Imaginamos"/>
        </a>
      </div>
    </div>
  </div>
</footer>