export const ssrTokens = {
  idCustomerWebSafe: 'ahZzfm9yYWNsZS1zZXJ2aWNlcy12emxhci4LEgRVc2VyIiQ4MDVhNDgxZi03NTA4LTQ3ZTMtOWY4My1hZWI3NDFmNmIzNjEM',
  token: {
    token: 'a42921ac273bc62673d388f65a60c67f',
    tokenExp: '2020-09-10T17:29:51.722Z',
    refreshToken: 'a1f10953896dbb0e925e200d5fd8391',
    tokenIdWebSafe: 'ahZzfm9yYWNsZS1zZXJ2aWNlcy12emxhcl0LEgRVc2VyIiQ4MDVhNDgxZi03NTA4LTQ3ZTMtOWY4My1hZWI3NDFmNmIzNjEMCxIFVG9rZW4iJGU5'
      + 'ODU2ZGRhLWMxZWEtNDQ2My1iODVlLTkxZmIyYjRhNGUxZAw',
  },
};
