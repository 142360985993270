import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SasProductNotAvailableEvent {
  private modalStatus = new Subject();
  currentStatus = this.modalStatus.asObservable();

  // constructor() { }

  changeStatus(modalStatus: any) {
    this.modalStatus.next(modalStatus);
  }
}
