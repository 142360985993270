export const generalVzlaEnv = {
  keyClient: 12345,
  source: 'WEB',
  coordinates: {
    lat: 4.681924599999999,
    lon: -74.0438379,
  },
  deliveryTypes: {
    express: {
      id: 'CCS',
      name: 'Caracas',
      defaultStore: 112,
      idStoreGroup: 112,
      deliveryType: 'EXPRESS',
    },
  },
};

export const profileConfigVzla = {
  documentTypes: [
    {id: 1, type: 'CI-V', description: 'CÉDULA DE IDENTIDAD'},
    {id: 2, type: 'CI-E', description: 'CÉDULA DE EXTRANJERÍA'},
    {id: 3, type: 'RIF-J', description: 'RIF PERSONA JURÍDICA'},
    {id: 4, type: 'RIF-P', description: 'RIF PERSONA NATURAL'},
    {id: 5, type: 'RIF-V', description: 'RIF-V'},
    {id: 6, type: 'RIF-E', description: 'RIF-E'},
    {id: 7, type: 'RIF-G', description: 'RIF-G'},
    {id: 8, type: 'Pasaporte', description: 'Pasaporte'},
  ],
  phoneCode: ['0412', '0416', '0414', '0424', '0212', '0426'],
  countries: [
    {
      id: 'VE',
      prefix: 58,
      flagUrl: 'assets/svg/icon-flag-ven.svg',
    },
  ],
};
