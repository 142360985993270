import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {customerAddress} from '../../api/customer/address.api';
import {RequestService} from '../../../handlers/request/request.service';
import {CreateAddressReq, UpdateAddressReq, ValidateAddressReq} from '../../models/customer/requests/address';
import {IdCustomerWebSafe} from '../../models/shared/requests/id-customer-web-safe';
import {AddresessRes, CreatedAddressRes, ValidateAddressRes} from '../../models/customer/responses/address-res';
import {ConfirmationResponse} from '../../models/shared/responses/confirmation-response';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  api = `${globalEnv.be2}${customerAddress.api}`;
  constructor(private req: RequestService) { }

  create(data: CreateAddressReq, params: IdCustomerWebSafe): Observable<CreatedAddressRes> {
    return this.req.post(this.api, data, params, true);
  }

  get(params: IdCustomerWebSafe): Observable<AddresessRes> {
    return this.req.get(this.api, params, null, true);
  }

  update(data: UpdateAddressReq, params: IdCustomerWebSafe): Observable<ConfirmationResponse> {
    return this.req.put(this.api, data, params, null, true);
  }

  delete(params): Observable<any> {
    return this.req.delete(this.api, null, params, true);
  }

  validate(data: ValidateAddressReq): Observable<ValidateAddressRes> {
    return this.req.post(`${globalEnv.be2}${customerAddress.validate}`, data, null, true);
  }
}
