import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ScreenSize} from '../../modules/size-detector/ScreenSize';

@Injectable({
  providedIn: 'root',
})
export class ResizeEvent {
  private screenSize = new BehaviorSubject(null);
  currentScreenSize = this.screenSize.asObservable();

  // constructor() { }

  changeScreenSize(size: ScreenSize) {
    this.screenSize.next(size);
  }
}
