import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerProfileMenuEvent {
  private option = new Subject();
  updatedOption = this.option.asObservable();

  updateOption(option: any) {
    this.option.next(option);
  }
}
