import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {Select, Store} from '@ngxs/store';
import {countryConfig} from 'src/country-config/country-config';
import * as turf from '@turf/turf';
import {AddressService} from '../../../networking/services/customer/address.service';
import {LocalStorageManager} from '../../local-storage/local-storage.manager';
import {CreateAddressAction, DeleteAddressAction, SaveAddressesAction, UpdateAddressAction} from '../../../actions/address.actions';
import {DeliveryTypeEnum} from '../../../networking/models/location/enum/delivery-type.enum';
import {UpdateAddressEvent} from '../../../../ui/shared/event-listeners/update-address/update-address.event';
import {LocationManager} from '../../location/location.manager';
import {GoogleTagService} from '../../../networking/services/shared/google-tag/google-tag.service';

@Injectable({
  providedIn: 'root',
})
export class AddressManager {
  customerAddresses = 'customerAddresses';
  deliveryAddress = 'deliveryAddress';

  @Select(state => state.addressStore) addressStore$: Observable<any>;

  get isVenezuela() { return !!countryConfig.isVenezuela; }

  constructor(private lsm: LocalStorageManager,
              private store: Store,
              private addressService: AddressService,
              private addressEvent: UpdateAddressEvent,
              private gtmService: GoogleTagService,
              private locationManager: LocationManager) { }


  setLastUpdate() {
    const now = (new Date()).getTime();
    this.lsm.set('lastUpdateAddresses', now );
  }

  getLastUpdate() {
    const now = (new Date()).getTime();
    const tm = this.lsm.get('lastUpdateAddresses') || 0;
    return (now - tm) > 15000;
  }


  validate(data): Observable<any> {
    const customer = this.lsm.get('customer');
    // console.log('validate data', data);

    const validateData: any = {
      address: data.address,
      city: data.cityId,
      idCustomer: customer.id,
    };
    if (data.coordinates) {
      const {lat, lng} = data.coordinates;
      validateData.lat = lat;
      validateData.lng = lng;
    }
    if (data.placeId) { validateData.placeId = data.placeId; }
    return this.addressService.validate(validateData);
  }

  handleAddressData(rawData, action, coordinates?) {
    const data = {
      ...rawData,
      cityName: rawData.city,
    };
    action = action.toLowerCase();
    // this.lsm.removeItem(this.customerAddresses);
    // console.log('AddressRawData:', data);
    data.city = data.cityId;
    delete data.cityId;
    if (!data.placeId) { delete data.placeId; }

    if (data.deliveryType !== DeliveryTypeEnum.EXPRESS) {
      delete data.latitude;
      delete data.longitude;
    }

    if (data.deliveryType !== DeliveryTypeEnum.ENVIALOYA) { delete data.courierCode; }

    //  || data.deliveryType === DeliveryTypeEnum.NATIONAL
    if (
      (data.deliveryType === DeliveryTypeEnum.EXPRESS)
      && (!data.latitude || !data.longitude)
      && countryConfig.isColombia
    ) {
      return this.validate(rawData)
        .pipe(switchMap(response => {
          // console.log('Vaidated Address:', response);
          if (response.statusCode > 200) { return throwError(response); }
          delete data.placeId;
          data.address = response.address;
          data.latitude = response.latitude;
          data.longitude = response.longitude;
          data.assignedStore = parseInt(response.idStore, 10);
          // return of(data);
          return this[action](data);
        }));
    }
    if (countryConfig.isVenezuela) {
      if (!data.latitude || data.latitude === '' || !data.longitude || data.longitude === '') {
        return throwError('Mueve el pin para indicar el punto exacto de tu dirección.');
      }
      if (coordinates && !this.verifyPoint(Number(data.latitude), Number(data.longitude), coordinates)) {
        return throwError('La dirección debe estar dentro del area hábilitada para domicilio.');
      }
    }
    // return of(data);
    return this[action](data);
  }

  getAddressesByCustomerId(customer) {
    return this.addressService.get({idCustomerWebSafe: customer.idCustomerWebSafe})
      .pipe(
        map(res => (res.addresses ? res.addresses : [])),
      );
  }

  create(data): Observable<any> {
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    const customer = callCenterCustomer || this.lsm.get('customer');
    const idCustomerWebSafe = {idCustomerWebSafe: customer.idCustomerWebSafe};
    // console.log('Create data', data);
    return this.addressService.create(data, idCustomerWebSafe)
      .pipe(tap(res => {
        // console.log('setDeliveryAddress', res, data.cityName);
        this.setDeliveryAddress(res.address);
        const address = {
          ...res.address,
          cityName: data.cityName,
        };
        this.store.dispatch(new CreateAddressAction(address))
        .subscribe(() => this.lsm.set(this.customerAddresses, this.store.snapshot().addressStore.addresses));
        this.locationManager.updateDeliveryType(res.address);
        this.addressEvent.changeAddress(res.address);

        if (callCenterCustomer) {
          // console.log(callCenterCustomer);
          if (!callCenterCustomer.addresses) {
            callCenterCustomer.addresses = [];
          }
          callCenterCustomer.addresses = [...callCenterCustomer.addresses, res.address];
          callCenterCustomer.deliveryAddress = res.address;
        }
        this.store.dispatch(new CreateAddressAction(address))
          .subscribe(() => this.lsm.set(this.customerAddresses, this.store.snapshot().addressStore.addresses));
      }));
  }

  get(): Observable<any> {
    const customer = this.lsm.get('customer');
    if (customer && !customer.isAnonymous) {
      return this.addressService.get({idCustomerWebSafe: customer.idCustomerWebSafe})
        .pipe(
          map(res => {
            if (this.isVenezuela) {
              if (res.addresses && !res.noitems) {
                return res.addresses;
              }
              return [];
            }
            return (res.addresses ? res.addresses : []);
          }),
          tap(addresses => {
            this.lsm.set(this.customerAddresses, addresses);
          }),
        );
    }
    return throwError({message: 'no customer found'});
  }

  update(data) {
    const customer = this.lsm.get('customer');
    const idCustomerWebSafe = {idCustomerWebSafe: customer.idCustomerWebSafe};
    // this.lsm.removeItem(this.customerAddresses);
    return this.addressService.update(data, idCustomerWebSafe)
      .pipe(
        tap(() => {
          const deliveryAddress = this.getDeliveryAddress();
          this.store.dispatch(new UpdateAddressAction(data)).subscribe(/*() =>
          this.lsm.set(this.customerAddresses, this.store.snapshot().addressStore.addresses)*/);

          if (deliveryAddress && deliveryAddress.deliveryType !== data.deliveryType) {
            this.locationManager.updateDeliveryType(data);
          }

          // console.log(data.idAddress, deliveryAddress.idAddress, deliveryAddress);
          if (deliveryAddress && data.idAddress === deliveryAddress.idAddress) {
            this.addressEvent.changeAddress(data);
            this.setDeliveryAddress(data);
          }
          this.addressEvent.changeAddresses(true);
        })
      );
  }

  delete(addressId) {
    const customer = this.lsm.get('customer');
    const params = {
      idAddress: addressId,
      idCustomerWebSafe: customer.idCustomerWebSafe,
    };
    return this.addressService.delete(params)
      .pipe(tap(() => {
        const deliveryAddress = this.getDeliveryAddress();
        if (deliveryAddress && deliveryAddress.idAddress.toString() === addressId.toString()) {
          this.lsm.removeItem(this.deliveryAddress);
        }
        this.store.dispatch(new DeleteAddressAction(addressId))
          .subscribe(/*() =>
          this.lsm.set(this.customerAddresses, this.store.snapshot().addressStore.addresses)*/
        );
      }));
  }

  setDeliveryAddress(address) {
    const callCenterCustomer = this.lsm.get('customerCallCenter');
    if (callCenterCustomer) {
      callCenterCustomer.deliveryAddress = address;
      this.lsm.set('customerCallCenter', callCenterCustomer);
    } else {
      this.lsm.set(this.deliveryAddress, address);
      this.gtmService.changeAddress(address);
    }
    this.validateDeliveryTypeUpdate(address, this.locationManager.city);
  }

  getDeliveryAddress() {
    let deliveryAddress;

    const callCenterCustomer = this.lsm.get('customerCallCenter');
    if (callCenterCustomer && callCenterCustomer.deliveryAddress) {
      deliveryAddress = callCenterCustomer.deliveryAddress;
    } else {
      deliveryAddress = this.lsm.get(this.deliveryAddress);
    }
    return deliveryAddress || null;
  }

  validateDeliveryTypeUpdate(location, localCity) {
    this.locationManager.validateDeliveryTypeUpdate(location, localCity);
  }

  // VLZA - Verificar punto en geomalla
  verifyPoint(latitude, longitude, polygon) {
    const pt = turf.point([latitude, longitude]);
    const poly = turf.polygon([polygon]);
    return turf.booleanPointInPolygon(pt, poly);
  }
}
