import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from './button.component';
import {LoadingModule} from '../loading/loading.module';

@NgModule({
  declarations: [ButtonComponent],
  imports: [
    CommonModule,
    LoadingModule,
  ],
  exports: [ButtonComponent],
})
export class ButtonModule { }
