import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {countryConfig} from 'src/country-config/country-config';
import {OrderService} from '../../../networking/services/order/order.service';
import {LocalStorageManager} from '../../local-storage/local-storage.manager';
import {CustomerManager} from '../../customer/customer/customer.manager';
import {LocationManager} from '../../location/location.manager';
import {AddressManager} from '../../customer/address/address.manager';
import {DeleteCartAction} from '../../../actions/cart.actions';
import {GoogleTagService} from '../../../networking/services/shared/google-tag/google-tag.service';
import {CallCenterManager} from '../../call-center/call-center.manager';
import {SessionStorageManager} from '../../session-storage/session-storage.manager';
import {DeliveryTypeEnum} from '../../../networking/models/location/enum/delivery-type.enum';
import {UpdateScanAndGoFirebaseStatusAction} from '../../../actions/scan-and-go.actions';
import {Router} from '@angular/router';
import {Product} from '../../../networking/models/product/responses/product.class';
import {MercadoPagoService} from '../../../networking/services/mercadopago/mercadopago.service';

@Injectable({
  providedIn: 'root',
})
export class OrderManager {
  get isVenezuela() { return countryConfig.isVenezuela; }
  tokenMP;
  constructor(private lsm: LocalStorageManager,
              private ssm: SessionStorageManager,
              private orderService: OrderService,
              private mercadoPagoService: MercadoPagoService,
              private customerManager: CustomerManager,
              private locationManager: LocationManager,
              private addressManager: AddressManager,
              private gtmService: GoogleTagService,
              private callCenterManager: CallCenterManager,
              private store: Store,
              private router: Router) { }

  create(rawData?, mercadoPago?): Observable<any> {
    const isSaG = this.lsm.get('mobileSaG');
    //  || !!this.store?.snapshot().scanAndGoStore?.config;
    if (isSaG) {
      rawData = this.store?.snapshot().scanAndGoStore?.order;
    }
    // console.log('rawData2', rawData);
    if (!rawData || !rawData.payment?.id) {
      this.router.navigate(['/checkout']);
      return of({});
    }
    const customerPagoMovil = rawData.customerPagoMovil || this.ssm.get('currentPM');
    const dollars = rawData.dollars || null;
    const {cart, orderDetails, payment} = rawData;
    const address = this.addressManager.getDeliveryAddress();
    const user = this.customerManager.customer;
    const itemList = cart.carts
      .flatMap(c => c.itemList
        .filter(i => parseInt(i.id, 10) > 0)
        .map(i => ({id: i.id, quantitySold: i.quantitySold})));

    let products = [];
    itemList
      .sort((a, b) => a.id - b.id)
      .forEach(product => {
      const currentProduct = products.filter(i => i && i.id === product.id)[0];
      if (!currentProduct) {
        products = [...products, product];
      } else {
        products.forEach(i => {
          if (i.id === product.id) {
            i.quantitySold += product.quantitySold;
          }
          return i;
        });
      }
    });
    // console.log('Products Sorted and Filtered:', products);
    // console.log('itemList:', itemList);
    let pickingDate;
    const expressCart = cart.carts.filter(c => c.isExpress)[0];
    if (expressCart) {
      pickingDate = expressCart.deliveryLabels && expressCart.deliveryLabels.picking ? expressCart.deliveryLabels.picking.timeStamp : null;
    }

    const callCenterCustomer = this.callCenterManager.getCustomer();

    let data: any = {
      addressDetails: address?.comments || '',
      customerPhone: user.phone,
      deliveryType: isSaG ? DeliveryTypeEnum.SCANANDGO : this.locationManager.getDeliveryType(),
      highDemand: cart.isHighDemand,
      idFarmatodo: callCenterCustomer ? callCenterCustomer.id : user.id,
      idStoreGroup: isSaG ? this.store?.snapshot().scanAndGoStore?.config.storeId : this.locationManager.getIdStoreGroup(),
      itemList,
      orderDetails,
      paymentType: {id: payment.id},
      source: countryConfig.generalEnv.source,
      paymentCardId: payment.creditCardId && !Number.isNaN(payment.creditCardId) ? payment.creditCardId : payment.id || 0,
      quotas: parseInt(payment.id, 10) === 3 ? payment.quotas : 1,
    };
    
    // P2C
    if(customerPagoMovil) {
      data.customerPagoMovil = customerPagoMovil;
    }
    // Pago-Mixto
    if(dollars != null) {
      data.dollars = dollars; 
    }
    // if (!isSaG) {
    //   data.idAddress = address?.idAddress;
    // }
    data.idAddress = address?.idAddress;

    if (callCenterCustomer) {
      data.customerIdCallCenter = user.id;
      data.customerPhone = callCenterCustomer.phone;
    }

    if (rawData.payment.creditCard?.creditCardNumber && rawData.isMercadoPago) {
      data = {
        ...data,
        creditCardToken: mercadoPago.id,
      };
    }

    if (pickingDate) {
      data = {
        ...data,
        pickingDate,
      };
    }

    // console.log('CreateOrderData:', data);
    // return of(data);
    // return of({});
    const idCustomerWebSafe = callCenterCustomer ? callCenterCustomer.idCustomerWebSafe : this.customerManager.customer.idCustomerWebSafe;

    if (data.paymentType.id.toString() === '2') {
      this.store.dispatch(new UpdateScanAndGoFirebaseStatusAction('ORDER_LOADED')).subscribe();
    }
    // return of({});
    // Antes de llegar aqui debo haber tokenizado la tarjeta
    return this.orderService.create(data, {idCustomerWebSafe})
      .pipe(tap(order => {
        // console.log('ORDER RESPONSE', {order});
        this.gtmService.onPurchase(cart, {...data, ...order}, this.customerManager.customer);
        this.store.dispatch(new DeleteCartAction()).subscribe();

        if (callCenterCustomer) {
          this.callCenterManager.logOut();
        }
      }));
  }

  getOrder(params): Observable<any> {
    const user = this.customerManager.customer;
    const dataSend = {
      ...params,
      idOrder: params,
      tokenIdWebSafe: user.token.tokenIdWebSafe,
      token: user.token.token,
    };
    return this.orderService.getSingle(dataSend)
      .pipe(map(res => ({...res, itemList: (res.itemList) ? res.itemList.map(i => new Product(i)) : []})));
  }

  getTracking(params): Observable<any> {
    const user = this.customerManager.customer;
    const data = {
      ...params,
      tokenIdWebSafe: user.token.tokenIdWebSafe,
      token: user.token.token,
    };
    return this.orderService.getTracking(data);
  }

  getOrders(data): Observable<any> {
    const user = this.customerManager.customer;
    // console.table(user);
    if (user) {
      const requestData: any = {
        ...data,
        idCustomerWebSafe: user.idCustomerWebSafe,
        token: {
          tokenIdWebSafe: user.token.tokenIdWebSafe,
          token: user.token.token,
        },
      };
      if (this.isVenezuela) { requestData.idCustomer = user.id; }
      return this.orderService.getAll(requestData);
    }
    return of(null);
  }

  rateOrder(data): Observable<any> {
    return this.orderService.rate(data);
  }

  reOrder(data): Observable<any> {
    const user = this.customerManager.customer;
    const params = {
      idStoreGroup: this.locationManager.getIdStoreGroup(),
      idCustomerWebSafe: user.idCustomerWebSafe,
    };
    return this.orderService.reOrder(data, params);
  }

  getRatings(): Observable<any> {
    const user = this.customerManager.customer;
    const data = {
      tokenIdWebSafe: user.token.tokenIdWebSafe,
      token: user.token.token,
    };
    return this.orderService.getRatings(data);
  }

  putQualifyService(data): Observable<any> {
    return this.orderService.rate(data);
  }

  getReviewLastOrderId() {
    return this.ssm.get('reviewLastOrderId');
  }

  setReviewLastOrderId(orderId: string) {
    return this.ssm.set('reviewLastOrderId', orderId);
  }
}
