import {State, Action, StateContext, StateOperator} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SaveSasCartAction, UpdateSasCartProductAction, DeleteSasCartAction} from '../actions/sas-cart.actions';
import {UpdateSasCartEvent} from '../../ui/shared/event-listeners/update-sas-cart/update-sas-cart.event';

function updateProductQuantity(payload): StateOperator<any> {
  // console.log('payload:', payload);
  const {product, quantity} = payload;
  // console.log({product, quantity});
  return state => {
    // console.log('>>>state:', state.sasCart.itemList);
    let quantityDiff = 0;
    let productExists = false;
    let itemList = !state.sasCart || !state.sasCart.itemList ? [] : state.sasCart.itemList;

    if (quantity > 0) {
      itemList = itemList.map(item => {
        if (item.id === product.id) {
          quantityDiff = quantity - item.quantity;
          item = {...item, quantity, quantitySold: quantity};
          productExists = true;
        }
        return item;
      });
    } else {
      itemList = itemList
        .filter(item => {
          if (item.id === product.id) {
            quantityDiff = quantity - item.quantity;
          }
          return item.id.toString() !== product.id.toString();
        });
      productExists = true;
    }

    if (!productExists) {
      quantityDiff = quantity;
      const item = {...product, quantity, quantitySold: quantity};
      itemList = [...itemList, item];
    }

    // console.log('SasCartState itemList:', itemList, itemList.length);

    return {
      ...state,
      sasCart: {
        // ...state.sasCart,
        itemList,
        amountCart: state.sasCart ? state.sasCart.amountCart ? state.sasCart.amountCart + quantityDiff : quantityDiff : 0,
      },
    };
  };
}

@State({
  name: 'sasCartStore',
  defaults: {sasCart: null},
})
@Injectable()
export class SasCartState {
  constructor(private updateSasCartEvent: UpdateSasCartEvent) {}

  @Action(SaveSasCartAction)
  SaveSasCart(ctx: StateContext<any>, action: SaveSasCartAction) {
    let state = ctx.getState();
    // console.log('action@SaveCart =>', action);
    ctx.setState({
      ...state,
      sasCart: action.cart,
    });
    state = ctx.getState();
    // console.log('State@SaveCart =>', state);
    this.updateSasCartEvent.changeSasCart(state.sasCart);
  }

  @Action(DeleteSasCartAction)
  DeleteSasCart(ctx: StateContext<any>) {
    ctx.setState({
      sasCart: null,
    });
    const state = ctx.getState();
    // console.log('State@DeleteSASCart =>', state);
    this.updateSasCartEvent.changeSasCart(state.sasCart);
  }

  @Action(UpdateSasCartProductAction)
  UpdateSasCartProduct(ctx: StateContext<any>, action: UpdateSasCartProductAction) {
    // console.log('State@UpdateSASCart =>', state);
    // console.log('action@UpdateSASCart =>', action);
    ctx.setState(updateProductQuantity(action));
    const state = ctx.getState();
    // console.log('newState =>>', state);
    this.updateSasCartEvent.changeSasCart(state.sasCart);
  }
}
