import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {customer} from '../../api/customer/customer.api';
import {RequestService} from '../../../handlers/request/request.service';

import {Logout} from '../../models/customer/requests/logout';
import {ValidateSmsToken} from '../../models/customer/requests/validate-sms-token';
import {ValidateCustomer} from '../../models/customer/requests/validate-customer';
import {Create, CreateParams} from '../../models/customer/requests/create';
import {Update} from '../../models/customer/requests/update';
import {ChangePassword, ResetPassword} from '../../models/customer/requests/password';
import {KeyClient} from '../../models/shared/requests/key-client';
import {Login} from '../../models/customer/requests/login';
import {Interests} from '../../models/customer/requests/interests';
import {CreatedCustomerRes} from '../../models/customer/responses/customer-res';
import {GetUserOrigin} from '../../models/customer/requests/get-user-origin';
import {GetCustomer} from '../../models/customer/requests/get';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private req: RequestService) {}

  get(params: GetCustomer): Observable<any> {
    return this.req.get(`${globalEnv.be2}${customer.get}`, params, null, true);
  }

  create(data: Create, params?: CreateParams): Observable<CreatedCustomerRes> {
    return this.req.post(`${globalEnv.be2}${customer.create}`, data, params);
  }

  createCustomerCallCenter(data, params): Observable<any> {
    return this.req.post(`${globalEnv.be2}${customer.createCallCenter}`, data, params, false, false);
  }

  validateToken(data): Observable<any> {
    return this.req.post(`${globalEnv.be2}${customer.validateToken}`, data, null, true);
  }

  validateSmsToken(data: ValidateSmsToken, params: KeyClient): Observable<any> {
    return this.req.post(`${globalEnv.be2}${customer.validateSmsToken}`, data, params);
  }

  validateSmsTokenFinal(params: KeyClient): Observable<any> {
    return this.req.get(`${globalEnv.be2}${customer.validateSmsTokenFinal}`, params);
  }

  validateWhatsapp(data): Observable<any> {
    return this.req.post(`${globalEnv.cloudFunction}${customer.validateWhatsapp}`, data, null, null, false);
  }

  validateCustomer(params: ValidateCustomer): Observable<any> {
    return this.req.get(`${globalEnv.be2}${customer.validate}`, params);
  }

  update(data: Update): Observable<any> {
    return this.req.put(`${globalEnv.be2}${customer.update}`, data);
  }

  changePassword(data: ChangePassword): Observable<any> {
    return this.req.put(`${globalEnv.be2}${customer.changePassword}`, data, null, null, true);
  }

  createPassword(data): Observable<any> {
    return this.req.put(`${globalEnv.be2}${customer.changePasswordV2}`, {}, null, null, false, null, null, data);
  }

  resetPassword(data: ResetPassword, params: KeyClient): Observable<any> {
    return this.req.post(`${globalEnv.be2}${customer.resetPassword}`, data, params);
  }

  login(params: Login, provider: string): Observable<any> {
    const api = provider === 'EMAIL'
      ? customer.login : provider === 'GOOGLE'
      ? customer.loginGoogle : customer.loginFacebook;
    return this.req.get(`${globalEnv.be2}${api}`, params);
  }

  loginPost(data: Login, provider: string): Observable<any> {
    let api;
    if (provider === 'EMAIL') {
      api = customer.loginPost;
      return this.req.post(`${globalEnv.be2}${api}`, data, null, true);
    }
    if (provider === 'GOOGLE') {
      api = customer.loginGoogle;
      return this.req.get(`${globalEnv.be2}${api}`, data);
    }
    if (provider === 'APPLE') {
      api = customer.loginFirebase;
      return this.req.get(`${globalEnv.be2}${api}`, data);
    }
    api = customer.loginFacebook;
    return this.req.get(`${globalEnv.be2}${api}`, data);
  }

  logOut(params: Logout): Observable<any> {
    return this.req.put(`${globalEnv.be2}${customer.deleteDevice}`, params, null, null, true);
  }

  createCustomerInterests(data: Interests, params: KeyClient): Observable<any> {
    return this.req.post(`${globalEnv.be2}${customer.interests}`, data, params, true);
  }

  getUserOrigin(params: GetUserOrigin): Observable<any> {
    return this.req.get(`${globalEnv.be2}${customer.getUserOrigin}`, params, null, true);
  }

  getCustomerByPhoneNumberOrDocumentNumber(data): Observable<any> {
    return this.req.post(`${globalEnv.be2}${customer.getCustomerByPhoneNumberOrDocumentNumber}`, data, null, null);
  }

  getCustomerByDocumentId(data): Observable<any> {
    return this.req.post(`${globalEnv.be2}${customer.getCustomerByDocumentId}`, data, null, null, true);
  }

  getCustomerLogin(data): Observable<any> {
    return this.req.post(`${globalEnv.be2}${customer.getCustomerLogin}`, data, null, false);
  }

  sendMessage(data): Observable<any> {
    return this.req.post(`${globalEnv.be2}${customer.sendMessage}`, data, null, false);
  }

  customerOnly(params): Observable<any> {
    return this.req.get(`${globalEnv.be2}${customer.customerOnly}`, params, null)
  }
}
