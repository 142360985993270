import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {first, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {KJUR} from 'jsrsasign';
import {Select, Store} from '@ngxs/store';
import {LocalStorageManager} from '../../local-storage/local-storage.manager';
import {CreditCardService} from '../../../networking/services/customer/credit-card.service';
import {Default, Delete, Tokenization} from '../../../networking/models/customer/requests/credit-card';
import {
  CreateCreditCardAction,
  DeleteCreditCardAction,
  SaveCreditCardAction,
  SetDefaultCreditCardAction,
} from '../../../actions/credit-card.actions';
import {CreditCardsEnum as CCEnum} from '../../../networking/models/customer/requests/credit-cards.enum';
import {AlgoliaService} from '../../../networking/services/algolia/algolia.service';
import {MercadoPagoService} from '../../../networking/services/mercadopago/mercadopago.service';

// TODO: Import session manager to verify is user is logged and isn't anonymous
@Injectable({
  providedIn: 'root',
})
export class CreditCardManager {
  tokenMP;
  constructor(
    private lsm: LocalStorageManager,
    private algoliaService: AlgoliaService,
    private creditCardService: CreditCardService,
    private store: Store,
    private mercadopagoService: MercadoPagoService,
  ) {}

  @Select(state => state.creditCardStore) creditCardStore$: Observable<any>;

  create(data, isMercadoPago?, tokenMP?): Observable<any> {
    // this.tokenizateMercadoPago(data);
    const token = this.tokenizate(data, isMercadoPago);
    if (isMercadoPago) {
      token.tokenCard = tokenMP.id;
    }
    return this.creditCardService.create(token).pipe(
      // tap(cc => console.log('Created CC:', cc)),
      tap(cc => this.store.dispatch(new CreateCreditCardAction(cc)).subscribe()),
    );
  }

  algoliaConfigurationPaymentMethod(): Observable<any> {
    return this.algoliaService.algoliaConfigurationPaymentMethod();
  }

  consultBin(creditCardNumber): Observable<any> {
    const creditCardData = {
      creditcardnumber: creditCardNumber.toString(),
    };
    return this.creditCardService.consultBin(creditCardData);
  }

  tokenizate(creditCardData: Tokenization, isMercadoPago) {
    const customer = this.lsm.get('customer');
    const formattedCreditCard = {
      payerId: customer.id.toString(),
      name: creditCardData.customerName,
      identificationNumber: creditCardData.documentNumber.toString(),
      paymentMethod: creditCardData.paymentMethod,
      number: creditCardData.maskedNumber.toString(),
      expirationDate: `20${creditCardData.year}/${creditCardData.month}`,
      cvv: creditCardData.cvv,
    };
    const header = {alg: 'HS256', cty: 'JWT'};
    const payload = JSON.stringify(formattedCreditCard);
    const signature = 'fc2aab5f-25af-465d-b67e-47879784fc19';
    const sJWT = KJUR.jws.JWS.sign('HS256', header, payload, signature);
    const data = {
      tokenCard: sJWT,
      isMercadoPago,
      id: customer.id,
      token: {
        token: customer.token.token,
        tokenIdWebSafe: customer.token.tokenIdWebSafe,
      },
    };
    return data;
  }

  tokenMercadoPago(data) {
    const credit = {
      card_number: data.maskedNumber.toString(),
      security_code: data.cvv.toString(),
      expiration_month: Number(data.month),
      expiration_year: Number(`20${data.year}`),
      cardholder: {
        name: data.customerName,
        identification: {
          number: data.documentNumber.toString(),
          type: 'CC',
        },
      },
      device: {
        fingerprint: {
           os: 'WEB',
        },
      },
    };
    return this.mercadopagoService.tokens(credit);
  }

  tokenCvv(data) {
    return this.mercadopagoService.tokens(data);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get(source?): Observable<any> {
    const customer = this.lsm.get('customer');

    if (customer && !customer.isAnonymous) {
      return this.creditCardStore$.pipe(
        switchMap(ccs => {
          // console.log(source + ' CC Store =>', ccs);
          if (ccs.creditCards.length > 0) {
            return of(ccs.creditCards);
          }

          const data = {
            idCustomerWebSafe: customer.idCustomerWebSafe,
          };
          return this.creditCardService.get(data)
            .pipe(
              map(cc => (cc.creditCardList ? cc.creditCardList : [])),
              map(creditCards => creditCards.map(cc => {
                  const src = franchise => {
                    switch (franchise) {
                      case CCEnum.AMEX:
                        return 'amex';
                      case CCEnum.VISA:
                        return 'visa';
                      case CCEnum.MASTERCARD:
                        return 'mastercard';
                      case CCEnum.DINERS:
                        return 'diners-club';
                      default:
                        return 'others-tdc';
                    }
                  };
                  return {...cc, src: `assets/svg/icon-${src(cc.paymentMethod)}.svg`};
                })),
              tap(cc => {
                if (cc.length > 0) {
                  this.store.dispatch(new SaveCreditCardAction(cc)).subscribe();
                }
              }),
              shareReplay(1),
            );
        }), first(),
);
    }
    return of(null);
  }

  default(data): Observable<any> {
    const customer = this.lsm.get('customer');
    const creditCard: Default = {
      idCard: data,
      id: customer.id,
    };
    return this.creditCardService
      .default(creditCard)
      .pipe(tap(() => this.store.dispatch(new SetDefaultCreditCardAction(data)).subscribe()));
  }

  delete(data): Observable<any> {
    const customer = this.lsm.get('customer');
    const validationData = {
      idCustomerWebSafe: customer.idCustomerWebSafe,
    };
    const creditCard: Delete = {
      id: customer.id,
      idCard: data,
    };
    return this.creditCardService.validateOrders(validationData).pipe(
      switchMap(res => {
        if (!res.confirmation) {
          return this.creditCardService.validateSubscription(creditCard).pipe(
            map(resValidate => {
              if (resValidate.confirmation) {
                // return this.creditCardService.delete(creditCard)
                // .pipe(tap(() =>
                return this.store.dispatch(new DeleteCreditCardAction(data)).subscribe();
                // ));
              }
              return {message: resValidate.message};
            }),
          );
        }
        return throwError({
          message: 'Su tarjeta de credito no pudo ser eliminada, por que tiene ordenes en proceso.',
        });
      }),
    );
  }

  sortByDefaultFirst(creditCards) {
    return [...creditCards].sort((a, b) => {
      if (a.defaultCard) {
        return -1;
      }
      if (b.defaultCard) {
        return 1;
      }
      return 0;
    });
  }
}
