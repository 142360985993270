import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {DepartmentService} from '../../networking/services/department/department.service';
import {Product} from '../../networking/models/product/responses/product.class';
import {LocationManager} from '../location/location.manager';
import {FormatUrlService} from '../../../ui/shared/utilities/format/format-url.service';
import {SessionStorageManager} from '../session-storage/session-storage.manager';

@Injectable({
  providedIn: 'root',
})
export class DepartmentManager {
  constructor(
    private departmentService: DepartmentService,
    private location: LocationManager,
    private formatUrl: FormatUrlService,
    private ssm: SessionStorageManager,
) {}

  get(): Observable<any> {
    const departments = this.ssm.get('departments');
    if (!departments) {
      return this.departmentService.get()
        .pipe(
          debounceTime(500),
          map(response => {
            response.departmentList = this.addDescriptionDeparment(response.departmentList);
            this.ssm.set('departments', response);
            return response;
          }),
      );
    }
    departments.departmentList = this.addDescriptionDeparment(departments.departmentList);
    return of(departments);
  }

  getCategoryProductsFromAlgolia(params): Observable<any> {
    params.idStoreGroup = this.location.getIdStoreGroup();
    return this.departmentService.getCategoryProductsFromAlgolia(params).pipe(map(response => {
      if (response.items) {
        response.items = response.items.map(item => new Product(item));
      }
      return response;
    }));
  }

  getBestSeller(params): Observable<any> {
    params.idStoreGroup = this.location.getIdStoreGroup();
    return this.departmentService.getBestSeller(params).pipe(map(response => response.items.map(item => new Product(item))));
  }

  getSubcategoriesAndFilters(params): Observable<any> {
    return this.departmentService.getSubcategoriesAndFilters(params);
  }

  getProductsFromFilter(data): Observable<any> {
    data.idStoreGroup = this.location.getIdStoreGroup();
    return this.departmentService.getProductsFromFilter(data).pipe(map(response => {
      if (response.items) {
        response.items = response.items.map(item => new Product(item));
      }
      return response;
    }));
  }

  addDescriptionDeparment(deparments) {
    const departmentsData = [
      {
        id: 1,
        id2: 1,
        imgLocal: './assets/svg/categoria-salud.svg',
        description: `Salud y medicamentos Consigue todo en
         medicamentos, medicinas para el alivio del dolor, tratamiento de la gripa, vitaminas, antinconceptivos y mucho más.`,
      },
      {
        id: 72,
        id2: 2,
        imgLocal: './assets/svg/categoria-belleza.svg',
        description: `Consigue todo en productos belleza, labiales, pestañinas,
         máscaras, polvos, bases, esmaltes, cremas faciales, contornos, estuches y kits.`,
      },
      {
        id: 178,
        id2: 3,
        imgLocal: './assets/svg/categoria-personal.svg',
        description: `Consigue todo en productos para cuidado personal, shampoos,
         jabones, afeitado, desodorantes, dermocosmética, solares, cuidado facial y bienestar sexual.`,
      },
      {
        id: 73,
        id2: 4,
        imgLocal: './assets/svg/categoria-hogar.svg',
        description: ' Consigue todo en productos para el cuidado y aseo del hogar y todo lo que necesitas para la casa y tus mascotas.',
      },
      {
        id: 71,
        id2: 5,
        imgLocal: './assets/svg/categoria-alimentos.svg',
        description: 'Consigue todo en snacks, alimentos saludables, alimentos del hogar, panes, quesos, bedidas y mucho más.',
      },
      {
        id: 154,
        id2: 6,
        imgLocal: './assets/svg/categoria-cuidad_bebe.svg',
        description: `Consigue todo en productos para tu bebé; fórmulas infantiles,
        pañales, pañitos húmedos, accesorios de bebé, shampoos, jabones, cremas, lociones y más.`,
      },
    ];

    departmentsData.forEach((item: any) => {
      const aux = deparments.findIndex(i => Number(i.id) === item.id || Number(i.id) === item.id2);

      if (aux !== -1) {
        deparments[aux].link = this.formatUrl.url(deparments[aux].name);
        deparments[aux] = {...item, ...deparments[aux]};
      }
    });

    return deparments;
  }
}
