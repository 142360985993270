export const brazeConfig = {
  production: {
    apiKey: '3750db13-95b5-4f40-80b9-3964e7e91e3b',
    baseUrl: 'sdk.iad-06.braze.com'
  },
  testing: {
    apiKey: '9887dd14-9e82-47b9-9188-d4c84af07563',
    baseUrl: 'sdk.iad-06.braze.com'
  }
};
