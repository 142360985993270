import {Injectable} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigurationManager} from '../../data/manager/configuration/configuration.manager';
import {LocationManager} from '../../data/manager/location/location.manager';
import {CustomerManager} from '../../data/manager/customer/customer/customer.manager';
import {LocalStorageManager} from '../../data/manager/local-storage/local-storage.manager';
import {AlgoliaManager} from '../../data/manager/algolia/algolia.manager';
import {SessionStorageManager} from '../../data/manager/session-storage/session-storage.manager';
import { FavoritesManager } from 'src/app/data/manager/favorites/favorites.manager';
import { isDevMode } from '@angular/core';

// import { MessagingManager } from '../../data/manager/messaging/messaging.manager';

@Injectable({
  providedIn: 'root',
})
export class RootVm {
  constructor(
    private configManager: ConfigurationManager,
    private locationManager: LocationManager,
    private customerManager: CustomerManager,
    private lsm: LocalStorageManager,
    private ssm: SessionStorageManager,
    public algoliaManager: AlgoliaManager,
    private favorites: FavoritesManager
    // private messagingManager: MessagingManager
  ) { }

  validateServices(): Observable<any> {
    return this.configManager.validateServices();
  }

  getOriginProperties() {
    this.configManager.getOriginProperties().subscribe();
  }

  getGeneralConfig(): any {
    const generalConfig = this.ssm.get('multiCountry');
    return (generalConfig && generalConfig.general) ? generalConfig.general : {};
  }

  getSupportContact() {
    return this.locationManager.getSupportContact();
  }

  createAnonymous(): Observable<any> {
    if (this.lsm.has('customer')) {
      return of(null);
    }
    return this.customerManager.create(null, true);
  }

  hasCustomer(): Observable<any> {
    return this.customerManager.hasCustomer();
  }

  getLifeMilesConfig(): Observable<any> {
    return from(
      this.algoliaManager.search('', '', '', 'featureEnabled=0', false, 'properties'),
    )
      .pipe(map(res => {
        if (res && res.hits && res.hits.length > 0) {
          this.ssm.set('lifeMilesEnabled', res.hits[0].featureEnabled);
          return res.hits[0].featureEnabled;
        }
        return res;
      }));
  }

  getConfigurationMultiCountry(): any {
    if (this.ssm.has('multiCountry')) { return this.configManager.setEventCountryConfig(); }
    return this.configManager.getConfigurationMultiCountry();
  }

  getAvailability() {
    return this.locationManager.getActiveCities();
  }

  getSaGStatus() {
    return this.ssm.get('sagActive');
  }

  removeSaGStatus() {
    this.ssm.removeItem('sagActive');
  }

  setSaGStatus() {
    this.ssm.set('sagActive', true);
  }

  getCustomer() {
    return this.customerManager.customer;
  }

  // firebaseMessageListener() {
  //   this.messagingManager.firebaseMessageListener();
  // }

  // requestPermission(customer?): Observable<any> {
  //   return this.messagingManager.requestPermission(customer);
  // }

  getFavorites(): Observable<any> {
    return this.favorites.get();
  }

  // disable console for Venezuela
  disableDevOutputs() {
    if( !isDevMode() || !this.lsm.get('verbosemode') ) {
      console.error = () => {};
      console.warn = () => {};
      console.info = () => {};
      console.log = () => {};
      console.table = () => {};
    }
  }

  createPassword( data ) {
    return this.customerManager.createPassword(data);
  }
}

