export const brazeConfigVzla = {
  production: {
    apiKey: '92d644f7-4daa-4620-afb4-56920af1f032',
    baseUrl: 'sdk.iad-06.braze.com'
  },
  testing: {
    apiKey: '9887dd14-9e82-47b9-9188-d4c84af07563',
    baseUrl: 'sdk.iad-06.braze.com'
  }
};
