import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {RequestService} from '../../../../handlers/request/request.service';
import {paymentMethod} from '../../../api/order/payment-method.api';
import {GetPaymentMethods} from '../../../models/order/requests/payment-method';
import {IdCustomerWebSafe} from '../../../models/shared/requests/id-customer-web-safe';
import {PaymentMethodsRes} from '../../../models/order/responses/payment-methods-res';
import {globalEnv} from '../../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  constructor(private req: RequestService) { }

  get(data: GetPaymentMethods): Observable<PaymentMethodsRes> {
    return this.req.post(`${globalEnv.be2}${paymentMethod.get}`, data, null, null, null);
  }

  validate(params: IdCustomerWebSafe): Observable<any> {
    return this.req.get(`${globalEnv.be2}${paymentMethod.validate}`, params, null, true);
  }
}
