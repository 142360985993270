import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollEvent {
  private scroll = new BehaviorSubject(0);
  scrollWindow = this.scroll.asObservable();

  changeScrollWindow(event: number) {
    this.scroll.next(event);
  }
}
