import {
  AfterViewInit, Component, OnInit, NgModule,
} from '@angular/core';
import {Router, NavigationEnd, RouterModule} from '@angular/router';
import {DepartmentVm} from 'src/app/view-model/department/department.vm';
import {countryConfig} from 'src/country-config/country-config';
import {CommonModule} from '@angular/common';
import {CustomerProfileMenuEvent} from '../../event-listeners/customer-profile-menu/customer-profile-menu.event';
import {SessionEvent} from '../../event-listeners/session/session.event';
import {LazyLoadObserverService} from '../../services/lazy-load-observer/lazy-load-observer.service';
import {BrowserDetectorService} from '../../../../data/networking/services/shared/broswer-detector/browser-detector.service';
import {PageEvent} from '../../event-listeners/event-page/event-page.event';
import {CountryConfigEvent} from '../../event-listeners/country-config-event/country-config-event';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit {
  show = false;
  lazyLoad = true;
  browser;
  version;
  showDownApp = true;
  currentYear = new Date().getFullYear();
  categories = [
      {
        'id': '1',
        'name': 'Salud y medicamentos',
        'link': 'salud-y-medicamentos'
      },
      {
        'id': '72',
        'name': 'Belleza',
        'link': 'belleza'
      },
      {
        'id': '154',
        'name': 'Cuidado del bebé',
        'link': (countryConfig.isColombia) ? 'cuidado-del-bebe' : 'bebe'
      },
      {
        'id': '178',
        'id2': 3,
        'name': 'Cuidado personal',
        'link': 'cuidado-personal'
      },
      {
        'id': '71',
        'name': 'Alimentos y bebidas',
        'link': 'alimentos-y-bebidas'
      },
      {
        'id': '73',
        'name': 'Hogar, mascotas y otros.',
        'link': 'hogar-mascotas-y-otros'
      }
  ];
  paymentMethods = [
    {id: 1, name: 'Visa', img: 'visa'},
    {id: 2, name: 'Master Card', img: 'mc'},
    {id: 3, name: 'American express', img: 'american-express'},
    {id: 4, name: 'Bancolombia', img: 'bancolombia'},
    {id: 5, name: 'Efecty', img: 'efecty'},
    {id: 6, name: 'Diners Club', img: 'diners-club'},
    {id: 7, name: 'Baloto', img: 'baloto'},
    {id: 8, name: 'Grupo Aval', img: 'grupoaval'},
    {id: 9, name: 'Cédito Facil Codensa', img: 'codensa'},
    {id: 10, name: 'PSE', img: 'pse'},
    {id: 11, name: countryConfig.isColombia ? 'Datáfono' : 'Punto de venta', img: 'datafono'},
    {id: 12, name: 'Contra entrega', img: 'contra-entrega'},
  ];
  otherLinks = [
    {
      indexNav: 7,
      link:'/legal/politicas-de-privacidad',
      name: 'Políticas de privacidad',
      show: true
    },
    {
      indexNav: 8,
      link:'/terminos-y-condiciones',
      name: 'Términos y condiciones',
      show: countryConfig.isColombia
    },
    {
      indexNav: 9,
      link:'/legal/habeas-data',
      name: 'Política de tratamiento de datos personales',
      show: countryConfig.isColombia
    },
    {
      indexNav: 10,
      link:'',
      name: 'Contacto',
      show: true
    },
    {
      indexNav: 11,
      link:'/envialo-ya',
      name: 'Envíalo ya',
      show: countryConfig.isColombia
    },
    {
      indexNav: 12,
      link:'/catalogo',
      name: 'Folleto de ofertas',
      show: countryConfig.isColombia
    },
  ];
  configModule: any;
  countryName = countryConfig.isColombia ? 'Colombia S.A.' : 'Venezuela C.A. RIF: J-00020200-1';

  constructor(private router: Router,
    private menuEvent: CustomerProfileMenuEvent,
    private vm: DepartmentVm,
    private loginEvent: SessionEvent,
    private browserDetector: BrowserDetectorService,
    private lazyLoadObserverService: LazyLoadObserverService,
    private configCountry: CountryConfigEvent,
    private eventPage: PageEvent) {

    this.configCountry.getCountryConfig.subscribe((config: any) => {
      if (config.footer) {
        this.configModule = config.footer;
        this.confModule();
      }
    });
  }

  ngOnInit() {
    this.version = countryConfig.version;
    this.showDownApp = !countryConfig.isVenezuela && window.screen.width <= 990;
    // console.log('version:', this.version);
    this.browser = this.browserDetector.checkBrowser();
    this.lazyLoad = this.browser && !this.browser.iphone;
    this.router.events.subscribe((events: any) => {
      if (events instanceof NavigationEnd) {
        // this.show = events.url.indexOf('carrito') >= 0 || events.url.indexOf('checkout') >= 0;
        if ((events.url.indexOf('carrito') >= 0 || events.url.indexOf('checkout') >= 0) && window.screen.width <= 990) {
          this.show = true;
        }
      }
    });
  }

  menuNavigation(option) {

    if ( option === 10 ) {
      this.menuEvent.updateOption({option: 'contacts', type: 'modal', value: {open: true}});
    }

  }

  ngAfterViewInit(): void {
    setTimeout(() => this.lazyLoadObserverService.init({imgClass: 'lazy-load-footer'}), 10);
  }

  private confModule() {
    this.paymentMethods = this.configModule.paymentMethods.map(n => this.paymentMethods.filter(pm => n === pm.id)[0]);
    this.otherLinks = this.configModule.otherLinks.map(n => this.otherLinks.filter(pm => n === pm.indexNav)[0]);
  }
}
@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent],
  imports: [CommonModule,RouterModule],
})
export class FooterModule { }
