import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  // constructor() { }

  handleError(operation = 'operation', error): any {
    const result = {error: {url: '', code: 0, errors: [], message: ''}};
    let err = error && error.error ? error.error.error ? error.error.error : error.error : error;
    err = {...error, ...err};
    // console.error('Error @' + operation + ':', error); // log to console instead
    // console.error(`Error @${operation}:`, error); // log to console instead
    // console.log('Error =>', error);
    // console.log('Err =>', err);

    if (error.status > 0) {
      result.error = err;
      result.error.url = err.url;
      result.error.message = err.message;
    } else {
      result.error = {
        url: operation,
        code: err.status,
        errors: [
          {
            reasons: err.statusText,
          },
        ],
        message: err.statusText,
      };
    }

    // console.log('result', result)
    return result.error;
  }
}
