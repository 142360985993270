import {TransferState, makeStateKey, StateKey} from '@angular/platform-browser';
import {Observable, of} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: any,
) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('----------------------StateInterceptor----------------------');
    // this.transferStateService.state();
    // console.log(req.url);

    const key: StateKey<string> = makeStateKey<string>(req.url);
    // console.log({key});
    // console.log(this.platformId, 'isPlatformServer', isPlatformServer(this.platformId));
    // let json;
    if (isPlatformServer(this.platformId)) {
      // console.log('----------------------IS SERVER----------------------');
      // const cachedData = memoryCache.get(req.url);
      // console.log('SERVER CACHE', {cachedData});
      return next.handle(req).pipe(tap(event => {
        // console.log('VALUE TO SET ===>>>', event, event instanceof HttpResponse);
        if (event instanceof HttpResponse) {
          this.transferState.set(key, (event as HttpResponse<any>).body);
          // json = this.transferState.toJson();
          // console.log('SERVER STATE', this.transferState);
          /*this.ngZone.runOutsideAngular(() => {
            memoryCache.put(req.url, event.body, 1000 * 60);
            console.log('SETTING DATA', memoryCache.get(req.url));
          });*/
        }
      }));
    }
      /*console.log(req.url, req.url.includes(home.new));
      if (req.url.includes(home.new)) {
        const response = new HttpResponse({body: defaultData, status: 200});
        console.log({response});
        return of(response);
      }*/
      // memoryCache.put(req.url, 'THIS DATAAAA', 1000 * 60);
      // const cachedData = memoryCache.get(req.url);
      // console.log('******* BROWSER STATE ===>>>>', this.transferState);
      // console.log('BROWSER STATE JSON', {json});
      // console.log('----------------------IS BROWSER----------------------');
      const storedResponse = this.transferState.get<any>(key, null);
      // console.log({storedResponse, cachedData});
      if (storedResponse) {
        const response = new HttpResponse({body: storedResponse, status: 200});
        // console.log({response});
        this.transferState.remove(key);
        // console.log('----------------------1----------------------');
        return of(response);
      }
      // console.log('----------------------2----------------------');
      return next.handle(req);
  }
}
