import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RequestService} from '../../../handlers/request/request.service';
import { favorites } from '../../api/favorites/favorites.api';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  constructor(private req: RequestService) { }

  get( params ): Observable<any> {
    return this.req.get(`${globalEnv.be2}${favorites.get}`, params, null, true);
  }

  update( data ) {
    return this.req.put(`${globalEnv.be2}${favorites.put}`, data, null, null, true );

  }

}
