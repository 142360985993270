import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JsonLdSeoEvent {
  private event = new Subject();
  content = this.event.asObservable();

  updateJson(event: any) {
    this.event.next(event);
  }
}
