import {Injectable} from '@angular/core';
import {ScanAndGoManager} from 'src/app/data/manager/scan-and-go/scan-and-go.manager';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {PaymentMethodManager} from 'src/app/data/manager/order/payment-method/payment-method.manager';
import {CreditCardManager} from '../../data/manager/customer/credit-card/credit-card.manager';
import {LocalStorageManager} from '../../data/manager/local-storage/local-storage.manager';
import {CartManager} from '../../data/manager/cart/cart.manager';
import {SessionStorageManager} from '../../data/manager/session-storage/session-storage.manager';
import {AddressManager} from '../../data/manager/customer/address/address.manager';
import {CustomerManager} from '../../data/manager/customer/customer/customer.manager';
import {LocationManager} from '../../data/manager/location/location.manager';
import {OrderManager} from '../../data/manager/order/order/order.manager';
import {tap} from 'rxjs/operators';
import {UpdateScanAndGoCartAction} from '../../data/actions/scan-and-go.actions';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScanAndGoVm {

  ratingData;
  sagPaymentMessage: any = {
    lottie: {path: ''},
    mainMessage: '',
    secondMessage: '',
  };

  get customer() { return this.lsm.get('customer'); }
  get webview()  { return <String>this.lsm.get('webview'); }
  get isIOS()    { return this.webview == 'IOS'; }
  get isAndroid(){ return this.webview == 'ANDROID'; }
  get isWebview(){ return this.webview != null; }
  get isMobileSaG(){ return this.lsm.get('mobileSaG'); }

  set hasP2c(sw: boolean) { this.lsm.set('sagp2c', sw); }
  get hasP2c() { return this.lsm.get('sagp2c'); }

  qrcodeEvent: EventEmitter<String> = new EventEmitter();

  constructor(private scanAndGoManager: ScanAndGoManager,
              private creditCardManager: CreditCardManager,
              private lsm: LocalStorageManager,
              private ssm: SessionStorageManager,
              private paymentMethodManager: PaymentMethodManager,
              private cartManager: CartManager,
              private locationManager: LocationManager,
              private customerManager: CustomerManager,
              private orderManager: OrderManager,
              private addressManager: AddressManager,
              private store: Store) { }

  getScanAngGo(): Observable<any> {
    return this.scanAndGoManager.getScanAngGo();
  }

  getCart(): Observable<any> {
    return this.cartManager.get();
  }

  getCreditCards(): Observable<any> {
    return this.creditCardManager.get();
  }

  setDefaultCreditCard(id): Observable<any> {
    return this.creditCardManager.default(id);
  }

  saveSelfie(photo) {
    return this.scanAndGoManager.saveSelfie(photo);
  }

  getCustomer() {
    return this.customerManager.customer;
  }

  getPaymentMethods() {
    return this.paymentMethodManager.get();
  }

  saveScanAndGoCart(cart) {
    this.ssm.set('sagCart', cart);
  }

  addProductByBarcode(barcode) {
    return this.cartManager.addProduct(barcode)
      .pipe(tap(() => {
        this.store.dispatch(new UpdateScanAndGoCartAction(true)).subscribe();
      }));
  }

  getAddressByCoordinates(coordinates): Observable<any> {
    return this.addressManager.validate(coordinates);
  }

  getAgile() {
    return this.cartManager.getAgile(true);
  }

  getIdStoreGroup() {
    return this.locationManager.getIdStoreGroup();
  }

  getOpenPhotoModal() {
    return this.ssm.get('openPhotoModal');
  }

  setOpenPhotoModal() {
    this.ssm.set('openPhotoModal', true);
  }

  createOrder(): Observable<any> {
    return this.orderManager.create();
  }

  removeSaGStatus() {
    this.ssm.removeItem('sagActive');
  }

  updateQuantity(data): Observable<any> {
    return this.cartManager.addProduct(data)
      .pipe(tap(() => {
        this.store.dispatch(new UpdateScanAndGoCartAction(true)).subscribe();
      }));
  }

  removeProduct(product): Observable<any> {
    return this.cartManager.removeProduct(product)
      .pipe(tap(() => {
        this.store.dispatch(new UpdateScanAndGoCartAction(true)).subscribe();
      }));
  }

  rate(data): Observable<any> {
    return this.scanAndGoManager.rate(data);
  }

  getRatingData() {
    const flag = this.store?.snapshot().scanAndGoStore?.config?.flags?.filter(f => f.feature === 'RATE_EXPERIENCE').pop();
    if (flag?.active && this.store?.snapshot().scanAndGoStore?.config?.qualifyExperience) {
      this.ratingData = this.store.snapshot().scanAndGoStore.config.qualifyExperience;
    }
  }

  getSagPaymentMessage(type) {
    return this.store?.snapshot().scanAndGoStore?.config?.paymentMessages?.filter(m => m.event === type).pop();
  }

  createOrderQR() {
    this.sagPaymentMessage = {lottie: {path: ''}}; // Loading image
    this.createOrder()
      .subscribe(success => {
        this.sagPaymentMessage = this.getSagPaymentMessage('ON_SUCCESS');
        this.sagPaymentMessage = {...this.sagPaymentMessage, lottie: {path: ''}};
        this.getRatingData();
      }, error => {
        this.sagPaymentMessage = this.getSagPaymentMessage('ON_ERROR');
        this.sagPaymentMessage = {...this.sagPaymentMessage, lottie: {path: ''}};
        // console.log('ERROR', {error});
      });
  }
}
