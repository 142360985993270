export class SaveSasCartAction {
  static readonly type = '[SasCart] Save SasCart';
  constructor(public cart: any) {}
}

export class DeleteSasCartAction {
  static readonly type = '[SasCart] Delete SasCart';
  // constructor() {}
}

export class UpdateSasCartProductAction {
  static readonly type = '[SasCart] Update SasCart Product';
  constructor(public product: any, public quantity: any) {}
}
