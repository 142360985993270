import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {LocalStorageManager} from '../../../../data/manager/local-storage/local-storage.manager';

@Injectable({
  providedIn: 'root',
})
export class UpdateAddressEvent {
  deliveryAddress = 'deliveryAddress';
  callCenterCustomer = 'customerCallCenter';
  private address;
  private selectAddress = new Subject();
  private addresses = new BehaviorSubject(null);
  private openModal = new Subject();
  private openAddressModal = new BehaviorSubject({open: false, address: null});
  private getAddresses = new Subject();
  updatedAddress;
  selectedAddress = this.selectAddress.asObservable();
  updatedAddresses = this.addresses.asObservable();
  addressModalStatus = this.openModal.asObservable();
  addAddressModalStatus = this.openAddressModal.asObservable();
  getCustomerAddresses = this.getAddresses.asObservable();

  constructor(private lsm: LocalStorageManager) {
    const address = this.lsm.has(this.callCenterCustomer)
      ? this.lsm.get(this.callCenterCustomer).deliveryAddress : this.lsm.get(this.deliveryAddress);
    // console.log(address);
    this.address = new BehaviorSubject(address);
    this.updatedAddress = this.address.asObservable();
  }

  changeAddress(address: any) {
   this.address.next(address);
  }

  selectNewAddress(address: any) {
   this.selectAddress.next(address);
  }

  changeAddresses(addresses: any) {
   this.addresses.next(addresses);
  }

  changeAddressModalStatus(status: any) {
    this.openModal.next(status);
  }

  changeAddAddressModalStatus(status: any) {
    this.openAddressModal.next(status);
  }

  getCurrentCustomerAddresses(status: any) {
    this.getAddresses.next(status);
  }
}
