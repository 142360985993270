export class Banner {
  urlBanner: string;
  redirectUrl: string;
  idWebSafeBanner: '';
  order: 1;
  directionBanner: false;
  bannerWeb: true;
  campaignName: '';
  creative: '';
  position = '0';
  banners;

  constructor(banner) {
    if (banner.subscribeAndSave) {
      this.urlBanner = '';
      this.banners = {mobile: banner.url, desktop: banner.urlWeb};
      this.redirectUrl = banner.redirectTo;
      this.order = banner.id;
      this.position = banner.id.toString();
      this.campaignName = banner.name;
      this.creative = banner.description;

      delete banner.url;
      delete banner.urlWeb;
      delete banner.redirectTo;
      delete banner.id;
      delete banner.name;
      delete banner.description;
      delete banner.allowClick;
      delete banner.subscribeAndSave;
    }

    Object.assign(this, banner);
  }
}
