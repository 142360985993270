<header class="header-cache">
  <div class="grid-container-header">
    <div class="logo">
      <a [routerLink]="['/']"><img class="img" src="/assets/svg/logo-farmatodo2.svg" alt="Logo Farmatodo"></a>
      <img class="icon" src="/assets/svg/icon-user-white.svg" alt="user">
    </div>
    <div class="search">
      <div class="cont-input">
        <p class="select-deparment">Todos <span class="icon">▼</span></p>
        <input class="input-search" type="text" placeholder="¿Qué estás buscando?">
      </div>
      <a [routerLink]="['/']"><img class="logo" src="/assets/svg/logo-farmatodo2.svg" alt="Logo Farmatodo"></a>
    </div>
    <div class="user">
      <p>Inicia sesión</p>
    </div>
    <div class="country">
      <p>Colombia <span class="icon">▼</span></p>
    </div>
    <div class="adress">
      <p>Selecciona tu ciudad <span class="icon">▼</span></p>
    </div>
    <div class="car">
      <img class="img" src="/assets/svg/carrito.svg" alt="carrito">
    </div>
  </div>
  <div class="departments-wrapper">
    <div class="row shadow bg-white categories">
      <div class="col-9 cont-deparments">
        <div class="row cont-list-deparments">
          <div id="department-menu-toggle" *ngFor="let department of arrayDepartments" class="col-md-2 department">
            <a class="link" [id]="'department-menu-department-' + department?.id" routerLink="/categorias/{{ department?.nameUrl }}" [ngClass]="department?.classColor">
              <span class="text-title-deparment">{{ department?.name }}</span>
            </a>
            <div class="content-category" [ngStyle]="{'border-top-color': department?.primaryColor}">
              <div *ngIf="!activeDepartment[department?.name]" [class]="'sub-category ' + department?.link">
                <div class="category-container" *ngFor="let categoryItem of department?.children">
                  <h3 class="titlte-category">
                    <a [ngStyle]="{color: department?.primaryColor}" [id]="'department-menu-category-' + categoryItem.id" routerLink="/categorias/{{ department?.nameUrl }}/{{ categoryItem.nameUrl }}">
                      {{ categoryItem.name }}
                    </a>
                  </h3>
                  <p class="cont-sub-category" *ngFor="let subCategory of categoryItem.children">
                    <a class="text-sub-category" [id]="'department-menu-sub-category-' + subCategory.id" routerLink="/categorias/{{ department?.nameUrl }}/{{ categoryItem.nameUrl }}/{{
                        subCategory.nameUrl
                      }}">
                      {{ subCategory.name }}
                    </a>
                  </p>
                </div>
              </div>
              <div *ngIf="activeDepartment[department?.name]" class="sub-category-img">
                <div class="category-container" *ngFor="let categoryItem of department?.children">
                  <h3 class="titlte-category">
                    <a [ngStyle]="{color: department?.primaryColor}" [id]="'department-menu-category-' + categoryItem.id" routerLink="/categorias/{{ department?.nameUrl }}/{{ categoryItem.nameUrl }}">
                      {{ categoryItem.name }}
                    </a>
                  </h3>
                  <p class="cont-sub-category" *ngFor="let subCategory of categoryItem.children">
                    <a class="text-sub-category" [id]="'department-menu-sub-category-' + subCategory.id" routerLink="/categorias/{{ department?.nameUrl }}/{{ categoryItem.nameUrl }}/{{
                        subCategory.nameUrl
                      }}">
                      {{ subCategory.name }}
                    </a>
                  </p>
                </div>
              </div>
              <div class="cont-load" *ngIf="!department?.children">
                <img class="img" src="assets/svg/icon-spinner.svg" alt="icono de cargando - Farmatodo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row other-category">
          <div class="col-6 pr-0 d-flex align-items-center justify-content-center">
            <a id="department-menu-open-modal">
              Ingresar cupón
            </a>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center" *ngIf="countryConfig.isColombia">
            <a id="department-menu-program-and-save-redirect" routerLink="/programate-y-ahorra/galeria">
              <p class="tetx-pya">
                <span class="text-one">PROGRÁMATE</span><span class="text-two"> Y </span
                ><span class="text-three">AHORRA</span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
