import { countryConfig } from "src/country-config/country-config";

const colDef = {
  homeSections: [
    {
      componentType: 'BANNER',
      list: [
        {
          urlBanner: 'https://lh3.googleusercontent.com/NtIpBl318WkKbL-Ag7jqUX-U4eWuUDQAQa'
            + 'XernRyzDtk4Vt1GiaThav3XXkGGY1w1RFD7NjR9H5fCo98yF1HRQvyuPM8snf7gBn0xFs7AFLWO0Q',
          redirectURL: 'https://www.farmatodo.com.co/mundoOfertas',
          id: '1',
          firstDescription: 'default2',
          orderingNumber: 2,
        },
        {
          urlBanner: 'https://lh3.googleusercontent.com/d4G22McK-iUo7tAO8IAFW7vT8POC41LszGlh'
            + 'zimNPY4sDMBq3tgOnEdveAvmap60kiCDM7Ac2WuQq5o3gkebtbQYp3jaaaX9Mls',
          redirectURL: 'https://www.farmatodo.com.co/mundoOfertas',
          id: '1',
          firstDescription: 'default',
          orderingNumber: 1,
        },
      ],
    },
    {
      componentType: 'HTML_LABEL',
      label: '<font color=\'#418fde\'>Hola </font> Estas son las mejores ofertas',
    },
    {
      componentType: 'ITEM_LIST',
    },
    {
      componentType: 'THIN_BANNER',
      redirectURL: 'https://www.farmatodo.com.co/destacados/21643',
      urlBanner: 'https://lh3.googleusercontent.com/EBR0D-3Zc7_GxHOb1QzA0oIB3Vb9PHfJIJu9'
        + 'Jyzto2TN82tqji2HDD4x1JuQfxPYIOkcT--saOzFTpjBlbpS1xwj_NOCjzfZwXc',
    },
    {
      componentType: 'SQUARE_BANNER',
      list: [
        {
          urlBanner: 'https://lh3.googleusercontent.com/ZwZE35dfEruJwI3LpoZekeHNc_-Oq57y4dazayQHzve'
            + 'HSE2mCbsK23DXPa463YFXUA4Za_h0exQoAcUkteSzu_Pw_yXvz9Z9GiM',
          redirectURL: 'https://www.farmatodo.com.co/programate-y-ahorra/galeria',
        },
        {
          urlBanner: 'https://lh3.googleusercontent.com/INtMGoW9LvCG1Ss7y7hbJJaZ'
            + 'b4BndpA-q5gE5LEX35uAD7qoTkDLcSLEWRDkfxCwhyOFOJ8eLqjN7zb6pkv-PVSy3HeRJYsb1g',
          redirectURL: 'https://www.farmatodo.com.co/buscar?producto=ensure',
        },
      ],
    },
    {
      componentType: 'THIN_BANNER',
      redirectURL: 'https://www.farmatodo.com.co/programate-y-ahorra/galeria',
      urlBanner: 'https://lh3.googleusercontent.com/bCeCSgA_xsWGXlVrman2Oy_JnDTB5RrvB6ny'
        + 'iVR1C0f83w78UL_-SlJtqZjiMMhJypaxz5_qNDw8GtxMq78axVXXnQpRgnD0LwA',
    },
    {
      componentType: 'HTML_LABEL',
      label: 'Destacados',
    },
  ],
};

const venDef = {
  homeSections: [
    {
      componentType: 'BANNER',
      list: [
        {
          urlBanner: 'https://lh3.googleusercontent.com/j3G10uXJkvuUP6lccOU6abnCR7_'
            + '-XTJsKYjIC3Mr3j3foYuuWKDdtxd7t4LDnhrru61l9DH9V5Z_Wy62FDgkl_rhGCTwy0D3zxp6KVYrhoEchHU',
          redirectURL: 'https://www.farmatodo.com.co/mundoOfertas',
          id: '1',
          firstDescription: 'default2',
          orderingNumber: 2,
        },
        {
          urlBanner: 'https://lh3.googleusercontent.com/d4G22McK-iUo7tAO8IAFW7vT8POC41LszGl'
            + 'hzimNPY4sDMBq3tgOnEdveAvmap60kiCDM7Ac2WuQq5o3gkebtbQYp3jaaaX9Mls',
          redirectURL: 'https://www.farmatodo.com.co/mundoOfertas',
          id: '1',
          firstDescription: 'default',
          orderingNumber: 1,
        },
        {
          urlBanner: 'https://lh3.googleusercontent.com/LJiyHmzDz00BBbTGCW_aeB2QI7pp3LGGoVbjIor'
            + 'NBV9JLe159kG_w0rz2r0jXj7pMXauQ9R8ngZiqNauK_IolUuL0qtVwgbHFo1YxRUiTOxUXTK2NA',
          redirectURL: 'https://www.farmatodo.com.co/mundoOfertas',
          id: '1',
          firstDescription: 'default2',
          orderingNumber: 2,
          mobile: true,
        },
        {
          urlBanner: 'https://lh3.googleusercontent.com/6nxQJ7wCGyCQ9K51eWFmfpJlemzO8RNuBEpB87z0FYy'
            + 'cTdBD7G-YcJ9VmThYiPkC24KPaqpTq7_XE9toTZDwtlqmW9RzY7W0lOjCuk0s3G-OBoR_Iw',
          redirectURL: 'https://www.farmatodo.com.co/mundoOfertas',
          id: '1',
          firstDescription: 'default',
          orderingNumber: 1,
          mobile: true,
        },
      ]
    },
    {
      componentType: 'HTML_LABEL',
      label: '<font color=\'#418fde\'>Hola </font> Estas son las mejores ofertas',
    },
    {
      componentType: 'ITEM_LIST',
    },
    {
      componentType: 'THIN_BANNER',
      redirectURL: 'https://www.farmatodo.com.co/destacados/21643',
      urlBanner: 'https://lh3.googleusercontent.com/EBR0D-3Zc7_GxHOb1QzA0oIB3Vb9PHfJIJu9'
        + 'Jyzto2TN82tqji2HDD4x1JuQfxPYIOkcT--saOzFTpjBlbpS1xwj_NOCjzfZwXc',
    },
    {
      componentType: 'SQUARE_BANNER',
      list: [
        {
          urlBanner: 'https://lh3.googleusercontent.com/ZwZE35dfEruJwI3LpoZekeHNc_-Oq57y4dazayQHzve'
            + 'HSE2mCbsK23DXPa463YFXUA4Za_h0exQoAcUkteSzu_Pw_yXvz9Z9GiM',
          redirectURL: 'https://www.farmatodo.com.co/programate-y-ahorra/galeria',
        },
        {
          urlBanner: 'https://lh3.googleusercontent.com/INtMGoW9LvCG1Ss7y7hbJJaZ'
            + 'b4BndpA-q5gE5LEX35uAD7qoTkDLcSLEWRDkfxCwhyOFOJ8eLqjN7zb6pkv-PVSy3HeRJYsb1g',
          redirectURL: 'https://www.farmatodo.com.co/buscar?producto=ensure',
        },
      ],
    },
    {
      componentType: 'THIN_BANNER',
      redirectURL: 'https://www.farmatodo.com.co/programate-y-ahorra/galeria',
      urlBanner: 'https://lh3.googleusercontent.com/bCeCSgA_xsWGXlVrman2Oy_JnDTB5RrvB6ny'
        + 'iVR1C0f83w78UL_-SlJtqZjiMMhJypaxz5_qNDw8GtxMq78axVXXnQpRgnD0LwA',
    },
    {
      componentType: 'HTML_LABEL',
      label: 'Destacados',
    },
  ],
};

export const defaultData = countryConfig.isColombia ? colDef : venDef;