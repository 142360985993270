import {algoliaEnv} from './colombia/algolia.env';
import {apiKey} from './api-keys';
import {algoliaConfig} from './algolia-config';
import {algoliaVzlaEnv} from './venezuela/algolia.env';
import {ApiExternalEnum} from '../base-urls/api-external.enum';
import {BackendGatewayColEnum} from '../base-urls/backend-gateway-col.enum';
import {BackendGatewayVenEnum} from '../base-urls/backend-gateway-ven.enum';

export const environment = {
  ssr: true,
  hmr: false,
  production: true,
  callCenter: false,
  multiCountry: true,
  algoliaColombia: algoliaEnv.envs.prod,
  algoliaVenezuela: algoliaVzlaEnv.envs.prod,
  algoliaConfig,
  deploymentVersion: {developmentMode: false},
  apiKey,
  date: new Date(),
  backendColombia: {
    be2: `${BackendGatewayColEnum.MULTICOUNTRY}_ah/api/`,
    sas: BackendGatewayColEnum.MULTICOUNTRY,
    gateway: BackendGatewayColEnum.MULTICOUNTRY,
    dataFoundation: BackendGatewayColEnum.MULTICOUNTRY,
    cms: BackendGatewayColEnum.MULTICOUNTRY,
    cloudFunction: BackendGatewayColEnum.CLOUD_FUNCTION,
    bzv: ApiExternalEnum.BZV,
    qa1: BackendGatewayColEnum.QA_ENV_1,
    crm: BackendGatewayColEnum.CRM,
    mp: BackendGatewayColEnum.MP,
    apiMp: BackendGatewayColEnum.API_MP,
    alg: BackendGatewayColEnum.ALGOLIA,
  },
  backendVenezuela: {
    be2: `${BackendGatewayVenEnum.MULTICOUNTRY}_ah/api/`,
    sas: BackendGatewayVenEnum.MULTICOUNTRY,
    gateway: BackendGatewayVenEnum.MULTICOUNTRY,
    dataFoundation: BackendGatewayVenEnum.MULTICOUNTRY,
    cms: BackendGatewayVenEnum.MULTICOUNTRY,
    cloudFunction: BackendGatewayVenEnum.CLOUD_FUNCTION,
    bzv: ApiExternalEnum.BZV,
  },
};
