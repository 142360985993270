import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionEvent {
  private loginStatus = new BehaviorSubject(false);
  currentLoginStatus = this.loginStatus.asObservable();

  private hasCustomerStatus = new BehaviorSubject({status: false, customer: null, isAnonymous: false});
  hasCustomer = this.hasCustomerStatus.asObservable();

  private closeSession = new BehaviorSubject(false);
  closeSessionUser = this.closeSession.asObservable();

  private customerNotFound = new Subject();
  onCustomerNotFound = this.closeSession.asObservable();

  private callCenterLogin = new BehaviorSubject(false);
  callCenterLoginStatus = this.callCenterLogin.asObservable();

  changeLoginStatus(status: any) {
    this.loginStatus.next(status);
  }

  updateHasCustomer(status) {
    this.hasCustomerStatus.next(status);
  }

  changeCloseSessionUser(status) {
    this.closeSession.next(status);
  }

  updateCustomerNoFoundData(status) {
    this.customerNotFound.next(status);
  }

  updateCallCenterLoginStatus(status: any) {
    this.callCenterLogin.next(status);
  }
}
