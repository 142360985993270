import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {order} from '../../api/order/order.api';
import {RequestService} from '../../../handlers/request/request.service';
import {Create, GetAll, GetSingle, Rate, ReOrder} from '../../models/order/requests/order';
import {IdCustomerWebSafe} from '../../models/shared/requests/id-customer-web-safe';
import {globalEnv} from '../../connection/global/global.env';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private req: RequestService) { }

  create(data: Create, params: IdCustomerWebSafe): Observable<any> {
    return this.req.post(`${globalEnv.be2}${order.create}`, data, params, true);
  }

  getAll(data: GetAll): Observable<any> {
    return this.req.post(`${globalEnv.be2}${order.getOrders}`, data);
  }

  getSingle(params: GetSingle): Observable<any> {
    return this.req.get(`${globalEnv.be2}${order.getOrder}`, params);
  }

  getTracking(params: GetSingle): Observable<any> {
    return this.req.get(`${globalEnv.be2}${order.getTracking}`, params);
  }

  getRatings(data): Observable<any> {
    return this.req.get(`${globalEnv.be2}${order.getRatings}`, data);
  }

  rate(data: Rate): Observable<any> {
    return this.req.put(`${globalEnv.be2}${order.rate}`, data, null, null, true);
  }

  reOrder(data: ReOrder, params?): Observable<any> {
    return this.req.post(`${globalEnv.be2}${order.reOrder}`, data, params, true);
  }
}
