import { EventEmitter, Injectable } from '@angular/core';

import {P2cManager} from '../../data/manager-v3/p2c/p2c.manager';
import {CustomerBank} from '../../data/networking/models-v3/p2c/p2c';
import {CustomerManager} from '../../data/manager/customer/customer/customer.manager';
import {LocalStorageManager} from 'src/app/data/manager/local-storage/local-storage.manager';

// import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import { ScanAndGoService } from 'src/app/data/networking/services/scan-and-go/scan-and-go.service';
import { ScanAndGoVm } from '../scan-and-go/scan-and-go.vm';
import { Router } from '@angular/router';

declare var JSBridge:any;
declare var window:any;

@Injectable({
  providedIn: 'root'
})
export class P2cVm {

  customer: any;
  orderId: any = null;
  updateState: EventEmitter<any> = new EventEmitter();
  infoModal: EventEmitter<any> = new EventEmitter();
  setCurrentPayment: EventEmitter<any> = new EventEmitter();
  msgReference = new EventEmitter<any>();
  loadingQrcode = new EventEmitter<any>();
  paymentAccepted = new EventEmitter<any>();
  qrcodeEvent = new EventEmitter<any>();
  activeOrders: any[] = [];
  documentTypes: any[] = [
    { description: 'C.I.', id: 'V'},
    { description: 'C.E.', id: 'E'},
    { description: 'RIF-G', id: 'G'},
  ];
  steps: any = [
    {
      img: '/assets/svg/icon-p2c-step1.svg',
      title: 'En el checkout completa los datos',
      subtitle: 'Realiza el pedido'
    },
    {
      img: '/assets/svg/icon-p2c-step2.svg',
      title: 'Tu pedido se va a facturar en la tienda',
      subtitle: 'Al recolectar todos los productos'
    },
    {
      img: '/assets/svg/icon-p2c-step3.svg',
      title: 'Enviaremos un mensaje con el precio final',
      subtitle: 'Para hacer el pago móvil'
    },
    {
      img: '/assets/svg/icon-p2c-step4.svg',
      title: 'Ingresa a tu banco y completa la información',
      subtitle: 'Al finalizar te darán un número de referencia'
    },
    {
      img: '/assets/svg/icon-p2c-step5.svg',
      title: 'Ingresa el número de referencia',
      subtitle: 'En el campo de la parte superior de la página'
    },
  ];

  constructor(
    private manager: P2cManager,
    private cmng: CustomerManager,
    private sagvm: ScanAndGoVm,
    private router: Router,
    private lsm: LocalStorageManager,
  ) {

    this.qrcodeEvent.subscribe((qrcode: String) => {
      const idStore = qrcode.split('/').pop();
      // this.store.dispatch(new UpdateScanAndGoStoreIdAction(storeId)).subscribe();
      this.loadingQrcode.emit(true);
      this.confirmPaymentOrder(this.orderId, idStore ).subscribe(() => {
        this.loadingQrcode.emit(false);
        if(!this.lsm.get('dataphone-order')){
          this.orderId = null;
          this.getRemoteData();
          this.msgReference.emit({open: true, qualification: true});
        } else {
          this.router.navigate(['/escanea-y-listo/datafono']);
        }
      });
    });

    this.customer = this.cmng.customer;
    this.updateState.subscribe(() => {});
    this.getRemoteData();


  }

  openInfoModal(sw: boolean = true) {
    this.infoModal.emit(sw);
  }

  getUser() { return this.lsm.get('customer'); }

  getDocuemntType(id: string) {
    let dtype: any = this.documentTypes.filter((tp:any) => tp.id == id );
    return dtype ? dtype.description : 'RIF';
  }

  getRemoteData(data: any = null) {
    this.verifyOrderActives().subscribe(
      (resp: any[]) => {
        this.updateState.emit(resp);
        if (data) {
        // } else {
          if(data.openOrder) {
            const payment = resp.find((ob) => ob.orderId == parseInt(data.openOrder));
            if(payment != null) { this.setCurrentPayment.emit({...payment, qrcode: false}); }
          }
        }
      });
  }

  createCustomerBank(data: any): Observable<CustomerBank> {
    return this.manager.createCustomerBank({...data, customerId: this.customer.id});
  }

  updateCustomerBank(data: any): Observable<CustomerBank> {
    return this.manager.updateCustomerBank({...data, customerId: this.customer.id});
  }

  getCustomerBanks(idCustomer: string): Observable<CustomerBank[]> {
    return this.manager.getCustomerBanks(idCustomer);
  }

  getMyBanks(): Observable<CustomerBank[]> {
    return this.manager.getCustomerBanks(this.customer.id);
  }

  getBanksList(): Observable<any> {
    return this.manager.getBanksList();
  }

  sendPayment(order: string, payment: string, reference: string, amount: string ): Observable<any> {
    return this.manager.sendPayment(order, payment, reference, amount);
  }

  verifyOrderActives(): Observable<any> {
    if(!this.customer){
      return of({"code":"OK","message":"Success","data":[]});
    } else {
      return this.manager.verifyOrderActives(this.customer.id);
    }
  }

  remCustomerBank(id: string ): Observable<any> {
    return this.manager.remCustomerBank(id);
  }


  confirmPaymentOrder(idOrder: string, idStore: string)  {
    return this.manager.confirmPaymentOrder({idOrder, idStore});
  }

  scanAndGoQR(orderId: string) {
    this.orderId = orderId;
    try {
      if (this.sagvm.isAndroid) {
        // console.log(`ejecuntando captura QR Nativo => JSBridge.onNavigateToSagQR();`)
        JSBridge.onNavigateToSagQR();
      }
      else if (this.sagvm.isIOS) {
        // console.log(`ejecuntando captura QR Nativo => postMessage('onNavigateToSagQR');`)
        window.webkit.messageHandlers.jsMessageHandler.postMessage('onNavigateToSagQR');
      }
    }
    catch {
      // console.log(`Falla al llamar el metodo JSBridge o postMessage`);
    }
  }

}
