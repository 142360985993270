import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'angular-bootstrap-md';
import {debounceTime} from 'rxjs/operators';
import {screenSize} from '../../size-detector/ScreenSize';
import {BrowserDetectorService} from '../../../../../data/networking/services/shared/broswer-detector/browser-detector.service';
import {ResizeEvent} from '../../../event-listeners/resize/resize.event';

@Component({
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicModalComponent implements OnChanges, OnInit {
  screenSize = screenSize;
  @Input() status = {open: false, close: false};
  @Input() modalSize = '';
  @Input() eventClick;
  @Input() title = '';
  @Input() titleClass = '';
  @Input() iconSrc = '';
  @Input() iconClass = '';
  @Input() contentClass = '';
  @Input() contentIconClass = '';
  @Input() headerClass = '';
  @Input() bodyClass = '';
  @Input() dialogClass = '';
  @Input() backDrop: any = true;
  @Input() mobileFullScreen = true;
  @Input() persistXToClose = false;
  @Input() showClose = true;
  @Input() options = {
    showFooter: false,
    closeButton: true,
    closeButtonText: 'Cerrar',
    customClass: 'transparent',
  };
  overlay = false;
  basicModalStatus = false;
  @ViewChild('basicModal') basicModal: ModalDirective;
  browser;
  constructor(private resizeEvent: ResizeEvent,
              private browserDetector: BrowserDetectorService) {
    this.browser = browserDetector.checkBrowser();
  }

  openModal() {
    this.overlay = true;
    this.basicModalStatus = true;
  }

  hideModal() {
    this.overlay = false;
    this.basicModalStatus = false;
  }

  onResize() {
    this.resizeEvent.currentScreenSize
      .pipe(debounceTime(1000))
      .subscribe(size => { this.screenSize = size; });
  }

  ngOnInit() {
    this.onResize();
  }

  ngOnChanges(): void {
    // console.log('changes:', changes);
    if (this.status && this.status.open) {
      this.openModal();
    } else if (this.status && this.status.close) {
      this.hideModal();
    }
  }
}
