import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormatUrlService {
  url(text) {
    if (text) {
      let textR = text;
      const from = 'ãàáäâèéëêìíïîòóöôùúüûñç';
      const to = 'aaaaaeeeeiiiioooouuuunc';
      let textOk = '';

      textR = textR.split('.').join('').split(',').join('').split(' ').join('-').toLowerCase();

      textR.split('').map(textSub => {
        const find = from.search(textSub);
        textOk += (find !== -1) ? to.substr(find, 1) : textSub;
        return textOk;
      });
      return textOk;
    }
  }

  deparments(array) {
    array.forEach(department => {
      department.nameUrl = this.url(department.name);

      if (department.children && department.children.length > 0) {
        department.children.forEach(categorie => {
          categorie.nameUrl = this.url(categorie.name);
          if (categorie.children && categorie.children.length > 0) {
            categorie.children.forEach(subCategorie => {
              subCategorie.nameUrl = this.url(subCategorie.name);
            });
          }
        });
      }
    });

    return array;
  }
}
