import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalModule, WavesModule, ButtonsModule} from 'angular-bootstrap-md';
import {BasicModalComponent} from './basic-modal.component';
import {ButtonModule} from '../../button/button.module';
import {SizeDetectorModule} from '../../size-detector/size-detector.module';
import {IconsModule} from '../../icons/icons.module';

@NgModule({
  declarations: [BasicModalComponent],
  imports: [
    CommonModule,
    ModalModule,
    ButtonsModule,
    WavesModule,
    ButtonModule,
    SizeDetectorModule,
    IconsModule,
  ],
  exports: [BasicModalComponent],
})
export class BasicModalModule { }
