export const metaTags = [
  {
    titleDepartment: 'salud-y-medicamentos',
    nameSimple: 'salud y medicamentos',
    metaTitle: 'Salud y Medicamentos',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
      + 'Ingresa ahora, compra todos los medicamentos y productos para el cuidado de tu '
      + 'salud y recibe a Domicilio: rápido, fácil y seguro.',
    description:
      'Compra medicamentos online en farmatodo.com. Ingresa ahora y encuentra todos los tipos de '
      + 'medicamentos que buscas: dolor muscular, dolor general, dolor fuerte, dermatológicos, gripa y mucho más.',
    categories: [
      {
        titleCategorie: 'drogueria',
        metaTitle: 'Droguería',
        description:
          'Droguería 24 horas: Encuentra todos los productos de droguería en farmatodo.com. '
          + 'Ingresa ahora a nuestra farmacia online, elige los medicamentos que necesitas y compra fácil, rápido y recibe a domicilio.',
      },
      {
        titleCategorie: 'tratamiento-de-la-gripa',
        metaTitle: 'Tratamiento para la Gripa',
        description:
          'Tratamiento de la gripa: encuentra medicamentos para el cuidado de la gripa y resfriados '
          + 'farmatodo.com. Ingresa ahora y encuentra marcas como advil, noraver, noxpirin, pax y muchos más.',
        subcategories: [
          {
            titleSubcategorie: 'resfriado-y-gripa',
            metaTitle: 'Medicamentos para el Resfriado y gripa',
            description:
              'Encuentra todo en medicamentos para la gripa en farmatodo.com. Desde dolex gripa, '
              + 'noxpirin gripa, pax noche y día, hasta noraver y advil gripa. Compra rápido, fácil y recibe a domicilio las 24 horas.',
          },
          {
            titleSubcategorie: 'dolor-de-garganta',
            metaTitle: 'Medicamentos para el Dolor de garganta',
            description:
              'Encuentra todo en medicamentos para el dolor de garganta en farmatodo.com. Desde mebucaina '
              + 'y Pastillas Vick hasta isodine bucofaríngeo y Benzirin. Compra rápido, fácil y recibe a domicilio las 24 horas.',
          },
          {
            titleSubcategorie: 'tratamiento-de-la-tos',
            metaTitle: 'Tratamientos para la Tos',
            description:
              'Encuentra todo en medicamentos para la Tos en farmatodo.com. Desde jarabes para '
              + 'la tos como abrilar, privatos y Vick hasta Bisovon, mieltertos y robitussin. '
              + 'Compra rápido, fácil y recibe a domicilio las 24 horas.',
          },
          {
            titleSubcategorie: 'descongestionante',
            metaTitle: 'Medicamentos Descongestionantes',
            description:
              'Encuentra todo en medicamentos descongestionantes en farmatodo.com. Desde Vick '
              + 'baporub y nasivin, hasta allerzitina, afrisal y muchos más. Compra rápido, fácil y recibe a domicilio las 24 horas.',
          },
          {
            titleSubcategorie: 'ninos',
            metaTitle: 'Medicamentos para la gripa niños',
            description:
              'Encuentra medicamentos para la gripa en niños en farmatodo.com. Desde dolex niños y '
              + 'advil children, hasta distrancito y robitussin niños. Compra rápido, fácil y recibe a domicilio las 24 horas.',
          },
        ],
      },
      {
        titleCategorie: 'alivio-del-dolor',
        metaTitle: 'Alivio del dolor',
        description:
          'Encuentra medicamentos para el alivio del dolor en nuestra droguería online '
          + 'farmatodo.com. Compra analgésicos para dolores fuertes, dolor general, dolores musculares y muchos más en un solo lugar.',
        subcategories: [
          {
            titleSubcategorie: 'dolor-general',
            metaTitle: 'Medicamentos para el dolor general',
            description:
              'Encuentra medicamentos para el dolor general en nuestra droguería online '
              + 'farmatodo.com. Encuentra desde naproxeno y acetaminofén dolex, hasta aspirina, '
              + 'sevedol y todo en analgésicos en un solo lugar.',
          },
          {
            titleSubcategorie: 'dolor-fuerte',
            metaTitle: 'Medicamentos para el dolor fuerte',
            description:
              'Compra medicamentos efectivos para el dolor fuerte en nuestra droguería online '
              + 'farmatodo.com. Encuentra desde dolex forte y advil ultra, hasta sevedol tense, ibuflash y más producto especializados.',
          },
          {
            titleSubcategorie: 'dolor-abdominal-y-colico',
            metaTitle: 'Medicamentos para el dolor abdominal y cólico',
            description:
              'Compra medicamentos para el dolor abdominal y los cólicos en farmatodo.com. Encuentra '
              + 'desde buscapina y calmidol, hasta advil fem, colik forte y muchos más en un solo lugar.',
          },
          {
            titleSubcategorie: 'muscular-y-articular',
            metaTitle: 'Medicamentos para el dolor muscular y articular',
            description:
              'Encuentra todo en medicamentos para el dolor muscular: espalda, cuello y más en '
              + 'farmatodo.com.co. Compra diclofenaco, desde x ray y voltaren, hasta movidol, lumbal y más medicamentos.',
          },
          {
            titleSubcategorie: 'ninos',
            metaTitle: 'Medicamentos para el dolor en niños',
            description:
              'Encuentra medicamentos para el dolor en niños en farmatodo.com. Compra desde '
              + 'dolex niños, advil children, hasta medicamentos para niños como dristancito y robitussin en un solo lugar.',
          },
          {
            titleSubcategorie: 'tratamiento-de-varices',
            metaTitle: 'Tratamiento de varices',
            description:
              'Compra tratamientos y medicamentos para las varices en farmatodo.com. Encontrarás '
              + 'productos especializados como venasfull y venatil, hasta reparil gel, novader k y muchos más en un solo lugar. ',
          },
        ],
      },
      {
        titleCategorie: 'salud-digestiva',
        metaTitle: 'Medicamentos para la salud digestiva',
        description:
          'Cuida tu salud digestiva con todos nuestros productos especializados en nuestra droguería '
          + '24 horas farmatodo.com. Ingresa ahora y compra los mejores productos digestivos en un solo lugar.',
        subcategories: [
          {
            titleSubcategorie: 'antiacidos-y-antiulcerosos',
            metaTitle: 'Antiácidos y antiulcerosos',
            description:
              'Encuentra medicamentos antiácidos y antiulcerosos en farmatodo.com. Podrás comprar '
              + 'medicamentos como gaviscon, alkaselter y bonfiest, hasta omeprazol, genoprazol, leche de magnesia y más en un solo lugar.',
          },
          {
            titleSubcategorie: 'digestivos',
            metaTitle: 'Medicamentos digestivos',
            description:
              'Encuentra medicamentos para la salud digestiva en farmatodo.com. Podrás comprar '
              + 'productos especializados como metamucil, stamyl, alflorex, pancreatina y muchos más en un solo lugar.',
          },
          {
            titleSubcategorie: 'antidiarreicos-y-sueros',
            metaTitle: 'Medicamentos antidiarreicos y sueros',
            description:
              'Compra medicamentos para la diarrea y suero fisiológico en farmatodo.com, droguería 24 '
              + 'horas. Encuentra productos como: pedialyte, floratil, vivera sobres, sulzinc y muchos más en un solo lugar.',
          },
          {
            titleSubcategorie: 'laxantes-y-fibras',
            metaTitle: 'Laxantes y fibras',
            description:
              'Encuentra laxantes y fibras digestivas en farmatodo.com.co. Compra desde ciruelax, '
              + 'dulcolax y citromel, hasta evalax y digestar en un solo lugar. Envíos a domicilio las 24 horas: fácil, rápido y seguro.',
          },
          {
            titleSubcategorie: 'antiflatulentos',
            metaTitle: 'Medicamentos antiflatulentos',
            description:
              'Compra medicamentos antiflatulentos de venta libre en farmatodo.com. En nuestra '
              + 'droguería 24 horas encuentras: finigax capsulas, finigax masticable, meticom y mucho más medicamentos especializados.',
          },
          {
            titleSubcategorie: 'productos-para-el-mareo',
            metaTitle: 'Medicamentos para el mareo',
            description:
              'Encuentra medicamentos para el mareo en niños y adultos en farmatodo.com. Ingresa y '
              + 'compra productos como mareol y dramamine en tabletas y recibe a domicilio '
              + 'rápido, fácil y seguro con nuestra droguería online.',
          },
        ],
      },
      {
        titleCategorie: 'vitaminas-y-productos-naturales',
        metaTitle: 'Vitaminas y productos naturales',
        description:
          'Encuentra vitaminas, multivitamínicos, productos naturales y mucho más en nuestra '
          + 'droguería 24 horas farmatodo.com. Ingresa ahora y compra todos los productos que buscas, rápido, fácil y a domicilio.',
        subcategories: [
          {
            titleSubcategorie: 'vitaminas',
            metaTitle: 'Vitaminas',
            description:
              'Compra todo en vitaminas en farmatodo.com. Encontrarás vitamina c, vitamina e en '
              + 'marcas como Cebión, Vita C, Redoxitos y muchas más. Ingresa ahora y compra a domicilio, rápido, fácil y seguro.',
          },
          {
            titleSubcategorie: 'multivitaminicos',
            metaTitle: 'Multivitamínicos',
            description:
              'Encuentra los mejores multivitamínicos para mujeres, hombres, niños y embarazadas en '
              + 'Farmatodo.com. Compra marcas como gelicart, viplena, centrum, natele, shot b y muchos más en un solo lugar.',
          },
          {
            titleSubcategorie: 'naturales',
            metaTitle: 'Productos naturales',
            description:
              'Compra vitaminas y multivitamínicos naturales en farmatodo.com. Encontrarás los '
              + 'mejores productos naturales como colágeno hidrolizado, Omega 3, árnica, extracto de caléndulay muchos más.',
          },
          {
            titleSubcategorie: 'minerales',
            metaTitle: 'Productos minerales',
            description:
              'Encuentra vitaminas y suplementos minerales en farmatodo.com. Compra productos como '
              + 'Caltrate, Gelicart, calcibon, magnesium, sundown naturals y muchos más en un solo lugar y a domicilio 24 horas.',
          },
        ],
      },
      {
        titleCategorie: 'nutricion-y-vida-saludable',
        metaTitle: 'Nutrición y vida saludable',
        description:
          'Compra productos saludables para una buena nutrición en farmatodo.com. Encontrarás las '
          + 'mejores marcas y productos a un clic de distancia con entrega a domicilio las 24 horas, fácil, rápido y seguro.',
        subcategories: [
          {
            titleSubcategorie: 'alimentos-saludables',
            metaTitle: 'Alimentos saludables',
            description:
              'Compra alimentos y snacks saludables en farmatodo.com. Encontrarás barras de cereales, '
              + 'ensure, proteínas, snacks veganos, frutas deshidratadas y mucho más en un solo lugar, con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'articulos-deportivos',
            metaTitle: 'Artículos deportivos',
            description:
              'Compra alimentos deportivos en farmatodo.com. Encontrarás los mejores productos como '
              + 'barras de proteína, pediasure, ensure advance, prowhey y muchos más en un solo lugar. Pide a domicilio rápido y fácil.',
          },
          {
            titleSubcategorie: 'nutricion',
            metaTitle: 'Nutrición',
            description:
              'Compra los mejores productos para una buena nutrición en farmatodo.com. Encontraras '
              + 'desde ensure advance en todos sus sabores, hasta pediasure, enterex, prowhey y muchos más en un solo lugar.',
          },
          {
            titleSubcategorie: 'pastilleros-y-dosificadores',
            metaTitle: 'Pastilleros y dosificadores',
            description:
              'Encuentra pastilleros, dosificadores, trituradores y más artículos para tus medicamentos '
              + 'y suplementos en farmatodo.com. Ingresa ahora y compra con entrega a domicilio, rápido, fácil y seguro.',
          },
        ],
      },
      {
        titleCategorie: 'botiquin-y-primeros-auxilios',
        metaTitle: 'Botiquín y primeros auxilios',
        description:
          'Compra botiquines y todos los artículos de primeros auxilios en farmatodo.com.co. '
          + 'Desde jeringas y recolectores de muestras, hasta algodón, vendas, gasas y curas en un solo lugar, con envíos a domicilio.',
        subcategories: [
          {
            titleSubcategorie: 'curitas-algodones-adhesivos-y-botiquin',
            metaTitle: 'Curitas, algodones, adhesivos y botiquín',
            description:
              'Compra curitas, algodón, esparadrapo, micropore, gasas y más productos de botiquín en '
              + 'farmatodo.com. Ingresa ahora a nuestra droguería online y compra a domicilio las 24 horas, fácil y rápido.',
          },
          {
            titleSubcategorie: 'antisepticos',
            metaTitle: 'Antisépticos',
            description:
              'Encuentra productos antisépticos como alcohol, agua oxigenada, isodine, '
              + 'antibacteriales y muchos más productos especializados en farmatodo.com. '
              + 'Ingresa ahora y compra a domicilio: fácil, rápido y seguro.',
          },
          {
            titleSubcategorie: 'compresas',
            metaTitle: 'Compresas',
            description:
              'Encuentra todo tipo de compresas en farmatodo.com. Ingresa y comprar compresas '
              + 'kamex para ojos, pélvicas y muchas más en un solo lugar y con envío a domicilio: rápido, fácil y seguro.',
          },
          {
            titleSubcategorie: 'vendas',
            metaTitle: 'Vendas',
            description:
              'Compra todo tipo de vendas en farmatodo.com. Ingresa ahora a nuestra droguería '
              + 'online y encuentra todos los artículos para botiquín y primeros auxilios con envío a domicilio.',
          },
          {
            titleSubcategorie: 'jeringas-guantes-y-material-hosp',
            metaTitle: 'Jeringas, guantes y material hospitalario',
            description:
              'Encuentra baja lenguas, guantes, jeringas y todo el material hospitalario que '
              + 'necesitas en farmatodo.com. Ingresa y compra a domicilio: fácil, rápido y seguro en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'aparatos-medicos-y-pastilleros',
            metaTitle: 'Aparatos médicos y pastilleros',
            description:
              'Encuentra pastilleros, nebulizadores y más artículos médicos en farmatodo.com. '
              + 'Ingresa ahora y comprar online y a domicilio: fácil, rápido y seguro con nuestra droguería online 24 horas.',
          },
        ],
      },
      {
        titleCategorie: 'dermatologicos',
        metaTitle: 'Medicamentos dermatológicos',
        description:
          'Encuentra todo en productos dermatológicos especializados en el cuidado de la piel en '
          + 'farmatodo.com. Ingresa ahora a nuestra droguería online y pide a domicilio: fácil, rápido y seguro.',
        subcategories: [
          {
            titleSubcategorie: 'antiprurignoso',
            metaTitle: 'Medicamentos Antipruriginoso',
            description:
              'Encuentra medicamentos Antipruriginoso en nuestra droguería online Farmatodo.com. '
              + 'Compra los mejores medicamentos antipicazón como caladerm, caladryl, dermiclear y muchos más a domicilio: rápido y fácil.',
          },
          {
            titleSubcategorie: 'antimicotico-topico',
            metaTitle: 'Antimicótico tópico',
            description:
              'Encuentra medicamentos antimicóticos tópicos en farmatodo.com. Compra cremas como '
              + 'clotrimazol, silk medic, lamisil, butemax y muchas más a domicilio: fácil, rápido y seguro en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'protectores-dermicos',
            metaTitle: 'Protectores dérmicos',
            description:
              'Encuentra medicamentos para la protección de tu piel en farmatodo.com. Compra productos '
              + 'dérmicos como domeboro, acid mantle, alergibon y más medicamentos especializados a domicilio 24 horas: rápido y fácil.',
          },
          {
            titleSubcategorie: 'tratamiento-de-cicatrices-y-heridas',
            metaTitle: 'Tratamiento de cicatrices y heridas',
            description:
              'Encuentra medicamentos y cremas cicatrizantes en farmatodo.com. Compra cicatricure, '
              + 'cicaderm, biocicar y muchos más productos especializados en la curación de '
              + 'cicatrices en nuestra droguería online con domicilios 24 horas.',
          },
          {
            titleSubcategorie: 'tratamiento-de-psoriasis',
            metaTitle: 'Tratamiento de psoriasis',
            description:
              'Encuentra medicamentos para la psoriasis en farmatodo.com. Compra medicamentos'
              + ' especializados y efectivos como glicolic loción y glicolic en crema en nuestra '
              + 'droguería online con envíos a domicilio 24 horas.',
          },
          {
            titleSubcategorie: 'queratoliticos',
            metaTitle: 'Medicamentos Queratolíticos',
            description:
              'Compra medicamentos queratolíticos en nuestra droguería online farmatodo.com.co. '
              + 'Encontrarás callicidas y productos especializados como Collomack. Ingresa ahora y '
              + 'realiza tus pedidos a domicilio las 24 horas: fácil y rápido.',
          },
          {
            titleSubcategorie: 'tratamiento-de-alopecia',
            metaTitle: 'Tratamiento para la alopecia',
            description:
              'Encuentra tratamientos para la alopecia en farmatodo.com. Compra medicamentos '
              + 'especializados como minoxidin, pilogán, edapil y muchos más en un solo lugar. '
              + 'Envíos a domicilio 24 horas: fácil y rápido.  ',
          },
        ],
      },
      {
        titleCategorie: 'cuidado-de-los-pies-y-antimicoticos',
        metaTitle: 'Productos para el cuidado de los pies y antimicóticos',
        description:
          'Encuentra tratamientos para el cuidado de los pies en farmatodo.com. Compra antimicóticos '
          + 'como unesia y lamisil, también productos como plantillas, antitranspirantes de pies y '
          + 'mucho más en un solo lugar y con envíos 24 horas.',
        subcategories: [
          {
            titleSubcategorie: 'talcos-y-desodorantes-de-pie',
            metaTitle: 'Talcos y desodorantes de pies',
            description:
              'Compra talcos para pies y antitranspirantes en farmatodo.com. Encontrarás los mejores '
              + 'productos como talcos mexana, Deo pies, tacos rexona y muchos más con envíos a '
              + 'domicilio las 24 horas: fácil, rápido y seguro.',
          },
          {
            titleSubcategorie: 'antimicotico',
            metaTitle: 'Antimicóticos',
            description:
              'Compra lamisil, unesia, canesten y más medicamentos antimicóticos en nuestra droguería '
              + 'online farmatodo.com. Ingresa ahora y elige los mejores productos con envío a '
              + 'domicilio: fácil, rápido y seguro.',
          },
          {
            titleSubcategorie: 'plantillas',
            metaTitle: 'Plantillas',
            description:
              'Compra plantilla especializadas para le cuidado de los pies en farmatodo.com. '
              + 'Encontrarás también taloneras, punteras y más productos con envíos a domicilio: '
              + 'fácil, rápido y seguro en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'apositos-y-tratamiento-de-callos',
            metaTitle: 'Apósitos y tratamiento de callos',
            description:
              'Encuentra apósitos para los pies y tratamientos para los callos en farmatodo.com. '
              + 'Ingresa ahora y comprar desde parques para callos, hasta protectores y correctores '
              + 'de juanete en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'accesorios-para-pies',
            metaTitle: 'Accesorios para pies',
            description:
              'Encuentra taloneras, protectores de callos y juanetes en farmatodo.com. Ingresa ahora '
              + 'comprar los productos que necesitas para el cuidado de tus pies en un solo lugar '
              + 'y con envío a domicilio 24 horas: rápido y fácil.',
          },
          {
            titleSubcategorie: 'refrescantes-y-humectantes-de-pies',
            metaTitle: 'Refrescantes y humectantes de pies',
            description:
              'Encuentra productos para humectar y refrescar tus pies en farmatodo.com. Ingresa ahora, '
              + 'elige los tuyos y compra con envíos a domicilio 24 horas: rápido, fácil y seguro',
          },
          {
            titleSubcategorie: 'antiprurignoso',
            metaTitle: 'Antiprurignosos',
            description:
              'Compra productos antipruriginosos para el cuidado de los pies en farmatodo.com. '
              + 'Ingresa ahora y encuentra crotamiton, caladerm clear y muchos más productos especializados en nuestra droguería online.',
          },
        ],
      },
      {
        titleCategorie: 'incontinencia',
        metaTitle: 'Productos para la Incontinencia',
        description:
          'Encuentra productos para la incontinencia urinaria en adultos en farmatodo.com. Podrás '
          + 'comprar pañales para adultos en diferentes tallas y marcas en un solo lugar con envíos a '
          + 'domicilio: fácil y rápido en nuestra droguería online.',
        subcategories: [
          {
            titleSubcategorie: 'panales-incontinencia',
            metaTitle: 'Pañales para la incontinencia | Adultos',
            description:
              'Compra pañales para adultos en farmatodo.com. Encontrarás variedad de marcas como '
              + 'pañales tena, pañales content o pañales slip en todas sus tallas, con entrega a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'otros',
            metaTitle: 'Toallas, protectores y productos para la incontinencia',
            description:
              'Compra protectores de cama tena, toallas húmedas content y más productos para la '
              + 'incontinencia urinaria en adultos en farmatodo.com. Ingresa ahora y realiza tus medidos'
              + ' online en nuestra droguería con domicilios.',
          },
        ],
      },
      {
        titleCategorie: 'rehabilitacion-y-equipos-medicos',
        metaTitle: 'Productos de Rehabilitación y equipos médicos',
        description:
          'Encuentra todo en productos especializados en rehabilitación ortopédica, equipos médicos, '
          + 'gafas de lectura y mucho más en farmatodo.com.co. Realiza pedidos a domicilio de forma fácil, rápida y segura.',
        subcategories: [
          {
            titleSubcategorie: 'vendas',
            metaTitle: 'Vendas',
            description:
              'Compra todo tipo de vendas en farmatodo.com. En nuestra droguería online podrás encontrar '
              + 'vendas elásticas, vendas en algodón, vendas de yeso y más a n clic de distancia. Realiza '
              + 'tus pedidos a domicilio: rápido y fácil.',
          },
          {
            titleSubcategorie: 'calcetines-y-medias-terapeuticas',
            metaTitle: 'Medias para las varices  y terapéuticas',
            description:
              'Compra medias para las várices en nuestra droguería online farmatodo.com. Encontrarás '
              + 'medias No Varix en todas las tallas, así como media medi varic. Realiza tus pedidos a '
              + 'domicilio: fácil y rápido en farmatodo.',
          },
          {
            titleSubcategorie: 'equipos-medicos',
            metaTitle: 'Equipos médicos',
            description:
              'Compra desde pruebas de embarazo, tensiómetros digitales y glucómetros, hasta cámaras '
              + 'inhaladoras, termómetros, vaporizadores y más equipos médicos en farmatodo.com. Ingresa '
              + 'ahora y realiza tus pedido por domicilio.',
          },
          {
            titleSubcategorie: 'accesorios-deportivos',
            metaTitle: 'Accesorios deportivos',
            description:
              'Encuentra accesorios deportivos en farmatodo.com. Podrás comprar desde fajas deportivas, '
              + 'mancuernas y tapetes de yoga, hasta vasos mezcladores  y cintas deportivas en un solo '
              + 'lugar. Ingresa ahora y realiza tus pedidos a domicilio.',
          },
          {
            titleSubcategorie: 'productos-para-rehabilitacion',
            metaTitle: 'Productos para rehabilitación',
            description:
              'Encuentra todo en artículos para rehabilitación ortopédica en farmatodo.com. Desde '
              + 'muñequeras, rodilleras y coderas, hasta correctores de postura, fajas, tobilleras y '
              + 'más productos especializados en un solo lugar.',
          },
          {
            titleSubcategorie: 'compresas',
            metaTitle: 'Compresas',
            description:
              'Encuentra todo tipo de compresas en nuestra droguería online farmatodo.com. Podrás comprar '
              + 'desde compresas para ojos y terapia cefálica, hasta compresas pélvicas en un solo '
              + 'lugar y con envío a domicilio: fácil y rápido.',
          },
        ],
      },
      {
        titleCategorie: 'cuidado-de-la-vista',
        metaTitle: 'Productos para el cuidado de la vista',
        description:
          'Encuentra todo en productos y medicamentos para el cuidado de la vita en farmatodo.com. '
          + 'Podrás compras desde gotas para los ojos y solución para lentes, hasta gafas de lectura y '
          + 'más en un solo lugar, con envío a domicilio.',
        subcategories: [
          {
            titleSubcategorie: 'solucion-para-lentes-de-contacto',
            metaTitle: 'Lentes de contacto',
            description:
              'Compra soluciones para lentes de contacto en farmatodo.com. Encuentra productos '
              + 'como opti free, Multi Solución Wasser y más productos especializados en un solo lugar y '
              + 'con envío a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'gotas-lubricantes-y-lagrimas-artificiales',
            metaTitle: 'Gotas, lubricantes y lágrimas artificiales',
            description:
              'Compra lagrimar artificiales en farmatodo.com. Encontrarás lubricantes para ojos y '
              + 'gotas como systane gotas, optive gotas, lagricel y más productos especializados para el '
              + 'cuidado de tus ojos en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'gafas-de-sol',
            metaTitle: 'Gafas de Sol',
            description:
              'Compra gafas de sol con filtro UV en farmatodo.com. Encontrarás gafas para hombre y '
              + 'mujer en variedad de diseños y marcas en un solo lugar. Realiza tus pedidos en nuestra droguería online: fácil y rápido.',
          },
          {
            titleSubcategorie: 'gafas-de-lectura',
            metaTitle: 'Gafas de lectura',
            description:
              'Compras gafas de lectura en farmatodo.com. Encontraras diferentes estilos, con el '
              + 'aumento perfecto para ti. Realiza tus pedidos a domicilio las 24 horas en nuestra droguería online: fácil y rápido.',
          },
        ],
      },
    ],
  },
  {
    titleDepartment: 'belleza',
    nameSimple: 'belleza',
    metaTitle: 'Productos y Accesorios de belleza',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
      + 'Ingresa ahora, compra todos los productos de maquillaje y belleza, y recibe a Domicilio: rápido, fácil y seguro.',
    description:
      'Ingresa ahora y podras ver la gran cantidad de accesorios de belleza que tenemos para que puedas lucir aún más bella.',
    categories: [
      {
        titleCategorie: 'accesorios-de-belleza',
        description: '',
        subcategories: [
          {
            titleSubcategorie: 'ojos',
            metaTitle: 'Accesorios para maquillaje de ojos ',
            description:
              'En farmatodo puedes encontrar brochas, pomos, gafas para dormir, esponjas removedores, '
              + 'y lo que necesites para tener el mejor kit de belleza.',
          },
          {
            titleSubcategorie: 'rostro-y-cuerpo',
            metaTitle: 'Accesorios para rostro y cuerpo ',
            description:
              'Estas buscando un accesorio para poner en tu kit de bellaza ingresa a farmatodo y podras '
              + 'ver los utencilios que tenemos para rostro y cuerpo.',
          },
          {
            titleSubcategorie: 'unas',
            metaTitle: 'Productos y Accesorios  para uñas ',
            description:
              'En farmatodo puedes encontrar todo lo que necesites para embellecer tus uñas, esmaltes, '
              + 'brillos, removedores ¡ingresa ahora!',
          },
          {
            titleSubcategorie: 'estuches-y-kits',
            metaTitle: 'Estuches y kits de belleza',
            description:
              'Puede ser para ti o para regalar pero tener un buen kit de belleza para tu día a día '
              + 'ayuda a que luzcas aún más bella y los mejores estan en Farmatodo',
          },
        ],
      },
      {
        titleCategorie: 'cosmeticos',
        metaTitle: 'Cosméticos ',
        description:
          'Mejora tu apariencia y mantén tu belleza con los productos cosméticos que encontrarás en Farmatodo.',
        subcategories: [
          {
            titleSubcategorie: 'ojos',
            metaTitle: 'Maquillaje para ojos ',
            description:
              'Haz que tus looks sean distintos todos los días con el maquillaje de ojos que Farmatodo '
              + 'te ofrece y logra una mirada fabulosa.',
          },
          {
            titleSubcategorie: 'labios',
            metaTitle: 'Maquillaje para labios ',
            description:
              'Demuestra como te sientes en cada momento contando una historia distinta a través de tus '
              + 'labios con el maquillaje que Farmatodo ofrece para ti.',
          },
          {
            titleSubcategorie: 'bases-y-polvos',
            metaTitle: 'Maquillaje para rostro ',
            description:
              'Embellece tu rostro y destaca tu belleza con la variedad de bases y polvos todo en '
              + 'maquillaje esta en Farmatodo ingres y encuentra todo lo que tenemos para ti.',
          },
          {
            titleSubcategorie: 'unas',
            metaTitle: 'Maquillaje de uñas ',
            description:
              'Todo lo que esperas en productos de belleza para uñas, como esmaltes, removedores, entre '
              + 'otros los encuentras en Farmatodo. ¡Ingresa ahora!',
          },
          {
            titleSubcategorie: 'lociones-y-siliconas',
            metaTitle: 'Tratamientos capilares ',
            description:
              'Estas buscando el mejor tratamiento para que tu cabello luzca sano y fuerte ingresa ahora '
              + 'y podras encontrar en Farmatodo las mejores marcas para que puedas iniciar ese tratamiento que tanto quieres.',
          },
        ],
      },
      {
        titleCategorie: 'cuidado-de-la-piel',
        metaTitle: 'Productos cuidado de la piel ',
        description:
          'Realza la belleza natural de tu piel dándole siempre el mejor cuidado de la piel con los '
          + 'productos que tenemos para ti. ¡Ingresa y adquiérelos!',
        subcategories: [
          {
            titleSubcategorie: 'cuidado-solar',
            metaTitle: 'Productos para el cuidado solar ',
            description:
              'La vitamina D es buena y se adquiere recibiendo el sol pero debes tener todo el cuidado '
              + 'para no exponer tu piel en farmatodo encontraras protectores solares, bronceadores y repelentes.',
          },
          {
            titleSubcategorie: 'cuidado-facial',
            metaTitle: 'Cuidado Facial ',
            description:
              'La piel de tu rostro es la más expuesta diarimante dale un descanso y cuidala en tu casa '
              + 'con los produtos y cremas que tenemos en farmatodo ¡ingresa ahora!',
          },
          {
            titleSubcategorie: 'cuidado-corporal',
            metaTitle: 'Cuidado Corporal ',
            description:
              'La crema que necesitas para cuidar la piel de tus brazos, piernas o manos la encuentras '
              + 'en farmatodo ¡Ingresa ahora y descubre los mejores precios y marcas!',
          },
        ],
      },
      {
        titleCategorie: 'dercosmeticos',
        metaTitle: 'Dermocosmética ',
        description:
          'Disfruta de los mejores beneficios para tu piel en Farmatodo. Disponemos de una gran variedad de marcas para ti.',
        subcategories: [
          {
            titleSubcategorie: 'cuidado-corporal',
            metaTitle: 'Productos cuidado corporal ',
            description:
              'Tu piel esta expuesta diaramente a la rutina cuidala de todas la maneras con cremas '
              + 'corporales, protectores labiales, aceites entre otros y todo lo encuentras en Farmatodo',
          },
          {
            titleSubcategorie: 'cuidado-facial',
            metaTitle: 'Productos de cuidado facial ',
            description:
              'Te van a encantar los productos de cuidado facial que tenemos para ti. Encuentra en '
              + 'farmatodo una gran variedad de artículos para limpieza facial, rejuvenecimiento y mucho mas para tu rostro.',
          },
          {
            titleSubcategorie: 'cuidado-capilar',
            metaTitle: 'Productos cuidado del cabello ',
            description:
              'Todo tipo de cabello necesita una gran variedad de productos para escoger. En farmatodo '
              + 'puedes encontrar diversas marcas. ¡Conócelas!',
          },
          {
            titleSubcategorie: 'maquillaje',
            metaTitle: 'Maquillaje protector ',
            description:
              'Encuentra el maquillaje ideal pa tu piel con el beneficio de la protección, como bases con '
              + 'protección solar, protector labial uv entre otros productos y todos esta en farmatodo.',
          },
          {
            titleSubcategorie: 'proteccion-solar',
            metaTitle: 'Productos de protección solar ',
            description:
              'Protege tu piel de los rayos ultravioleta con los productos de protección solar, ideales '
              + 'para todo tipo de piel. ¡Encuéntralos en Farmatodo!',
          },
        ],
      },
      {
        titleCategorie: 'fragancias',
        metaTitle: 'Perfumes para Mujer y Hombre',
        description:
          'Encuentra perfumes para mujer y perfumes para hombres en farmatodo.com. Compra las mejores '
          + 'marcas como Benetton, antonio banderas, lavante, sofía vergara y muchas más.',
        subcategories: [
          {
            titleSubcategorie: 'fragancias',
            metaTitle: 'Fragancias para hombre y mujer',
            description:
              'Compra lociones, fragancias y los mejores perfumes para hombre y mujer en Farmatodo.com. '
              + 'Ingresa ahora y realiza todas tus compras con envío a domicilio.',
          },
        ],
      },
    ],
  },
  {
    titleDepartment: 'cuidado-del-bebe',
    nameSimple: 'cuidado del bebé',
    metaTitle: 'Productos para el cuidado del bebé',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
      + 'Ingresa ahora, compra todos los productos para el cuidado y bienestar de tu bebé y '
      + 'recibe a Domicilio: rápido, fácil y seguro.',
    description:
      'Encuentra todos los productos que necesitas para el cuidado de tu bebé en farmatodo.com. Compra '
      + 'pañales, cremas, productos de aseo, teteros, chupos y mucho más en un solo lugar. Realiza tus '
      + 'pedidos a domicilio: fácil y rápido.',
    categories: [
      {
        titleCategorie: 'panales-y-panitos-humedos',
        metaTitle: 'Pañales y pañitos húmedos',
        description:
          'Encuentra pañales para bebe y toallas húmedas en las mejores marcas: winny, huggies, pequeñín '
          + 'y más en farmatodo.com. Compra a domicilio en nuestra droguería online las 24 horas del día: fácil, rápido y seguro.',
        subcategories: [
          {
            titleSubcategorie: 'panales',
            metaTitle: 'Pañales para bebé',
            description:
              'Compra pañales para bebé en Farmatodo.com.co. Desde pañales winny y huggies, hasta pañales '
              + 'pequeñín en todas las etapas: etapa 0, etapa 1, etapa 2 y más. Ingresa ahora y '
              + 'realiza tus comprar a domicilio en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'toallitas-y-panitos-humedos',
            metaTitle: 'Toallitas y pañitos húmedos para bebé',
            description:
              'Compra pañitos húmedos para bebé en farmatodo.com. Encontrarás toallas húmedas huggies, '
              + 'pequeñín, winny y más productos para el cuidado de tus hijos en un solo lugar. '
              + 'Ingresa ahora y realiza tus pedidos a domicilio.',
          },
        ],
      },
      {
        titleCategorie: 'formulas-infantiles',
        metaTitle: 'Fórmulas infantiles',
        description:
          'Compra leche de fórmula para bebé en farmatodo.com. Encontrarás las mejores marcas como enfamil, '
          + 'somilac, Nan, enfagrow, alimentum, mah y más para todas las etapas de crecimiento. Ingresa ahora y compra a domicilio.',
        subcategories: [
          {
            titleSubcategorie: 'crecimiento',
            metaTitle: 'Fórmulas de Crecimiento',
            description:
              'Encuentra fórmulas infantiles para el crecimiento en farmatodo.com. Compra leche de fórmula '
              + 'como enfamil Premium, Nan Optipro, similac 1,alpina baby plus y muchas más en un '
              + 'solo lugar. Realiza tus pedidos a domicilio.',
          },
          {
            titleSubcategorie: 'prematuros',
            metaTitle: 'Fórmulas de Prematuros',
            description:
              'Encuentra leches de fórmula para bebés prematuros en farmatodo.com. Compra desde leche '
              + 'klim 1, Nan optipro, alula gold, nestrogeno 1 y muchas más en un solo lugar. Realiza tus pedidos a domicilio.',
          },
          {
            titleSubcategorie: 'seguimiento',
            metaTitle: 'Fórmulas de seguimiento',
            description:
              'Encuentra leches de fórmula para el seguimiento el bebé en farmatodo.com. Compra desde '
              + 'formular infantil nutrilon, Puramino, Nan AR, Nan confort y muchas más en un solo lugar. Realiza tus domicilios ahora.',
          },
          {
            titleSubcategorie: 'especializadas',
            metaTitle: 'Fórmulas especializadas para bebé',
            description:
              'Encuentra leche de fórmula especializadas en farmatodo.com. Compra desde nutramigen '
              + 'Premium, alimentum, Nutril on, hasta en enfamil Premium, Novamil ar plus y muchas más en nuestra droguería online.',
          },
        ],
      },
      {
        titleCategorie: 'compotas-y-cereales',
        metaTitle: 'Compotas y cereales',
        description:
          'Encuentra compotas y cereales para niños y bebés en Farmato.com. Podrás comprar las mejores '
          + 'marcas como: alpina, nestle, mah y más en nuestra droguería online. Pide domicilios 24 horas: rápido, fácil y seguro.',
        subcategories: [
          {
            titleSubcategorie: 'compotas',
            metaTitle: 'Compotas',
            description:
              'Compra comportas para bebé en farmatodo.com. Encontrarás compotas Gerber, componas heinz, '
              + 'baby evolution, alpina baby, smile kids y muchas más en nuestra droguería online con domicilios 24 horas.',
          },
          {
            titleSubcategorie: 'cereales',
            metaTitle: 'Cereales para niños',
            description:
              'Encuentra los cereales que quieres en farmatodo.com. Compra cereales kellogs, Flips, Choco '
              + 'Krispis, cereales Quaker, Zucaritas, cereales nestum y muchos más a un clic de distancia. '
              + 'Realiza tus pedidos a domicilio.',
          },
          {
            titleSubcategorie: 'organicos',
            metaTitle: 'Alimentos orgánicos para niños',
            description:
              'Encuentra alimentos saludables para tu bebé en farmatodo.com. Podrás comprar compotas '
              + 'orgánicas en marcas como baby evolution y más. Ingresa ahora y realiza tus pedidos a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'galletas-papillas-y-yogurts',
            metaTitle: 'Galletas, papillas y yogurts para niños',
            description:
              'Encuentra papillas para bebé, galletas, yogurts y más alimentos para los más pequeños en '
              + 'farmatodo.com. Ingresa ahora y compra las mejores marcas como Alpina, Colombina, Mah y más en un solo lugar.',
          },
        ],
      },
      {
        titleCategorie: 'higiene-del-bebe',
        metaTitle: 'Productos de Higiene para bebé',
        description:
          'Encuentra todos los productos de higiene y cuidado del bebé en farmatodo.com. Compra las mejores'
          + ' marcas y todos los productos que buscas: habones, colonias, cremas, aceites y más en un solo lugar.',
        subcategories: [
          {
            titleSubcategorie: 'shampoo',
            metaTitle: 'Shampoo para bebé',
            description:
              'Compra shampoo para bebés en farmatodo.com. Encontrarás las mejores marcas como shampoo '
              + 'johnsons, Arrurú, Dove Baby, Mustela y más en todas sus referencias. Realiza tus pedidos a domicilio.',
          },
          {
            titleSubcategorie: 'jabones',
            metaTitle: 'Jabones para bebé',
            description:
              'Compra jabones para bebé en farmatodo.com. Encontrarás las mejores marcas como jabones '
              + 'johnsons, Dove Baby, Fresh kids y muchas más en un solo lugar. Realiza tus pedidos a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'acondicionadores',
            metaTitle: 'Acondicionadores para bebé',
            description:
              'Compra acondicionadores para bebé en farmatodo.com. Encontrarás las mejores marcas como '
              + 'acondicionadores johnsons, Avent, Barbie y muchas más en un solo lugar. Realiza tus pedidos a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'fragancias-y-colonias',
            metaTitle: 'Fragancias y colonias para bebé',
            description:
              'Compra colonias para bebé en farmatodo.com. Encontrarás desde colonias arrurrú, colonias '
              + 'Disney para niños y niñas, hasta colonias johnsons. Ingresa ahora y realiza tus pedidos a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'cremas',
            metaTitle: 'Cremas para bebé',
            description:
              'Encuentra todo en cremas para bebés en farmatodo.com. Compra desde crema anti pañalitis, '
              + 'hasta cremas corporales como johnsons, Dove baby y muchas más en un solo lugar. Realiza tus pedidos a domicilio.',
          },
          {
            titleSubcategorie: 'aceites',
            metaTitle: 'Aceites para bebé',
            description:
              'Compra aceites para bebés en farmatodo.com. Encontrarás las mejores marcas como aceites '
              + 'johnsons, Mustela, Burts bees y más en un solo lugar. Ingresa ahora y realiza tus pedidos a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'talcos',
            metaTitle: 'Talcos para bebé',
            description:
              'Compra talcos para bebés en farmatodo.com. Encontrarás las mejores marcas como tacos '
              + 'johnsons y mucho más en un solo lugar. Realiza tus pedidos a domicilio: fácil y rápido en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'accesorios-de-bebe',
            metaTitle: 'Accesorios para bebé',
            description:
              'Encuentra todo en accesorios de bebé: teteros, cepillos, chupos, extractores de leche y '
              + 'muchos más en las mejores marcas en farmatodo.com. Ingresa ahora y compras online a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'copitos',
            metaTitle: 'Copitos para bebé',
            description:
              'Compra copitos para bebés en farmatodo.com. Encontrarás copitos hipo alergénicos, copitos '
              + 'johnsons y mucho más en un solo lugar. Ingresa ahora y realiza tus pedidos a domicilio: fácil y rápido.',
          },
        ],
      },
      {
        titleCategorie: 'juguetes-cunas-y-todo-para-tu-bebe',
        metaTitle: 'Juguetes, cunas y todo para tu bebé',
        description:
          'Encuentra desde cuñas y corrales, hasta todo en juguetes para niños y niñas en farmatodo.com. '
          + 'Ingresa ahora y descubre todo lo que tenemos para tu bebé. Realiza tus medidos online.',
        subcategories: [
          {
            titleSubcategorie: 'accesorios-de-vestir',
            metaTitle: 'Accesorios de vestir para bebe',
            description:
              'Encuentra accesorios para verter para tus bebés y niños en farmatodo.com.co. Ingresa ahora '
              + 'y realiza todas tu compras online de forma fácil y rápido en un solo lugar.',
          },
          {
            titleSubcategorie: 'almohadas',
            metaTitle: 'Almohadas para bebé',
            description:
              'Encuentra almohadas para bebés y niños en farmatodo.com. Ingresa ahora y elige el diseño y '
              + 'confort que buscas y realiza tus compras online con domicilio las 24 horas: fácil y rápido.',
          },
          {
            titleSubcategorie: 'arte-y-manualidades',
            metaTitle: 'Arte y manualidades',
            description:
              'Encuentra juguetes de arte y manualidades en farmatodo.com. Ingresa ahora y elige el diseño '
              + 'y confort que buscas y realiza tus compras online con domicilio las 24 horas: fácil y rápido.',
          },
          {
            titleSubcategorie: 'aseo-del-bebe',
            metaTitle: 'Aseo del bebé',
            description:
              'Encuentra todos los productos de aseo para bebé que necesitas en farmatodo.com. Ingresa '
              + 'ahora y elige el diseño y confort que buscas y realiza tus compras online con domicilio las 24 horas: fácil y rápido.',
          },
          {
            titleSubcategorie: 'aseo-y-cuidado-personal',
            metaTitle: 'Aseo y cuidado personal del bebé',
            description:
              'Encuentra todos los productos de cuidado para bebés y aseo que necesitas en farmatodo.com. '
              + 'Ingresa ahora y elige el diseño y confort que buscas y realiza tus compras online con '
              + 'domicilio las 24 horas: fácil y rápido.',
          },
          {
            titleSubcategorie: 'baneras-y-bacinillas',
            metaTitle: 'Bañeras y Bacinilla',
            description:
              'Encuentra bañeras, tinas y bacinillas para bebés y niños en farmatodo.com. Ingresa ahora y '
              + 'elige la que buscas y realiza tus compras online con domicilio las 24 horas: fácil y rápido.',
          },
          {
            titleSubcategorie: 'calzado',
            metaTitle: 'Zapatos para bebé',
            description:
              'Encuentra zapatos para bebés en farmatodo.com.co. Ingresa ahora y elige el diseño y confort '
              + 'que buscas y realiza tus compras online con domicilio las 24 horas: fácil y rápido.',
          },
          {
            titleSubcategorie: 'cartucheras',
            metaTitle: 'Cartucheras',
            description:
              'Encuentra cartucheras para niños en farmatodo.com.co. Ingresa ahora y elige el diseño que '
              + 'buscas y realiza tus compras online con domicilio las 24 horas: fácil y rápido.',
          },
          {
            titleSubcategorie: 'coches',
            metaTitle: 'Coches para niños',
            description:
              'Compra coches para bebé en farmatodo.com.co. Ingresa ahora y elige la marca, el diseño y '
              + 'confort que buscas para tus hijos. Realiza tus compras online con domicilio las 24 horas: fácil y rápido.',
          },
          {
            titleSubcategorie: 'comedores-y-sillas',
            metaTitle: 'Comedores y sillas para niños',
            description:
              'Encuentra sillas de comedor para niños y comedores para niños en farmatodo.com. Ingresa '
              + 'ahora elige el diseño y marca que quieres y realiza tus compras online con entrega a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'compotas',
            metaTitle: 'Compotas',
            description:
              'Compra compotas para bebé en farmatodo.com. Ingresa ahora y compra online con envío a '
              + 'domicilio: fácil, rápido y seguro en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'cuidado-de-la-ropa',
            metaTitle: 'Cuidado de la ropa para bebé',
            description:
              'Encuentra todos los productos para el cuidado de la ropa de tus bebés en farmatodo.com. '
              + 'Ingresa ahora y compra online con envío a domicilio: fácil, rápido y seguro en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'cuidado-de-la-vista',
            metaTitle: 'Cuidado de la vista para bebés',
            description:
              'Encuentra todos los productos para el cuidado de la vista  de tus bebés en farmatodo.com. '
              + 'Ingresa ahora y compra online con envío a domicilio: fácil, rápido y seguro en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'cunas-y-corrales',
            metaTitle: 'Cunas y corrales',
            description:
              'Compra cunas y corrales para tu bebé en farmatodo.com. Ingresa ahora y encuentra variedad '
              + 'de diseños y marcas en un solo lugar. Realiza tus compras online con envío a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'decoracion',
            metaTitle: 'Decoración para niños',
            description:
              'Encuentra todo en decoración para la habitación y espacios de tu bebé en farmatodo.com. '
              + 'Ingresa ahora y realiza tus compras online con entrega a domicilio: fácil, rápido y seguro en un solo lugar.',
          },
          {
            titleSubcategorie: 'decoracion-y-almacenaje',
            metaTitle: 'Productos de decoración y almacenaje',
            description:
              'Encuentra los productos de decoración y almacenamiento para los espacios de tus hijos en '
              + 'farmatodo.com. Ingresa ahora y realiza tus compras online fácil, rápido y seguro en un solo lugar.',
          },
          {
            titleSubcategorie: 'deportes-y-juegos-al-aire-libre',
            metaTitle: 'Juegos al aire libre para niños',
            description:
              'Compra juegos para el aire  libre y accesorios deportivos en farmatodo.com. Ingresa ahora y'
              + ' realiza tus compras online fácil, rápido y seguro en un solo lugar con envíos a domicilio.',
          },
          {
            titleSubcategorie: 'desarrollo-motriz',
            metaTitle: 'Productos para el desarrollo motriz',
            description:
              'Encuentra juguetes para el desarrollo motriz de tus hijos en farmatodo.com. Ingresa ahora y'
              + ' realiza tus compras online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'despues-del-bano',
            metaTitle: 'Productos para después del baño',
            description:
              'Encuentra en productos para después del baño de tus bebés en farmatodo.com. Ingresa ahora y'
              + ' realiza tus compras online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'electricos-y-montables',
            metaTitle: 'Eléctricos y montables para niños',
            description:
              'Encuentra montables eléctricos para niños en farmatodo.com. Compra motos eléctricas para '
              + 'niños y carros eléctricos en un solo lugar. Ingresa ahora y realiza tus compras online en un solo lugar: fácil y rápido.',
          },
          {
            titleSubcategorie: 'estimulacion-sensorial',
            metaTitle: 'Estimulación sensorial para niños',
            description:
              'Encuentra productos para la estimulación sensorial de bebés en farmatodo.com Ingresa ahora y '
              + 'realiza tus compras online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'juegos-de-construccion',
            metaTitle: 'Juegos de Construcción',
            description:
              'Encuentra juguetes de construcción para niños y niñas en farmatodo.com. Ingresa ahora y '
              + 'realiza tus compras online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'juegos-didacticos',
            metaTitle: 'Juegos didácticos',
            description:
              'Encuentra juegos didácticos para niños y niñas de todas las edades en farmatodo.com.co. '
              + 'Ingresa ahora y realiza tus compras online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'juegos-para-armar',
            metaTitle: 'Juegos para armar',
            description:
              'Encuentra juegos para armar como bloques y rompecabezas en farmatodo.com. Ingresa ahora y '
              + 'realiza tus compras online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'juguetes',
            metaTitle: 'Juguetes',
            description:
              'Encuentra juguetes de moda para niños y niñas en farmatodo.com. Ingresa ahora y realiza tus '
              + 'compras online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'lactancia',
            metaTitle: 'Productos para lactancia',
            description:
              'Encuentra productos especializados en lactancia en farmatodo.com.co  Ingresa ahora y realiza '
              + 'tus compras online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'loncheras',
            metaTitle: 'Loncheras para niños',
            description:
              'Encuentra loncheras para niños en farmatodo.com.co. Ingresa ahora y realiza tus compras '
              + 'online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'mecedoras-y-caminadores',
            metaTitle: 'Mecedoras y Caminadores para niños',
            description:
              'Compras mecedoras y caminadores para bebés en farmatodo.com.co Ingresa ahora y realiza tus '
              + 'compras online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'morrales-y-maletines',
            metaTitle: 'Morrales y maletines',
            description:
              'Encuentra morrales y maletas para niños en farmatodo.com. Ingresa ahora y realiza tus compras '
              + 'online de tus marcas preferidas en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'muebles',
            metaTitle: 'Muebles para niños',
            description:
              'Encuentra muebles para niños en farmatodo.com. Ingresa ahora y realiza tus compras online de, '
              + 'sillas, mesas, escritorios y más, en  tus marcas preferidas en un solo lugar: fácil, '
              + 'rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'panaleras',
            metaTitle: 'Pañaleras',
            description:
              'Encuentra pañaleras modernas para niños y niñas en farmatodo.com. Ingresa ahora y realiza tus '
              + 'compras online de tus marcas preferidas en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'panales-reutilizables',
            metaTitle: 'Pañales reutilizables',
            description:
              'Compra pañales reutilizables en las marcas, referencias y tallas que buscas en farmatodo.com. '
              + 'Ingresa ahora y realiza tus compras online en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'portabebes',
            metaTitle: 'Portabebés',
            description:
              'Compra el portabebés que necesitas en farmatodo.com.co. Encontrarás variedad de diseños y '
              + 'marcas. Realiza el pedido de tu porta bebé de forma fácil y rápida a domicilio.',
          },
          {
            titleSubcategorie: 'ropa-de-cama-y-toallas',
            metaTitle: 'Ropa de cama y baño para niños',
            description:
              'Compra ropa de cama para niños en farmatodo.com. Desde juegos de sábanas, hasta cubre lechos '
              + 'para niños. Ingresa y realiza tus pedidos de forma fácil y segura con envíos a domicilio.',
          },
          {
            titleSubcategorie: 'ropa-ninas',
            metaTitle: 'Ropa para niña',
            description:
              'Compra ropa para niñas en farmatodo.com. Encontrarás blusas, pantalones y mucho más en un '
              + 'solo lugar. Ingresa ahora y realiza tus pedidos online con entrega a domicilio: fácil y rápido.',
          },
          {
            titleSubcategorie: 'salud',
            metaTitle: 'Productos para la salud del bebé',
            description:
              'Encuentra productos para la salud de tus bebés en farmatodo.com.co. Ingresa ahora y realiza '
              + 'tus compras online de tus marcas preferidas en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'salud-dental',
            metaTitle: 'Productos para la salud dental del bebé',
            description:
              'Encuentra productos para la salud dental de tus hijos en farmatodo.com.co. Ingresa ahora y '
              + 'realiza tus compras online de tus marcas preferidas en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'seguridad',
            metaTitle: 'Productos para la seguridad del bebé',
            description:
              'Encuentra productos para la seguridad de tus hijos en farmatodo.com.co. Ingresa ahora y '
              + 'realiza tus compras online de tus marcas preferidas en un solo lugar: fácil, rápido y seguro con entrega a domicilio.',
          },
          {
            titleSubcategorie: 'sillas-para-carro',
            metaTitle: 'Sillas para carro',
            description:
              'Compra sillas para carro para tus hijos en farmatodo.com. Encontrarás boosters, bases para '
              + 'sillas y más productos para la seguridad de los más pequeños en el carro. Ingresa ahora y realiza tus pedidos online.',
          },
          {
            titleSubcategorie: 'tecnologia-y-videojuegos',
            metaTitle: 'Tecnología y videojuegos',
            description:
              'Encuentra accesorios tecnológicos y videojuegos en farmatodo.com.co. Ingresa ahora y '
              + 'realiza tus compras online de forma fácil, rápida y segura en un solo lugar.',
          },
          {
            titleSubcategorie: 'teteros--chupos-y-mordedores',
            metaTitle: 'Teteros, chupos y mordedores',
            description:
              'Encuentra teteros, chupos, mordedores en farmatodo.com.co. Compra teteros avent, nudy '
              + 'natural y más marcas en un solo lugar. Ingresa ahora y realiza tus pedidos online de forma fácil y segura.',
          },
          {
            titleSubcategorie: 'utensilios-de-mesa-y-cocina',
            metaTitle: 'Utensilios de Mesa y Cocina',
            description:
              'Encuentra utensilios para mesa y cocina en farmatodo.co.co. Ingresa ahora y realiza tus '
              + 'compras online de forma fácil, rápida y segura en un solo lugar.',
          },
        ],
      },
    ],
  },
  {
    titleDepartment: 'cuidado-personal',
    nameSimple: 'cuidado personal',
    metaTitle: 'Productos para el cuidado personal',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
      + 'Ingresa ahora, compra todos los productos que necesitas para el cuidado personal y recibe a '
      + 'Domicilio: rápido, fácil y seguro.',
    description:
      'Encuentra productos para el cuidado personal en farmatodo.com. Ingresa ahora, compra online y '
      + 'recibe a domicilio: fácil, rápido y sin filas en un solo lugar.',
    categories: [
      {
        titleCategorie: 'cuidado-bucal',
        metaTitle: 'Productos para el cuidado bucal',
        description:
          'Encuentra todos los productos para el cuidado bucal en faramatodo.com. Compra desde cremas '
          + 'dentales y cepillos, hasta enjuagues, seda dental y mucho más. Realiza tus pedidos a domicilio: fácil y rápido.',
        subcategories: [
          {
            titleSubcategorie: 'cremas-dentales',
            metaTitle: 'Cremas dentales',
            description:
              'Compra cremas dentales en farmatodo.com. Encontrarás desde crema de dientes Colgate, Cremas '
              + 'Oral B, cremas especializadas como sensodyne,  cremas dentales para niños y muchas más en un solo lugar.',
          },
          {
            titleSubcategorie: 'cepillos-dentales',
            metaTitle: 'Cepillos dentales',
            description:
              'Compra cepillos de dientes en las mejores marcas en Farmatodo.com. Encontraras cepillos '
              + 'dentales Colgate, cepillos de dientes para niños, kits dentales, cepillos eléctricos y mucho más en un solo lugar.',
          },
          {
            titleSubcategorie: 'enjuagues-dentales',
            metaTitle: 'Enjuagues dentales',
            description:
              'Compra enjuagues bucales en farmatodo.com. Encontrarás los productos que buscas: Listerine,'
              + '  Enjuague bucal Colgate y más en todas las presentaciones y tamaños que necesitas. Lleva el tuyo ahora.',
          },
          {
            titleSubcategorie: 'seda-dental',
            metaTitle: 'Seda dental',
            description:
              'Compra seda dental en farmatodo.com y cuida tu salud bucal. Encontrarás las mejores marcas'
              + ' como hilo dental Colgate, Oral B, seda dental para niños y mucho más en un solo lugar.',
          },
          {
            titleSubcategorie: 'especializados',
            metaTitle: 'Productos especializados para el cuidado bucal',
            description:
              'Compra productos especializados para el cuidado buscar en Farmatodo. Encuentra cera dental,'
              + ' kits de ortodoncia, cepillos interdentales, repuestos de cepillos eléctricos y mucho más en un solo lugar.',
          },
        ],
      },
      {
        titleCategorie: 'cuidado-del-cabello',
        metaTitle: 'Productos para el cuidado del cabello',
        description:
          'Encuentra todos los productos para cuidar y restaurar tu cabello para mujer y hombre en un solo'
          + ' lugar. Ingresa ahora y descubre las mejores marcas y referencias. Realiza tus pedidos a domicilio: fácil y rápido.',
        subcategories: [
          {
            titleSubcategorie: 'shampoo-',
            metaTitle: 'Shampoo',
            description:
              'Compra shampoo para mujer y hombre en farmatodo.com. Encontrarás marcas como Pantene, Sedal,'
              + ' Dove, H&S, Ego, Konzil y más, además del shampoo sin sal que quieres. Ingresa y lleva el tuyo.',
          },
          {
            titleSubcategorie: 'acondicionador',
            metaTitle: 'Acondicionadores',
            description:
              'Compra acondicionadores en las mejores marcas como: Pantene, Dove, Elvive, Sedal, H&S, '
              + 'Bioexpert, Savital y más, además de los acondicionadores sin sal que quieres. Ingresa y lleva el tuyo.',
          },
          {
            titleSubcategorie: 'cremas-geles-y-lociones',
            metaTitle: 'Cremas, geles y lociones',
            description:
              'Compra cremas para peinar, cera,  gel para el cabello y más productos en farmatodo.com, '
              + 'Encontrarás marcas como Ego, Sedal,  Nutribela, Elvive, Pantene, Dove y muchas más en '
              + 'un solo lugar con envío a domicilio.',
          },
          {
            titleSubcategorie: 'ampollas',
            metaTitle: 'Ampollas para el cabello',
            description:
              'Encuentra ampollas para el cabello en farmatodo.com y restaura tu pelo. Compra ampolletas '
              + 'para el crecimiento, caída y más, en las mejores marcas: Dove, Elvive, Pantene, Konzil. Ingresa y lleva las tuyas.',
          },
          {
            titleSubcategorie: 'tintes-para-cabello',
            metaTitle: 'Tintes pata el cabello',
            description:
              'Compra tintes para cabellos en todos los tonos de color que buscas en Farmatodo.com. '
              + 'Encontrarás marcas como: igora, Loreal, Excellence, Luminance, Nutrisse y muchas más en un solo lugar.',
          },
          {
            titleSubcategorie: 'lociones-y-siliconas',
            metaTitle: 'Lociones y siliconas para el cabello',
            description:
              'Encuentra tratamientos para el cabello maltratado, pelo seco, para la caída y más productos'
              + ' para tu cabello en farmatodo.com. Compra las mejores marcas como Dove, María Salomé, Elvive, Tío Nacho y más.',
          },
          {
            titleSubcategorie: 'desrizadores',
            metaTitle: 'Desrizadores',
            description:
              'Compra todo en productos desrizadores para tu cabello en farmatodo.com. Ingresa ahora elige'
              + ' las mejores marcas y realiza tus pedidos a domicilio: fácil, rápido y seguro en nuestra droguería online.',
          },
          {
            titleSubcategorie: 'spray',
            metaTitle: 'Spray para el cabello',
            description:
              'Compra laca para el cabello, y retocadores de raíz para el pelo tinturado en farmatodo.com.'
              + ' Encuentra marcas como Schwarzkopf, Tío Nacho y más en un solo lugar. Realiza tus pedidos a domicilio.',
          },
          {
            titleSubcategorie: 'banos-de-crema',
            metaTitle: 'Baños de crema',
            description:
              'Compra mascarillas para el cabello, tratamientos capilares y más productos para el cuidado'
              + ' del pelo en farmatodo.com. Ingresa ahora y descubre las mejores marcas como Dove, Elvive, Naissant, Klorane.',
          },
          {
            titleSubcategorie: 'accesorios-cabello',
            metaTitle: 'Accesorios para el cabello',
            description:
              'Compra cepillos para el cabello, moñas,  Balacas, Bambas elásticas y más accesorios para el'
              + ' cabello en farmatodo.com. Ingresa ahora y realiza tus pedidos a domicilio en un solo lugar: fácil y rápido.',
          },
        ],
      },
      {
        titleCategorie: 'higiene-personal',
        metaTitle: 'Productos de Higiene personal',
        description:
          'Encuentra todos los productos para la higiene personas en hombres, mujeres, niños, adultos '
          + 'mayores en farmatodo.com. Ingresa y compra con pedidos a domicilio: fácil, rápido y seguro.',
        subcategories: [
          {
            titleSubcategorie: 'jabones',
            metaTitle: 'Jabones corporales',
            description:
              'Compra jabones corporales: hidratantes, para pieles delicadas, líquidos y más en '
              + 'farmatodo.com. Encuentra las mejores marcas como Dove, Nivea, Protex, Lux, Palmolive, '
              + 'Old Spice y muchas más en un solo lugar.',
          },
          {
            titleSubcategorie: 'desodorantes-y-antitranspirantes',
            metaTitle: 'Desodorantes y antitranspirantes',
            description:
              'Compra desodorantes para mujer y hombre en farmatodo.com. Encuentra las mejores marcas '
              + 'como: Axe, Dove, Gillette, Balance, Speed stick, yodora y muchas más en un solo lugar. Ingresa ahora.',
          },
          {
            titleSubcategorie: 'depilacion-y-afeitado',
            metaTitle: 'Depilación y afeitado',
            description:
              'Encuentra máquinas de afeitar para hombre y mujer, cremas depiladoras, repuestos para '
              + 'afeitar, gel y espuma para para afeitar y más en las mejores marcas: Gillette, Veet, '
              + 'Philips, Arden for men en un solo lugar.',
          },
          {
            titleSubcategorie: 'proteccion-femenina',
            metaTitle: 'Protección femenina',
            description:
              'Encuentra toallas higiénicas, tampones, protectores diarios, jabones íntimos y más productos '
              + 'en farmatodo.com. Compra las mejores marcas como: Nosotras, Kotex, OB, Caregree, Nivea y más en un solo lugar.',
          },
          {
            titleSubcategorie: 'incontinencia',
            metaTitle: 'Productos para la incontinencia',
            description:
              'Compra producto para la incontinencia en hombres y mujeres en farmatodo.com. Encontrarás '
              + 'pañales para adultos Tena, Protectores, Tena mujer,  Ropa interior plenitud y más productos especializados.',
          },
        ],
      },
      {
        titleCategorie: 'cuidado-de-la-piel',
        metaTitle: 'Productos para el cuidado de la piel',
        description:
          'Cuida y restaura tu piel con todo los productos especializados que tenemos en farmatodo.com. '
          + 'Ingresa ahora y compra las mejores marcas como: Ponds, Nivea, Garnier, Cicatricure y muchas más en un solo lugar.',
        subcategories: [
          {
            titleSubcategorie: 'cuidado-solar',
            metaTitle: 'Productos para el cuidado solar',
            description:
              'Encuentra productos para el cuidado, protección solar y bronceado en farmatodo.com. Compra '
              + 'bloqueadores solares como Tanga, Sundown, Umbrella y muchos más en un solo lugar. Realiza pedidos a domicilio.',
          },
          {
            titleSubcategorie: 'cuidado-corporal',
            metaTitle: 'Productos para el cuidado corporal',
            description:
              'Compra cremas corporales para hidratar, exfoliar, proteger y restaurar la piel en '
              + 'farmatodo.com. Encuentra las mejores marcas como Lubriderm,  Nivea, Eucerin, '
              + 'Johnsons, Mustela y muchas más en un solo lugar.',
          },
          {
            titleSubcategorie: 'cuidado-facial',
            metaTitle: 'Productos para el cuidado facial',
            description:
              'Compra cremas para la cara y más productos para el cuidado facial en farmatodo.com. '
              + 'Encontrarás las mejores marcas como cicatricure, garnier con aguas micelares, cremas Ponds y más en un solo lugar.',
          },
          {
            titleSubcategorie: 'repelentes-e-insecticidas',
            metaTitle: 'Repelentes e insecticidas',
            description:
              'Compra repelentes y antimosquitos en farmatodo.com. Encontrarás los mejores productos '
              + 'como Nopikex, bacterión, repelente stay off y mucho más en un solo lugar. Realiza tus pedidos a domicilio.',
          },
        ],
      },
      {
        titleCategorie: 'bienestar-sexual',
        metaTitle: 'Productos para el bienestar sexual',
        description:
          'Encuentra todo en productos para el bienestar y salud sexual en farmatodo.com. Compra las '
          + 'mejores marcas y referencias como condones Today y Duo en un solo lugar y con envío a domicilio: fácil y rápido.',
        subcategories: [
          {
            titleSubcategorie: 'preservativos',
            metaTitle: 'Preservativos',
            description:
              'Encuentra todas las marcas de preservativos que buscas en en farmatodo.com. Compra desde '
              + 'condones today, hasta condones Dou en todas las presentaciones. Realiza tus pedidos a domicilio.',
          },
          {
            titleSubcategorie: 'lubricantes',
            metaTitle: 'Lubricantes',
            description:
              'Compra lubricantes íntimos en farmatodo.com a domicilio. Encuentra todas las marcas como '
              + 'lubricantes durex, KY Gel y lubricantes Duo en un solo lugar. Ingresa y compra fácil y rápido.',
          },
          {
            titleSubcategorie: 'higiene-intima',
            metaTitle: 'Productos Higiene íntima',
            description:
              'Compra productos para la higiene íntima en farmatodo.com. Encuentra jabones íntimos '
              + 'Nosotras, Lactacyd Pro bio, Jabón íntimo lavanté y muchos más en un solo lugar. Realiza tus pedidos a domicilio.',
          },
        ],
      },
      {
        titleCategorie: 'dermocosmetica',
        metaTitle: 'Productos Dermocosméticos',
        description:
          'Encuentra todo en productos dermocosméticos en farmatodo.com. Compra las mejores marcas como '
          + 'Cetaphil, Synderm, Afelius, Eucerin, Vichy y muchos más en un solo lugar. Realiza tus pedidos a domicilio.',
        subcategories: [
          {
            titleSubcategorie: 'capilar',
            metaTitle: 'Productos para cuidado capilar',
            description:
              'Compra productos especializados en el cuidado capilar en Farmatodo.com. Encontrarás desde '
              + 'Acondicionadores y shampoo anti caída del pelo, hasta lociones capilares. Realiza tus pedidos ahora.',
          },
          {
            titleSubcategorie: 'corporal',
            metaTitle: 'Productos para cuidado Corporal',
            description:
              'Encuentra productos dermocosméticos para el cuidado corporal en farmatodo.com. Compra las '
              + 'mejores marcas como cremas y jabones Eucerin,  además de lociones y aceites corporales especializados.',
          },
          {
            titleSubcategorie: 'facial',
            metaTitle: 'Productos para cuidado facial',
            description:
              'Encuentra productos especializados para el cuidado facial en Farmatodo.com. Desde Cremas '
              + 'antiedad y cremas hidratantes, hasta limpiadores faciales y lociones para el rostro en un solo lugar.',
          },
          {
            titleSubcategorie: 'proteccion-solar',
            metaTitle: 'Productos para protección solar',
            description:
              'Encuentra protectores solares especializados en farmatodo.com Compra los mejores bloqueadores'
              + ' como Eucerin, Vichy, Avene, Umbrella y muchos más en un solo lugar. Realiza tus pedidos ahora.',
          },
          {
            titleSubcategorie: 'maquillaje',
            metaTitle: 'Maquillaje dermocosmético',
            description:
              'Compra maquillaje dermocosmético para todo tipo de piel en farmatodo.com. Encontrarás las '
              + 'mejores marcas y referencias como Polvos Vichy, Protectores Nivea y mucho más en un solo lugar.',
          },
        ],
      },
    ],
  },
  {
    titleDepartment: 'alimentos-y-bebidas',
    nameSimple: 'alimentos y bebidas',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
    + 'Ingresa ahora, compra todas las bebidas y alimentos que quieres y recibe a Domicilio: rápido, fácil y seguro.',
    description:
      'Encuentra en Farmatodo todo el Alimentos, bebidas, licores, alimentos saludables y mucho más a un '
      + 'clic de distancia. Ingresa ahora y llévalos fácil y rápido.',
    categories: [
      {
        titleCategorie: 'aguas-gaesosas-jugos-tes-y-energizantes',
        metaTitle: 'Aguas, gaseosas, jugos, tés y energizantes',
        description: 'En farmatodo podras encontrar las bebidas que te gustan como aguas, gaesosas, jugos, '
          + 'tés y energizantes ¡Ingresa ahora!',
        subcategories: [
          {
            titleSubcategorie: 'aguas',
            metaTitle: 'Aguas Manantial, Cristal entre otras',
            description: 'Encuentra en Farmatodo botellas de agua naturales y saborizadas de las mejores '
              + 'marcas. Cristal, Manantial, H2O, Brisa. ¡Ingresa ahora!',
          },
          {
            titleSubcategorie: 'gaseosas',
            metaTitle: 'Gaseosas',
            description: 'Encuentra en Farmatodo Gaseosas Coca Cola, Sprite, Pepsi, Colombiana, Schweppes '
              + 'Ginger ¡Ingresa ahora!',
          },
          {
            titleSubcategorie: 'jugos',
            metaTitle: 'Jugos Frutto, Country Hill, Biofrut entre otras',
            description: 'Encuentra en Farmatodo juegos Frutto con sabores de tus frutas favoritas o jugos '
              + 'de Naranja Country Hill entre muchos marcas mas ¡Ingresa ahora!',
          },
          {
            titleSubcategorie: 'tes',
            metaTitle: 'Tés de las marcas Mr Tea, Fuze Tea entre otras',
            description: 'Encuentra en Faramtodo tés de las marcas Mr Tea, Fuze Tea . ¡Ingresa ahora!',
          },
          {
            titleSubcategorie: 'energizantes',
            metaTitle: 'Energizantes',
            description: 'Encuentra en Farmatodo botellas energizantes de las mejores marcas. Gatorade, Redbull. ¡Ingresa ahora!',
          },
        ],
      },
      {
        titleCategorie: 'alimentos-saludables',
        metaTitle: 'Alimentos Saludables',
        description: 'Ingresa ahora y podras comprar los productos que tenemos para que cuides tu salud '
          + 'y figura snacks dulces, snacks salados, endulzantes.',
        subcategories: [
          {
            titleSubcategorie: 'alimentos',
            metaTitle: 'Alimentos',
            description: 'En farmatodo tenemos una amplia selección de alimentos saludables para cuidar tu salud ¡Ingresa ahora!',
          },
          {
            titleSubcategorie: 'snacks-dulces',
            metaTitle: 'Snacks dulces saludables',
            description: 'Encuetra en farmatodo snacks sin azucar para esas tardes donde los antojos llegan ¡Ingresa ahora a Farmatodo!',
          },
          {
            titleSubcategorie: 'snacks-salados',
            metaTitle: 'Snacks Salados',
            description: 'Quieres comer algo en tus onces en farmatodo puedes comprar snacks salados saludables',
          },
          {
            titleSubcategorie: 'endulzantes',
            metaTitle: 'Endulzantes naturales y artificiales',
            description: 'Encuentra endulzantes naturales y artificiales !Ingresa ahora en Farmatodo!',
          },
        ],
      },
      {
        titleCategorie: 'alimentos-panes-quesos-y-huevos',
        metaTitle: 'Alimentos y bebidas, panes, quesos y huevos',
        description: 'En Farmatodo puedes encontrar lácteos, Huevos, cereales, Atunes, queso y charcutería '
          + 'entre otras categorias ¡Ingresa ahora!',
        subcategories: [
          {
            titleSubcategorie: 'panes',
            metaTitle: 'Productos de Panadería',
            description: 'Puedes comprar pan blanco, pan de cerales, tostadas, panes dulces y los mejores '
              + 'ingredientes y marcas ¡Ingresa Ahora!',
          },
          {
            titleSubcategorie: 'huevos-pastas-y-harinas',
            metaTitle: 'Huevos, pastas y harinas',
            description: 'Encuentra y compra huevos, pastas, maizena, azucar, arepas y muchos más productos en Farmatodo',
          },
          {
            titleSubcategorie: 'lacteos',
            metaTitle: 'Productos Lácteos',
            description: 'Encuentra y leche entera o desalactosada, yogurt, avenas y muchos productos lacteos en Farmatodo',
          },
          {
            titleSubcategorie: 'quesos-y-charcuteria',
            metaTitle: 'Quesos y Charcuteria',
            description: 'Encuentra y compra salchichon, salchichas, queso blanco, queso parmesano y muchos más productos en Farmatodo',
          },
          {
            titleSubcategorie: 'cafe-y-te',
            metaTitle: 'Café y té',
            description: 'Encuentra y compra aroamticas, café, infusiones y muchos más productos en Farmatodo',
          },
          {
            titleSubcategorie: 'cereales',
            metaTitle: 'Cereales',
            description: 'Quieres disfrutar en tu desayuno de unos delicios cereales ingresa a Farmatodo '
              + 'y encientra los mejores precios y marcas en cereales ¡Ingresa ahora!',
          },
          {
            titleSubcategorie: 'ensaladas-sanduches-y-frutas',
            metaTitle: 'Ensaladas, sanduches y frutas',
            description: 'Que tal un delicioso sanduche, una ensalada o una frUta para esta tarde ingresa '
              + 'y encuentra la mejor oferta para calmar ese antojo, ingresa ahora a Farmatodo',
          },
          {
            titleSubcategorie: 'sopas-salsas-y-aderezos',
            metaTitle: 'Sopas, salsas y aderezos',
            description: 'Encuentra y compra salsa de tomate fruko o una sopa enlatada de tomate maggiy '
              + 'miles de productos más ingresa ahora a Farmatodo',
          },
          {
            titleSubcategorie: 'atunes',
            metaTitle: 'Atunes',
            description: 'Encuentra y compra atún van camps o Isalbe y muchos más productos en Farmatodo',
          },
        ],
      },
      {
        titleCategorie: 'bebidas-alcoholicas',
        metaTitle: 'Vinos, cervezas y licores',
        description: 'Dentro de nuestro catalogo de productos contamos con la mejor seleccion de licores de '
          + 'las mejores marcas ¡Ingresa ahora Farmatodo!',
        subcategories: [
          {
            titleSubcategorie: 'cervezas',
            metaTitle: 'Compra las mejores cervezas nacionales e importadas',
            description: 'Encuentra en Farmatodo las mejores cervezas del mercado con entrega a domicilio. '
              + 'Corona, Heineken, Budweiser, Aguila, Poker y muchas más ¡Ingresa Ahora!',
          },
          {
            titleSubcategorie: 'vinos',
            metaTitle: 'Encuentra la mejor selección de Vinos',
            description: 'Compra online vinos en Farmatodo. diferentes variedades Vinos tintos, blancos, '
              + 'rosados, espumosos, mucho más. ¡Ingresa ahora!',
          },
          {
            titleSubcategorie: 'bebidas-alcoholicas',
            metaTitle: 'Encuentra la mejor selección de licores',
            description: 'Compra la mejor seleccion de licores online whisky, vodka, tequila, ginebra '
              + 'entre otros ¡Ingresa a Farmatodo ahora!',
          },
        ],
      },
      {
        titleCategorie: 'dulces-chocolates-galletas-tortas-y-helados',
        metaTitle: 'Dulces, chocolates y helados',
        description: 'Deleítate con las opciones de helados, tortas y postres que tenemos en nuestra sección '
          + 'dulces. ¡Compra desde casa en Farmatodo ahora!',
        subcategories: [
          {
            titleSubcategorie: 'chocolates',
            metaTitle: 'Chocolates',
            description: 'Compra las diferentes opciones de chocolates para cada ocacion. ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'helados-y-postres',
            metaTitle: 'Helados y postres',
            description: 'Encuentra y compra desde casa Helados y postres Crem Helado, Colombina, Popsy, '
              + 'de tus sabores preferidos, Chocolate, Vainilla, Fresa y muchos más. ¡Ingresa ahora Farmatodo!.',
          },
          {
            titleSubcategorie: 'galletas',
            metaTitle: 'Galletas',
            description: 'Compra en nuestra seccion de galletas el mejor scnack para tus onces o las de tus '
              + 'hijos ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'dulces-chupetas-y-caramelos',
            metaTitle: 'Dulces, chupetas y caramelos',
            description: 'Compra en nuestra seccion de Dulces, chupetas y caramelos y encuentra bon bon bun,'
              + ' frunas, gomas, entre otros productos ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'tortas-y-ponques',
            metaTitle: 'Tortas y ponqués',
            description: 'Compra en nuestra seccion de tortas y ponqués el mejor scnack para tus onces o las'
              + ' de tus hijos ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'granolas',
            metaTitle: 'Granolas',
            description: 'Compra en nuestra seccion de granolas el mejor scnack para tus onces o las de tus'
              + ' hijos ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'cereales',
            metaTitle: 'Cereales',
            description: 'Compra en nuestra seccion de cereales el mejor scnack para tus onces o las de tus'
              + ' hijos ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'gomas',
            metaTitle: 'Gomas',
            description: 'Compra en nuestra seccion de gomas el mejor scnack para tus onces o las de tus hijos ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'chicles-y-mentas',
            metaTitle: 'Chicles y mentas',
            description: 'Compra en nuestra seccion de chicles y mentas el mejor scnack para tus onces o '
              + 'las de tus hijos ¡Ingresa a Farmatodo ahora!',
          },
        ],
      },
      {
        titleCategorie: 'leches-avenas-y-yogurts',
        metaTitle: 'Leches, avenas y yogurt',
        description: 'Compra en nuestra seccion de lacteos con los mejores precios del mercado y todo desde casa ¡ingresa ahora!',
        subcategories: [
          {
            titleSubcategorie: 'leches-y-bebidas-lacteas',
            metaTitle: 'Leches y bebidas lácteas',
            description: 'Encuentra y leche entera o desalactosada, yogurt, avenas y muchos productos lacteos en Farmatodo',
          },
          {
            titleSubcategorie: 'yogurt',
            metaTitle: 'Yogurt',
            description: 'Compra en nuestra seccion de yogurt y encuentra la mejor presentación de productos'
              + ' y sabores para tus onces o las de tus hijos ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'avenas',
            metaTitle: 'Avenas',
            description: 'Compra en nuestra seccion de Avenas y encuentra la mejor presentación de productos'
              + ' para tus onces o las de tus hijos ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'lacteos-saborizados',
            metaTitle: 'Lácteos saborizados',
            description: 'Compra en nuestra seccion de leche saborizada y encuentra la mejor presentación de'
              + ' productos y sabores para tus onces o las de tus hijos ¡Ingresa a Farmatodo ahora!',
          },
        ],
      },
      {
        titleCategorie: 'snacks-salados-y-pasabocas',
        metaTitle: 'Snacks salados y pasabocas',
        description: 'Compra en nuestra seccion de pasabocas el mejor scnack para tus onces o las de tus hijos ¡Ingresa a Farmatodo ahora!',
        subcategories: [
          {
            titleSubcategorie: 'snacks-y-pasabocas',
            metaTitle: 'Snacks y pasabocas',
            description: 'Podras encontrar ese snack dulce o salado perfecto para cualquier tarde como barras'
              + ' de granola, frutos secos, maní, entre otros ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'manies-y-frutos-secos',
            metaTitle: 'Maní y frutos secos',
            description: 'Compra en nuestra seccion de maní el mejor scnack para tus onces o las de tus hijos ¡Ingresa a Farmatodo ahora!',
          },
          {
            titleSubcategorie: 'granolas',
            metaTitle: 'Barras de granola',
            description: 'Compra en nuestra seccion de barras de granola el mejor scnack para tus onces o'
              + ' las de tus hijos ¡Ingresa a Farmatodo ahora!',
          },
        ],
      },
    ],
  },
  {
    titleDepartment: 'hogar-mascotas-y-otros',
    nameSimple: 'hogar, mascotas y otros',
    metaTitle: 'Productos para el hogar, ferretería y más',
    descriptionSeoInProduct: 'Encuentra {productName} en Farmatodo. '
      + 'Ingresa ahora, compra todos los productos que necesitas y recibe a Domicilio: rápido, fácil y seguro.',
    description:
      'Encuentra hogar, mascotas y otros en Farmatodo.com.co. Ingresa ahora, compra todos los productos'
      + ' que necesitas y recibe a Domicilio: rápido, fácil y seguro.',
    categories: [
      {
        titleCategorie: 'acc-del-hogar-ferreteria-y-cuidado-de-zapatos',
        metaTitle: 'Productos para el hogar, ferretería y más',
        description: 'Compra betunes, kits viajeros, calculadoras, extensiones, flotadores, soportes para '
          + 'celuculare y muchos accesorios en nuestra tienda online Farmatodo',
        subcategories: [
          {
            titleSubcategorie: 'accesorios-del-hogar',
            metaTitle: 'Accesorios para el hogar',
            description:
              'Estas pensando en un viaje y necesitas un kit de viajero o tal vez un flotador puedes '
              + 'compralos desde la comodidad de tu casa en nuestra tieneda online Farmatodo',
          },
          {
            titleSubcategorie: 'cuidado-de-los-zapatos',
            metaTitle: 'Betún para el cuidado de tus zapatos',
            description:
              'Estas buscando un betún adecuado para el color de tus zapatos o tenis ingresa ahora tenemos'
              + ' multiples opciones para que luzcas unos zapatos impecables',
          },
          {
            titleSubcategorie: 'ferrereria-y-otros',
            metaTitle: 'Ferretería y otros',
            description:
              'En nuestra seccion de ferreteria encuentras todos esos pequeños accesorios que son utiles '
              + 'en reparaciones pequeñas como paños de microfibra u cinstas aislantes ingresa ahora a Farmatodo',
          },
        ],
      },
      {
        titleCategorie: 'higiene-del-hogar',
        metaTitle: 'Productos para la higinene en el hogar',
        description: 'Compra desde casa papel higíenico, servilletas, repelentes, detergentes, lavaplatos '
          + 'o ambientadores a los mejores precios ingresa ahora a Farmatodo',
        subcategories: [
          {
            titleSubcategorie: 'papel-higienico',
            metaTitle: 'Papel higiénico',
            description:
              'Papel higiénico Familia o Suave al mejor precio, mayor contenido y calidad.. Entra ahora y '
              + 'compra online encuentralo en Farmatodo',
          },
          {
            titleSubcategorie: 'servilletas-secantes-y-panuelos',
            metaTitle: 'Servilletas, secantes y pañuelos',
            description:
              'En Farmatodo Servilletas o pañuelos humedos al mejor precio, mayor contenido y calidad. Entra ahora y compra online.',
          },
          {
            titleSubcategorie: 'repelentes-e-insecticidas',
            metaTitle: 'Repelentes e insecticidas',
            description:
              'Para evitar enfermedades o molestas picaduras de mosquitos en Farmatodo encontraras todo en repelentes e insecticidas.',
          },
          {
            titleSubcategorie: 'detergentes-y-jabones',
            metaTitle: 'Detergentes y jabones',
            description:
              'En Farmatodo detergentes y jabones al mejor precio, mayor contenido y calidad. Entra ahora y compra online.',
          },
          {
            titleSubcategorie: 'lavaplatos',
            metaTitle: 'Lavaplatos',
            description:
              'En Farmatodo jabones para lavar loza al mejor precio, mayor contenido, mayor rendimiento y '
              + 'calidad. Entra ahora y compra online.',
          },
          {
            titleSubcategorie: 'ambientadores',
            metaTitle: 'Ambientadores',
            description:
              'Quieres un aroma limpio y fresco en tu hogar ingresa ahora tenemos las mejores grancias y'
              + ' marcas en nuestra sección de ambientadores compra desde casa en Farmatodo',
          },
          {
            titleSubcategorie: 'desinfectantes-y-acc-de-limpieza',
            metaTitle: 'Desinfectantes',
            description:
              'Para mantener tu hogar libre de baterias compra en Farmatodo todos lo productos necesarios'
              + ' como bolsas de basura, limpiadores, toallas desinfectantes, entre otros.',
          },
        ],
      },
      {
        titleCategorie: 'mascotas',
        metaTitle: 'Productos para Cuidado y alimentación de macotas',
        description: 'Compra en Farmatodo todo lo que necesitas para la alimentación, salud, higiene que tu mascota .',
        subcategories: [
          {
            titleSubcategorie: 'alimentos',
            metaTitle: 'Alimentación de mascotas',
            description:
              'Encuentra comida para mascotas de las mejores marcas de alimento para perros, gatos y otras'
              + ' mascotas. Hills, Pedigree, Purina,y muchas más. ingresa a Farmatodo',
          },
          {
            titleSubcategorie: 'salud-e-higiene',
            metaTitle: 'Salud e higiene de mascotas',
            description:
              'Encuentra todo para el cuidado e higiene de tu mascota perros, gato, como repelentes y shampo. ingresa a Farmatodo',
          },
          {
            titleSubcategorie: 'accesorios',
            metaTitle: 'Accesorios para mascotas',
            description:
              'Todo en accesorios para mascotas juguetes, porta bolsa o comederos. ingresa a Farmatodo y'
              + ' encuntra todo para mostrar todo ese cariño a tu mascota',
          },
        ],
      },
      {
        titleCategorie: 'otros',
        metaTitle: 'Todos los productos para salir de apuros',
        description: 'Compra en farmatodo, medias, sombrillas, juguetes, pilas, bolsas de regalos, libros'
          + ' entre otras cosas y todo desde la comodidad de casa u oficina.',
        subcategories: [
          {
            titleSubcategorie: 'baterias',
            metaTitle: 'Encuentra todo tipo de pilas',
            description:
              'Pilas de las mejores marcas y a los mejores precios de las referencias AA, AAA, 4X, normales'
              + ' o recargables todo en Farmatodo',
          },
          {
            titleSubcategorie: 'decoracion',
            metaTitle: 'Artículos de decoración',
            description:
              'Todo para que las fiestas de oficina o en tu hogar luzcan increibles, bombas, lanza confeti,'
              + ' infladores, velas para tortas o peluches.Todo esta en Farmatodo',
          },
          {
            titleSubcategorie: 'diversion',
            metaTitle: 'Productos para diversion',
            description:
              'Encuentra y comrpra en farmatodo juegos de mesas, gafas de piscina, piscinas inflables, tapa oidos de piscina, entre otros',
          },
          {
            titleSubcategorie: 'libros',
            metaTitle: 'Los mejores libros',
            description:
              'Los mejores libros y revistas para estar actualizado en todos los temas revista semana,'
              + ' revista Axxis, Cromos, el Espectador, Dinero. Adquierelas en Farmatodo',
          },
          {
            titleSubcategorie: 'medias',
            metaTitle: 'Encuentra Medias',
            description:
              'Medias veladas de la mejores marcas TALL, Ritchi de las referencias media pantalón,'
              + ' invisibles, tipo panty entre otras compra en Farmatodo',
          },
          {
            titleSubcategorie: 'tecnologia',
            metaTitle: 'Productos de tecnología',
            description:
              'Encuentra en Farmatodo baterias recargables, estuches para celulares, cables usb entre'
              + ' otros productos de la mejor calidad y precio.',
          },
          {
            titleSubcategorie: 'textiles',
            metaTitle: 'Artículos textiles',
            description:
              'Textiles termincos y para uso personal en Farmatodo',
          },
          {
            titleSubcategorie: 'viajero',
            metaTitle: 'Productos para viajes',
            description:
              'Para hacer de tu viaje un momento sin problemas encuentra en farmatodo organizadores de'
              + ' maletas, adpatadores, cojines viajeros todo lo puedes comprar en Farmatodo',
          },
        ],
      },
      {
        titleCategorie: 'vasos-platos-envases-cocina-y-picnic',
        metaTitle: 'Todo en artículos desechables y de cocina',
        description: 'Encuentra vasos, tenedores, platos, refractarias bolsas ziploc entre otros en Farmatodo',
        subcategories: [
          {
            titleSubcategorie: 'cocina-y-picnic',
            metaTitle: 'Artículos para cocina y picnic',
            description:
              'Compra en Farmatodo peladores, bolsas resellables, encendores entre otros a los mejores'
              + ' precios ingresa ahora a nuestra tienda online',
          },
          {
            titleSubcategorie: 'envases-y-contenedores',
            metaTitle: 'Envases y contenedores',
            description:
              'Compra en Farmatodo refractarias de vidrio de la mejor calidad y los mejores precios ingresa ahora a nuestra tienda online',
          },
          {
            titleSubcategorie: 'papeles-envolventes',
            metaTitle: 'Papeles envolventes',
            description:
              'Compra papel aluminio ingresa ahora a Farmatodo',
          },
          {
            titleSubcategorie: 'platos',
            metaTitle: 'Platos desechable para todo tipo de ocasiones',
            description:
              'Encuentra en Farmatodo platos desechables para toda ocasion a los mejores precios ingresa ahora y compra',
          },
          {
            titleSubcategorie: 'vasos',
            metaTitle: 'Vasos desechable para todo tipo de ocasiones',
            description:
              'Compra vasos desechables para toda ocasion a los mejores precios ingresa ahora a Farmatodo',
          },
          {
            titleSubcategorie: 'navidad',
            metaTitle: 'Todo para la decoración en Navidad',
            description:
              'Todo para la decoración de una de las fechas del año más bonitas ingresa ahora y conoce lo que Farmatodo tienen para ti',
          },
        ],
      },
    ],
  },
];
