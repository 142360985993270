import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LazyLoadObserverService {
  // constructor() { }

  init(options: any = {}) {
    options = {
      imgClass: 'lozad-ads',
      ...options,
    };
    // console.log('init ll fn. 2..');
    const config = {
      rootMargin: '0px 0px 50px 0px',
      threshold: 0,
    };
    // this.lazyService.lazyLoad(this.lazyLoadOptions);
    const observer = new IntersectionObserver((entries, self) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          // console.log('Entry =>', entry, entry.target);
          entry.target.src = entry.target.dataset.src;
          entry.target.classList.remove('v-none');
          const closest = entry.target.closest('.background-gray');
          if (closest) {
            closest.classList.remove('background-gray');
          }
          self.unobserve(entry.target);
        }
      });
    }, config);

    const imgs = document.querySelectorAll(`.${options.imgClass}`);
    // console.log(options.imgClass, ' =>', imgs);

    if (imgs) {
      imgs.forEach(img => {
        observer.observe(img);
        img.classList.remove(options.imgClass);
      });
    }
  }
}
