import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import { LocalStorageManager } from 'src/app/data/manager/local-storage/local-storage.manager';
import {BrowserDetectorService} from '../../../data/networking/services/shared/broswer-detector/browser-detector.service';

enum ModalTypes {
  PERMISSION_GEO = 'PERMISSION_GEO',
  PERMISSION_CAMERA = 'PERMISSION_CAMERA',
  CHOOSEN_FOR_SECURITY_REVIEW = 'CHOOSEN_FOR_SECURITY_REVIEW',
  PERSONAL_SECURITY_REVIEW = 'PERSONAL_SECURITY_REVIEW',
  NO_FEATURE_SUPPORT = 'NO_FEATURE_SUPPORT'
}

@Component({
  selector: 'app-scan-and-go-modal',
  templateUrl: './scan-and-go-modal.component.html',
  styleUrls: ['./scan-and-go-modal.component.scss']
})
export class ScanAndGoModalComponent implements OnInit, OnChanges {
  @Input() status: any = {open: false};
  @Input() type: string;
  modal = {
    image: '',
    title: '',
    message: '',
    action: null
  };
  typesEnum = ModalTypes;
  scanAndGoStore;
  modals = [];
  chosenForReview;
  constructor(private router: Router,
              private store: Store,
              private lsm: LocalStorageManager,
              private browser: BrowserDetectorService) { }

  get isMobileSag() {
    return this.lsm.get('mobileSaG');
  }

  ngOnInit() {
    this.modals = [...this.defaultModalTypes()];
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(this.store?.snapshot().scanAndGoStore?.config?.modals);
    if (this.store?.snapshot().scanAndGoStore?.config?.modals) {
      this.modals = [...this.defaultModalTypes(), ...this.store.snapshot().scanAndGoStore.config.modals];
    }
    if (changes?.type?.currentValue !== changes?.type?.previousValue || this.type) {
      this.modal = this.filterModalByType(this.type);
      this.chosenForReview = this.type === this.typesEnum.CHOOSEN_FOR_SECURITY_REVIEW;
    }
    // if(this.isMobileSag) {
    //   this.modal
    // }
  }

  defaultModalTypes() {
    return [
      {
        type: ModalTypes.PERMISSION_GEO,
        image: '',
        title: 'El servicio de ubicación está deshabilitado',
        message: 'Para poder usar este servicio es necesario que actives los ' +
          'permisos de ubicación, dirígete a Configuración y activa el permiso de ubicación.',
        action: {
          redirect: true,
          link: this.getLocationConfigLink(),
        }
      },
      {
        type: ModalTypes.PERMISSION_CAMERA,
        image: '',
        title: 'La cámara está deshabilitada',
        message: 'Para poder usar este servicio es necesario que actives el ' +
          'permiso de cámara, dirígete a Configuración y activa el permiso de cámara.',
        action: {
          redirect: true,
          link: this.getCameraConfigLink(),
        }
      },
      {
        type: ModalTypes.NO_FEATURE_SUPPORT,
        image: '',
        title: 'El navegador no soporta la funcionalidad de cámara o ubicación',
        message: 'Para usar este servicio por favor usa otro navegador o descarga la aplicación.',
        action: null
      }
    ];
  }

  filterModalByType(type) {
    return !this.modals ? {} : this.modals?.filter(m => m.type === type).pop();
  }

  closeAndExit() {
    this.status = {close: true};
    this.router.navigate(['/']);
  }

  action() {
    if (this.modal.action?.redirect) {
      window.open(this.modal.action.link);
    }
  }

  getLocationConfigLink() {
    const browser = this.browser.checkBrowser();
    if (browser.firefox) {
      return 'https://support.mozilla.org/es/kb/firefox-comparte-mi-ubicacion-con-sitios-web';
    } else if (browser.safari) {
      return 'https://support.apple.com/es-co/HT207092';
    } else { // Chrome and others
      return 'https://support.google.com/chrome/answer/142065?co=GENIE.Platform%3DAndroid&hl=es';
    }
  }

  getCameraConfigLink() {
    const browser = this.browser.checkBrowser();
    if (browser.firefox) {
      return 'https://support.mozilla.org/es/kb/como-administrar-los-permisos-de-tu-camara-y-micro';
    } else if (browser.safari) {
      return 'https://support.apple.com/es-co/guide/safari/ibrw7f78f7fe/mac';
    } else { // Chrome and others
      return 'https://support.google.com/chrome/answer/2693767?hl=es&co=GENIE.Platform=Android';
    }
  }

}
