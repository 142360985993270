export const generalEnv = {
  keyClient: 12345,
  source: 'WEB',
  coordinates: {
    lat: 4.681924599999999,
    lon: -74.0438379,
  },
  deliveryTypes: {
    express: {
      id: 'BOG',
      name: 'Bogotá',
      defaultStore: 26,
      idStoreGroup: 26,
      deliveryType: 'EXPRESS',
    },
    national: {
      name: 'Nacional',
      defaultStore: 1000,
      idStoreGroup: 1000,
      deliveryType: 'NATIONAL',
    },
    envialoya: {
      name: 'Envialo Ya',
      defaultStore: 1001,
      idStoreGroup: 1001,
      deliveryType: 'ENVIALOYA',
    },
  },
};

export const profileConfig = {
  documentTypes: [],
  phoneCode: [],
  countries: [
    {
      id: 'CO',
      prefix: 57,
      flagUrl: 'assets/svg/icon-flag-co.svg',
    },
    {
      id: 'VE',
      prefix: 58,
      flagUrl: 'assets/svg/icon-flag-ven.svg',
    },
    {
      id: 'USA',
      prefix: 1,
      flagUrl: 'assets/svg/icon-flag-usa.svg',
    },
  ],
};
