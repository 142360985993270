export const ssrTokens = {
  idCustomerWebSafe: 'ahZzfnN0dW5uaW5nLWJhc2UtMTY0NDAyci4LEgRVc2VyIiQwYzU1MzYzOC05YTlkLTQ3ZjYtYjE5OS1lMWVmMjkxY2JjMWQM',
  token: {
    token: '84cd722a2c7c2e4d2eb4f8dcf181d915',
    tokenExp: '2020-08-21T16:30:20.627Z',
    refreshToken: '5868bc592689eee66ee83d6c9fc5901e',
    tokenIdWebSafe: 'ahZzfnN0dW5uaW5nLWJhc2UtMTY0NDAycl0LEgRVc2VyIiQwYzU1MzYzOC05YTlkLTQ3ZjYtYjE5OS1lM'
    + 'WVmMjkxY2JjMWQMCxIFVG9rZW4iJDZmYTAyYWExLTliNmMtNGY0Ny05ZDk4LTBjYWQ0M2JhMTBiZAw',
  },
};
