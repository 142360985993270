import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverlayComponent} from './overlay/overlay.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {LoadPageComponent} from './load-page/load-page.component';

@NgModule({
  declarations: [OverlayComponent, SpinnerComponent, LoadPageComponent, LoadPageComponent],
  imports: [
    CommonModule,
  ],
  exports: [OverlayComponent, SpinnerComponent, LoadPageComponent],
})
export class LoadingModule { }
