import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { P2cApi } from '../../api-v3/p2c/p2c.api';
import { RequestService } from '../../../handlers/request/request.service';
import { BackendGatewayVenEnum } from 'src/base-urls/backend-gateway-ven.enum';

const base_url = BackendGatewayVenEnum.PAGOMOVIL ;

@Injectable({
  providedIn: 'root'
})
export class P2cService {

  constructor(private req: RequestService) { }

  createCustomerBank(data: any): Observable<any> {
    return this.req.post(`${base_url}${P2cApi.createCustomerBank}`, data);
  }

  getCustomerBanks(idCustomer: string): Observable<any> {
    return this.req.get(`${base_url}${P2cApi.getCustomerBanks}/${idCustomer}`);
  }

  updateCustomerBank(data: any): Observable<any> {
    return this.req.put(`${base_url}${P2cApi.updateCustomerBanks}`, data);
  }

  remCustomerBank(customerBank: any) {
    return this.req.put(`${base_url}${P2cApi.removeCustomerBank}`, {id: customerBank}) ;
  }

  getBanksList(): Observable<any> {
    return this.req.get(`${base_url}${P2cApi.getBanksList}`);
  }

  sendPayment(order: string, payment: string, reference: string, amount: string ): Observable<any> {
    let params = { idOrder: order, idPagoMovil: payment, reference, amount };
    console.log(params);
    return this.req.post(base_url+P2cApi.verifyPayment, params);
  }

  confirmPaymentOrder(params: any) {
    return this.req.post(base_url+P2cApi.confirmPaymentOrder, params);
  }

  verifyOrderActives(idCustomer: string) {
    return this.req.get(`${base_url}${P2cApi.verifyOrderActives}/${idCustomer}`);
  }

}
