import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollInfiniteEvent {
  private scrollInfinite = new BehaviorSubject(null);
  scrollInfiniteWindow = this.scrollInfinite.asObservable();

  changeInfiniteWindow(event: any) {
    this.scrollInfinite.next(event);
  }
}
